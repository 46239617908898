import { createSlice } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { concat } from "ramda";

import { deserialize, deserializeErrors } from "utils/storeUtils";
import InviteRepository from "repositories/InviteRepository";
import { jjLogger } from "utils/logUtils";
import localStorage, { INVITE_SHARE_TYPE, INVITE_SHOW_SHARE_CONFIRMATION } from "utils/localStorage";

const initialState = {
  invite: null,
  invites: null,
  meta: null,
  shareType: null,
  showShareConfirmation: false,
};

const invitesSlice = createSlice({
  name: "invite",
  initialState,
  reducers: {
    setInvite(state, { payload }) {
      state.invite = payload;
    },
    setInvites(state, { payload }) {
      state.invites = payload.invites;
      state.meta = payload.meta;
    },
    setMoreInvites(state, { payload }) {
      state.invites = concat(state.invites, payload);
    },
    setShowShareConfirmation(state, { payload }) {
      state.showShareConfirmation = payload;
    },
    setShareType(state, { payload }) {
      state.shareType = payload;
    },
    resetShareType(state) {
      state.shareType = initialState.shareType;
      state.showShareConfirmation = initialState.showShareConfirmation;
    },
  },
});

export const { actions } = invitesSlice;
export default invitesSlice.reducer;

export const useInviteActions = () => {
  const dispatch = useDispatch();

  const createInvite = (params) => {
    return InviteRepository.create(params)
      .then(({ data }) => {
        const invite = deserialize(data);
        dispatch(invitesSlice.actions.setInvite(invite));
        return invite;
      })
      .catch((error) => {
        return Promise.reject(deserializeErrors(error));
      });
  };

  const updateInvite = (slug, params) => {
    return InviteRepository.update(slug, params)
      .then(({ data }) => {
        const invite = deserialize(data);
        dispatch(invitesSlice.actions.setInvite(invite));
        return invite;
      })
      .catch((error) => {
        return Promise.reject(deserializeErrors(error));
      });
  };

  const loadInvite = (slug) => {
    return InviteRepository.show(slug).then((response) => {
      const invite = deserialize(response.data);
      dispatch(invitesSlice.actions.setInvite(invite));
      return invite;
    });
  };

  const loadInvites = (params) => {
    return InviteRepository.index(params)
      .then((response) => {
        const { data } = response;
        const invites = deserialize(data);
        dispatch(invitesSlice.actions.setInvites({ invites, meta: data.meta }));
        return invites;
      })
      .catch((error) => {
        jjLogger.logError(`InvitesSlice.js: loadInvites() error: ${JSON.stringify(error)}`);
        return Promise.reject(deserializeErrors(error));
      });
  };

  const loadMoreInvites = (params) => {
    return InviteRepository.index(params)
      .then((response) => {
        const invites = deserialize(response.data);
        dispatch(invitesSlice.actions.setMoreInvites(invites));
        return invites;
      })
      .catch((error) => {
        jjLogger.logError(`InvitesSlice.js: loadMoreInvites() error: ${JSON.stringify(error)}`);
        return Promise.reject(deserializeErrors(error));
      });
  };

  const deleteInvite = (slug) => {
    return InviteRepository.delete(slug)
      .then(() => {
        dispatch(invitesSlice.actions.setInvite(null));
      })
      .catch((error) => {
        jjLogger.logError(`InvitesSlice.js: deleteInvite() error: ${JSON.stringify(error)}`);
        return Promise.reject(deserializeErrors(error));
      });
  };

  const createGuest = (invite, params, role = null) => {
    return InviteRepository.addGuest(invite.id, params, role)
      .then((response) => {
        const guest = deserialize(response.data);
        const inviteWithNewGuest = { ...invite, guests: [guest, ...invite.guests] };
        dispatch(invitesSlice.actions.setInvite(inviteWithNewGuest));
        return guest;
      })
      .catch((error) => {
        return Promise.reject(deserializeErrors(error));
      });
  };

  const updateGuest = (invite, guestId, params, role = null) => {
    return InviteRepository.updateGuest(invite.id, guestId, params, role)
      .then((response) => {
        const guest = deserialize(response.data);
        const inviteWithUpdatedGuest = {
          ...invite,
          guests: invite.guests.map((g) => (g.id === guest.id ? guest : g)),
        };
        dispatch(invitesSlice.actions.setInvite(inviteWithUpdatedGuest));
        return guest;
      })
      .catch((error) => {
        return Promise.reject(deserializeErrors(error));
      });
  };

  const deleteGuest = (invite, guestId) => {
    return InviteRepository.deleteGuest(invite.id, guestId)
      .then(() => {
        const inviteWithDeletedGuest = {
          ...invite,
          guests: invite.guests.filter((g) => g.id !== guestId),
        };
        dispatch(invitesSlice.actions.setInvite(inviteWithDeletedGuest));
        return guestId;
      })
      .catch((error) => {
        return Promise.reject(deserializeErrors(error));
      });
  };

  const loadGuestInvite = (slug, guestSlug) => {
    return InviteRepository.show(slug, { guest_id: guestSlug }).then((response) => {
      const invite = deserialize(response.data);
      dispatch(invitesSlice.actions.setInvite(invite));
      return invite;
    });
  };

  const setShareType = (type) => {
    localStorage.setItem(INVITE_SHARE_TYPE, type);
    dispatch(invitesSlice.actions.setShareType(type));
  };

  const setShowShareConfirmation = (show) => {
    localStorage.setItem(INVITE_SHOW_SHARE_CONFIRMATION, show);
    dispatch(invitesSlice.actions.setShowShareConfirmation(show));
  };

  const resetShareType = () => {
    localStorage.removeItem(INVITE_SHARE_TYPE);
    localStorage.removeItem(INVITE_SHOW_SHARE_CONFIRMATION);
    dispatch(invitesSlice.actions.resetShareType());
  };

  return {
    createInvite,
    loadInvite,
    loadInvites,
    loadMoreInvites,
    deleteInvite,
    updateInvite,
    createGuest,
    updateGuest,
    deleteGuest,
    loadGuestInvite,
    setShareType,
    setShowShareConfirmation,
    resetShareType,
  };
};
