import makeStyles from "@mui/styles/makeStyles";

export default makeStyles(
  () => ({
    linkList: {
      display: "flex",
      flexDirection: "column",
      marginLeft: 11,
    },
    menuItem: {
      margin: [16, 0],
      display: "block",
    },
    linkInList: {
      margin: [13, 0],
    },
  }),
  { name: "MenuItem" },
);
