import { useSelector } from "react-redux";

import { usePillsActions } from "store/PillsSlice";

const usePills = () => {
  const { loadPills } = usePillsActions();

  const { pills } = useSelector((state) => state.PillsSlice);

  return { loadPills, pills };
};

export default usePills;
