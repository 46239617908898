import Cookies from "js-cookie";

import FacebookConversionRepository from "repositories/FacebookConversionRepository";

export const storeTestEventCode = () => {
  const url = new URL(window.location.href);
  const searchParams = new URLSearchParams(url.searchParams);

  if (!searchParams.has("test_event_code")) return;

  const in30min = 1 / 48;
  Cookies.set("test_event_code", searchParams.get("test_event_code"), { expires: in30min });
};

export const trackCompleteRegistration = (currentUser, url) => {
  FacebookConversionRepository.post(currentUser, {
    event_name: "CompleteRegistration",
    event_source_url: url,
  });
};

export const trackPageView = (currentUser) => {
  FacebookConversionRepository.post(currentUser, {
    event_name: "PageView",
    event_source_url: window.location.href,
  });
};

export const trackInitiateCheckout = (currentUser) => {
  FacebookConversionRepository.post(currentUser, {
    event_name: "InitiateCheckout",
    event_source_url: window.location.href,
  });
};

export const trackPurchase = (currentUser, offer) => {
  FacebookConversionRepository.post(currentUser, {
    event_name: "Purchase",
    event_source_url: window.location.href,
    custom_data: {
      value: offer.price,
      currency: offer.currency,
    },
  });
};

export const trackViewContent = (currentUser, customData) => {
  FacebookConversionRepository.post(currentUser, {
    event_name: "ViewContent",
    event_source_url: window.location.href,
    custom_data: customData,
  });
};
