import makeStyles from "@mui/styles/makeStyles";

export default makeStyles(
  (theme) => ({
    root: {},
    main: {
      backgroundImage: theme.palette.baseGradient,
      padding: [44, 10, 56],
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      [theme.breakpoints.up("tablet")]: {
        flexDirection: "row",
        justifyContent: "space-between",
        padding: [50, 71, 50, 54],
      },
    },
    logo: {
      height: 90,
      marginBottom: 40,
      [theme.breakpoints.up("tablet")]: {
        marginBottom: 0,
        marginRight: 52,
      },
    },
    nav: {
      width: "100%",
      maxWidth: 316,
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      marginBottom: 52,
      [theme.breakpoints.up("mobile")]: {
        maxWidth: 352,
      },
      [theme.breakpoints.up("tablet")]: {
        marginBottom: 0,
        flex: 1,
      },
    },
    navLink: {
      color: theme.palette.common.white,
      fontSize: theme.typography.small.fontSize,
    },
    social: {
      width: "100%",
      maxWidth: 305,
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      [theme.breakpoints.up("tablet")]: {
        marginLeft: "auto",
      },
    },
    socialLogo: {
      width: 32,
      height: 32,
    },
    copyright: {
      padding: [34, 32],
      [theme.breakpoints.up("mobile")]: {
        padding: [32, 140],
      },
      [theme.breakpoints.up("tablet")]: {
        padding: [43, 168],
      },
    },
  }),
  { name: "UserLayoutFooter" },
);
