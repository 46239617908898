import { externalRoutes } from "routes";
import FetchHelpers from "utils/FetchHelpers";

export default {
  show(makeId) {
    const url = externalRoutes.fmvrPath(makeId);
    return FetchHelpers.get(url);
  },
  create(makeId, params) {
    const url = externalRoutes.fmvrPath(makeId);
    return FetchHelpers.post(url, params);
  },
};
