import { createSlice } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { concat, isNil } from "ramda";

import MakePresenter from "presenters/MakePresenter";
import MakeRepository from "repositories/MakeRepository";
import TemplateGroupRepository from "repositories/TemplateGroupRepository";
import { deserialize, deserializeErrors } from "utils/storeUtils";
import { jjLogger } from "utils/logUtils";

const initialState = {
  make: null,
  makes: null,
  meta: null,
};

const makesSlice = createSlice({
  name: "makes",
  initialState,
  reducers: {
    setMake(state, { payload }) {
      state.make = payload;
    },
    clearMakeSuccess(state) {
      state.make = null;
    },
    loadMakesSuccess(state, { payload }) {
      state.makes = payload.makes;
      state.meta = payload.meta;
    },
    loadMoreMakesSuccess(state, { payload }) {
      state.makes = concat(state.makes, payload);
    },
    clearMakesSuccess(state) {
      state.makes = null;
    },
    deleteMakeSuccess(state, { payload }) {
      const newMakes = state.makes.filter(({ id }) => id !== payload);
      const newMeta = { ...state.meta, totalCount: state.meta.totalCount - 1 };
      state.makes = newMakes;
      state.meta = newMeta;
    },
    patchMakeSuccess(state, { payload }) {
      const params = isNil(payload.params) ? null : { ...state.make[`${payload.key}`], ...payload.params };
      state.make[`${payload.key}`] = params;
    },

    updateMakeSuccess(state, { payload }) {
      state.makes = payload.makes;
      state.meta = payload.meta;
    },
  },
});

export const { actions } = makesSlice;
export default makesSlice.reducer;

export const useMakesActions = () => {
  const dispatch = useDispatch();

  const createHash = (array, key = "id") => {
    const newHash = {};
    array.forEach((element) => {
      newHash[element[key]] = element;
    });

    return newHash;
  };

  const addContentfulData = async (makes) => {
    try {
      if (!makes || !makes?.included) return makes;

      const contentfulMakes = makes;
      const makeTemplates = makes.included.filter((t) => t.type === "templates");
      const slugs = makeTemplates.map((t) => t.attributes.templateGroupSlug).join(",");
      const { data: contentfulData } = await TemplateGroupRepository.index(slugs);

      const groupsById = createHash(Array.isArray(contentfulData.data) ? contentfulData.data : [contentfulData.data]);
      const templatesById = createHash(contentfulData.included);
      const groupsByTemplate = {};

      contentfulMakes.included.forEach((t, index) => {
        if (t.type !== "templates") return;

        const contentfulTemplate = templatesById[t.id];
        if (!contentfulTemplate) {
          contentfulMakes.included[index].attributes.status = "Deleted";
          return;
        }

        contentfulMakes.included[index] = contentfulTemplate;

        const tgSlug = contentfulTemplate.attributes.templateGroupSlug;
        groupsByTemplate[contentfulTemplate.id] = groupsById[tgSlug];
      });

      contentfulMakes.data.forEach((m, index) => {
        const contentfulTG = groupsByTemplate[m.relationships.template.data.id];
        if (!contentfulTG) {
          contentfulMakes.data[index].attributes.templateGroupActive = false;
          return;
        }

        contentfulMakes.data[index].attributes.tags = contentfulTG.attributes.tags;
        contentfulMakes.data[index].attributes.templateGroupActive = true;
      });

      return contentfulMakes;
    } catch (error) {
      jjLogger.logError(`MakeSlice.js: addContentfulData() - ${error}`);
      return makes;
    }
  };

  const createMake = (params) => {
    return MakeRepository.create(params)
      .then(({ data }) => {
        const make = deserialize(data);
        dispatch(makesSlice.actions.setMake(make));
        return make;
      })
      .catch((error) => {
        return Promise.reject(deserializeErrors(error));
      });
  };

  const loadMake = (slug) => {
    return MakeRepository.show(slug).then(({ data }) => {
      const make = deserialize(data);
      dispatch(makesSlice.actions.setMake(make));
      return make;
    });
  };

  const loadMakes = async (params) => {
    const { data } = await MakeRepository.index(params);
    const makes = deserialize(await addContentfulData(data));
    dispatch(makesSlice.actions.loadMakesSuccess({ makes, meta: data.meta }));
    return makes;
  };

  const loadMoreMakes = async (params) => {
    const { data } = await MakeRepository.index(params);
    const makes = deserialize(await addContentfulData(data));
    dispatch(makesSlice.actions.loadMoreMakesSuccess(makes));
    return makes;
  };

  const deleteMake = (slug) => {
    return MakeRepository.destroy(slug).then(() => {
      dispatch(makesSlice.actions.deleteMakeSuccess(slug));
    });
  };

  const clearMake = () => dispatch(makesSlice.actions.clearMakeSuccess());
  const clearMakes = () => dispatch(makesSlice.actions.clearMakesSuccess());

  const patchMake = (key, params) => dispatch(makesSlice.actions.patchMakeSuccess({ key, params }));

  const setCurrentMake = (currentMake) => dispatch(makesSlice.actions.setMake(currentMake));

  const updateMake = (make) => {
    const params = {
      data: {
        type: "makes",
        id: MakePresenter.id(make),
        attributes: {
          status: "live",
        },
      },
    };

    return MakeRepository.update(MakePresenter.id(make), params).catch((error) => {
      throw new Error(error);
    });
  };

  return {
    createMake,
    loadMake,
    clearMake,
    patchMake,
    loadMakes,
    clearMakes,
    loadMoreMakes,
    deleteMake,
    updateMake,
    setCurrentMake,
  };
};
