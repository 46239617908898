import PropTypes from "prop-types";
import { isEmpty, isNil } from "ramda";

import { GUEST_RSVP } from "enums";
import Presenter from "utils/PropTypesPresenter";

export default new Presenter(
  {
    id: PropTypes.string,
    inviteId: PropTypes.string,
    name: PropTypes.string,
    email: PropTypes.string,
    userId: PropTypes.string,
    rsvp: PropTypes.string,
    rsvpAt: PropTypes.string,
    seenAt: PropTypes.string,
    additionalGuests: PropTypes.number,
    createdAt: PropTypes.string,
    updatedAt: PropTypes.string,
  },
  {
    isAttending(g) {
      return this.rsvp(g) === GUEST_RSVP.yes;
    },
    isNotAttending(g) {
      return this.rsvp(g) === GUEST_RSVP.no;
    },
    isMaybeAttending(g) {
      return this.rsvp(g) === GUEST_RSVP.maybe;
    },
    hasResponded(g) {
      return !isNil(this.rsvp(g)) && !isEmpty(this.rsvp(g));
    },
    hasEmail(g) {
      return !isNil(this.email(g)) && !isEmpty(this.email(g));
    },
    populatedFieldNames(g) {
      const fields = { name: "guest_name", email: "guest_email" };
      const value = [];

      Object.keys(fields).forEach((field) => {
        if (this[field](g)) value.push(fields[field]);
      });

      return value;
    },
  },
);
