import { propOr } from "ramda";
import { createSlice } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";

import PeopleRepository from "repositories/PeopleRepository";
import { getScore } from "utils/dateTimeUtils";
import { createCelebration, createRelationOccasionDay } from "utils/calendarUtils";
import { deserialize } from "utils/storeUtils";
import { jjLogger } from "utils/logUtils";
import { getRelationKeyByValue } from "utils/relativeUtils";
import { DATE_TYPE, FACE_RELATIONS, FACE_RELATIONS_TAGS } from "enums";
import { getRelationsOccasionDay } from "utils/holidaysUtils";

const initialState = {
  celebrations: [],
  meta: null,
};

const calendarSlice = createSlice({
  name: "calendar",
  initialState,
  reducers: {
    loadCelebrationsSuccess(state, { payload }) {
      state.celebrations = payload;
      state.meta = propOr(null, "meta", payload);
    },
  },
});

export const { actions } = calendarSlice;
export default calendarSlice.reducer;

export const useCalendarActions = () => {
  const dispatch = useDispatch();

  const loadCelebrations = async ({ anniversaryParams, birthdayParams, relationParams }) => {
    try {
      const responses = await Promise.all([
        PeopleRepository.index(anniversaryParams),
        PeopleRepository.index(birthdayParams),
        PeopleRepository.index(relationParams),
      ]);
      const dataList = responses.map((response) => response.data);
      const anniversaryData = deserialize(dataList[0]);
      const birthdayData = deserialize(dataList[1]).reverse();
      const relationsData = deserialize(dataList[2]);
      const partnerData = relationsData.filter((person) => person.relation === FACE_RELATIONS_TAGS.valentines);
      const motherData = relationsData.filter((person) => person.relation === FACE_RELATIONS_TAGS.mother);
      const fatherData = relationsData.filter((person) => person.relation === FACE_RELATIONS_TAGS.father);
      let celebrations = [];

      const { valentinesDay, mothersDay, fathersDay } = getRelationsOccasionDay();

      const todayScore = getScore(new Date());
      const nextYearCelebrations = [];
      const currentYearCelebrations = [];

      const addPeopleToCelebrations = (peopleData, dateType, occasionDay = "") => {
        peopleData.forEach((person) => {
          if (person.relation === getRelationKeyByValue(FACE_RELATIONS.me)) return;
          const celebration = occasionDay
            ? createRelationOccasionDay(person, dateType, occasionDay)
            : createCelebration(person, dateType);
          if (todayScore > celebration.score) {
            nextYearCelebrations.push(celebration);
          } else {
            currentYearCelebrations.push(celebration);
          }
        });
      };

      addPeopleToCelebrations(partnerData, DATE_TYPE.valentines, valentinesDay);
      addPeopleToCelebrations(motherData, DATE_TYPE.mothersDay, mothersDay);
      addPeopleToCelebrations(fatherData, DATE_TYPE.fathersDay, fathersDay);
      addPeopleToCelebrations(anniversaryData, DATE_TYPE.anniversary);
      addPeopleToCelebrations(birthdayData, DATE_TYPE.birthday);

      currentYearCelebrations.sort(({ score: currentScore }, { score: nextScore }) => currentScore - nextScore);
      nextYearCelebrations.sort(({ score: currentScore }, { score: nextScore }) => currentScore - nextScore);

      celebrations = [...currentYearCelebrations, ...nextYearCelebrations];

      dispatch(calendarSlice.actions.loadCelebrationsSuccess(celebrations));
      return celebrations;
    } catch (error) {
      jjLogger.log(error);
      return error;
    }
  };

  return {
    loadCelebrations,
  };
};
