import makeStyles from "@mui/styles/makeStyles";

export default makeStyles(
  (theme) => ({
    listContainer: {
      minWidth: 200,
    },
    listItem: {
      paddingTop: 0,
      paddingBottom: 0,
    },
    listButton: {
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
    link: {
      textDecoration: "none",
      color: theme.palette.text.main,
      "&:hover": {
        color: theme.palette.primary.main,
        fontWeight: 600,
      },
    },
  }),
  { name: "LinkList" },
);
