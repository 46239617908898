import { useDetectAdBlock } from "adblock-detect-react";

const ADBLOCK_DETECTOR = "ADBLOCK_DETECTOR";

export const adblockDetected = () => sessionStorage.getItem(ADBLOCK_DETECTOR) === "true";

export default function useDetectAdblock() {
  const initialValue = useDetectAdBlock();
  const clearValue = () => {
    sessionStorage.removeItem(ADBLOCK_DETECTOR);
  };
  const initializeValue = () => {
    sessionStorage.setItem(ADBLOCK_DETECTOR, initialValue);
  };

  return { initialValue, initializeValue, clearValue };
}
