import { resetCacheKey } from "utils/imageUtils";

export const rolesAttrs = (roles) =>
  roles.map((role) => ({
    attributes: {
      "head-id": role.headID,
      "role-rank": role.roleRank,
    },
    type: "castings",
  }));

export const makeAttributes = ({ makeThumb, shareThumb, roles, templateId }) => {
  return {
    data: {
      attributes: {
        "user-status": "owner",
        thumb: {
          s3_key: makeThumb,
        },
        "share-thumb": {
          s3_key: shareThumb,
        },
      },
      relationships: {
        template: {
          data: {
            type: "templates",
            id: templateId,
          },
        },
        castings: {
          data: rolesAttrs(roles),
        },
      },
      type: "makes",
    },
  };
};

export const castingsToActors = (castings) =>
  castings.reduce(
    (acc, { roleRank, headAsset, jawSvg }) => ({
      ...acc,
      [`actorhead0${roleRank}`]: { image: resetCacheKey(headAsset), casted: true, svg: jawSvg },
    }),
    {},
  );
