import MakePresenter from "presenters/MakePresenter";
import TemplatePresenter from "presenters/TemplatePresenter";
import TemplateGroupPresenter from "presenters/TemplateGroupPresenter";

export const fmvrAttributes = (make, templateGroup) => {
  return {
    data: {
      attributes: {
        size: TemplateGroupPresenter.videoSize(templateGroup),
        template: {
          type: TemplateGroupPresenter.templateGroupType(templateGroup),
          slug: TemplatePresenter.id(make.template),
          file: MakePresenter.webglFeatureVideo(make, templateGroup),
          position_data: MakePresenter.webglPositionData(make, templateGroup),
          actors: MakePresenter.castings(make).map(({ roleRank, headAsset, jawSvg }) => ({
            rank: roleRank,
            file: headAsset,
            svg: jawSvg,
          })),
        },
      },
      type: "fmvr-requests",
      id: MakePresenter.id(make),
    },
  };
};
