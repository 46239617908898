import React from "react";
import { Typography } from "@mui/material";

import useStyles from "./useStyles";

const Banner = () => {
  const classes = useStyles();
  const message = "Make 'em Laugh! Fun Video Ecards and Greetings Starring You®, Family & Friends";

  return (
    <div className={classes.banner}>
      <Typography component="span" variant="h6" fontWeight="medium" color="neutral.main">
        {message}
      </Typography>
      <div className={classes.arrow} />
    </div>
  );
};

export default Banner;
