import { createSlice } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";

import OfferGroupsRepository from "repositories/OfferGroupsRepository";
import { deserialize } from "utils/storeUtils";

const initialState = {
  offerGroups: null,
  errors: null,
  currentOffer: null,
};

const offerGroupsSlice = createSlice({
  name: "offerGroups",
  initialState,
  reducers: {
    loadOfferGroupsSuccess(state, { payload }) {
      state.offerGroups = payload;
    },
    loadOfferGroupsFail(state, { payload }) {
      state.errors = payload;
    },
    switchCurrentOffer(state) {
      state.offerGroups.offers.push(state.currentOffer);
      state.currentOffer = state.offerGroups?.offers.shift();
    },
    loadCurrentOfferSuccess(state, { payload }) {
      state.currentOffer = payload;
    },
  },
});

export const { actions } = offerGroupsSlice;
export default offerGroupsSlice.reducer;

export const useOfferGroupsActions = () => {
  const dispatch = useDispatch();

  const loadOfferGroups = async () => {
    try {
      const { data } = await OfferGroupsRepository.index();
      const dataDeserialized = deserialize(data);
      const firstOffer = dataDeserialized.offers.shift() || {
        displayName: "No Active Offers",
        description: `No active offers in offer group "${dataDeserialized?.name}"`,
        image: "",
      };

      dispatch(offerGroupsSlice.actions.loadOfferGroupsSuccess(dataDeserialized));
      dispatch(offerGroupsSlice.actions.loadCurrentOfferSuccess(firstOffer));
    } catch (error) {
      dispatch(offerGroupsSlice.actions.loadOfferGroupsFail(error));
      throw new Error(error);
    }
  };

  const switchCurrentOffer = () => {
    dispatch(offerGroupsSlice.actions.switchCurrentOffer());
  };

  return {
    loadOfferGroups,
    switchCurrentOffer,
  };
};
