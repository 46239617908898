import { useSelector } from "react-redux";

import useUsers from "hooks/useUsers";
import useAssetUpload from "hooks/useAssetUpload";
import AWSRepository from "repositories/AWSRepository";
import { useSourcePhotoActions } from "store/SourcePhotoSlice";

const useSourcePhoto = () => {
  const { createSourcePhoto, openModal, closeModal } = useSourcePhotoActions();
  const { sourcePhoto, modalOpened } = useSelector((state) => state.SourcePhotoSlice);
  const { ensureCurrentUser } = useUsers();
  const { uploadSourcePhotoAsset } = useAssetUpload();

  const saveSourcePhoto = (imageBlob) => {
    return ensureCurrentUser().then(() => {
      return uploadSourcePhotoAsset(
        AWSRepository.presignedUploadToS3withToken({ method: "faceswap_source_photo" }, imageBlob),
      ).then((key) => {
        const attrs = {
          data: {
            type: "source-photos",
            attributes: {
              asset: {
                "s3-key": key,
              },
            },
          },
        };
        return createSourcePhoto(attrs);
      });
    });
  };

  return {
    saveSourcePhoto,
    sourcePhoto,
    openModal,
    closeModal: () => closeModal(),
    modalOpened,
  };
};

export default useSourcePhoto;
