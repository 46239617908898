import { FETCH_STATUSES } from "enums";
import useUsers from "hooks/useUsers";
import useNotifications from "hooks/useNotifications";
import useMakes from "hooks/useMakes";
import useRouter from "hooks/useRouter";
import useGlobalLoading from "hooks/useGlobalLoading";
import UserPresenter from "presenters/UserPresenter";
import MakePresenter from "presenters/MakePresenter";
import { jjLogger } from "utils/logUtils";
import TemplateGroupPresenter from "presenters/TemplateGroupPresenter";
import { appRoutes } from "routes";
import { trackMakeCreationError } from "tracking/google/analytics";

import useFmvr from "./useFmvr";

const useSaveMakeAndGo = (saveMakeSuccess, templateGroup, getLabel = null) => {
  const { saveMake, loadMake } = useMakes();
  const { ensureCurrentUser } = useUsers();
  const { addErrorNotification } = useNotifications();
  const { createFmvr } = useFmvr();
  const { push } = useRouter();
  const { setStatus: setLoaderStatus } = useGlobalLoading(null, { labelFunction: getLabel, crossPage: true });

  const redirectTo = (make, currentUser) => {
    const makeViewPath = appRoutes.makeViewPath(templateGroup?.id, MakePresenter.id(make));
    const makePreviewPath = appRoutes.makePreviewPath(templateGroup?.id, MakePresenter.id(make));
    const signUpPath = appRoutes.signUpPath();
    if (UserPresenter.isAnonymous(currentUser) && TemplateGroupPresenter.isStraightUp(templateGroup)) {
      setLoaderStatus(FETCH_STATUSES.fulfilled);
      loadMake(make.id);
      return push({ pathname: signUpPath, state: { referrer: "inFlow" } });
    }

    if (UserPresenter.isAnonymous(currentUser)) {
      const path = MakePresenter.isDraft(make) ? makePreviewPath : signUpPath;
      if (path === signUpPath) setLoaderStatus(FETCH_STATUSES.fulfilled);
      return push({ pathname: path, state: { referrer: "inFlow" } });
    }

    if (UserPresenter.isPaid(currentUser)) {
      return push({ pathname: makeViewPath, state: { referrer: "inFlow" } });
    }
    const path = MakePresenter.isDraft(make) ? makePreviewPath : makeViewPath;
    return push({ pathname: path, state: { referrer: "inFlow" } });
  };

  const isRenderMp4 = (currentUser) => {
    const isPreview =
      UserPresenter.isAnonymous(currentUser) ||
      (!UserPresenter.isPaid(currentUser) && TemplateGroupPresenter.premium(templateGroup));
    return !isPreview;
  };

  const saveMakeAndGo = async ({ roles, templateId, makeThumb, shareThumb }) => {
    try {
      const currentUser = await ensureCurrentUser();
      const make = await saveMake({ roles, templateId, templateGroup, makeThumb, shareThumb });

      saveMakeSuccess(currentUser, make);

      if (isRenderMp4(currentUser)) {
        createFmvr(make, templateGroup);
      }

      await redirectTo(make, currentUser);
    } catch (error) {
      let message = "Oops! Something went wrong!";
      let errors = "UseSaveMakeAndGo.js: Make Creation Error: ";

      if (Array.isArray(error)) {
        error.forEach((err) => {
          errors += err;
          if (err.includes("too few castings") || err.includes("head: can't be blank")) {
            message = "This card needs more people! Cast another face!";
          }
        });
      } else {
        errors += error;
      }

      trackMakeCreationError(errors);
      addErrorNotification(message);
      jjLogger.logError(errors);
    }
  };

  return { saveMakeAndGo };
};

export default useSaveMakeAndGo;
