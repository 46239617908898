import makeStyles from "@mui/styles/makeStyles";

export default makeStyles(
  (theme) => ({
    avatar: {
      "& .MuiAvatar-root": {
        backgroundColor: theme.palette.avatar,
        width: 28,
        height: 28,
      },
    },
  }),
  { name: "Avatar" },
);
