import dayjs from "dayjs";
import { createSlice } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";

import SharesRepository from "repositories/SharesRepository";

const initialState = {
  errors: null,
  dateToShare: dayjs().format("YYYY-MM-DD"),
};

const sharesSlice = createSlice({
  name: "shares",
  initialState,
  reducers: {
    createSharesFail(state, { payload }) {
      state.errors = payload;
    },
    setDateToShareSucess(state, { payload }) {
      state.dateToShare = payload;
    },
  },
});

export const { actions } = sharesSlice;
export default sharesSlice.reducer;

export const useSharesActions = () => {
  const dispatch = useDispatch();

  const createShare = (params) => {
    return SharesRepository.create(params).catch((error) => {
      dispatch(sharesSlice.actions.createShareFail(error));
      throw new Error(error);
    });
  };

  const setDateToShare = (date) => {
    dispatch(sharesSlice.actions.setDateToShareSucess(date));
  };
  const resetDateToShare = () => {
    dispatch(sharesSlice.actions.setDateToShareSucess(dayjs().format("YYYY-MM-DD")));
  };

  return {
    createShare,
    setDateToShare,
    resetDateToShare,
  };
};
