import { useState } from "react";

const useScroll = (scrollX = false, SCROLL_POSITION_OFFSET = 200) => {
  const [scrollToEnd, setsScrollToEnd] = useState(0);

  const handleScroll = (event) => {
    const { scrollTop, scrollHeight, clientHeight, scrollLeft, scrollWidth, clientWidth } = event.currentTarget;
    let scrollValue;
    let maxScrollValue;
    if (!scrollX) {
      scrollValue = scrollTop;
      maxScrollValue = scrollHeight - clientHeight;
    } else {
      scrollValue = scrollLeft;
      maxScrollValue = scrollWidth - clientWidth;
    }
    setsScrollToEnd(maxScrollValue - scrollValue);
  };

  const scrollIsEnded = scrollToEnd < SCROLL_POSITION_OFFSET;

  return { handleScroll, scrollToEnd, scrollIsEnded };
};

export default useScroll;
