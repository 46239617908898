import { createSlice } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";

import { deserialize } from "utils/storeUtils";
import OffersRepository from "repositories/OffersRepository";

const initialState = {
  currentOffer: null,
  offers: [],
  error: null,
};

const offersSlice = createSlice({
  name: "offers",
  initialState,
  reducers: {
    loadCurrentOfferSuccess(state, { payload }) {
      state.currentOffer = deserialize(payload);
    },
    loadOffersSuccess(state, { payload }) {
      state.offers = deserialize(payload);
    },
    loadOfferFail(state, { payload }) {
      state.error = payload;
    },
    switchCurrentOffer(state) {
      state.offers.push(state.currentOffer);
      state.currentOffer = state.offers.shift();
    },
  },
});

export const { actions } = offersSlice;
export default offersSlice.reducer;

export const useOffersActions = () => {
  const dispatch = useDispatch();

  const loadCurrentOffer = (offer) => {
    dispatch(offersSlice.actions.loadCurrentOfferSuccess(offer));
  };

  const loadOffers = async (uriSlugs) => {
    try {
      const slugs = uriSlugs.split(",");
      const offerPromises = slugs.map((slug) => OffersRepository.show(slug));

      const responses = await Promise.all(offerPromises);
      const offersData = { data: responses.map(({ data: { data } }) => data) };

      const firstOffer = offersData.data.shift();

      dispatch(offersSlice.actions.loadOffersSuccess(offersData));
      loadCurrentOffer({ data: firstOffer });
    } catch (error) {
      dispatch(offersSlice.actions.loadOfferFail(error.toString()));
      throw new Error(error);
    }
  };

  const switchCurrentOffer = () => {
    dispatch(offersSlice.actions.switchCurrentOffer());
  };

  return {
    loadOffers,
    switchCurrentOffer,
  };
};
