import { apiRoutes } from "routes";
import FetchHelpers from "utils/FetchHelpers";
import { newRelic, jjLogger } from "utils/logUtils";

const sameOriginAssets = (response) => {
  const newResponse = response;
  try {
    if (newResponse.data.included) {
      for (let i = 0; i < newResponse.data.included.length; i += 1) {
        const { asset } = newResponse.data.included[i].attributes;
        if (asset) {
          newResponse.data.included[i].attributes.asset = FetchHelpers.toSameOrigin(asset);
        }
      }
    }
  } catch (error) {
    newRelic(`PeopleRepository Error: ${error.message}`, error);
    jjLogger.log(error.message);
  }

  return newResponse;
};

export default {
  index(params) {
    const url = apiRoutes.peoplePath(1, params);
    return FetchHelpers.get(url).then(sameOriginAssets);
  },
  create(params) {
    const url = apiRoutes.peoplePath(1);
    return FetchHelpers.post(url, params).then(sameOriginAssets);
  },
  delete(personId, params) {
    const url = apiRoutes.personPath(1, personId);
    const { deleteHeads = false } = params;
    const finalUrl = deleteHeads ? `${url}?delete_heads=${deleteHeads}` : url;
    return FetchHelpers.delete(finalUrl, params);
  },
  update(personId, params) {
    const url = apiRoutes.personPath(1, personId);
    return FetchHelpers.patch(url, params).then(sameOriginAssets);
  },
};
