import { useSelector } from "react-redux";

import { useTemplateGroupActions } from "store/TemplateGroupSlice";

const useTemplateGroup = () => {
  const { loadTemplateGroup, loadThemedTemplateGroup, clearTemplateGroup } = useTemplateGroupActions();
  const { templateGroup } = useSelector((state) => state.TemplateGroupSlice);

  return {
    loadTemplateGroup,
    clearTemplateGroup,
    templateGroup,
    loadThemedTemplateGroup,
  };
};

export default useTemplateGroup;
