import { Grid } from "@mui/material";
import React from "react";

import MegaMenuItemPresenter from "presenters/MegaMenuItemPresenter";

import useStyles from "./useStyles";
import LinkListContainer from "./components/LinkListContainer";

const SubNavigation = ({ menuItem }) => {
  const clasess = useStyles();
  const links = MegaMenuItemPresenter.childrenMenu(menuItem);
  return (
    <Grid container className={clasess.root}>
      <LinkListContainer links={links} />
    </Grid>
  );
};

SubNavigation.propTypes = {
  menuItem: MegaMenuItemPresenter.shape(),
};
export default SubNavigation;
