import palette from "layouts/ThemeProvider/palette";

const linkDefault = {
  fontWeight: 500,
  "&:hover": {
    "@media (hover: hover)": {
      color: palette.primary.main,
    },
  },
};

export default {
  MuiLink: {
    defaultProps: {
      color: palette.neutral.light,
      underline: "none",
    },
    styleOverrides: {
      root: linkDefault,
    },
  },
};
