const GUEST_RSVP = {
  yes: "yes",
  no: "no",
  maybe: "maybe",
};

Object.freeze(GUEST_RSVP);

const RSVP_MESSAGES = {
  [GUEST_RSVP.yes]: "You are attending!",
  [GUEST_RSVP.no]: "You are not attending",
  [GUEST_RSVP.maybe]: "You responded Maybe",
};

Object.freeze(RSVP_MESSAGES);

const RSVP_TITLES = {
  [GUEST_RSVP.yes]: "Attending",
  [GUEST_RSVP.no]: "Not Attending",
  [GUEST_RSVP.maybe]: "Said Maybe",
};

Object.freeze(RSVP_TITLES);

const INVITE_STATUS = Object.freeze({
  PENDING: "pending",
  LIVE: "live",
  DRAFT: "draft",
  DELETED: "deleted",
});

export { GUEST_RSVP, RSVP_MESSAGES, RSVP_TITLES, INVITE_STATUS };
