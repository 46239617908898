const PAGE_TYPES = {
  LOGIN: "login",
  SELECT_ALBUM: "select_album",
  SELECT_PHOTO: "select_photo",
  SETTINGS: "settings",
};

Object.freeze(PAGE_TYPES);

export { PAGE_TYPES };
