import R from "ramda";

import { isBlank } from "utils/storeUtils";
import { HEAD, SCALE_FACE_BOX } from "enums";
import { transformPoint, zeroPoint } from "utils/pointUtils";
import { matrixFor } from "utils/canvasUtils";

const JAW_DEFAULT_TOP = 150;
const JAW_DEFAULT_WIDTH = 140;

export const defaultGVisionData = (sourceImage) => {
  const faceMidPoint = { x: sourceImage.width / 2, y: sourceImage.height / 2 };
  const faceSize = {
    width: sourceImage.width > HEAD.width ? HEAD.width : sourceImage.width,
    height: sourceImage.height > HEAD.height ? HEAD.height : sourceImage.height,
  };
  const scale = HEAD.height / faceSize.height;
  const jawTop = JAW_DEFAULT_TOP / scale;
  const jawWidth = JAW_DEFAULT_WIDTH / scale;

  return {
    rotation: 0,
    faceMidPoint,
    faceSize,
    jaw: {
      left: { x: faceMidPoint.x - jawWidth / 2, y: faceMidPoint.y + jawTop },
      right: { x: faceMidPoint.x + jawWidth / 2, y: faceMidPoint.y + jawTop },
      top: { x: faceMidPoint.x, y: faceMidPoint.y + jawTop },
      bottom: { x: faceMidPoint.x, y: faceMidPoint.y + faceSize.height / 2 },
    },
  };
};

const getSafeCoordinate = (coordName, point) => R.propOr(0, coordName, point);
const getSafeXCoordinate = (point) => getSafeCoordinate("x", point);
const getSafeYCoordinate = (point) => getSafeCoordinate("y", point);
const getSafeLandmarkCoordinates = (point) => {
  return {
    x: getSafeXCoordinate(point),
    y: getSafeYCoordinate(point),
    z: getSafeCoordinate("z", point),
  };
};

const convertKey = (key) => {
  let newKey = key.toLocaleLowerCase();
  newKey = newKey.replace(/(_\w)/g, (m) => {
    return m[1].toUpperCase();
  });

  return newKey;
};

const scaleBoxVertices = (box, scale) => {
  const safeBox = box.map((point) => ({ x: getSafeXCoordinate(point), y: getSafeYCoordinate(point) }));
  const center = { x: (safeBox[0].x + safeBox[2].x) / 2, y: (safeBox[0].y + safeBox[2].y) / 2 };
  const scaledBox = safeBox.map(({ x, y }) => ({
    x: center.x + (x - center.x) * scale,
    y: center.y + (y - center.y) * scale,
  }));

  return scaledBox;
};

export const gVisionToNewHead = (data, sourceImage) => {
  if (isBlank(data)) {
    return defaultGVisionData(sourceImage);
  }

  const {
    fdBoundingPoly: { vertices: faceBox },
    boundingPoly: { vertices: headBox },
    landmarks,
    rollAngle,
  } = data;
  const landmarksObj = landmarks.reduce(
    (acc, el) => ({ ...acc, [convertKey(el.type)]: { ...zeroPoint, ...el.position } }),
    {},
  );

  const scaledFaceBox = scaleBoxVertices(faceBox, SCALE_FACE_BOX);
  const faceSize = {
    width: getSafeXCoordinate(scaledFaceBox[1]) - getSafeXCoordinate(scaledFaceBox[0]),
    height: getSafeYCoordinate(scaledFaceBox[3]) - getSafeYCoordinate(scaledFaceBox[0]),
  };
  const faceMidPoint = {
    x: getSafeXCoordinate(scaledFaceBox[0]) + faceSize.width / 2,
    y: getSafeYCoordinate(scaledFaceBox[0]) + faceSize.height / 2,
  };

  const rotation = rollAngle * (Math.PI / 180);
  const matrix = matrixFor({ rotation, center: faceMidPoint });
  const rotatePoint = (point) => transformPoint(matrix, point);

  const jaw = {
    left: rotatePoint(getSafeLandmarkCoordinates(landmarksObj.mouthLeft)),
    right: rotatePoint(getSafeLandmarkCoordinates(landmarksObj.mouthRight)),
    top: rotatePoint(getSafeLandmarkCoordinates(landmarksObj.mouthCenter)),
    bottom: rotatePoint(getSafeLandmarkCoordinates(landmarksObj.chinGnathion)),
  };

  return {
    ...data,
    ...landmarksObj,
    faceBox,
    headBox,
    faceSize,
    faceMidPoint,
    rotation,
    jaw,
  };
};
