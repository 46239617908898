import { validate as uuidValidate, version as uuidVersion } from "uuid";

export const uuidValidateV4 = (uuid) => {
  return uuidValidate(uuid) && uuidVersion(uuid) === 4;
};

export const isAnAnonEmail = (email) => {
  const [uuid, domain] = email.split("@");
  return uuidValidateV4(uuid) && domain === "jibjab.com";
};
