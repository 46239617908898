import { appRoutes } from "routes";

const ECARD_TYPES = {
  INVITE: "invites",
  MAKE: "makes",
  MORPHABLE: "morphed-packs",
};

const FLOW_START_PAGES = [appRoutes.makePreviewPath, appRoutes.inviteMakePreviewPath, appRoutes.syaiPackPreviewPath];

const FLOW_WHITELIST_PAGES = [
  appRoutes.loginPath(),
  appRoutes.signUpPath(),
  appRoutes.paymentPath(),
  appRoutes.purchaseConfirmationPath(),
  appRoutes.paypalConfirmationPath(),
];

export { ECARD_TYPES, FLOW_START_PAGES, FLOW_WHITELIST_PAGES };
