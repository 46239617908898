import React from "react";
import { create } from "jss";
import PropTypes from "prop-types";
import { StylesProvider } from "@mui/styles";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import jssPresetDefault from "jss-preset-default";

import theme from "./theme";

const jss = create(jssPresetDefault());

const CustomThemeProvider = ({ children }) => (
  <StyledEngineProvider injectFirst>
    <StylesProvider jss={jss}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </StylesProvider>
  </StyledEngineProvider>
);

CustomThemeProvider.propTypes = {
  children: PropTypes.node,
};

CustomThemeProvider.defaultProps = {
  children: null,
};

export default CustomThemeProvider;
