export const transformPoint = (matrix, point) => ({
  x: matrix.a * point.x + matrix.c * point.y + matrix.e,
  y: matrix.b * point.x + matrix.d * point.y + matrix.f,
});

export const addPoints = (...points) => points.reduce((acc, point) => ({ x: acc.x + point.x, y: acc.y + point.y }));

export const subtractPoints = (pointA, pointB) => {
  return {
    x: pointA.x - pointB.x,
    y: pointA.y - pointB.y,
  };
};

export const multiplyPointBy = (multiple, point) => {
  return {
    x: point.x * multiple,
    y: point.y * multiple,
  };
};

export const zeroPoint = { x: 0, y: 0 };

export const rotatePoint = (pointOfRotation, angle, point) => {
  const shiftedPoint = subtractPoints(point, pointOfRotation);
  const rotationOffset = {
    x: shiftedPoint.y * Math.sin(angle) + shiftedPoint.x * Math.cos(angle),
    y: shiftedPoint.y * Math.cos(angle) - shiftedPoint.x * Math.sin(angle),
  };

  return addPoints(rotationOffset, pointOfRotation);
};

export const isZeroDistance = (a, b) => a.x - b.x === 0 && a.y - b.y === 0;

export const distanceMoreThan = (a, b, threshold) => Math.abs(a.x - b.x) > threshold || Math.abs(a.y - b.y) > threshold;

export const toArray = (point) => [point.x, point.y];

export const radToDeg = (angle) => angle * (180 / Math.PI);

export const getMiddlePoint = (pointA, pointB) => {
  return {
    x: (pointA.x + pointB.x) / 2,
    y: (pointA.y + pointB.y) / 2,
  };
};

export const getDistanceBetweenPoints = (pointA, pointB) =>
  Math.sqrt((pointA.y - pointB.y) ** 2 + (pointA.x - pointB.x) ** 2);

export const getAngleBetweenPoints = (pointA, pointB) => {
  const pointM = getMiddlePoint(pointA, pointB);
  const angle = Math.atan2(pointM.y - pointA.y, pointM.x - pointA.x);
  return radToDeg(angle);
};
