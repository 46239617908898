import { lazy } from "react";

const ChangeEmail = lazy(() => import("./ChangeEmail"));
const ChangeName = lazy(() => import("./ChangeName"));
const ChangePassword = lazy(() => import("./ChangePassword"));
const Overview = lazy(() => import("./Overview"));
const Receipts = lazy(() => import("./Receipts"));
const Receipt = lazy(() => import("./Receipt"));
const SubscriptionDetails = lazy(() => import("./SubscriptionDetails"));
const EditRenewal = lazy(() => import("./EditRenewal"));
const UpdateCreditCard = lazy(() => import("./UpdateCreditCard"));

export {
  Overview,
  ChangeEmail,
  ChangeName,
  ChangePassword,
  Receipts,
  Receipt,
  SubscriptionDetails,
  EditRenewal,
  UpdateCreditCard,
};
