import copyToClipBoard from "clipboard-copy";

import { jjLogger } from "utils/logUtils";

import useNotifications from "./useNotifications";

const useClipboard = () => {
  const { addSuccessNotification } = useNotifications();

  const copy = async (text, msg) => {
    return copyToClipBoard(text)
      .then(() => {
        addSuccessNotification(msg);
      })
      .catch((error) => {
        jjLogger.logError(`useClipboard error ${error}`);
      });
  };

  return { copy };
};

export default useClipboard;
