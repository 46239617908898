import makeStyles from "@mui/styles/makeStyles";

export default makeStyles(
  (theme) => ({
    toolbar: {
      boxShadow: [0, 0, 5, "#00000030"],
      [theme.breakpoints.up("tablet")]: {
        height: theme.sizes.desktop.header.height,
      },
      display: "block",
    },
    searchBar: {
      position: "relative",
      alignSelf: "right",
    },
  }),
  { name: "UserLayoutHeader" },
);
