import appRoutes from "./appRoutes";
import apiRoutesWithoutHost from "./apiRoutes";
import externalRoutes from "./externalRoutes";
import contentfulRoutes from "./contentfulRoutes";
import apiRoutesV2 from "./apiRoutesV2";

const apiRoutes = Object.keys(apiRoutesWithoutHost).reduce((acc, key) => {
  const prefix = "Path";
  if (key.includes(prefix)) {
    const newKey = [key.split(prefix)[0], "Url"].join("");
    return { ...acc, [key]: (...params) => apiRoutesWithoutHost[newKey](...params) };
  }
  return { ...acc };
}, {});

export { appRoutes, apiRoutes, externalRoutes, contentfulRoutes, apiRoutesV2 };
