import SubscriptionPresenter from "presenters/SubscriptionPresenter";
import MorphedPackPresenter from "presenters/MorphedPackPresenter";
import MorphedPhotoPresenter from "presenters/MorphedPhotoPresenter";
import UserPresenter from "presenters/UserPresenter";
import { jjLogger } from "utils/logUtils";

import { trackEvent, buildEventData, validateAdditionalData } from "./faceswap/request";

export const completedPackEvent = (data) => {
  const subscriptionId = SubscriptionPresenter.id(UserPresenter.activeSubscription(data?.user));
  const additionalEventData = { subscription_id: subscriptionId };

  if (!validateAdditionalData("completedPackEvent", ["subscription_id"], additionalEventData)) {
    jjLogger.logError(`completedPackEvent: - Invalid data provided - data: ${JSON.stringify(data)}`);
    return;
  }

  const eventData = buildEventData("morphed_pack_live", data, additionalEventData);

  trackEvent(eventData);
};

export const previewViewEvent = (data) => {
  const morphedPreviewPhoto = MorphedPackPresenter.previewMorphedPhoto(data?.morphedPack);
  const morphedPhotoId = MorphedPhotoPresenter.id(morphedPreviewPhoto);
  const requiredData = ["morphed_image_id"];
  const additionalEventData = { morphed_image_id: morphedPhotoId };

  if (!validateAdditionalData("previewViewEvent", requiredData, additionalEventData)) {
    jjLogger.logError(`previewViewEvent: - Invalid data provided - data: ${JSON.stringify(data)}`);
    return;
  }

  const eventData = buildEventData("preview_view", data, additionalEventData);

  trackEvent(eventData);
};

export const subscriptionPurchaseEvent = (data) => {
  const subscriptionId = SubscriptionPresenter.id(UserPresenter.activeSubscription(data?.user));
  const additionalEventData = { subscription_id: subscriptionId };

  if (!validateAdditionalData("subscriptionPurchaseEvent", ["subscription_id"], additionalEventData)) {
    jjLogger.logError(`subscriptionPurchaseEvent: - Invalid data provided - data: ${JSON.stringify(data)}`);
    return;
  }

  const eventData = buildEventData("faceswap_subscribed", data, additionalEventData);

  trackEvent(eventData);
};

export const actionEvent = (data) => {
  const morphedImageId = MorphedPhotoPresenter.id(data?.photoSelected);
  const requiredData = ["morphed_image_id", "event_subtype"];

  const additionalEventData = {
    morphed_image_id: morphedImageId,
    event_subtype: data?.event_subtype,
  };

  if (!validateAdditionalData("actionEvent", requiredData, additionalEventData)) {
    jjLogger.logError(`actionEvent: - Invalid data provided - data: ${JSON.stringify(data)}`);
    return;
  }

  const eventData = buildEventData("morphed_pack_shares", data, additionalEventData);

  trackEvent(eventData);
};
