/*
File generated by js-routes 1.4.9
Based on Rails 5.0.7.2 routes of JibjabApi::Application
 */

(function() {
  var DeprecatedGlobbingBehavior, NodeTypes, ParameterMissing, ReservedOptions, SpecialOptionsKey, UriEncoderSegmentRegex, Utils, result, root,
    hasProp = {}.hasOwnProperty,
    slice = [].slice;

  root = typeof exports !== "undefined" && exports !== null ? exports : this;

  ParameterMissing = function(message, fileName, lineNumber) {
    var instance;
    instance = new Error(message, fileName, lineNumber);
    if (Object.setPrototypeOf) {
      Object.setPrototypeOf(instance, Object.getPrototypeOf(this));
    } else {
      instance.__proto__ = this.__proto__;
    }
    if (Error.captureStackTrace) {
      Error.captureStackTrace(instance, ParameterMissing);
    }
    return instance;
  };

  ParameterMissing.prototype = Object.create(Error.prototype, {
    constructor: {
      value: Error,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });

  if (Object.setPrototypeOf) {
    Object.setPrototypeOf(ParameterMissing, Error);
  } else {
    ParameterMissing.__proto__ = Error;
  }

  NodeTypes = {"GROUP":1,"CAT":2,"SYMBOL":3,"OR":4,"STAR":5,"LITERAL":6,"SLASH":7,"DOT":8};

  DeprecatedGlobbingBehavior = false;

  SpecialOptionsKey = "_options";

  UriEncoderSegmentRegex = /[^a-zA-Z0-9\-\._~!\$&'\(\)\*\+,;=:@]/g;

  ReservedOptions = ['anchor', 'trailing_slash', 'subdomain', 'host', 'port', 'protocol'];

  Utils = {
    configuration: {
      prefix: "",
      default_url_options: { "host": process.env.JIBJAB_API_HOST },
      special_options_key: "_options",
      serializer: null
    },
    default_serializer: function(object, prefix) {
      var element, i, j, key, len, prop, s;
      if (prefix == null) {
        prefix = null;
      }
      if (object == null) {
        return "";
      }
      if (!prefix && !(this.get_object_type(object) === "object")) {
        throw new Error("Url parameters should be a javascript hash");
      }
      s = [];
      switch (this.get_object_type(object)) {
        case "array":
          for (i = j = 0, len = object.length; j < len; i = ++j) {
            element = object[i];
            s.push(this.default_serializer(element, prefix + "[]"));
          }
          break;
        case "object":
          for (key in object) {
            if (!hasProp.call(object, key)) continue;
            prop = object[key];
            if ((prop == null) && (prefix != null)) {
              prop = "";
            }
            if (prop != null) {
              if (prefix != null) {
                key = prefix + "[" + key + "]";
              }
              s.push(this.default_serializer(prop, key));
            }
          }
          break;
        default:
          if (object != null) {
            s.push((encodeURIComponent(prefix.toString())) + "=" + (encodeURIComponent(object.toString())));
          }
      }
      if (!s.length) {
        return "";
      }
      return s.join("&");
    },
    serialize: function(object) {
      var custom_serializer;
      custom_serializer = this.configuration.serializer;
      if ((custom_serializer != null) && this.get_object_type(custom_serializer) === "function") {
        return custom_serializer(object);
      } else {
        return this.default_serializer(object);
      }
    },
    clean_path: function(path) {
      var last_index;
      path = path.split("://");
      last_index = path.length - 1;
      path[last_index] = path[last_index].replace(/\/+/g, "/");
      return path.join("://");
    },
    extract_options: function(number_of_params, args) {
      var last_el, options;
      last_el = args[args.length - 1];
      if ((args.length > number_of_params && last_el === void 0) || ((last_el != null) && "object" === this.get_object_type(last_el) && !this.looks_like_serialized_model(last_el))) {
        options = args.pop() || {};
        delete options[this.configuration.special_options_key];
        return options;
      } else {
        return {};
      }
    },
    looks_like_serialized_model: function(object) {
      return !object[this.configuration.special_options_key] && ("id" in object || "to_param" in object);
    },
    path_identifier: function(object) {
      var property;
      if (object === 0) {
        return "0";
      }
      if (!object) {
        return "";
      }
      property = object;
      if (this.get_object_type(object) === "object") {
        if ("to_param" in object) {
          if (object.to_param == null) {
            throw new ParameterMissing("Route parameter missing: to_param");
          }
          property = object.to_param;
        } else if ("id" in object) {
          if (object.id == null) {
            throw new ParameterMissing("Route parameter missing: id");
          }
          property = object.id;
        } else {
          property = object;
        }
        if (this.get_object_type(property) === "function") {
          property = property.call(object);
        }
      }
      return property.toString();
    },
    clone: function(obj) {
      var attr, copy, key;
      if ((obj == null) || "object" !== this.get_object_type(obj)) {
        return obj;
      }
      copy = obj.constructor();
      for (key in obj) {
        if (!hasProp.call(obj, key)) continue;
        attr = obj[key];
        copy[key] = attr;
      }
      return copy;
    },
    merge: function() {
      var tap, xs;
      xs = 1 <= arguments.length ? slice.call(arguments, 0) : [];
      tap = function(o, fn) {
        fn(o);
        return o;
      };
      if ((xs != null ? xs.length : void 0) > 0) {
        return tap({}, function(m) {
          var j, k, len, results, v, x;
          results = [];
          for (j = 0, len = xs.length; j < len; j++) {
            x = xs[j];
            results.push((function() {
              var results1;
              results1 = [];
              for (k in x) {
                v = x[k];
                results1.push(m[k] = v);
              }
              return results1;
            })());
          }
          return results;
        });
      }
    },
    normalize_options: function(parts, required_parts, default_options, actual_parameters) {
      var i, j, key, len, options, part, parts_options, result, route_parts, url_parameters, use_all_parts, value;
      options = this.extract_options(parts.length, actual_parameters);
      if (actual_parameters.length > parts.length) {
        throw new Error("Too many parameters provided for path");
      }
      use_all_parts = actual_parameters.length > required_parts.length;
      parts_options = {};
      for (key in options) {
        if (!hasProp.call(options, key)) continue;
        use_all_parts = true;
        if (this.indexOf(parts, key) >= 0) {
          parts_options[key] = value;
        }
      }
      options = this.merge(this.configuration.default_url_options, default_options, options);
      result = {};
      url_parameters = {};
      result['url_parameters'] = url_parameters;
      for (key in options) {
        if (!hasProp.call(options, key)) continue;
        value = options[key];
        if (this.indexOf(ReservedOptions, key) >= 0) {
          result[key] = value;
        } else {
          url_parameters[key] = value;
        }
      }
      route_parts = use_all_parts ? parts : required_parts;
      i = 0;
      for (j = 0, len = route_parts.length; j < len; j++) {
        part = route_parts[j];
        if (i < actual_parameters.length) {
          if (!parts_options.hasOwnProperty(part)) {
            url_parameters[part] = actual_parameters[i];
            ++i;
          }
        }
      }
      return result;
    },
    build_route: function(parts, required_parts, default_options, route, full_url, args) {
      var options, parameters, result, url, url_params;
      args = Array.prototype.slice.call(args);
      options = this.normalize_options(parts, required_parts, default_options, args);
      parameters = options['url_parameters'];
      result = "" + (this.get_prefix()) + (this.visit(route, parameters));
      url = Utils.clean_path(result);
      if (options['trailing_slash'] === true) {
        url = url.replace(/(.*?)[\/]?$/, "$1/");
      }
      if ((url_params = this.serialize(parameters)).length) {
        url += "?" + url_params;
      }
      url += options.anchor ? "#" + options.anchor : "";
      if (full_url) {
        url = this.route_url(options) + url;
      }
      return url;
    },
    visit: function(route, parameters, optional) {
      var left, left_part, right, right_part, type, value;
      if (optional == null) {
        optional = false;
      }
      type = route[0], left = route[1], right = route[2];
      switch (type) {
        case NodeTypes.GROUP:
          return this.visit(left, parameters, true);
        case NodeTypes.STAR:
          return this.visit_globbing(left, parameters, true);
        case NodeTypes.LITERAL:
        case NodeTypes.SLASH:
        case NodeTypes.DOT:
          return left;
        case NodeTypes.CAT:
          left_part = this.visit(left, parameters, optional);
          right_part = this.visit(right, parameters, optional);
          if (optional && ((this.is_optional_node(left[0]) && !left_part) || ((this.is_optional_node(right[0])) && !right_part))) {
            return "";
          }
          return "" + left_part + right_part;
        case NodeTypes.SYMBOL:
          value = parameters[left];
          delete parameters[left];
          if (value != null) {
            return this.encode_segment(this.path_identifier(value));
          }
          if (optional) {
            return "";
          } else {
            throw new ParameterMissing("Route parameter missing: " + left);
          }
          break;
        default:
          throw new Error("Unknown Rails node type");
      }
    },
    encode_segment: function(segment) {
      return segment.replace(UriEncoderSegmentRegex, function(str) {
        return encodeURIComponent(str);
      });
    },
    is_optional_node: function(node) {
      return this.indexOf([NodeTypes.STAR, NodeTypes.SYMBOL, NodeTypes.CAT], node) >= 0;
    },
    build_path_spec: function(route, wildcard) {
      var left, right, type;
      if (wildcard == null) {
        wildcard = false;
      }
      type = route[0], left = route[1], right = route[2];
      switch (type) {
        case NodeTypes.GROUP:
          return "(" + (this.build_path_spec(left)) + ")";
        case NodeTypes.CAT:
          return "" + (this.build_path_spec(left)) + (this.build_path_spec(right));
        case NodeTypes.STAR:
          return this.build_path_spec(left, true);
        case NodeTypes.SYMBOL:
          if (wildcard === true) {
            return "" + (left[0] === '*' ? '' : '*') + left;
          } else {
            return ":" + left;
          }
          break;
        case NodeTypes.SLASH:
        case NodeTypes.DOT:
        case NodeTypes.LITERAL:
          return left;
        default:
          throw new Error("Unknown Rails node type");
      }
    },
    visit_globbing: function(route, parameters, optional) {
      var left, right, type, value;
      type = route[0], left = route[1], right = route[2];
      value = parameters[left];
      delete parameters[left];
      if (value == null) {
        return this.visit(route, parameters, optional);
      }
      value = (function() {
        switch (this.get_object_type(value)) {
          case "array":
            return value.join("/");
          default:
            return value;
        }
      }).call(this);
      if (DeprecatedGlobbingBehavior) {
        return this.path_identifier(value);
      } else {
        return encodeURI(this.path_identifier(value));
      }
    },
    get_prefix: function() {
      var prefix;
      prefix = this.configuration.prefix;
      if (prefix !== "") {
        prefix = (prefix.match("/$") ? prefix : prefix + "/");
      }
      return prefix;
    },
    route: function(parts_table, default_options, route_spec, full_url) {
      var j, len, part, parts, path_fn, ref, required, required_parts;
      required_parts = [];
      parts = [];
      for (j = 0, len = parts_table.length; j < len; j++) {
        ref = parts_table[j], part = ref[0], required = ref[1];
        parts.push(part);
        if (required) {
          required_parts.push(part);
        }
      }
      path_fn = function() {
        return Utils.build_route(parts, required_parts, default_options, route_spec, full_url, arguments);
      };
      path_fn.required_params = required_parts;
      path_fn.toString = function() {
        return Utils.build_path_spec(route_spec);
      };
      return path_fn;
    },
    route_url: function(route_defaults) {
      var hostname, port, protocol, subdomain;
      if (typeof route_defaults === 'string') {
        return route_defaults;
      }
      hostname = route_defaults.host || Utils.current_host();
      if (!hostname) {
        return '';
      }
      subdomain = route_defaults.subdomain ? route_defaults.subdomain + '.' : '';
      protocol = route_defaults.protocol || Utils.current_protocol();
      port = route_defaults.port || (!route_defaults.host ? Utils.current_port() : void 0);
      port = port ? ":" + port : '';
      return protocol + "://" + subdomain + hostname + port;
    },
    has_location: function() {
      return (typeof window !== "undefined" && window !== null ? window.location : void 0) != null;
    },
    current_host: function() {
      if (this.has_location()) {
        return window.location.hostname;
      } else {
        return null;
      }
    },
    current_protocol: function() {
      if (this.has_location() && window.location.protocol !== '') {
        return window.location.protocol.replace(/:$/, '');
      } else {
        return 'http';
      }
    },
    current_port: function() {
      if (this.has_location() && window.location.port !== '') {
        return window.location.port;
      } else {
        return '';
      }
    },
    _classToTypeCache: null,
    _classToType: function() {
      var j, len, name, ref;
      if (this._classToTypeCache != null) {
        return this._classToTypeCache;
      }
      this._classToTypeCache = {};
      ref = "Boolean Number String Function Array Date RegExp Object Error".split(" ");
      for (j = 0, len = ref.length; j < len; j++) {
        name = ref[j];
        this._classToTypeCache["[object " + name + "]"] = name.toLowerCase();
      }
      return this._classToTypeCache;
    },
    get_object_type: function(obj) {
      if (root.jQuery && (root.jQuery.type != null)) {
        return root.jQuery.type(obj);
      }
      if (obj == null) {
        return "" + obj;
      }
      if (typeof obj === "object" || typeof obj === "function") {
        return this._classToType()[Object.prototype.toString.call(obj)] || "object";
      } else {
        return typeof obj;
      }
    },
    indexOf: function(array, element) {
      if (Array.prototype.indexOf) {
        return array.indexOf(element);
      } else {
        return this.indexOfImplementation(array, element);
      }
    },
    indexOfImplementation: function(array, element) {
      var el, i, j, len, result;
      result = -1;
      for (i = j = 0, len = array.length; j < len; i = ++j) {
        el = array[i];
        if (el === element) {
          result = i;
        }
      }
      return result;
    },
    namespace: function(root, namespace, routes) {
      var index, j, len, part, parts;
      parts = namespace ? namespace.split(".") : [];
      if (parts.length === 0) {
        return routes;
      }
      for (index = j = 0, len = parts.length; j < len; index = ++j) {
        part = parts[index];
        if (index < parts.length - 1) {
          root = (root[part] || (root[part] = {}));
        } else {
          return root[part] = routes;
        }
      }
    },
    configure: function(new_config) {
      return this.configuration = this.merge(this.configuration, new_config);
    },
    config: function() {
      return this.clone(this.configuration);
    },
    make: function() {
      var routes;
      routes = {
// admin_alert => /v:api_version/admin/alerts/:id(.:format)
  // function(api_version, id, options)
  adminAlertPath: Utils.route([["api_version",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"alerts",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
adminAlertUrl: Utils.route([["api_version",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"alerts",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]], true),
// admin_alerts => /v:api_version/admin/alerts(.:format)
  // function(api_version, options)
  adminAlertsPath: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"alerts",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
adminAlertsUrl: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"alerts",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]], true),
// admin_categories => /v:api_version/admin/categories(.:format)
  // function(api_version, options)
  adminCategoriesPath: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"categories",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
adminCategoriesUrl: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"categories",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]], true),
// admin_category => /v:api_version/admin/categories/:id(.:format)
  // function(api_version, id, options)
  adminCategoryPath: Utils.route([["api_version",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"categories",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
adminCategoryUrl: Utils.route([["api_version",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"categories",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]], true),
// admin_content_collection => /v:api_version/admin/content_collections/:id(.:format)
  // function(api_version, id, options)
  adminContentCollectionPath: Utils.route([["api_version",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"content_collections",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
adminContentCollectionUrl: Utils.route([["api_version",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"content_collections",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]], true),
// admin_content_collection_content_collection_template_group => /v:api_version/admin/content_collections/:content_collection_id/content_collection_template_groups/:id(.:format)
  // function(api_version, content_collection_id, id, options)
  adminContentCollectionContentCollectionTemplateGroupPath: Utils.route([["api_version",true],["content_collection_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"content_collections",false],[2,[7,"/",false],[2,[3,"content_collection_id",false],[2,[7,"/",false],[2,[6,"content_collection_template_groups",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
adminContentCollectionContentCollectionTemplateGroupUrl: Utils.route([["api_version",true],["content_collection_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"content_collections",false],[2,[7,"/",false],[2,[3,"content_collection_id",false],[2,[7,"/",false],[2,[6,"content_collection_template_groups",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]], true),
// admin_content_collection_content_collection_template_groups => /v:api_version/admin/content_collections/:content_collection_id/content_collection_template_groups(.:format)
  // function(api_version, content_collection_id, options)
  adminContentCollectionContentCollectionTemplateGroupsPath: Utils.route([["api_version",true],["content_collection_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"content_collections",false],[2,[7,"/",false],[2,[3,"content_collection_id",false],[2,[7,"/",false],[2,[6,"content_collection_template_groups",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
adminContentCollectionContentCollectionTemplateGroupsUrl: Utils.route([["api_version",true],["content_collection_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"content_collections",false],[2,[7,"/",false],[2,[3,"content_collection_id",false],[2,[7,"/",false],[2,[6,"content_collection_template_groups",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]], true),
// admin_content_collections => /v:api_version/admin/content_collections(.:format)
  // function(api_version, options)
  adminContentCollectionsPath: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"content_collections",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
adminContentCollectionsUrl: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"content_collections",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]], true),
// admin_email_template => /v:api_version/admin/email_templates/:id(.:format)
  // function(api_version, id, options)
  adminEmailTemplatePath: Utils.route([["api_version",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"email_templates",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
adminEmailTemplateUrl: Utils.route([["api_version",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"email_templates",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]], true),
// admin_email_templates => /v:api_version/admin/email_templates(.:format)
  // function(api_version, options)
  adminEmailTemplatesPath: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"email_templates",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
adminEmailTemplatesUrl: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"email_templates",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]], true),
// admin_feature_unit => /v:api_version/admin/feature_unit(.:format)
  // function(api_version, options)
  adminFeatureUnitPath: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"feature_unit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
adminFeatureUnitUrl: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"feature_unit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]], true),
// admin_featured_ecards => /v:api_version/admin/featured_ecards(.:format)
  // function(api_version, options)
  adminFeaturedEcardsPath: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"featured_ecards",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
adminFeaturedEcardsUrl: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"featured_ecards",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]], true),
// admin_head => /v:api_version/admin/heads/:id(.:format)
  // function(api_version, id, options)
  adminHeadPath: Utils.route([["api_version",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"heads",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
adminHeadUrl: Utils.route([["api_version",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"heads",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]], true),
// admin_heads => /v:api_version/admin/heads(.:format)
  // function(api_version, options)
  adminHeadsPath: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"heads",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
adminHeadsUrl: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"heads",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]], true),
// admin_identity => /v:api_version/admin/identities/:id(.:format)
  // function(api_version, id, options)
  adminIdentityPath: Utils.route([["api_version",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"identities",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
adminIdentityUrl: Utils.route([["api_version",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"identities",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]], true),
// admin_legal_notices => /v:api_version/admin/legal_notices(.:format)
  // function(api_version, options)
  adminLegalNoticesPath: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"legal_notices",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
adminLegalNoticesUrl: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"legal_notices",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]], true),
// admin_make => /v:api_version/admin/makes/:id(.:format)
  // function(api_version, id, options)
  adminMakePath: Utils.route([["api_version",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"makes",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
adminMakeUrl: Utils.route([["api_version",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"makes",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]], true),
// admin_makes => /v:api_version/admin/makes(.:format)
  // function(api_version, options)
  adminMakesPath: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"makes",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
adminMakesUrl: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"makes",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]], true),
// admin_offer => /v:api_version/admin/offers/:id(.:format)
  // function(api_version, id, options)
  adminOfferPath: Utils.route([["api_version",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"offers",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
adminOfferUrl: Utils.route([["api_version",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"offers",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]], true),
// admin_offer_group => /v:api_version/admin/offer_groups/:id(.:format)
  // function(api_version, id, options)
  adminOfferGroupPath: Utils.route([["api_version",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"offer_groups",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
adminOfferGroupUrl: Utils.route([["api_version",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"offer_groups",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]], true),
// admin_offer_grouping => /v:api_version/admin/offer_groupings/:id(.:format)
  // function(api_version, id, options)
  adminOfferGroupingPath: Utils.route([["api_version",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"offer_groupings",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
adminOfferGroupingUrl: Utils.route([["api_version",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"offer_groupings",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]], true),
// admin_offer_groupings => /v:api_version/admin/offer_groupings(.:format)
  // function(api_version, options)
  adminOfferGroupingsPath: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"offer_groupings",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
adminOfferGroupingsUrl: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"offer_groupings",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]], true),
// admin_offer_groups => /v:api_version/admin/offer_groups(.:format)
  // function(api_version, options)
  adminOfferGroupsPath: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"offer_groups",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
adminOfferGroupsUrl: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"offer_groups",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]], true),
// admin_offers => /v:api_version/admin/offers(.:format)
  // function(api_version, options)
  adminOffersPath: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"offers",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
adminOffersUrl: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"offers",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]], true),
// admin_payment_provider_offer => /v:api_version/admin/payment_provider_offers/:id(.:format)
  // function(api_version, id, options)
  adminPaymentProviderOfferPath: Utils.route([["api_version",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"payment_provider_offers",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
adminPaymentProviderOfferUrl: Utils.route([["api_version",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"payment_provider_offers",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]], true),
// admin_payment_provider_offers => /v:api_version/admin/payment_provider_offers(.:format)
  // function(api_version, options)
  adminPaymentProviderOffersPath: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"payment_provider_offers",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
adminPaymentProviderOffersUrl: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"payment_provider_offers",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]], true),
// admin_payment_providers => /v:api_version/admin/payment_providers(.:format)
  // function(api_version, options)
  adminPaymentProvidersPath: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"payment_providers",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
adminPaymentProvidersUrl: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"payment_providers",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]], true),
// admin_pill => /v:api_version/admin/pills/:id(.:format)
  // function(api_version, id, options)
  adminPillPath: Utils.route([["api_version",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"pills",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
adminPillUrl: Utils.route([["api_version",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"pills",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]], true),
// admin_pills => /v:api_version/admin/pills(.:format)
  // function(api_version, options)
  adminPillsPath: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"pills",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
adminPillsUrl: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"pills",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]], true),
// admin_privacy_policies => /v:api_version/admin/privacy_policies(.:format)
  // function(api_version, options)
  adminPrivacyPoliciesPath: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"privacy_policies",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
adminPrivacyPoliciesUrl: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"privacy_policies",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]], true),
// admin_search_index => /v:api_version/admin/search(.:format)
  // function(api_version, options)
  adminSearchIndexPath: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"search",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
adminSearchIndexUrl: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"search",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]], true),
// admin_search_link => /v:api_version/admin/search_links/:id(.:format)
  // function(api_version, id, options)
  adminSearchLinkPath: Utils.route([["api_version",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"search_links",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
adminSearchLinkUrl: Utils.route([["api_version",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"search_links",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]], true),
// admin_search_links => /v:api_version/admin/search_links(.:format)
  // function(api_version, options)
  adminSearchLinksPath: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"search_links",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
adminSearchLinksUrl: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"search_links",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]], true),
// admin_subscription => /v:api_version/admin/subscriptions/:id(.:format)
  // function(api_version, id, options)
  adminSubscriptionPath: Utils.route([["api_version",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"subscriptions",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
adminSubscriptionUrl: Utils.route([["api_version",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"subscriptions",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]], true),
// admin_subscription_cancellation => /v:api_version/admin/subscriptions/:subscription_id/cancellation(.:format)
  // function(api_version, subscription_id, options)
  adminSubscriptionCancellationPath: Utils.route([["api_version",true],["subscription_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"subscriptions",false],[2,[7,"/",false],[2,[3,"subscription_id",false],[2,[7,"/",false],[2,[6,"cancellation",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
adminSubscriptionCancellationUrl: Utils.route([["api_version",true],["subscription_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"subscriptions",false],[2,[7,"/",false],[2,[3,"subscription_id",false],[2,[7,"/",false],[2,[6,"cancellation",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]], true),
// admin_subscription_pause => /v:api_version/admin/subscriptions/:subscription_id/pause(.:format)
  // function(api_version, subscription_id, options)
  adminSubscriptionPausePath: Utils.route([["api_version",true],["subscription_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"subscriptions",false],[2,[7,"/",false],[2,[3,"subscription_id",false],[2,[7,"/",false],[2,[6,"pause",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
adminSubscriptionPauseUrl: Utils.route([["api_version",true],["subscription_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"subscriptions",false],[2,[7,"/",false],[2,[3,"subscription_id",false],[2,[7,"/",false],[2,[6,"pause",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]], true),
// admin_subscription_reactivation => /v:api_version/admin/subscriptions/:subscription_id/reactivation(.:format)
  // function(api_version, subscription_id, options)
  adminSubscriptionReactivationPath: Utils.route([["api_version",true],["subscription_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"subscriptions",false],[2,[7,"/",false],[2,[3,"subscription_id",false],[2,[7,"/",false],[2,[6,"reactivation",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
adminSubscriptionReactivationUrl: Utils.route([["api_version",true],["subscription_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"subscriptions",false],[2,[7,"/",false],[2,[3,"subscription_id",false],[2,[7,"/",false],[2,[6,"reactivation",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]], true),
// admin_subscription_termination => /v:api_version/admin/subscriptions/:subscription_id/termination(.:format)
  // function(api_version, subscription_id, options)
  adminSubscriptionTerminationPath: Utils.route([["api_version",true],["subscription_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"subscriptions",false],[2,[7,"/",false],[2,[3,"subscription_id",false],[2,[7,"/",false],[2,[6,"termination",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
adminSubscriptionTerminationUrl: Utils.route([["api_version",true],["subscription_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"subscriptions",false],[2,[7,"/",false],[2,[3,"subscription_id",false],[2,[7,"/",false],[2,[6,"termination",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]], true),
// admin_subscriptions => /v:api_version/admin/subscriptions(.:format)
  // function(api_version, options)
  adminSubscriptionsPath: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"subscriptions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
adminSubscriptionsUrl: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"subscriptions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]], true),
// admin_template => /v:api_version/admin/templates/:id(.:format)
  // function(api_version, id, options)
  adminTemplatePath: Utils.route([["api_version",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"templates",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
adminTemplateUrl: Utils.route([["api_version",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"templates",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]], true),
// admin_template_group => /v:api_version/admin/template_groups/:id(.:format)
  // function(api_version, id, options)
  adminTemplateGroupPath: Utils.route([["api_version",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"template_groups",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
adminTemplateGroupUrl: Utils.route([["api_version",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"template_groups",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]], true),
// admin_template_groups => /v:api_version/admin/template_groups(.:format)
  // function(api_version, options)
  adminTemplateGroupsPath: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"template_groups",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
adminTemplateGroupsUrl: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"template_groups",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]], true),
// admin_template_role => /v:api_version/admin/templates/:template_id/roles/:id(.:format)
  // function(api_version, template_id, id, options)
  adminTemplateRolePath: Utils.route([["api_version",true],["template_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"templates",false],[2,[7,"/",false],[2,[3,"template_id",false],[2,[7,"/",false],[2,[6,"roles",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
adminTemplateRoleUrl: Utils.route([["api_version",true],["template_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"templates",false],[2,[7,"/",false],[2,[3,"template_id",false],[2,[7,"/",false],[2,[6,"roles",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]], true),
// admin_template_roles => /v:api_version/admin/templates/:template_id/roles(.:format)
  // function(api_version, template_id, options)
  adminTemplateRolesPath: Utils.route([["api_version",true],["template_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"templates",false],[2,[7,"/",false],[2,[3,"template_id",false],[2,[7,"/",false],[2,[6,"roles",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
adminTemplateRolesUrl: Utils.route([["api_version",true],["template_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"templates",false],[2,[7,"/",false],[2,[3,"template_id",false],[2,[7,"/",false],[2,[6,"roles",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]], true),
// admin_templates => /v:api_version/admin/templates(.:format)
  // function(api_version, options)
  adminTemplatesPath: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"templates",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
adminTemplatesUrl: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"templates",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]], true),
// admin_terms_of_sales => /v:api_version/admin/terms_of_sales(.:format)
  // function(api_version, options)
  adminTermsOfSalesPath: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"terms_of_sales",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
adminTermsOfSalesUrl: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"terms_of_sales",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]], true),
// admin_terms_of_services => /v:api_version/admin/terms_of_services(.:format)
  // function(api_version, options)
  adminTermsOfServicesPath: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"terms_of_services",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
adminTermsOfServicesUrl: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"terms_of_services",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]], true),
// admin_transaction_record_refund => /v:api_version/admin/transaction_records/:transaction_record_id/refund(.:format)
  // function(api_version, transaction_record_id, options)
  adminTransactionRecordRefundPath: Utils.route([["api_version",true],["transaction_record_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"transaction_records",false],[2,[7,"/",false],[2,[3,"transaction_record_id",false],[2,[7,"/",false],[2,[6,"refund",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
adminTransactionRecordRefundUrl: Utils.route([["api_version",true],["transaction_record_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"transaction_records",false],[2,[7,"/",false],[2,[3,"transaction_record_id",false],[2,[7,"/",false],[2,[6,"refund",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]], true),
// admin_transaction_records => /v:api_version/admin/transaction_records(.:format)
  // function(api_version, options)
  adminTransactionRecordsPath: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"transaction_records",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
adminTransactionRecordsUrl: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"transaction_records",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]], true),
// admin_user => /v:api_version/admin/users/:id(.:format)
  // function(api_version, id, options)
  adminUserPath: Utils.route([["api_version",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
adminUserUrl: Utils.route([["api_version",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]], true),
// admin_user_impersonate => /v:api_version/admin/users/:user_id/impersonate(.:format)
  // function(api_version, user_id, options)
  adminUserImpersonatePath: Utils.route([["api_version",true],["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"impersonate",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
adminUserImpersonateUrl: Utils.route([["api_version",true],["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"impersonate",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]], true),
// admin_users => /v:api_version/admin/users(.:format)
  // function(api_version, options)
  adminUsersPath: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"users",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
adminUsersUrl: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"users",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]], true),
// admin_video_categories => /v:api_version/admin/video_categories(.:format)
  // function(api_version, options)
  adminVideoCategoriesPath: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"video_categories",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
adminVideoCategoriesUrl: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"video_categories",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]], true),
// alerts => /v:api_version/alerts(.:format)
  // function(api_version, options)
  alertsPath: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"alerts",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
alertsUrl: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"alerts",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]], true),
// android_notifications => /v:api_version/android_notifications(.:format)
  // function(api_version, options)
  androidNotificationsPath: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"android_notifications",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
androidNotificationsUrl: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"android_notifications",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]], true),
// apple_notifications => /v:api_version/apple_notifications(.:format)
  // function(api_version, options)
  appleNotificationsPath: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"apple_notifications",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
appleNotificationsUrl: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"apple_notifications",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]], true),
// campaign_attributions => /v:api_version/campaign_attributions(.:format)
  // function(api_version, options)
  campaignAttributionsPath: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"campaign_attributions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
campaignAttributionsUrl: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"campaign_attributions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]], true),
// cancellation_feedbacks => /v:api_version/cancellation_feedbacks(.:format)
  // function(api_version, options)
  cancellationFeedbacksPath: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"cancellation_feedbacks",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
cancellationFeedbacksUrl: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"cancellation_feedbacks",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]], true),
// categories => /v:api_version/categories(.:format)
  // function(api_version, options)
  categoriesPath: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"categories",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
categoriesUrl: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"categories",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]], true),
// category => /v:api_version/categories/:slug(.:format)
  // function(api_version, slug, options)
  categoryPath: Utils.route([["api_version",true],["slug",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"categories",false],[2,[7,"/",false],[2,[3,"slug",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
categoryUrl: Utils.route([["api_version",true],["slug",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"categories",false],[2,[7,"/",false],[2,[3,"slug",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]], true),
// db => /v:api_version/health/db(.:format)
  // function(api_version, options)
  dbPath: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"health",false],[2,[7,"/",false],[2,[6,"db",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
dbUrl: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"health",false],[2,[7,"/",false],[2,[6,"db",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]], true),
// edit_oauth_application => /v:api_version/oauth/applications/:id/edit(.:format)
  // function(api_version, id, options)
  editOauthApplicationPath: Utils.route([["api_version",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"oauth",false],[2,[7,"/",false],[2,[6,"applications",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
editOauthApplicationUrl: Utils.route([["api_version",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"oauth",false],[2,[7,"/",false],[2,[6,"applications",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]], true),
// edit_person => /v:api_version/people/:slug/edit(.:format)
  // function(api_version, slug, options)
  editPersonPath: Utils.route([["api_version",true],["slug",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"slug",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
editPersonUrl: Utils.route([["api_version",true],["slug",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"slug",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]], true),
// facebook_notifications => /v:api_version/facebook_notifications(.:format)
  // function(api_version, options)
  facebookNotificationsPath: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"facebook_notifications",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
facebookNotificationsUrl: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"facebook_notifications",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]], true),
// feature_unit => /v:api_version/feature_unit(.:format)
  // function(api_version, options)
  featureUnitPath: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"feature_unit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
featureUnitUrl: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"feature_unit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]], true),
// featured_ecards => /v:api_version/featured_ecards(.:format)
  // function(api_version, options)
  featuredEcardsPath: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"featured_ecards",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
featuredEcardsUrl: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"featured_ecards",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]], true),
// forgot_password_index => /v:api_version/forgot_password(.:format)
  // function(api_version, options)
  forgotPasswordIndexPath: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"forgot_password",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
forgotPasswordIndexUrl: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"forgot_password",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]], true),
// head => /v:api_version/heads/:slug(.:format)
  // function(api_version, slug, options)
  headPath: Utils.route([["api_version",true],["slug",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"heads",false],[2,[7,"/",false],[2,[3,"slug",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
headUrl: Utils.route([["api_version",true],["slug",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"heads",false],[2,[7,"/",false],[2,[3,"slug",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]], true),
// heads => /v:api_version/heads(.:format)
  // function(api_version, options)
  headsPath: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"heads",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
headsUrl: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"heads",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]], true),
// html_make => /v:api_version/html/makes/:slug(.:format)
  // function(api_version, slug, options)
  htmlMakePath: Utils.route([["api_version",true],["slug",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"html",false],[2,[7,"/",false],[2,[6,"makes",false],[2,[7,"/",false],[2,[3,"slug",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
htmlMakeUrl: Utils.route([["api_version",true],["slug",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"html",false],[2,[7,"/",false],[2,[6,"makes",false],[2,[7,"/",false],[2,[3,"slug",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]], true),
// identities => /v:api_version/identities(.:format)
  // function(api_version, options)
  identitiesPath: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"identities",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
identitiesUrl: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"identities",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]], true),
// identity => /v:api_version/identities/:id(.:format)
  // function(api_version, id, options)
  identityPath: Utils.route([["api_version",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"identities",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
identityUrl: Utils.route([["api_version",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"identities",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]], true),
// internal_heads => /v:api_version/internal/heads(.:format)
  // function(api_version, options)
  internalHeadsPath: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"internal",false],[2,[7,"/",false],[2,[6,"heads",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
internalHeadsUrl: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"internal",false],[2,[7,"/",false],[2,[6,"heads",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]], true),
// internal_identities => /v:api_version/internal/identities(.:format)
  // function(api_version, options)
  internalIdentitiesPath: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"internal",false],[2,[7,"/",false],[2,[6,"identities",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
internalIdentitiesUrl: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"internal",false],[2,[7,"/",false],[2,[6,"identities",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]], true),
// internal_user_index => /v:api_version/internal/users/:user_id/index(.:format)
  // function(api_version, user_id, options)
  internalUserIndexPath: Utils.route([["api_version",true],["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"internal",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"index",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
internalUserIndexUrl: Utils.route([["api_version",true],["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"internal",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"index",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]], true),
// legacy_view => /v:api_version/legacy/views/:external_key(.:format)
  // function(api_version, external_key, options)
  legacyViewPath: Utils.route([["api_version",true],["external_key",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"legacy",false],[2,[7,"/",false],[2,[6,"views",false],[2,[7,"/",false],[2,[3,"external_key",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
legacyViewUrl: Utils.route([["api_version",true],["external_key",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"legacy",false],[2,[7,"/",false],[2,[6,"views",false],[2,[7,"/",false],[2,[3,"external_key",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]], true),
// legal_notices => /v:api_version/legal_notices(.:format)
  // function(api_version, options)
  legalNoticesPath: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"legal_notices",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
legalNoticesUrl: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"legal_notices",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]], true),
// location_info => /v:api_version/location/info(.:format)
  // function(api_version, options)
  locationInfoPath: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"location",false],[2,[7,"/",false],[2,[6,"info",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
locationInfoUrl: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"location",false],[2,[7,"/",false],[2,[6,"info",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]], true),
// make => /v:api_version/makes/:slug(.:format)
  // function(api_version, slug, options)
  makePath: Utils.route([["api_version",true],["slug",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"makes",false],[2,[7,"/",false],[2,[3,"slug",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
makeUrl: Utils.route([["api_version",true],["slug",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"makes",false],[2,[7,"/",false],[2,[3,"slug",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]], true),
// make_events => /v:api_version/make_events(.:format)
  // function(api_version, options)
  makeEventsPath: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"make_events",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
makeEventsUrl: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"make_events",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]], true),
// make_make_message => /v:api_version/makes/:make_slug/make_message(.:format)
  // function(api_version, make_slug, options)
  makeMakeMessagePath: Utils.route([["api_version",true],["make_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"makes",false],[2,[7,"/",false],[2,[3,"make_slug",false],[2,[7,"/",false],[2,[6,"make_message",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
makeMakeMessageUrl: Utils.route([["api_version",true],["make_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"makes",false],[2,[7,"/",false],[2,[3,"make_slug",false],[2,[7,"/",false],[2,[6,"make_message",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]], true),
// make_static => /v:api_version/makes/:make_slug/static(.:format)
  // function(api_version, make_slug, options)
  makeStaticPath: Utils.route([["api_version",true],["make_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"makes",false],[2,[7,"/",false],[2,[3,"make_slug",false],[2,[7,"/",false],[2,[6,"static",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
makeStaticUrl: Utils.route([["api_version",true],["make_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"makes",false],[2,[7,"/",false],[2,[3,"make_slug",false],[2,[7,"/",false],[2,[6,"static",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]], true),
// makes => /v:api_version/makes(.:format)
  // function(api_version, options)
  makesPath: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"makes",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
makesUrl: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"makes",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]], true),
// mobile_heads => /v:api_version/mobile/heads(.:format)
  // function(api_version, options)
  mobileHeadsPath: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"mobile",false],[2,[7,"/",false],[2,[6,"heads",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
mobileHeadsUrl: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"mobile",false],[2,[7,"/",false],[2,[6,"heads",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]], true),
// mobile_make_completion => /v:api_version/mobile/makes/:make_slug/completion(.:format)
  // function(api_version, make_slug, options)
  mobileMakeCompletionPath: Utils.route([["api_version",true],["make_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"mobile",false],[2,[7,"/",false],[2,[6,"makes",false],[2,[7,"/",false],[2,[3,"make_slug",false],[2,[7,"/",false],[2,[6,"completion",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
mobileMakeCompletionUrl: Utils.route([["api_version",true],["make_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"mobile",false],[2,[7,"/",false],[2,[6,"makes",false],[2,[7,"/",false],[2,[3,"make_slug",false],[2,[7,"/",false],[2,[6,"completion",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]], true),
// mobile_make_make_events => /v:api_version/mobile/makes/:make_slug/make_events(.:format)
  // function(api_version, make_slug, options)
  mobileMakeMakeEventsPath: Utils.route([["api_version",true],["make_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"mobile",false],[2,[7,"/",false],[2,[6,"makes",false],[2,[7,"/",false],[2,[3,"make_slug",false],[2,[7,"/",false],[2,[6,"make_events",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
mobileMakeMakeEventsUrl: Utils.route([["api_version",true],["make_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"mobile",false],[2,[7,"/",false],[2,[6,"makes",false],[2,[7,"/",false],[2,[3,"make_slug",false],[2,[7,"/",false],[2,[6,"make_events",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]], true),
// mobile_make_render => /v:api_version/mobile/makes/:make_slug/render(.:format)
  // function(api_version, make_slug, options)
  mobileMakeRenderPath: Utils.route([["api_version",true],["make_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"mobile",false],[2,[7,"/",false],[2,[6,"makes",false],[2,[7,"/",false],[2,[3,"make_slug",false],[2,[7,"/",false],[2,[6,"render",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
mobileMakeRenderUrl: Utils.route([["api_version",true],["make_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"mobile",false],[2,[7,"/",false],[2,[6,"makes",false],[2,[7,"/",false],[2,[3,"make_slug",false],[2,[7,"/",false],[2,[6,"render",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]], true),
// mobile_makes => /v:api_version/mobile/makes(.:format)
  // function(api_version, options)
  mobileMakesPath: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"mobile",false],[2,[7,"/",false],[2,[6,"makes",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
mobileMakesUrl: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"mobile",false],[2,[7,"/",false],[2,[6,"makes",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]], true),
// mobile_subscription => /v:api_version/mobile/subscriptions/:id(.:format)
  // function(api_version, id, options)
  mobileSubscriptionPath: Utils.route([["api_version",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"mobile",false],[2,[7,"/",false],[2,[6,"subscriptions",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
mobileSubscriptionUrl: Utils.route([["api_version",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"mobile",false],[2,[7,"/",false],[2,[6,"subscriptions",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]], true),
// mobile_subscriptions => /v:api_version/mobile/subscriptions(.:format)
  // function(api_version, options)
  mobileSubscriptionsPath: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"mobile",false],[2,[7,"/",false],[2,[6,"subscriptions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
mobileSubscriptionsUrl: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"mobile",false],[2,[7,"/",false],[2,[6,"subscriptions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]], true),
// native_oauth_authorization => /v:api_version/oauth/authorize/native(.:format)
  // function(api_version, options)
  nativeOauthAuthorizationPath: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"oauth",false],[2,[7,"/",false],[2,[6,"authorize",false],[2,[7,"/",false],[2,[6,"native",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
nativeOauthAuthorizationUrl: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"oauth",false],[2,[7,"/",false],[2,[6,"authorize",false],[2,[7,"/",false],[2,[6,"native",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]], true),
// new_oauth_application => /v:api_version/oauth/applications/new(.:format)
  // function(api_version, options)
  newOauthApplicationPath: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"oauth",false],[2,[7,"/",false],[2,[6,"applications",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
newOauthApplicationUrl: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"oauth",false],[2,[7,"/",false],[2,[6,"applications",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]], true),
// new_person => /v:api_version/people/new(.:format)
  // function(api_version, options)
  newPersonPath: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
newPersonUrl: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]], true),
// oauth_application => /v:api_version/oauth/applications/:id(.:format)
  // function(api_version, id, options)
  oauthApplicationPath: Utils.route([["api_version",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"oauth",false],[2,[7,"/",false],[2,[6,"applications",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
oauthApplicationUrl: Utils.route([["api_version",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"oauth",false],[2,[7,"/",false],[2,[6,"applications",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]], true),
// oauth_applications => /v:api_version/oauth/applications(.:format)
  // function(api_version, options)
  oauthApplicationsPath: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"oauth",false],[2,[7,"/",false],[2,[6,"applications",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
oauthApplicationsUrl: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"oauth",false],[2,[7,"/",false],[2,[6,"applications",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]], true),
// oauth_authorization => /v:api_version/oauth/authorize(.:format)
  // function(api_version, options)
  oauthAuthorizationPath: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"oauth",false],[2,[7,"/",false],[2,[6,"authorize",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
oauthAuthorizationUrl: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"oauth",false],[2,[7,"/",false],[2,[6,"authorize",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]], true),
// oauth_authorized_application => /v:api_version/oauth/authorized_applications/:id(.:format)
  // function(api_version, id, options)
  oauthAuthorizedApplicationPath: Utils.route([["api_version",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"oauth",false],[2,[7,"/",false],[2,[6,"authorized_applications",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
oauthAuthorizedApplicationUrl: Utils.route([["api_version",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"oauth",false],[2,[7,"/",false],[2,[6,"authorized_applications",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]], true),
// oauth_authorized_applications => /v:api_version/oauth/authorized_applications(.:format)
  // function(api_version, options)
  oauthAuthorizedApplicationsPath: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"oauth",false],[2,[7,"/",false],[2,[6,"authorized_applications",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
oauthAuthorizedApplicationsUrl: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"oauth",false],[2,[7,"/",false],[2,[6,"authorized_applications",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]], true),
// oauth_introspect => /v:api_version/oauth/introspect(.:format)
  // function(api_version, options)
  oauthIntrospectPath: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"oauth",false],[2,[7,"/",false],[2,[6,"introspect",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
oauthIntrospectUrl: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"oauth",false],[2,[7,"/",false],[2,[6,"introspect",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]], true),
// oauth_revoke => /v:api_version/oauth/revoke(.:format)
  // function(api_version, options)
  oauthRevokePath: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"oauth",false],[2,[7,"/",false],[2,[6,"revoke",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
oauthRevokeUrl: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"oauth",false],[2,[7,"/",false],[2,[6,"revoke",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]], true),
// oauth_token => /v:api_version/oauth/token(.:format)
  // function(api_version, options)
  oauthTokenPath: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"oauth",false],[2,[7,"/",false],[2,[6,"token",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
oauthTokenUrl: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"oauth",false],[2,[7,"/",false],[2,[6,"token",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]], true),
// oauth_token_info => /v:api_version/oauth/token/info(.:format)
  // function(api_version, options)
  oauthTokenInfoPath: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"oauth",false],[2,[7,"/",false],[2,[6,"token",false],[2,[7,"/",false],[2,[6,"info",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
oauthTokenInfoUrl: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"oauth",false],[2,[7,"/",false],[2,[6,"token",false],[2,[7,"/",false],[2,[6,"info",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]], true),
// offer => /v:api_version/offers/:slug(.:format)
  // function(api_version, slug, options)
  offerPath: Utils.route([["api_version",true],["slug",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"offers",false],[2,[7,"/",false],[2,[3,"slug",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
offerUrl: Utils.route([["api_version",true],["slug",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"offers",false],[2,[7,"/",false],[2,[3,"slug",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]], true),
// offer_groups => /v:api_version/offer_groups(.:format)
  // function(api_version, options)
  offerGroupsPath: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"offer_groups",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
offerGroupsUrl: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"offer_groups",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]], true),
// offers => /v:api_version/offers(.:format)
  // function(api_version, options)
  offersPath: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"offers",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
offersUrl: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"offers",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]], true),
// paypal_express_token => /v:api_version/paypal/express_tokens/:id(.:format)
  // function(api_version, id, options)
  paypalExpressTokenPath: Utils.route([["api_version",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"paypal",false],[2,[7,"/",false],[2,[6,"express_tokens",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
paypalExpressTokenUrl: Utils.route([["api_version",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"paypal",false],[2,[7,"/",false],[2,[6,"express_tokens",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]], true),
// paypal_express_tokens => /v:api_version/paypal/express_tokens(.:format)
  // function(api_version, options)
  paypalExpressTokensPath: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"paypal",false],[2,[7,"/",false],[2,[6,"express_tokens",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
paypalExpressTokensUrl: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"paypal",false],[2,[7,"/",false],[2,[6,"express_tokens",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]], true),
// paypal_ipns => /v:api_version/paypal_ipns(.:format)
  // function(api_version, options)
  paypalIpnsPath: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"paypal_ipns",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
paypalIpnsUrl: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"paypal_ipns",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]], true),
// people => /v:api_version/people(.:format)
  // function(api_version, options)
  peoplePath: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"people",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
peopleUrl: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"people",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]], true),
// person => /v:api_version/people/:slug(.:format)
  // function(api_version, slug, options)
  personPath: Utils.route([["api_version",true],["slug",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"slug",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
personUrl: Utils.route([["api_version",true],["slug",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"slug",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]], true),
// pills => /v:api_version/pills(.:format)
  // function(api_version, options)
  pillsPath: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"pills",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
pillsUrl: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"pills",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]], true),
// presign => /v:api_version/presign(.:format)
  // function(api_version, options)
  presignPath: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"presign",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
presignUrl: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"presign",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]], true),
// presign_head => /v:api_version/presign/head(.:format)
  // function(api_version, options)
  presignHeadPath: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"presign",false],[2,[7,"/",false],[2,[6,"head",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
presignHeadUrl: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"presign",false],[2,[7,"/",false],[2,[6,"head",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]], true),
// privacy_policies => /v:api_version/privacy_policies(.:format)
  // function(api_version, options)
  privacyPoliciesPath: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"privacy_policies",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
privacyPoliciesUrl: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"privacy_policies",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]], true),
// recipient => /v:api_version/recipients/:slug(.:format)
  // function(api_version, slug, options)
  recipientPath: Utils.route([["api_version",true],["slug",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"recipients",false],[2,[7,"/",false],[2,[3,"slug",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
recipientUrl: Utils.route([["api_version",true],["slug",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"recipients",false],[2,[7,"/",false],[2,[3,"slug",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]], true),
// recipient_views => /v:api_version/recipient_views(.:format)
  // function(api_version, options)
  recipientViewsPath: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"recipient_views",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
recipientViewsUrl: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"recipient_views",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]], true),
// recipients => /v:api_version/recipients(.:format)
  // function(api_version, options)
  recipientsPath: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"recipients",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
recipientsUrl: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"recipients",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]], true),
// recurly_billing_info => /v:api_version/recurly_billing_info(.:format)
  // function(api_version, options)
  recurlyBillingInfoPath: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"recurly_billing_info",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
recurlyBillingInfoUrl: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"recurly_billing_info",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]], true),
// recurly_notifications => /v:api_version/recurly_notifications(.:format)
  // function(api_version, options)
  recurlyNotificationsPath: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"recurly_notifications",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
recurlyNotificationsUrl: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"recurly_notifications",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]], true),
// reset_password => /v:api_version/reset_password(.:format)
  // function(api_version, options)
  resetPasswordPath: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"reset_password",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
resetPasswordUrl: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"reset_password",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]], true),
// root => /
  // function(options)
  rootPath: Utils.route([], {}, [7,"/",false]),
rootUrl: Utils.route([], {}, [7,"/",false], true),
// search_index => /v:api_version/search(.:format)
  // function(api_version, options)
  searchIndexPath: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"search",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
searchIndexUrl: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"search",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]], true),
// search_links => /v:api_version/search_links(.:format)
  // function(api_version, options)
  searchLinksPath: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"search_links",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
searchLinksUrl: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"search_links",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]], true),
// shares => /v:api_version/shares(.:format)
  // function(api_version, options)
  sharesPath: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"shares",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
sharesUrl: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"shares",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]], true),
// sign_index => /v:api_version/sign(.:format)
  // function(api_version, options)
  signIndexPath: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"sign",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
signIndexUrl: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"sign",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]], true),
// status => /v:api_version/health/status(.:format)
  // function(api_version, options)
  statusPath: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"health",false],[2,[7,"/",false],[2,[6,"status",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
statusUrl: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"health",false],[2,[7,"/",false],[2,[6,"status",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]], true),
// subscription_cancellation => /v:api_version/subscriptions/:subscription_slug/cancellation(.:format)
  // function(api_version, subscription_slug, options)
  subscriptionCancellationPath: Utils.route([["api_version",true],["subscription_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"subscriptions",false],[2,[7,"/",false],[2,[3,"subscription_slug",false],[2,[7,"/",false],[2,[6,"cancellation",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
subscriptionCancellationUrl: Utils.route([["api_version",true],["subscription_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"subscriptions",false],[2,[7,"/",false],[2,[3,"subscription_slug",false],[2,[7,"/",false],[2,[6,"cancellation",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]], true),
// subscription_pause => /v:api_version/subscriptions/:subscription_slug/pause(.:format)
  // function(api_version, subscription_slug, options)
  subscriptionPausePath: Utils.route([["api_version",true],["subscription_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"subscriptions",false],[2,[7,"/",false],[2,[3,"subscription_slug",false],[2,[7,"/",false],[2,[6,"pause",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
subscriptionPauseUrl: Utils.route([["api_version",true],["subscription_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"subscriptions",false],[2,[7,"/",false],[2,[3,"subscription_slug",false],[2,[7,"/",false],[2,[6,"pause",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]], true),
// subscription_reactivation => /v:api_version/subscriptions/:subscription_slug/reactivation(.:format)
  // function(api_version, subscription_slug, options)
  subscriptionReactivationPath: Utils.route([["api_version",true],["subscription_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"subscriptions",false],[2,[7,"/",false],[2,[3,"subscription_slug",false],[2,[7,"/",false],[2,[6,"reactivation",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
subscriptionReactivationUrl: Utils.route([["api_version",true],["subscription_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"subscriptions",false],[2,[7,"/",false],[2,[3,"subscription_slug",false],[2,[7,"/",false],[2,[6,"reactivation",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]], true),
// subscriptions => /v:api_version/subscriptions(.:format)
  // function(api_version, options)
  subscriptionsPath: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"subscriptions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
subscriptionsUrl: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"subscriptions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]], true),
// template_group => /v:api_version/template_groups/:slug(.:format)
  // function(api_version, slug, options)
  templateGroupPath: Utils.route([["api_version",true],["slug",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"template_groups",false],[2,[7,"/",false],[2,[3,"slug",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
templateGroupUrl: Utils.route([["api_version",true],["slug",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"template_groups",false],[2,[7,"/",false],[2,[3,"slug",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]], true),
// terms_of_sales => /v:api_version/terms_of_sales(.:format)
  // function(api_version, options)
  termsOfSalesPath: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"terms_of_sales",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
termsOfSalesUrl: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"terms_of_sales",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]], true),
// terms_of_services => /v:api_version/terms_of_services(.:format)
  // function(api_version, options)
  termsOfServicesPath: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"terms_of_services",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
termsOfServicesUrl: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"terms_of_services",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]], true),
// transaction_record => /v:api_version/transaction_records/:slug(.:format)
  // function(api_version, slug, options)
  transactionRecordPath: Utils.route([["api_version",true],["slug",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"transaction_records",false],[2,[7,"/",false],[2,[3,"slug",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
transactionRecordUrl: Utils.route([["api_version",true],["slug",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"transaction_records",false],[2,[7,"/",false],[2,[3,"slug",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]], true),
// transaction_records => /v:api_version/transaction_records(.:format)
  // function(api_version, options)
  transactionRecordsPath: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"transaction_records",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
transactionRecordsUrl: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"transaction_records",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]], true),
// user => /v:api_version/user(.:format)
  // function(api_version, options)
  userPath: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"user",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
userUrl: Utils.route([["api_version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v",false],[2,[3,"api_version",false],[2,[7,"/",false],[2,[6,"user",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]], true)}
;
      routes.configure = function(config) {
        return Utils.configure(config);
      };
      routes.config = function() {
        return Utils.config();
      };
      Object.defineProperty(routes, 'defaults', {
        get: function() {
          throw new Error("Routes" + ".defaults is removed. Use " + "Routes" + ".configure() instead.");
        },
        set: function(value) {}
      });
      routes.default_serializer = function(object, prefix) {
        return Utils.default_serializer(object, prefix);
      };
      return Utils.namespace(root, "Routes", routes);
    }
  };

  result = Utils.make();

  if (typeof define === "function" && define.amd) {
    define([], function() {
      return result;
    });
  }

  return result;

}).call(this);
