import R from "ramda";
import { createSlice, current } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";

import FaceRepository from "repositories/FaceRepository";
import PersonFacePresenter from "presenters/PersonFacePresenter";
import { deserialize } from "utils/storeUtils";

const initialState = {
  faces: [],
  meta: null,
};

const personFacesSlice = createSlice({
  name: "personFaces",
  initialState,
  reducers: {
    loadPersonFacesSuccess(state, { payload }) {
      state.faces = deserialize(payload);
      state.meta = R.propOr(null, "meta", payload);
    },
    resetPersonFaces(state) {
      state.faces = [];
      state.meta = null;
    },
    removePersonFaces(state, { payload }) {
      const filterData = state.faces.filter((item) => PersonFacePresenter.id(item) !== payload);
      state.faces = filterData;
      state.meta = filterData.length;
    },
    updatePersonFaces(state, { payload }) {
      const faces = [...current(state.faces)];
      const currenDefaultFaceIndex = faces.findIndex(PersonFacePresenter.default);
      const newDefaultFaceIndex = faces.findIndex((face) => face.id === payload);

      faces[currenDefaultFaceIndex] = { ...faces[currenDefaultFaceIndex], default: false };
      faces[newDefaultFaceIndex] = { ...faces[newDefaultFaceIndex], default: true };

      state.faces = faces;
    },
  },
});

export const { actions } = personFacesSlice;
export default personFacesSlice.reducer;

export const usePersonFacesActions = () => {
  const dispatch = useDispatch();

  const loadPersonFaces = (params) => {
    return FaceRepository.index(params).then(({ data }) => {
      dispatch(personFacesSlice.actions.loadPersonFacesSuccess(data));
      return data ? deserialize(data) : null;
    });
  };

  const resetPersonFaces = () => {
    dispatch(personFacesSlice.actions.resetPersonFaces());
  };

  const deletePersonFace = (personFaceId) => {
    dispatch(personFacesSlice.actions.removePersonFaces(personFaceId));
  };

  const removePersonFace = (personFaceId, params) => {
    return FaceRepository.partialUpdate(personFaceId, params).then(async () => {
      deletePersonFace(personFaceId);
    });
  };

  const updatePersonFaces = (personFaceId) => {
    dispatch(personFacesSlice.actions.updatePersonFaces(personFaceId));
  };

  return {
    loadPersonFaces,
    resetPersonFaces,
    removePersonFace,
    deletePersonFace,
    updatePersonFaces,
  };
};
