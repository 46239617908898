import { useSelector } from "react-redux";

import { useInviteActions } from "store/InvitesSlice";
import { jjLogger } from "utils/logUtils";
import { trackInviteEmailShare } from "tracking/google/analytics";
import { inviteAttributes, buildGuestAttributes, updateSettingsAttributes } from "utils/inviteUtils";
import TemplateGroupPresenter from "presenters/TemplateGroupPresenter";
import InvitePresenter from "presenters/InvitePresenter";
import UserPresenter from "presenters/UserPresenter";
import useAlgoliaEvents from "hooks/useAlgoliaEvents";
import useUsers from "hooks/useUsers";
import useRouter from "hooks/useRouter";
import useLastEcard from "hooks/useLastEcard";
import { trackInviteEvent, INVITE_SHARE_LINK, INVITE_SHARE_EMAIL } from "utils/inviteEventUtils";

const useInvites = () => {
  const { currentUser } = useUsers();
  const { setLastEcard } = useLastEcard();
  const {
    createInvite,
    loadInvite,
    loadInvites,
    loadMoreInvites,
    updateInvite,
    createGuest,
    updateGuest: _updateGuest,
    deleteGuest,
    loadGuestInvite,
    deleteInvite,
    setShareType,
    setShowShareConfirmation,
    resetShareType,
  } = useInviteActions();
  const { trackEventAfterSearchAndShare } = useAlgoliaEvents();
  const { pushLogin, push404 } = useRouter();

  const { invite, invites, meta, shareType, showShareConfirmation } = useSelector((state) => state.InvitesSlice);

  const saveInvite = ({ makeThumb, shareThumb, roles, templateId, templateGroup, themeId }) => {
    const templateGroupId = TemplateGroupPresenter.id(templateGroup);
    const attrs = inviteAttributes({ makeThumb, shareThumb, roles, templateId, templateGroupId, themeId });

    return createInvite(attrs).then((inv) => {
      setLastEcard(inv, templateGroup);
      return inv;
    });
  };

  const saveGuest = (values, role) => {
    const body = buildGuestAttributes(values);
    return createGuest(invite, body, role);
  };

  const updateGuest = (guestId, values) => {
    const body = buildGuestAttributes(values, guestId);
    return _updateGuest(invite, guestId, body);
  };

  const updateGuestAsGuest = (guestId, values) => {
    const body = buildGuestAttributes(values, guestId);
    return _updateGuest(invite, guestId, body, "guest");
  };

  const removeGuest = (guestId) => {
    return deleteGuest(invite, guestId);
  };

  const updateInviteAsPublic = async (inviteId) => {
    const params = updateSettingsAttributes(inviteId, { public: true });
    const wasLive = InvitePresenter.isLive(invite);

    if (!wasLive) params.data.attributes.status = "live";

    try {
      await updateInvite(inviteId, params);
      if (!wasLive) trackInviteEvent(invite, INVITE_SHARE_LINK);
    } catch (error) {
      jjLogger.logError(`useInvites.js updateInviteAsPublic: ${error}`);
      throw error;
    }
  };

  const sendInvites = (inv, templateGroup) => {
    const wasDraft = InvitePresenter.isDraft(inv);
    return updateInvite(inv.id, { data: { type: "invites", id: inv.id, attributes: { status: "live" } } })
      .then(() => {
        if (wasDraft) trackEventAfterSearchAndShare(inv);
        trackInviteEmailShare(inv, templateGroup);
        trackInviteEvent(inv, INVITE_SHARE_EMAIL);
      })
      .catch((error) => {
        jjLogger.logError(`useInvites.js sendInvites: ${error}`);
        throw error;
      });
  };

  const loadOwnerInvite = (slug) => {
    if (UserPresenter.noUser(currentUser)) return Promise.resolve(pushLogin());

    return loadInvite(slug)
      .then((inv) => {
        const userId = parseInt(UserPresenter.id(currentUser), 10);
        if (userId !== InvitePresenter.userId(inv)) {
          jjLogger.log(`useInvites.js: loadOwnerInvite(): invite slug ${slug} not owned by current user`);
          return push404();
        }

        return inv;
      })
      .catch((error) => {
        jjLogger.log(`useInvites.js: loadOwnerInvite(): invite slug ${slug}, ${error}`);
        return push404();
      });
  };

  return {
    invite,
    invites,
    saveInvite,
    deleteInvite,
    updateInvite,
    loadInvite,
    loadInvites,
    loadMoreInvites,
    loadOwnerInvite,
    saveGuest,
    updateGuest,
    updateGuestAsGuest,
    removeGuest,
    loadGuestInvite,
    meta,
    updateInviteAsPublic,
    sendInvites,
    shareType,
    setShareType,
    showShareConfirmation,
    setShowShareConfirmation,
    resetShareType,
  };
};

export default useInvites;
