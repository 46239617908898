import useShare from "react-use-web-share";

import { jjLogger } from "utils/logUtils";

import useNotifications from "./useNotifications";

const useWebShare = (callback) => {
  const { addSuccessNotification, addErrorNotification } = useNotifications();

  const onSuccess = () => {
    callback();
    addSuccessNotification("The link was successfully shared");
  };

  const onError = (error) => {
    if (error.name !== "AbortError") {
      addErrorNotification(error.message);
    }
  };

  const shareImageOnMobile = async (url, fileName, text) => {
    try {
      const imageToShare = new File([await fetch(url).then((res) => res.blob())], fileName, {
        type: "image/jpeg",
      });

      await navigator.share({ files: [imageToShare], text });
    } catch (error) {
      jjLogger.logError(`useWebShare.js - shareImageOnMobile() - error: ${JSON.stringify(error)}`);
    }
  };

  const { loading, isSupported, share } = useShare(onSuccess, onError);

  return {
    isWebShareSupported: isSupported,
    share,
    isWebShareProcessing: loading,
    shareImageOnMobile,
  };
};

export default useWebShare;
