import { useCancellationFeedbackActions } from "store/CancellationFeedbackSlice";

const useCancellationFeedback = () => {
  const { createCancellationFeedback } = useCancellationFeedbackActions();

  return {
    createCancellationFeedback,
  };
};

export default useCancellationFeedback;
