import { useSelector } from "react-redux";
import aa from "search-insights";

import { useAlgoliaActions } from "store/AlgoliaSlice";
import { SORT_ITEMS } from "enums";
import { jjLogger } from "utils/logUtils";
import UserPresenter from "presenters/UserPresenter";

import useUsers from "./useUsers";

const useAlgolia = () => {
  const { currentUser } = useUsers();
  const {
    addOneFilter,
    removeOneFilter,
    clearFilter,
    clearAllFilters,
    setIsClearAllClicked,
    setItemToRemove,
    setSortFilterSelected,
  } = useAlgoliaActions();
  const { castNumFilters, templateGroupTypeFilters, isClearAllClicked, itemToRemove, sortFilterSelected } = useSelector(
    (state) => state.AlgoliaSlice,
  );

  const isDefaultSort = sortFilterSelected?.label === SORT_ITEMS.bestMatch.label;

  const getUserToken = (sendTokenOnlyForPaidUser) => {
    let userToken;

    if (sendTokenOnlyForPaidUser) {
      if (UserPresenter.isPaid(currentUser)) userToken = UserPresenter.id(currentUser);
    } else {
      userToken = UserPresenter.id(currentUser);
      if (!userToken) {
        aa("getUserToken", null, (err, newUserToken) => {
          if (err) {
            jjLogger.log(`useAlgoliaEvents.js - getUserToken() - Error while generating newUserToken - ${err}`);
            return;
          }
          userToken = newUserToken;
        });
      }
    }
    return userToken;
  };

  return {
    addOneFilter,
    removeOneFilter,
    clearFilter,
    clearAllFilters,
    castNumFilters,
    templateGroupTypeFilters,
    isClearAllClicked,
    setIsClearAllClicked,
    itemToRemove,
    setItemToRemove,
    setSortFilterSelected,
    sortFilterSelected,
    isDefaultSort,
    getUserToken,
  };
};

export default useAlgolia;
