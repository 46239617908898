import { createSlice } from "@reduxjs/toolkit";

import CancellationFeedbackRepository from "repositories/CancellationFeedbackRepository";

const cancellationFeedbackSlice = createSlice({
  name: "cancellationFeedback",
  initialState: {},
});

export const { actions } = cancellationFeedbackSlice;
export default cancellationFeedbackSlice.reducer;

export const useCancellationFeedbackActions = () => {
  const createCancellationFeedback = (params) => {
    return CancellationFeedbackRepository.create(params).then(() => {
      return Promise.resolve(true);
    });
  };

  return {
    createCancellationFeedback,
  };
};
