import React, { createContext, useState, useMemo } from "react";

const initialState = {
  entryPoint: "",
  setEntryPoint: () => {},
};
export const FaceOrgContext = createContext(initialState);

export const FaceOrgProvider = ({ children }) => {
  const [faceOrgValue, setFaceOrgValue] = useState("");
  const value = useMemo(() => ({ entryPoint: faceOrgValue, setEntryPoint: setFaceOrgValue }), [faceOrgValue]);
  return <FaceOrgContext.Provider value={value}>{children}</FaceOrgContext.Provider>;
};
