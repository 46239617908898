import { FETCH_STATUSES } from "enums";
import { serializeFunction } from "utils/storeUtils";
import { useLoadingActions } from "store/LoadingSlice";
import useNotifications from "hooks/useNotifications";
import useGlobalLoading from "hooks/useGlobalLoading";

const useAssetUpload = () => {
  const { setLabelFunc } = useLoadingActions();
  const { addErrorNotification } = useNotifications();
  const { setStatus: setLoaderStatus } = useGlobalLoading();

  const errorMsgFunc = serializeFunction(
    () => "Uh Oh! We encountered an issue casting your head. Please try again later.",
  );

  const uploadThumbAssets = (requests) => {
    return Promise.all(requests)
      .then((results) => results.map(({ fields: { key } }) => key))
      .catch((error) => {
        setLoaderStatus(FETCH_STATUSES.pending);
        setLabelFunc(errorMsgFunc);

        throw new Error(`Failed to upload make thumb assets: ${error?.message}`);
      });
  };

  const uploadAsset = (request) => {
    return request.then((results) => results.fields.key);
  };

  const uploadHeadAsset = (request) => {
    return uploadAsset(request).catch((error) => {
      addErrorNotification("Uh Oh! We encountered an issue creating your head. Please try again later.");

      throw new Error(`Failed to upload head asset: ${error?.message}`);
    });
  };

  const uploadSourcePhotoAsset = (request) => {
    return uploadAsset(request).catch((error) => {
      addErrorNotification("Uh Oh! We encountered an issue creating your photo. Please try again later.");

      throw new Error(`Failed to upload source photo asset: ${error?.message}`);
    });
  };

  return { uploadThumbAssets, uploadHeadAsset, uploadSourcePhotoAsset };
};

export default useAssetUpload;
