const indexName = `TemplateGroup_${process.env.DEPLOY_ENV}`;

const SORT_ITEMS = {
  bestMatch: {
    label: "Best Match",
    value: indexName,
  },
  trending: {
    label: "Trending",
    value: `${indexName}_past_week_shares_descending`,
  },
  newOld: {
    label: "New - Old",
    value: `${indexName}_created_at_i_descending`,
  },
  oldNew: {
    label: "Old - New",
    value: `${indexName}_created_at_i_ascending`,
  },
  allTimeFaves: {
    label: "All time faves",
    value: `${indexName}_total_shares_descending`,
  },
};

Object.freeze(SORT_ITEMS);

export { SORT_ITEMS };
