/* eslint no-underscore-dangle: ["error", { "allow": ["__queryID", "__position"] }] */
import aa from "search-insights";

import MakePresenter from "presenters/MakePresenter";
import TemplateGroupPresenter from "presenters/TemplateGroupPresenter";
import EcardPresenter from "presenters/EcardPresenter";

import useSettings from "./useSettings";
import useAlgolia from "./useAlgolia";
import useAlgoliaTracker from "./useAlgoliaTracker";

const useAlgoliaEvents = () => {
  const {
    settings: { algolia },
  } = useSettings();
  const { getUserToken } = useAlgolia();
  const { setHitSelected, clearHitSelected, hitSelected } = useAlgoliaTracker();

  const trackEventAfterSearch = (item) => {
    aa("clickedObjectIDsAfterSearch", {
      userToken: getUserToken(),
      eventName: "click_on_after_search",
      index: algolia.indexName,
      queryID: item.__queryID,
      objectIDs: [item.objectID],
      positions: [item.__position],
    });

    setHitSelected(item);
  };

  const trackEventAfterSearchAndPurchase = (item) => {
    aa("convertedObjectIDsAfterSearch", {
      userToken: getUserToken(),
      eventName: "purchase_after_search",
      index: algolia.indexName,
      queryID: item.__queryID,
      objectIDs: [item.objectID],
    });
  };

  const trackEventAfterSearchAndShare = (ecard) => {
    const templateGroupId = EcardPresenter.templateGroupId(ecard);

    if (templateGroupId === hitSelected?.objectID) {
      aa("convertedObjectIDsAfterSearch", {
        userToken: getUserToken(),
        eventName: "share_after_search",
        index: algolia.indexName,
        queryID: hitSelected.__queryID,
        objectIDs: [hitSelected.objectID],
      });

      clearHitSelected();
    }
  };

  const trackEventAfterSearchAndMakeIsPreview = (ecard, templateGroup) => {
    const isMake = EcardPresenter.isMake(ecard);
    if (isMake && !MakePresenter.isDraft(ecard)) return;

    if (TemplateGroupPresenter.id(templateGroup) === hitSelected?.objectID) {
      aa("viewedObjectIDs", {
        userToken: getUserToken(),
        eventName: "make_preview_after_search",
        index: algolia.indexName,
        objectIDs: [hitSelected.objectID],
        queryID: hitSelected.__queryID,
      });
    }
  };

  return {
    trackEventAfterSearch,
    trackEventAfterSearchAndPurchase,
    trackEventAfterSearchAndShare,
    trackEventAfterSearchAndMakeIsPreview,
  };
};

export default useAlgoliaEvents;
