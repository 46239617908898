import dayjs from "dayjs";

let startTime = null;

const useTimer = () => {
  const startTimer = () => {
    startTime = new Date();
  };

  const endTimer = () => {
    if (!startTime) return 0;

    const timeDiff = dayjs(new Date()).diff(startTime);
    startTime = null;

    return timeDiff;
  };

  return {
    startTimer,
    endTimer,
  };
};

export default useTimer;
