import { createSlice } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";

const initialState = {
  visible: false,
};

const joinModalSlice = createSlice({
  name: "joinModal",
  initialState,
  reducers: {
    open(state) {
      state.visible = true;
    },
    close(state) {
      state.visible = false;
    },
  },
});

export const { actions } = joinModalSlice;
export default joinModalSlice.reducer;

export const useJoinModalActions = () => {
  const dispatch = useDispatch();

  const openJoinModal = () => {
    dispatch(actions.open());
  };

  const closeJoinModal = () => {
    dispatch(actions.close());
  };

  return {
    openJoinModal,
    closeJoinModal,
  };
};
