import PropTypes from "prop-types";

import Presenter from "utils/PropTypesPresenter";

export default new Presenter(
  {
    name: PropTypes.string,
    contentType: PropTypes.string,
    linkText: PropTypes.string,
    linkTo: PropTypes.string,
    image: PropTypes.string,
    childrenMenu: PropTypes.arrayOf(PropTypes.shape()),
  },
  {
    isExternalLinkTo(m) {
      const externalLinkRegex = /^(https|http)+.*/;
      return externalLinkRegex.test(m.linkTo);
    },
    createComponentId(m) {
      return m.name.replace(/\s/g, "-").toLowerCase();
    },
  },
);
