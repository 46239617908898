import axios from "axios";
import qs from "qs";
import { v4 } from "uuid";
import XMLParser from "fast-xml-parser";

import { apiRoutes } from "routes";
import { decamelize } from "utils/keysConverter";
import FetchHelpers from "utils/FetchHelpers";
import { getAuthFromLocalStorage } from "utils/authUtils";
import { jjLogger } from "utils/logUtils";

const instance = axios.create();

const getToken = () => {
  const auth = getAuthFromLocalStorage();
  return auth.accessToken;
};

const paramsSerializer = (parameters) => qs.stringify(parameters, { encode: false, arrayFormat: "brackets" });

const baseSign = (url, params) => {
  const id = v4();
  return instance.get(url, {
    params: decamelize({ ...params, id }),
    paramsSerializer,
  });
};

const presign = (params) => {
  const url = apiRoutes.presignPath(1);
  return baseSign(url, params);
};

const sign = (params) => {
  const url = apiRoutes.signIndexPath(1);
  return baseSign(url, params);
};

const uploadToS3 = (bucket, fields, blob) => {
  const formData = new FormData();
  Object.keys(fields).forEach((k) => formData.append(k, fields[k]));
  formData.append("file", blob);

  return FetchHelpers.postMultipartFormData(bucket, formData)
    .then((response) => {
      const parsedResponse = XMLParser.parse(response);
      return { response: parsedResponse, fields };
    })
    .catch((error) => {
      jjLogger.log(
        "AWSRepository.js uploadToS3: tried uploading to S3 bucket: " +
          `${bucket}, blob: { size: ${blob?.size}, type: "${blob?.type}" }, ` +
          `fields: { key: ${fields?.key}, success_action_status: ${fields?.success_action_status} }`,
      );

      throw error;
    });
};

const signedUploadToS3 = (signParams, blob) =>
  sign(signParams).then((response) => {
    const {
      data: { bucket, ...fields },
    } = response;
    const endpoint = `https://${bucket}.s3.amazonaws.com`;
    return uploadToS3(endpoint, fields, blob);
  });

const presignedUploadToS3 = (presignParams, blob) =>
  presign(presignParams).then((response) => {
    const {
      data: { endpoint, ...fields },
    } = response;
    return uploadToS3(endpoint, fields, blob);
  });

const presignedUploadToS3withToken = (presignParams, blob) => {
  const url = apiRoutes.presignPath(1);
  const params = decamelize(presignParams);
  const headers = { Authorization: `Bearer ${getToken()}` };

  return instance.get(url, { params, headers, paramsSerializer }).then((response) => {
    const {
      data: { endpoint, ...fields },
    } = response;
    return uploadToS3(endpoint, fields, blob);
  });
};

export default {
  signedUploadToS3,
  presignedUploadToS3,
  presignedUploadToS3withToken,
};
