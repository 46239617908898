import qs from "qs";
import { createBrowserHistory } from "history";

const paramsToPreserve = ["utm_source", "utm_medium", "utm_campaign", "clickId", "includeDraft"];

const preserveQueryParameters = (history, preserve, location) => {
  const currentQuery = qs.parse(history.location.search, { ignoreQueryPrefix: true });
  let params = {};
  if (!currentQuery) return location;
  if (location.search) {
    params = qs.parse(location.search, { ignoreQueryPrefix: true });
  }

  const preservedQuery = preserve.reduce(
    (acc, preserved) => ({
      ...acc,
      ...(currentQuery[preserved] && { [preserved]: currentQuery[preserved] }),
    }),
    params,
  );
  const search = qs.stringify(preservedQuery);
  if (search) return { ...location, search };
  return location;
};

function createLocationDescriptorObject(location, state) {
  return typeof location === "string" ? { pathname: location, state } : location;
}

function createPreserveQueryHistory(createHistory, queryParameters) {
  const history = createHistory();
  const { push: oldPush, replace: oldReplace } = history;
  history.push = (path, state) =>
    oldPush.apply(history, [
      preserveQueryParameters(history, queryParameters, createLocationDescriptorObject(path, state)),
    ]);
  history.replace = (path, state) =>
    oldReplace.apply(history, [
      preserveQueryParameters(history, queryParameters, createLocationDescriptorObject(path, state)),
    ]);
  return history;
}

export default createPreserveQueryHistory(createBrowserHistory, paramsToPreserve);
