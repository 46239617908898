export default {
  MuiSlider: {
    styleOverrides: {
      thumb: {
        width: 28,
        height: 28,
      },
    },
  },
};
