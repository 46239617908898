import palette from "layouts/ThemeProvider/palette";

export default {
  MuiDrawer: {
    styleOverrides: {
      paper: {
        borderRadius: 0,
        background: palette.drawer.main,
      },
    },
  },
};
