import { useEffect } from "react";

import localStorage, { CURRENT_MAKE } from "utils/localStorage";

import useMakes from "./useMakes";

const usePersistMake = () => {
  const { make, setCurrentMake } = useMakes();
  const currentMake = localStorage.getItem(CURRENT_MAKE);

  useEffect(() => {
    if (currentMake === null && make !== null) {
      localStorage.setItem(CURRENT_MAKE, make);
    } else if (currentMake !== null) {
      setCurrentMake(currentMake);
    }
    return () => {
      localStorage.removeItem(CURRENT_MAKE);
    };
  }, []);
  return make;
};

export default usePersistMake;
