import PropTypes from "prop-types";
import { isNil } from "ramda";

import Presenter from "utils/PropTypesPresenter";
import HeroPresenter from "presenters/HeroPresenter";

export default new Presenter(
  {
    id: PropTypes.string,
    type: PropTypes.string,
    title: PropTypes.string,
    query: PropTypes.string,
    displayName: PropTypes.string,
    description: PropTypes.string,
    backgroundColor: PropTypes.string,
    metaDescription: PropTypes.string,
    metaKeywords: PropTypes.string,
    metaTitle: PropTypes.string,
    indexable: PropTypes.bool,
    footer: PropTypes.shape({}),
    heroImage: PropTypes.string,
    hero: HeroPresenter.shape(),
    algoliaCard: PropTypes.shape({}),
    fullImage: PropTypes.string,
  },
  {
    renderCategoryName(c) {
      if (isNil(c?.displayName)) return "All";

      const name = c.displayName;

      return name.toLowerCase().includes("ecards") || c.type === "Invites" ? `All ${name}` : `All ${name} Ecards`;
    },

    isCumpleanos(c) {
      return c?.id === "cumpleanos";
    },
  },
);
