import { useState } from "react";

import { jjLogger } from "utils/logUtils";

const useWebGLRenderLibrary = () => {
  const [renderLib, setRenderLib] = useState(null);

  const load = () => {
    try {
      import("webgl-render-library").then((response) => {
        setRenderLib(response);
      });
    } catch (error) {
      jjLogger.logError(`Load WebGL Render Library Error: ${error?.message}`);
    }
  };

  return { load, renderLib };
};

export default useWebGLRenderLibrary;
