/* global DetectUserAgent */
import { useEffect } from "react";

const LOADEDCURRENT = "LOADEDCURRENT";

const useLoadedCurrent = () => {
  const getData = () => sessionStorage.getItem(LOADEDCURRENT);

  const clearCurrent = () => sessionStorage.removeItem(LOADEDCURRENT);

  const setCurrentTrue = () => {
    sessionStorage.setItem(LOADEDCURRENT, true);
  };
  const isLoaded = () => getData() === "true" && DetectUserAgent.isFB4Android();

  useEffect(() => {
    clearCurrent();
  }, []);

  return { isLoaded, clearCurrent, setCurrentTrue, getData };
};

export default useLoadedCurrent;
