import { apiRoutes } from "routes";
import FetchHelpers from "utils/FetchHelpers";

export default {
  sendEmail(params) {
    const url = apiRoutes.forgotPasswordIndexPath(1);
    return FetchHelpers.post(url, params);
  },
  update(params) {
    const url = apiRoutes.resetPasswordPath("1.1");
    return FetchHelpers.patch(url, params);
  },
};
