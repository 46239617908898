import React from "react";
import PropTypes from "prop-types";

import LoadingModal from "components/LoadingModal";
import MetaTags from "components/MetaTags";
import MetaRobots from "components/MetaRobots";
import { useRouter, useGlobalLoading } from "hooks";

const NoLayout = (props) => {
  const { children } = props;
  const { isPending, labelFunc, progress } = useGlobalLoading();
  const { pathname } = useRouter();
  const { origin } = window.location;
  const url = `${origin}${pathname}`;

  return (
    <div>
      <main>{children}</main>
      <MetaTags>
        <link rel="canonical" href={url} />
        <meta property="og:site_name" content="JibJab" />
        <meta property="og:url" content={url} />
        <meta property="og:type" content="website" />
      </MetaTags>
      <MetaRobots />
      {isPending && <LoadingModal labelFunc={labelFunc} progress={progress} />}
    </div>
  );
};

NoLayout.propTypes = {
  children: PropTypes.node,
};

export default NoLayout;
