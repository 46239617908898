import { useSelector } from "react-redux";

import { usePaypalActions } from "store/PaypalSlice";

const usePaypal = () => {
  const { loadCheckoutDetails } = usePaypalActions();
  const { checkoutDetails } = useSelector((state) => state.PaypalSlice);

  return { checkoutDetails, loadCheckoutDetails };
};

export default usePaypal;
