const PASSWORD_CHECKLIST_ERRORS = {
  minLength: "At least 8 characters",
  number: "At least one number",
  lowercase: "At least one lowercase letter",
  capital: "At least one uppercase letter",
  specialChar: "At least one symbol",
};

Object.freeze(PASSWORD_CHECKLIST_ERRORS);

export { PASSWORD_CHECKLIST_ERRORS };
