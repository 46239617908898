import { createSlice } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";

import { UPDATED_PAGE_KEY } from "utils/localStorage";

const initialState = {
  pageUpdated: sessionStorage.getItem(UPDATED_PAGE_KEY) === "true",
  loading: false,
};

const PageUpdatedSlice = createSlice({
  name: "PageUpdatedModal",
  initialState,
  reducers: {
    changePageUpdated(state, { payload }) {
      state.pageUpdated = payload;
    },
    changeLoading(state, { payload }) {
      state.loading = payload;
    },
  },
});

export const { actions } = PageUpdatedSlice;
export default PageUpdatedSlice.reducer;

export const usePageUpdatedActions = () => {
  const dispatch = useDispatch();

  const changeValue = (data) => {
    dispatch(PageUpdatedSlice.actions.changePageUpdated(data));
    sessionStorage.setItem(UPDATED_PAGE_KEY, data);
  };

  const removeValue = () => {
    dispatch(PageUpdatedSlice.actions.changePageUpdated(false));
    sessionStorage.removeItem(UPDATED_PAGE_KEY);
  };

  const changeLoading = (loading) => {
    dispatch(PageUpdatedSlice.actions.changeLoading(loading));
  };

  return { changeValue, removeValue, changeLoading };
};
