import { lazy } from "react";

import {
  ChangeEmail,
  ChangeName,
  ChangePassword,
  Overview,
  Receipts,
  Receipt,
  SubscriptionDetails,
  EditRenewal,
  UpdateCreditCard,
} from "./Account";

const Home = lazy(() => import("./Home"));
const MakeView = lazy(() => import("./MakeView"));
const MakePreview = lazy(() => import("./MakePreview"));
const Contentful = lazy(() => import("./Contentful"));
const TemplateGroup = lazy(() => import("./TemplateGroup"));
const Categories = lazy(() => import("./Categories"));
const Category = lazy(() => import("./Category"));
const InviteCategory = lazy(() => import("./InviteCategory"));
const Login = lazy(() => import("./Login"));
const NewCast = lazy(() => import("./NewCast"));
const People = lazy(() => import("./People"));
const Legal = lazy(() => import("./Legal"));
const AboutUs = lazy(() => import("./AboutUs"));
const Privacy = lazy(() => import("./Privacy"));
const TermsOfSale = lazy(() => import("./TermsOfSale"));
const TermsOfService = lazy(() => import("./TermsOfService"));
const SignUp = lazy(() => import("./SignUp"));
const ResetPassword = lazy(() => import("./ResetPassword"));
const ResetPasswordReturn = lazy(() => import("./ResetPasswordReturn"));
const LoginHelp = lazy(() => import("./LoginHelp"));
const Payment = lazy(() => import("./Payment"));
const PurchaseConfirmation = lazy(() => import("./PurchaseConfirmation"));
const PaypalConfirmation = lazy(() => import("./PaypalConfirmation"));
const Face = lazy(() => import("./Face"));
const Search = lazy(() => import("./Search"));
const MyEcards = lazy(() => import("./MyEcards"));
const MyInvites = lazy(() => import("./MyInvites"));
const Page404 = lazy(() => import("./Page404"));
const Unauthorized = lazy(() => import("./Unauthorized"));
const OauthValidation = lazy(() => import("./OauthValidation"));
const InvitesPreview = lazy(() => import("./InvitesPreview"));
const InvitesCasting = lazy(() => import("./InvitesCasting"));
const InviteMakePreview = lazy(() => import("./InviteMakePreview"));
const InviteGuestView = lazy(() => import("./InviteGuestView"));
const ManageInvite = lazy(() => import("./ManageInvite"));
const InvitePublicView = lazy(() => import("./InvitePublicView"));
const InvitesLandingPage = lazy(() => import("./InvitesLandingPage"));
const StarringYouAILandingPage = lazy(() => import("./StarringYouAILandingPage"));
const MorphablePackGroup = lazy(() => import("./MorphablePackGroup"));
const SYAIPhotoConfirmation = lazy(() => import("./SYAIPhotoConfirmation"));
const SYAIPackPreview = lazy(() => import("./SYAIPackPreview"));
const SYAIPackView = lazy(() => import("./SYAIPackView"));
const SYAIMyPacks = lazy(() => import("./SYAIMyPacks"));

export {
  MakeView,
  MakePreview,
  Contentful,
  Face,
  TemplateGroup,
  Categories,
  Category,
  InviteCategory,
  Home,
  Login,
  SignUp,
  NewCast,
  People,
  MyEcards,
  MyInvites,
  Overview,
  ChangeEmail,
  ChangeName,
  ChangePassword,
  Receipts,
  Receipt,
  SubscriptionDetails,
  EditRenewal,
  UpdateCreditCard,
  Legal,
  AboutUs,
  Privacy,
  TermsOfSale,
  TermsOfService,
  ResetPassword,
  ResetPasswordReturn,
  LoginHelp,
  Payment,
  PurchaseConfirmation,
  PaypalConfirmation,
  Search,
  Page404,
  Unauthorized,
  OauthValidation,
  InvitesPreview,
  InvitesCasting,
  InviteMakePreview,
  InviteGuestView,
  ManageInvite,
  InvitePublicView,
  InvitesLandingPage,
  StarringYouAILandingPage,
  MorphablePackGroup,
  SYAIPhotoConfirmation,
  SYAIPackPreview,
  SYAIPackView,
  SYAIMyPacks,
};
