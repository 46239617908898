import { useSelector } from "react-redux";

import { useLastEcardActions } from "store/LastEcardSlice";
import { appRoutes } from "routes";
import { ECARD_TYPES } from "enums";
import useUtmsParams from "hooks/useUtmsParams";
import useUsers from "hooks/useUsers";
import localStorage, { ECARD, TEMPLATE_GROUP, IN_FLOW, SOURCE_PHOTO, MORPHABLE_PACK_GROUP } from "utils/localStorage";
import UserPresenter from "presenters/UserPresenter";
import MorphedPackPresenter from "presenters/MorphedPackPresenter";
import MakePresenter from "presenters/MakePresenter";
import InvitePresenter from "presenters/InvitePresenter";
import TemplateGroupPresenter from "presenters/TemplateGroupPresenter";
import { jjLogger } from "utils/logUtils";

const useLastEcard = () => {
  const { utmsParams } = useUtmsParams();
  const { currentUser } = useUsers();
  const { setLastEcard, setInFlow, clearLastEcard, setSourcePhoto } = useLastEcardActions();

  const {
    ecard: currentEcard,
    templateGroup: currentTemplateGroup,
    inFlow: currentFlow,
    sourcePhoto: currentPhoto,
    morphablePackGroup: currentMorphablePackGroup,
  } = useSelector((state) => state.LastEcardSlice);

  const ecard = (() => currentEcard || localStorage.getItem(ECARD))();
  const templateGroup = (() => currentTemplateGroup || localStorage.getItem(TEMPLATE_GROUP))();
  const inFlow = (() => currentFlow || localStorage.getItem(IN_FLOW))();
  const sourcePhoto = (() => currentPhoto || localStorage.getItem(SOURCE_PHOTO))();
  const morphablePackGroup = (() => currentMorphablePackGroup || localStorage.getItem(MORPHABLE_PACK_GROUP))();

  const isInvite = ecard && ecard.type === ECARD_TYPES.INVITE;
  const isMake = ecard && ecard.type === ECARD_TYPES.MAKE;
  const isMorphable = ecard && ecard.type === ECARD_TYPES.MORPHABLE;

  const buildInvitePreviewPath = () => {
    const id = InvitePresenter.id(ecard);

    return appRoutes.inviteMakePreviewPath(id);
  };

  const buildMakePreviewPath = () => {
    const makeId = MakePresenter.id(ecard);
    const tgId = TemplateGroupPresenter.id(templateGroup);

    return appRoutes.makeViewPath(tgId, makeId);
  };

  const buildMorphedPackPreviewPath = () => {
    const morphableId = MorphedPackPresenter.id(ecard);
    return appRoutes.syaiPackPreviewPath(morphableId);
  };

  const buildPreviewPath = () => {
    if (isInvite) return buildInvitePreviewPath();
    if (isMorphable) return buildMorphedPackPreviewPath();

    return buildMakePreviewPath();
  };

  const buildRedirectPath = (user) => {
    const needsPayment = UserPresenter.notPaid(user) && TemplateGroupPresenter.premium(templateGroup);
    const previewPath = buildPreviewPath();
    const path = needsPayment ? appRoutes.paymentPath() : previewPath;

    jjLogger.logDebug(`useLastEcard.js: buildRedirectPath(): path: ${path}`);
    return { pathname: path, state: { referrer: "inFlow" }, search: utmsParams };
  };

  const isFlowRequired =
    UserPresenter.notRegistered(currentUser) ||
    (UserPresenter.notPaid(currentUser) && TemplateGroupPresenter.premium(templateGroup));

  return {
    setLastEcard,
    setSourcePhoto,
    setInFlow,
    inFlow,
    clearLastEcard,
    ecard,
    isInvite,
    isMake,
    isMorphable,
    templateGroup,
    buildRedirectPath,
    isFlowRequired,
    sourcePhoto,
    morphablePackGroup,
  };
};

export default useLastEcard;
