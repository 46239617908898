/* global DetectUserAgent */
import { v4 as uuidv4 } from "uuid";

import UserPresenter from "presenters/UserPresenter";
import { getTimeZone, getCurrentDateInUTC } from "utils/dateTimeUtils";
import { jjLogger } from "utils/logUtils";
import { JIBJAB_TRACKING_ID } from "utils/localStorage";
import { METRICS } from "enums/tracking";

export const METRIC = METRICS.PREANON;
const URL = process.env.JIBJAB_ANALYTICS_URL;

export const getTrackingId = () => localStorage.getItem(JIBJAB_TRACKING_ID);

export const setupTrackingId = () => {
  if (DetectUserAgent.isPrerender()) return;

  const trackingId = getTrackingId();

  if (!trackingId) localStorage.setItem(JIBJAB_TRACKING_ID, uuidv4());
};

export const eventData = (event, user) => {
  const trackingId = getTrackingId();

  return {
    event,
    tracking_id: trackingId,
    event_timestamp: getCurrentDateInUTC(),
    metric: METRIC,
    referrer_url: document.referrer,
    current_url: `${window.location.origin}${window.location.pathname}`,
    user_agent: navigator.userAgent,
    env: process.env.DEPLOY_ENV,
    user_id: UserPresenter.id(user),
    user_status: UserPresenter.analyticsStatus(user),
    client_timezone: getTimeZone(),
    query_string: window.location.search,
  };
};

export const trackEvent = (eventName, user) => {
  if (!URL || DetectUserAgent.isPrerender()) return;

  const trackingId = getTrackingId();

  if (!trackingId) {
    jjLogger.logError("trackEvent: - Tracking ID is not set");
    return;
  }

  const values = eventData(eventName, user);

  navigator.sendBeacon(URL, JSON.stringify(values));
};

export const pageViewEvent = (user) => {
  trackEvent("page_view", user);
};

export const signUpEvent = (user) => {
  trackEvent("sign_up", user);
};

export const purchaseEvent = (user) => {
  trackEvent("purchase", user);
};

export const loginEvent = (user) => {
  trackEvent("login", user);
};

export const draftContentEvent = (user) => {
  if (!UserPresenter.isAnonymous(user)) return;

  trackEvent("draft_content", user);
};
