/* eslint-disable*/
const detectWebGL = () => {
  // Check for the WebGL rendering context
  if (window.WebGLRenderingContext) {
    const canvas = document.createElement("canvas");
    const names = ["webgl", "experimental-webgl", "moz-webgl", "webkit-3d"];
    const context = false;

    for (const i in names) {
      try {
        context = canvas.getContext(names[i]);
        if (context && typeof context.getParameter === "function") {
          // WebGL is enabled.
          return 1;
        }
      } catch (e) {
        // should continue on error
      }
    }

    // WebGL is supported, but disabled.
    return 0;
  }

  // WebGL not supported.
  return -1;
};
/* eslint-enable */

const isWebGLSupportedAndEnabled = () => {
  return detectWebGL() === 1;
};

export const noWebGL = () => !isWebGLSupportedAndEnabled();
