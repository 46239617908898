import { createSlice } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";

import { deserialize } from "utils/storeUtils";
import PaypalTokensRepository from "repositories/PaypalTokensRepository";

const initialState = {
  errors: null,
  checkoutDetails: null,
};

const paypalSlice = createSlice({
  name: "paypal",
  initialState,
  reducers: {
    loadCheckoutDetailsSuccess(state, { payload }) {
      state.checkoutDetails = deserialize(payload);
    },
    loadCheckoutDetailsFail(state, { payload }) {
      state.errors = payload;
    },
  },
});

export const { actions } = paypalSlice;
export default paypalSlice.reducer;

export const usePaypalActions = () => {
  const dispatch = useDispatch();

  const loadCheckoutDetails = (id) => {
    return PaypalTokensRepository.show(id)
      .then(({ data }) => dispatch(paypalSlice.actions.loadCheckoutDetailsSuccess(data)))
      .catch((error) => {
        dispatch(paypalSlice.actions.loadCheckoutDetailsFail(error));
        throw new Error(error);
      });
  };

  return {
    loadCheckoutDetails,
  };
};
