const DEFAULT_INVITE_DETAILS = {
  title: "An Amazingly Fun Party",
  host: "Leslie",
  description: "Join us all to celebrate!",
  eventDate: new Date().toString(),
  location: "123 Location Avenue, Los Angeles, CA 90012",
  details: "Parking is limited so please carpool (which is super fun) if possible!",
};

Object.freeze(DEFAULT_INVITE_DETAILS);

export { DEFAULT_INVITE_DETAILS };
