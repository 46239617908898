import makeStyles from "@mui/styles/makeStyles";

export default makeStyles(
  () => ({
    body: {
      margin: 0,
      padding: 0,
    },
    root: {
      width: "100vw",
      height: "100vh",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      background: "#fff",
    },
    "@keyframes bricsContainer-1": {
      "0%": {
        top: "36px",
        height: "128px",
      },
      "50%": {
        top: "60px",
        height: "80px",
      },
      "100%": {
        top: "60px",
        height: "80px",
      },
    },
    "@keyframes bricsContainer-2": {
      "0%": {
        top: "41.99999999999999px",
        height: "116.00000000000001px",
      },
      "50%": {
        top: "60px",
        height: "80px",
      },
      "100%": {
        top: "60px",
        height: "80px",
      },
    },
    "@keyframes bricsContainer-3": {
      "0%": {
        top: "48px",
        height: "104px",
      },
      "50%": {
        top: "60px",
        height: "80px",
      },
      "100%": {
        top: "60px",
        height: "80px",
      },
    },
    loadingPulse: {
      width: "200px",
      height: "200px",
      display: "inline-block",
      overflow: "hidden",
    },
    bricsContainer: {
      width: "100%",
      height: "100%",
      position: "relative",
      transform: "translateZ(0) scale(1)",
      backfaceVisibility: "hidden",
      transformOrigin: "0 0",
      "& > div": {
        position: "absolute",
        width: "30px",
        boxSizing: "content-box",
        "&:nth-child(1)": {
          left: "35px",
          background: "#51a0f7",
          animation: "$bricsContainer-1 1s cubic-bezier(0,0.5,0.5,1) infinite",
          animationDelay: "-0.2s",
        },
        "&:nth-child(2)": {
          left: "85px",
          background: "#51a0f7",
          animation: "$bricsContainer-2 1s cubic-bezier(0,0.5,0.5,1) infinite",
          animationDelay: "-0.1s",
        },
        "&:nth-child(3)": {
          left: "135px",
          background: "#51a0f7",
          animation: "$bricsContainer-3 1s cubic-bezier(0,0.5,0.5,1) infinite",
          animationDelay: "undefineds",
        },
      },
    },
  }),
  { name: "LoaderBricks" },
);
