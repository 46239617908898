import PropTypes from "prop-types";
import R from "ramda";

import { appRoutes } from "routes";
import { TAG_TYPES } from "enums";
import Presenter from "utils/PropTypesPresenter";

export default new Presenter(
  {
    tag: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    animation: PropTypes.string,
    playerAnimation: PropTypes.string,
    category: PropTypes.string,
    ctaText: PropTypes.string,
  },
  {
    name(tag) {
      return R.isNil(tag) ? null : this.tag(tag);
    },
    isTemplateType(tag) {
      return R.isNil(tag) ? false : this.type(tag) === TAG_TYPES.template.type;
    },
    callToActionText(tag) {
      return R.isNil(tag) ? null : R.propOr(null, "ctaText", tag);
    },
    notOwnerButtonText(tag) {
      const callToActionText = this.callToActionText(tag);
      if (!R.isNil(callToActionText)) {
        return callToActionText;
      }
      return this.isTemplateType(tag) ? TAG_TYPES.template.buttonText : TAG_TYPES.thanks.buttonText;
    },
    notOwnerButtonPath(tag, templateGroupId) {
      const category = this.category(tag);
      if (!R.isNil(category)) {
        return appRoutes.categoryPath(category);
      }
      return this.isTemplateType(tag)
        ? appRoutes.templateGroupPath(templateGroupId)
        : appRoutes.categoryPath(TAG_TYPES.thanks.slug);
    },
  },
);
