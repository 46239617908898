import { apiRoutes } from "routes";
import FetchHelpers from "utils/FetchHelpers";

const trimEmail = (params) => {
  const attrs = params;
  if (attrs?.username) attrs.username = attrs.username.trim();
};

export default {
  getToken(params, separator) {
    trimEmail(params);
    const url = apiRoutes.oauthTokenPath(1);
    return FetchHelpers.post(url, params, separator);
  },

  revokeToken(params) {
    const url = apiRoutes.oauthRevokePath(1);
    return FetchHelpers.post(url, params);
  },
};
