import R from "ramda";

import { FACE_RELATIONS, UNIQUE_RELATIONS_KEYS, DATE_TYPE } from "enums";

const relativeKeys = Object.keys(FACE_RELATIONS).concat("default");
const occasionsKeys = Object.keys(DATE_TYPE);
const relativeBgValues = (theme) =>
  relativeKeys.map((relation) => {
    return {
      background: theme.palette.relatives[relation].background,
    };
  });
const relativeFillValues = (theme) =>
  relativeKeys.map((relation) => {
    return {
      fill: theme.palette.relatives[relation].background,
    };
  });
const relativeCTABgValues = (theme) =>
  relativeKeys.map((relation) => {
    return {
      background: theme.palette.relatives[relation].CTAbackground,
    };
  });
const relativeProfileBgValues = (theme) =>
  relativeKeys.map((relation) => {
    return {
      background: theme.palette.relatives[relation].profileBackground,
    };
  });
const relativeCalendarBgValues = (theme) =>
  occasionsKeys.map((relation) => {
    return {
      background: theme.palette.calendarRelations[relation].background,
    };
  });

export const relativeProfileBackgrounds = (theme) => R.zipObj(relativeKeys, relativeProfileBgValues(theme));
export const calendarOccasionBackground = (theme) => R.zipObj(occasionsKeys, relativeCalendarBgValues(theme));
export const relativeCTABackgrounds = (theme) => R.zipObj(relativeKeys, relativeCTABgValues(theme));
export const relativeBackgrounds = (theme) => R.zipObj(relativeKeys, relativeBgValues(theme));
export const relativeFills = (theme) => R.zipObj(relativeKeys, relativeFillValues(theme));

export const getRelationKeyByValue = (value) => relativeKeys.find((key) => FACE_RELATIONS[key] === value);

export const getOccasionKeyByValue = (value) => occasionsKeys.find((key) => DATE_TYPE[key] === value);

export const hasRelation = (relationValue, people) => {
  const relationKey = getRelationKeyByValue(relationValue);
  return Boolean(people.find((person) => person.relation === relationKey));
};

export const getAvailableRelations = (people) => {
  return R.omit(
    R.intersection(
      UNIQUE_RELATIONS_KEYS,
      people.map((person) => person?.relation),
    ),
    FACE_RELATIONS,
  );
};

export const getAvailableRelationsWithCurrent = (people, relation) => {
  const availableRelations = getAvailableRelations(people);
  return { ...availableRelations, [relation]: FACE_RELATIONS[relation] };
};

export const relationToPronoun = (value) => {
  switch (value) {
    case FACE_RELATIONS.me:
      return "You";
    case undefined:
      return "the Person";
    default:
      return value;
  }
};

export const filterFirstPersonByRelation = (relation, people) => R.find(R.propEq("relation", relation))(people);
