import { is } from "ramda";

import localStorage, { LOCAL_STORAGE_AUTH_KEY } from "utils/localStorage";
import emberLoginSync from "utils/emberLoginSync";

export const defaultAuth = {
  accessToken: null,
  createdAt: null,
  expiresIn: null,
  refreshToken: null,
  tokenType: null,
};

export const getAuthFromLocalStorage = () => {
  let storage = localStorage.getItem(LOCAL_STORAGE_AUTH_KEY);

  const emberAuthData = emberLoginSync.getAuthInfo();
  if (emberAuthData) {
    localStorage.setItem(LOCAL_STORAGE_AUTH_KEY, { auth: { ...emberAuthData } });
    return emberAuthData;
  }

  if (is(String, storage)) {
    storage = null;
  }

  if (storage) {
    return storage.auth;
  }

  return defaultAuth;
};
