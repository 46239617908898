import { take, takeLast } from "ramda";
import { useSelector } from "react-redux";

import { useCategoryActions } from "store/CategorySlice";
import TemplateGroupPresenter from "presenters/TemplateGroupPresenter";

const useCategory = () => {
  const { loadCategory, resetCategory, loadCategoryFromContentful, loadInviteCategory } = useCategoryActions();

  const { category } = useSelector((state) => state.CategorySlice);

  const CategoryCards = category && category.templateGroups;

  let categoryStarringYouCards =
    CategoryCards &&
    CategoryCards.filter(
      (ecard) => TemplateGroupPresenter.isStarringYou(ecard) || TemplateGroupPresenter.isStarringYouVertical(ecard),
    );

  const featuredStarringYouCards = CategoryCards && take(4, categoryStarringYouCards);

  categoryStarringYouCards =
    categoryStarringYouCards && categoryStarringYouCards.length > 4
      ? takeLast(categoryStarringYouCards.length - 4, categoryStarringYouCards)
      : [];

  const categoryStarringYouClipCards =
    CategoryCards && CategoryCards.filter((ecard) => TemplateGroupPresenter.isStarringYouClip(ecard));

  const categoryStraightUpCards =
    CategoryCards && CategoryCards.filter((ecard) => TemplateGroupPresenter.isStraightUp(ecard));

  return {
    loadCategory,
    loadCategoryFromContentful,
    loadInviteCategory,
    resetCategory,
    category,
    categoryStarringYouCards,
    categoryStraightUpCards,
    categoryStarringYouClipCards,
    featuredStarringYouCards,
  };
};

export default useCategory;
