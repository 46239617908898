import dayjs from "dayjs";

import { getScore } from "utils/dateTimeUtils";
import { DATE_TYPE } from "enums";

export const createCelebration = (person, dateType) => ({
  person,
  key: `${person.id}${dateType}`,
  dateType,
  score: getScore(person[dateType]),
  dateToCelebrate: dateType === DATE_TYPE.birthday ? person.birthday : person.anniversary,
});

export const createRelationOccasionDay = (person, dateType, occasionDay) => ({
  person,
  key: `${person.id}${dateType}`,
  dateType,
  score: getScore(occasionDay),
  dateToCelebrate: dayjs(occasionDay).format("YYYY-MM-DD"),
});

const isBirthday = (dataType) => dataType === DATE_TYPE.birthday;
const isAnniversary = (dataType) => dataType === DATE_TYPE.anniversary;
const isValentinesDay = (dataType) => dataType === DATE_TYPE.valentines;
const isMotherDay = (dataType) => dataType === DATE_TYPE.mothersDay;
const isFatherDay = (dataType) => dataType === DATE_TYPE.fathersDay;
const defaultValue = DATE_TYPE.birthday;

export { isBirthday, isAnniversary, isValentinesDay, isMotherDay, isFatherDay, defaultValue };
