import { useSelector } from "react-redux";

import { useFeaturedEcardsActions } from "store/FeaturedEcardsSlice";

const useFeaturedEcards = () => {
  const { loadFeaturedEcards } = useFeaturedEcardsActions();

  const { featuredEcards } = useSelector((state) => state.FeaturedEcardsSlice);
  const starringYouCards = featuredEcards && featuredEcards.templateGroups;
  const starringYouClipCards = featuredEcards && featuredEcards.clips;

  return { loadFeaturedEcards, featuredEcards, starringYouCards, starringYouClipCards };
};

export default useFeaturedEcards;
