import { FETCH_STATUSES } from "enums";
import useUsers from "hooks/useUsers";
import useNotifications from "hooks/useNotifications";
import useInvites from "hooks/useInvites";
import useRouter from "hooks/useRouter";
import useGlobalLoading from "hooks/useGlobalLoading";
import { jjLogger } from "utils/logUtils";
import { appRoutes } from "routes";

const useSaveInviteAndGo = (onSuccess, getLabel = null) => {
  const { saveInvite } = useInvites();
  const { ensureCurrentUser } = useUsers();
  const { addErrorNotification } = useNotifications();
  const { push } = useRouter();
  const { setStatus: setLoaderStatus } = useGlobalLoading(null, { labelFunction: getLabel, crossPage: true });

  const redirectToInvite = (invite) => {
    const invitesPreviewPath = appRoutes.inviteMakePreviewPath(invite?.id);
    setLoaderStatus(FETCH_STATUSES.fulfilled);
    return push({ pathname: invitesPreviewPath, state: { referrer: "inFlow" } });
  };

  const saveInviteAndGo = ({ roles, templateId, makeThumb, shareThumb, templateGroup, themeId }) =>
    ensureCurrentUser().then((currentUser) => {
      saveInvite({ roles, templateId, makeThumb, shareThumb, templateGroup, themeId }).then(
        (invite) => {
          onSuccess(currentUser, invite);
          return redirectToInvite(invite, currentUser);
        },
        (error) => {
          let message = "Oops! Something went wrong!";
          let errors = "UseSaveInviteAndGo.js: Invite Creation Error: ";

          if (error) {
            error.forEach((err) => {
              errors += err;
              if (err.includes("too few castings") || err.includes("head: can't be blank")) {
                message = "This card needs more people! Cast another face!";
              }
            });
          } else {
            errors += "Unknown Error";
          }

          addErrorNotification(message);
          jjLogger.logError(errors);
        },
      );
    });

  return { saveInviteAndGo };
};

export default useSaveInviteAndGo;
