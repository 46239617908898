import { createSlice } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";

import { DATA_VOLUME } from "utils/localStorage";

const initialState = {
  pauseVideo: false,
  volume: sessionStorage.getItem(DATA_VOLUME) || 0.5,
};

const playerControlsSlice = createSlice({
  name: "playerControls",
  initialState,
  reducers: {
    setPauseVideo(state, { payload }) {
      state.pauseVideo = payload;
    },
    setVolume(state, { payload }) {
      state.volume = payload;
    },
  },
});

export const { actions } = playerControlsSlice;
export default playerControlsSlice.reducer;

export const usePlayerControlsActions = () => {
  const dispatch = useDispatch();

  const setPauseVideo = (params) => {
    dispatch(playerControlsSlice.actions.setPauseVideo(params));
  };

  const setVolume = (params) => {
    dispatch(playerControlsSlice.actions.setVolume(params));
  };

  return {
    setPauseVideo,
    setVolume,
  };
};
