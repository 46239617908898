import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

const MetaTags = ({ children }) => {
  return <Helmet>{children}</Helmet>;
};

MetaTags.propTypes = {
  children: PropTypes.node.isRequired,
};

export default MetaTags;
