import { useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";

import { useAppActions } from "store/AppSlice";
import { useAuthActions } from "store/AuthSlice";
import { getAuthFromLocalStorage } from "utils/authUtils";
import { jjLogger } from "utils/logUtils";
import { isAnAnonEmail } from "utils/anonymousUtils";

import useRecaptcha from "./useRecaptcha";
import useFetchStatus from "./useFetchStatus";

const ANON_PASSWORD = "Jibjab123!";

const getToken = () => {
  const auth = getAuthFromLocalStorage();
  return auth.accessToken;
};

const generateAnonUserAttributes = () => {
  return {
    data: {
      type: "users",
      attributes: {
        "first-name": "jibjab",
        password: ANON_PASSWORD,
        role: "anonymous",
        "registration-source": "web",
        email: `${uuidv4()}@jibjab.com`,
      },
    },
  };
};

const useUsers = () => {
  const {
    loadCurrentUser,
    setCurrentUserLoadingStatus,
    createUser,
    updateUser,
    clearErrors,
    reactivateSubscription,
    deactivateSubscription,
  } = useAppActions();

  const authActions = useAuthActions();
  const { needsRefreshScheduled } = useSelector((state) => state.AuthSlice);

  const { recaptcha } = useRecaptcha();

  const { currentUser, currentUserLoadingStatus, errors } = useSelector((state) => state.AppSlice);
  const { isFulfilled, isFailed } = useFetchStatus(currentUserLoadingStatus);
  const currentUserLoaded = isFulfilled || isFailed;

  const createAnonUser = () => {
    const attributes = generateAnonUserAttributes();
    return createUser(attributes);
  };

  const signIn = ({ password = ANON_PASSWORD, email, username = email, relogin = false }) => {
    let attributes = {};
    if (currentUser && !relogin) {
      const anonymousToken = getToken();
      attributes = { grantType: "password", username, password, anonymousToken };
      const anonEmail = isAnAnonEmail(currentUser.email);
      if (!anonEmail) {
        jjLogger.log(`useUsers: signIn - User email does not match anonymous pattern: ${JSON.stringify(currentUser)}`);
      }
      jjLogger.log(`useUsers: signIn - currentUser: ${JSON.stringify(currentUser)}`);
    } else {
      attributes = { grantType: "password", username, password };
    }

    return recaptcha({ actionName: "login" }).then((gToken) => {
      return authActions.signIn({ ...attributes, g_token: gToken });
    });
  };

  const ensureCurrentUser = () => {
    if (currentUser) return Promise.resolve(currentUser);

    return createAnonUser()
      .then(({ attributes: { email: username } }) => signIn({ username }))
      .then(() => loadCurrentUser());
  };

  const fbSignIn = ({ accessToken }) => {
    const attributes = { grantType: "assertion", provider: "facebook", token: accessToken };

    if (currentUser) {
      attributes.anonymousToken = getToken();
      const anonEmail = isAnAnonEmail(currentUser.email);
      if (!anonEmail) {
        jjLogger.log(
          `useUsers: fbSignIn - User email does not match anonymous pattern: ${JSON.stringify(currentUser)}`,
        );
      }
      jjLogger.log(`useUsers: fbSignIn - currentUser: ${JSON.stringify(currentUser)}`);
    }

    return authActions.signIn(attributes);
  };

  const regularSignUp = ({ username, password }) => {
    if (currentUser) {
      const attributes = {
        data: {
          type: "users",
          attributes: {
            email: username,
            password,
            registrationSource: "web",
            role: "regular",
            firstName: "",
          },
        },
      };
      const anonEmail = isAnAnonEmail(currentUser.email);
      if (!anonEmail) {
        jjLogger.log(
          `useUsers: regularSignUp - User email does not match anonymous pattern: ${JSON.stringify(currentUser)}`,
        );
      }
      jjLogger.log(`useUsers: regularSignUp - currentUser: ${JSON.stringify(currentUser)}`);
      return updateUser(attributes);
    }
    const attributes = {
      data: {
        type: "users",
        attributes: {
          email: username,
          password,
          registrationSource: "web",
        },
      },
    };
    return createUser(attributes);
  };

  const fbSignUp = ({ name, email, password, id, accessToken }) => {
    const attributes = {
      data: {
        type: "users",
        attributes: {
          email,
          firstName: name,
          password,
          registrationSource: "web",
        },
        relationships: {
          identities: {
            data: [
              {
                type: "identities",
                attributes: {
                  provider: "facebook",
                  uid: id,
                  token: accessToken,
                },
              },
            ],
          },
        },
      },
    };

    if (currentUser) {
      attributes.data.attributes.role = "regular";
      const anonEmail = isAnAnonEmail(currentUser.email);
      if (!anonEmail) {
        jjLogger.log(
          `useUsers: fbSignUp - User email does not match anonymous pattern: ${JSON.stringify(currentUser)}`,
        );
      }
      jjLogger.log(`useUsers: fbSignUp - currentUser: ${JSON.stringify(currentUser)}`);
      return updateUser(attributes);
    }

    return createUser(attributes);
  };

  return {
    loadCurrentUser,
    currentUserLoaded,
    currentUserLoadingStatus,
    setCurrentUserLoadingStatus,
    currentUser,
    createAnonUser,
    updateUser,
    reactivateSubscription,
    deactivateSubscription,
    signIn,
    signOut: authActions.signOut,
    ensureCurrentUser,
    fbSignIn,
    regularSignUp,
    fbSignUp,
    errors,
    clearErrors,
    needsRefreshScheduled,
    scheduleRefresh: authActions.scheduleRefresh,
  };
};

export default useUsers;
