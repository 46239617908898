import React, { lazy } from "react";

import { useFaces, useSourcePhoto, useGlobalLoading, usePageUpdated, useFacebook } from "hooks";
import LoadingModal from "components/LoadingModal";

const FaceUploadModal = lazy(() => import("components/FaceUploadModal"));
const PageUpdatedModal = lazy(() => import("components/PageUpdatedModal"));
const FacebookModalNotice = lazy(() => import("components/FacebookModalNotice"));
const ImageUploadModal = lazy(() => import("components/ImageUploadModal"));

const RenderModal = () => {
  const { modalOpened } = useFaces();
  const { modalOpened: imageUploadModalOpen } = useSourcePhoto();
  const { isPending, labelFunc, progress } = useGlobalLoading();
  const { isUpdating } = usePageUpdated(true);
  const { showNoticeModal } = useFacebook();

  return (
    <>
      {modalOpened ? <FaceUploadModal /> : null}
      {imageUploadModalOpen ? <ImageUploadModal /> : null}
      {isUpdating ? <PageUpdatedModal /> : null}
      {isPending ? <LoadingModal labelFunc={labelFunc} progress={progress} /> : null}
      {showNoticeModal ? <FacebookModalNotice /> : null}
    </>
  );
};

export default RenderModal;
