const TAG_TYPES = {
  template: {
    type: "template",
    buttonText: "MAKE MY OWN",
  },
  thanks: {
    type: "thanks",
    buttonText: "SAY THANK YOU",
    slug: "thank-you-ecards",
  },
};

Object.freeze(TAG_TYPES);

export { TAG_TYPES };
