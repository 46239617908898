import { trackMakeEvent, IN_FLOW_SUBSCRIPTION_PURCHASE } from "utils/makeEventUtils";
import EcardPresenter from "presenters/EcardPresenter";
import { jjLogger } from "utils/logUtils";
import { subscriptionPurchaseEvent } from "tracking/jibjab/faceswap";

import useAlgoliaEvents from "./useAlgoliaEvents";
import useAlgoliaTracker from "./useAlgoliaTracker";
import useFmvr from "./useFmvr";
import useMakes from "./useMakes";
import useLastEcard from "./useLastEcard";

const usePaymentEvents = () => {
  const { trackEventAfterSearchAndPurchase } = useAlgoliaEvents();
  const { hitSelected } = useAlgoliaTracker();
  const { updateMake } = useMakes();
  const { ecard, isMake, templateGroup, isMorphable } = useLastEcard();
  const { createFmvr } = useFmvr();

  const onMorphablePurchase = (user) => {
    if (!user) {
      jjLogger.logError("usePaymentEvents.js: onSuccessfulInFlowPayment. Missing user parameter");
      return;
    }

    subscriptionPurchaseEvent({ user, morphedPack: ecard });
  };

  const onMakePurchase = async () => {
    await trackMakeEvent(ecard, IN_FLOW_SUBSCRIPTION_PURCHASE);
    await updateMake(ecard);
    createFmvr(ecard, templateGroup);
  };

  const onSuccessfulInFlowPayment = async (user) => {
    try {
      if (ecard) {
        if (isMorphable) {
          onMorphablePurchase(user);
          return;
        }

        if (isMake) await onMakePurchase();

        const templateGroupId = EcardPresenter.templateGroupId(ecard);
        if (templateGroupId === hitSelected?.objectID) trackEventAfterSearchAndPurchase(hitSelected);
      }
    } catch (error) {
      let errorLog = "usePaymentEvents.js: onSuccessfulInFlowPayment(): error: ";

      if (Array.isArray(error)) {
        error.forEach((err) => {
          errorLog += err;
        });
      } else {
        errorLog += error;
      }

      jjLogger.logError(errorLog);
    }
  };

  return { onSuccessfulInFlowPayment };
};

export default usePaymentEvents;
