import dayjs from "dayjs";
import PropTypes from "prop-types";
import R from "ramda";

import Presenter from "utils/PropTypesPresenter";

export default new Presenter(
  {
    id: PropTypes.number,
    status: PropTypes.string,
    active: PropTypes.bool,
    gifted: PropTypes.bool,
    endAt: PropTypes.string,
    offerDescription: PropTypes.string,
    paymentProviderName: PropTypes.string,
  },
  {
    endAtDisplayDate(s) {
      return R.isNil(s) || dayjs(s.endAt).format("MMM D, YYYY");
    },
    isPaid(s) {
      return R.isNil(s) || s.status === "paid";
    },
    isPaused(s) {
      return R.isNil(s) || s.status === "paused";
    },
    isRecurly(s) {
      return R.isNil(s) || s.paymentProviderName === "recurly";
    },
    isAmazon(s) {
      return R.isNil(s) || s.paymentProviderName === "amazon";
    },
    isPayPal(s) {
      return R.isNil(s) || s.paymentProviderName === "paypal";
    },
    isApple(s) {
      return R.isNil(s) || s.paymentProviderName === "apple";
    },
    isAndroid(s) {
      return R.isNil(s) || s.paymentProviderName === "android";
    },
  },
);
