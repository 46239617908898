import R from "ramda";
import { useContentful } from "react-contentful";
import responseResolve from "contentful-resolve-response";

const useContentfulPage = (slug, lang) => {
  const { data, error, fetched, loading } = useContentful({
    contentType: "page",
    query: {
      "fields.slug": slug,
      include: 10,
      content_type: "page",
      limit: 1,
      skip: 0,
      locale: R.isNil(lang) ? "en-US" : lang,
    },
  });

  return {
    data: R.isNil(data) ? data : responseResolve(data),
    error,
    fetched,
    loading,
  };
};

export default useContentfulPage;
