import PropTypes from "prop-types";

import Presenter from "utils/PropTypesPresenter";
import PersonPresenter from "presenters/PersonPresenter";
import JawPresenter from "presenters/JawPresenter";

export default new Presenter({
  id: PropTypes.string,
  type: PropTypes.string,
  asset: PropTypes.string,
  jaw: JawPresenter.shape(),
  person: PersonPresenter.shape(),
  createdAt: PropTypes.number,
  default: PropTypes.bool,
});
