import axios from "axios";

import { externalRoutes } from "routes";

export default {
  track(params) {
    const url = externalRoutes.cjTracking();
    const headers = { "Content-Type": "application/json" };

    return axios.post(url, params, headers);
  },
};
