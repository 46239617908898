import palette from "layouts/ThemeProvider/palette";

const iconButtonDefault = {
  padding: 0,
  "&:hover": {
    "@media (hover: hover)": {
      color: palette.primary.main,
    },
  },
};

const roundDefault = {
  ...iconButtonDefault,
  border: 0,
  borderRadius: 100,
  backgroundColor: palette.neutral.main,
};

export default {
  MuiIconButton: {
    defaultProps: {
      disableRipple: true,
      disableFocusRipple: true,
    },
    variants: [
      {
        props: { variant: "round" },
        style: roundDefault,
      },
    ],
    styleOverrides: {
      root: iconButtonDefault,
    },
  },
};
