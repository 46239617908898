import { createSlice } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";

import SearchRepository from "repositories/SearchRepository";
import { deserialize } from "utils/storeUtils";

const initialState = {
  searchResults: [],
  error: {},
};

const searchSlice = createSlice({
  name: "searchResults",
  initialState,
  reducers: {
    searchSuccess(state, { payload }) {
      state.searchResults = deserialize(payload);
      state.error = {};
    },
    searchFail(state, { payload }) {
      state.searchResults = [];
      state.error = payload.message;
    },
    resetSearchResults(state) {
      state.searchResults = null;
      state.error = {};
    },
  },
});

export const { actions } = searchSlice;
export default searchSlice.reducer;

export const useSearchActions = () => {
  const dispatch = useDispatch();

  const loadSearchResults = async (params) => {
    try {
      const { data } = await SearchRepository.get(params);
      dispatch(searchSlice.actions.searchSuccess(data));
    } catch (error) {
      dispatch(searchSlice.actions.searchFail(error));
      throw new Error(error);
    }
  };

  const resetSearchResults = () => {
    return dispatch(searchSlice.actions.resetSearchResults());
  };

  return {
    loadSearchResults,
    resetSearchResults,
  };
};
