import { useState } from "react";

import InvitePresenter from "presenters/InvitePresenter";
import { appRoutes } from "routes";
import { trackInvitePublicLinkShare } from "tracking/google/analytics";

import useInvites from "./useInvites";
import useClipboard from "./useClipboard";

const usePublicInviteLink = () => {
  const { updateInviteAsPublic, invite } = useInvites();
  const { copy } = useClipboard();

  const [isLoading, setIsLoading] = useState(false);

  const handleCopyPublicLink = async (templateGroup) => {
    const inviteId = InvitePresenter.id(invite);

    const { origin } = window.location;
    const path = appRoutes.invitePublicViewPath(inviteId);
    const guestLink = `${origin}${path}`;

    if (!InvitePresenter.public(invite)) {
      try {
        setIsLoading(true);
        await updateInviteAsPublic(inviteId);
      } finally {
        setIsLoading(false);
      }
    }

    trackInvitePublicLinkShare(invite, templateGroup);
    await copy(guestLink, "The Invite link has been copied.");
  };

  return { handleCopyPublicLink, isLoading };
};

export default usePublicInviteLink;
