import { apiRoutes } from "routes";
import FetchHelpers from "utils/FetchHelpers";

export default {
  index() {
    const url = apiRoutes.transactionRecordsPath(1);
    return FetchHelpers.get(url);
  },
  show(id) {
    const url = apiRoutes.transactionRecordPath(1, id);
    return FetchHelpers.get(url);
  },
};
