import palette from "layouts/ThemeProvider/palette";

export default {
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        "&.Mui-error": {
          "& input": {
            backgroundColor: palette.melon,
            color: palette.radicalRed,
          },
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: palette.flamingo,
          },
        },
        "&.Mui-focused": {
          "fieldset.MuiOutlinedInput-notchedOutline": {
            border: `2px solid ${palette.neutral.black}`,
          },
        },
        "@media (hover: none)": {
          "&:hover": {
            "fieldset.MuiOutlinedInput-notchedOutline": {
              border: `2px solid ${palette.neutral.black}`,
            },
          },
        },
      },
      input: {
        "&:placeholder-shown": {
          background: palette.polar,
        },
      },
      notchedOutline: {
        borderRadius: 5,
        border: `2px solid ${palette.primary.main}`,
        "&:hover": {
          "@media (hover: hover)": {
            border: `2px solid ${palette.neutral.black}`,
          },
        },
      },
      multiline: {
        padding: 0,
        "&:placeholder-shown": {
          background: palette.polar,
        },
        "&.Mui-error": {
          backgroundColor: palette.melon,
        },
      },
      inputMultiline: {
        padding: "10px 9px",
        height: "auto",
        resize: "none",
        "&:placeholder-shown": {
          background: palette.polar,
        },
      },
    },
  },
};
