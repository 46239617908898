import makeStyles from "@mui/styles/makeStyles";

export default makeStyles(
  (theme) => ({
    banner: {
      position: "relative",
      display: "block",
      width: "100%",
      padding: [5, 40, 5, 47],
      color: "white",
      backgroundColor: theme.palette.primary.main,
      textAlign: "center",
      [theme.breakpoints.up("tablet")]: {
        textAlign: "left",
      },
      [theme.breakpoints.up("mobile")]: {
        height: 30,
        padding: [2, 40, 2, 47],
      },
    },
    arrow: {
      position: "absolute",
      bottom: -4,
      left: "50%",
      marginLeft: -60,
      transform: "rotate(45deg)",
      width: 0,
      height: 0,
      borderLeft: "6px solid transparent",
      borderRight: "6px solid transparent",
      borderBottom: `6px solid ${theme.palette.primary.main}`,
      [theme.breakpoints.up("tablet")]: {
        left: "auto",
        marginLeft: 0,
      },
    },
  }),
  { name: "Banner" },
);
