import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

const useScreen = () => {
  const theme = useTheme();
  const { mobile, tablet, sm, md, lg, xl } = theme.breakpoints.values;
  const isMobile = useMediaQuery(`(max-width:${mobile - 1}px)`);
  const isTablet = useMediaQuery(`(min-width:${mobile}px) and (max-width:${tablet - 1}px)`);
  const isDesktop = useMediaQuery(`(min-width:${tablet}px)`);

  let currentScreen = null;

  switch (true) {
    case isMobile:
      currentScreen = "mobile";
      break;
    case isTablet:
      currentScreen = "tablet";
      break;
    default:
      currentScreen = "desktop";
      break;
  }

  const isXS = useMediaQuery(`(max-width:${sm}px)`);
  const isSM = useMediaQuery(`(min-width:${sm + 1}px) and (max-width:${md}px)`);
  const isMD = useMediaQuery(`(min-width:${md + 1}px) and (max-width:${lg}px)`);
  const isLG = useMediaQuery(`(min-width:${lg + 1}px) and (max-width:${xl}px)`);
  const isXL = useMediaQuery(`(min-width:${xl + 1}px)`);

  let currentBreakpoint = null;

  switch (true) {
    case isXS:
      currentBreakpoint = "xs";
      break;
    case isSM:
      currentBreakpoint = "sm";
      break;
    case isMD:
      currentBreakpoint = "md";
      break;
    case isLG:
      currentBreakpoint = "lg";
      break;
    case isXL:
      currentBreakpoint = "xl";
      break;
    default:
      break;
  }

  return {
    isMobile,
    isTablet,
    isDesktop,
    currentScreen,
    currentBreakpoint,
  };
};

export default useScreen;
