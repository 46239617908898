import React from "react";
import { Container } from "@mui/material";

import useStyles from "./useStyles";

const LoaderBricks = () => {
  const classes = useStyles();
  return (
    <Container className={classes.root}>
      <div className={classes.loadingPulse}>
        <div className={classes.bricsContainer} data-testid="loaderBricks-container">
          <div />
          <div />
          <div />
        </div>
      </div>
    </Container>
  );
};

export default LoaderBricks;
