import React from "react";
import PropTypes from "prop-types";

import LDJsonSchema from "components/LDJsonSchema";
import MetaTags from "components/MetaTags";
import { useRouter } from "hooks";
import MetaRobots from "components/MetaRobots";

import Header from "./components/Header";
import Footer from "./components/Footer";
import useStyles from "./useStyles";

const UserLayout = (props) => {
  const classes = useStyles();
  const { children } = props;
  const {
    pathname,
    camelizedQuery: { readable },
  } = useRouter();
  const { origin } = window.location;
  const url = `${origin}${pathname}`;
  const isLegalPage = pathname.match(/\/legal|\/privacy|\/terms-of-service|\/terms-of-sale/);
  const isChristmasCategoryPage = pathname.match(/\/browse\/christmas-cast-?\d-ecards/);
  const canonicalLinkReference = isChristmasCategoryPage
    ? `${origin}/browse/holiday-ecards`
    : `${origin}${pathname.replace(/\/$/, "")}`;
  const readableView = readable && isLegalPage ? readable === "true" : false;
  const siteSchema = {
    "@context": "http://schema.org",
    "@type": "WebSite",
    name: "JibJab",
    url: "https://www.jibjab.com/",
  };

  return (
    <div className={classes.root}>
      {!readableView && <Header />}
      <main>{children}</main>
      {!readableView && <Footer />}
      <LDJsonSchema schema={siteSchema} />
      <MetaTags>
        <link rel="canonical" href={canonicalLinkReference} />
        <meta property="og:site_name" content="JibJab" />
        <meta property="og:url" content={url} />
        <meta property="og:type" content="website" />
      </MetaTags>
      <MetaRobots />
    </div>
  );
};

UserLayout.propTypes = {
  children: PropTypes.node,
};

export default UserLayout;
