import makeStyles from "@mui/styles/makeStyles";

export default makeStyles(
  (theme) => ({
    root: {},
    notSellMyDataLink: {
      textAlign: "center",
      padding: [34, 32, 0, 32],
      fontSize: theme.typography.caption.fontSize,
      textDecoration: "underline",
      [theme.breakpoints.up("mobile")]: {
        padding: [32, 140, 0, 140],
      },
      [theme.breakpoints.up("tablet")]: {
        padding: [43, 168, 0, 168],
        fontSize: theme.typography.caption.fontSize,
      },
    },
  }),
  { name: "NotSellMyDataLink" },
);
