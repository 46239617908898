import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import advancedFormat from "dayjs/plugin/advancedFormat";
import isBetween from "dayjs/plugin/isBetween";
import R from "ramda";

import { isBlank } from "utils/storeUtils";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);
dayjs.extend(isBetween);

const diffAscDate = (a, b, key) => {
  return new Date(a[key]).getTime() - new Date(b[key]).getTime();
};
const diffDscDate = (a, b, key) => {
  return new Date(b[key]).getTime() - new Date(a[key]).getTime();
};

const LEAP_YEAR = 1904;

const isoDateString = (month = "1", day = "01", year = LEAP_YEAR) => {
  return `${year}-${month}-${day}`;
};

const dateToLeapYearString = (dateObject) => [LEAP_YEAR, dateObject.month, dateObject.day].join("-");

const getTomorrow = () =>
  dayjs()
    .add(1, "day")
    .startOf("day");

const formatDateToUTC = (date) =>
  dayjs(date)
    .utc()
    .format();

const formatLocalDate = (date) =>
  dayjs
    .utc(date)
    .local()
    .format("DD/MM/YYYY");

const daysInMonth = (month) => dayjs(isoDateString(month), "YYYY-M-DD").daysInMonth();

const monthOptions = () => {
  return R.range(1, 13).map((month) => {
    const date = dayjs(isoDateString(month), "YYYY-M-DD");
    return {
      value: date.format("MM"),
      label: date.format("MMM"),
    };
  });
};

const sortByKeyDate = (key, data, order = "ASC") => {
  return R.sort((a, b) => (order === "ASC" ? diffAscDate(a, b, key) : diffDscDate(a, b, key)), data);
};

const dayOptions = (month) => {
  const currentMonth = isBlank(month) ? 1 : month;

  return R.range(1, daysInMonth(currentMonth) + 1).map((day) => {
    const date = dayjs(isoDateString(currentMonth, day), "YYYY-M-D");

    return {
      value: date.format("DD"),
      label: date.format("D"),
    };
  });
};

const destructuredDate = (date) => {
  const parsedDate = dayjs(date, "YYYY-MM-DD");
  return {
    day: parsedDate.format("DD"),
    month: parsedDate.format("MM"),
  };
};

const isToday = (date) => {
  const today = dayjs();
  const currentdate = dayjs(date);
  return today.isSame(currentdate, "day");
};

const getScore = (date) => parseInt(dayjs(date).format("MDD"), 10);

const shouldAddNextYear = (date) => {
  const todayScore = getScore(new Date());
  const celebrateDateScore = getScore(date);
  const currentYear = dayjs().year();
  let yearToCelebrate = currentYear;
  if (todayScore > celebrateDateScore) yearToCelebrate += 1;
  const dateToCelebrate = dayjs(date).year(yearToCelebrate);
  return dateToCelebrate;
};

const nullOption = { day: null, month: null };

const buildEventDate = (date) => {
  const eventDate = dayjs(date);
  return {
    date: eventDate.format("dddd, MMMM D, YYYY"),
    time: eventDate.format("h:mm A"),
    timeWithZone: eventDate.format("h:mm A z"),
    timeZone: dayjs.tz.guess(),
    zoneAbbr: eventDate.format("z"),
  };
};

const getTimeZone = () => dayjs.tz.guess();

const getCurrentDateInUTC = () => dayjs.utc().format("YYYY-MM-DD HH:mm:ss.SSS");

export {
  dateToLeapYearString,
  destructuredDate,
  isToday,
  getTomorrow,
  monthOptions,
  dayOptions,
  daysInMonth,
  formatDateToUTC,
  LEAP_YEAR,
  getScore,
  nullOption,
  shouldAddNextYear,
  sortByKeyDate,
  buildEventDate,
  formatLocalDate,
  getTimeZone,
  getCurrentDateInUTC,
};
