import { isNil } from "ramda";
import dayjs from "dayjs";

import localStorage from "./localStorage";
import { jjLogger } from "./logUtils";

export const LOCAL_STORAGE_EMBER_AUTH_KEY = "ember_simple_auth:session";

export default {
  getAuthInfo() {
    try {
      const authData = localStorage.getItem(LOCAL_STORAGE_EMBER_AUTH_KEY);

      if (isNil(authData)) {
        return null;
      }

      return {
        tokenType: "Bearer",
        accessToken: authData.authenticated.access_token,
        refreshToken: authData.authenticated.refresh_token,
        expiresIn: authData.authenticated.expires_in,
        createdAt: authData.authenticated.created_at,
      };
    } catch (error) {
      const msg = `Error fetching Ember Auth Data: ${error}`;
      jjLogger.log(`emberLoginSync.js | ${msg}`);
    }

    return null;
  },

  setAuthInfo(authData) {
    try {
      localStorage.setItem(LOCAL_STORAGE_EMBER_AUTH_KEY, {
        authenticated: {
          authenticator: "authenticator:oauth2",
          token_type: authData.tokenType,
          access_token: authData.accessToken,
          refresh_token: authData.refreshToken,
          created_at: authData.createdAt,
          expires_in: authData.expiresIn,
          expires_at: dayjs(authData.createdAt * 1000)
            .add(authData.expiresIn, "ms")
            .valueOf(),
        },
      });
    } catch (error) {
      const msg = `Error storing Ember Auth Data: ${error}`;
      jjLogger.log(`emberLoginSync.js | ${msg}`);
    }
  },

  logOut() {
    localStorage.removeItem(LOCAL_STORAGE_EMBER_AUTH_KEY);
  },
};
