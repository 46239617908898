import PropTypes from "prop-types";
import { filter } from "ramda";

import { getPrimaryTag } from "utils/tagUtils";
import { isPresent } from "utils/storeUtils";
import Presenter from "utils/PropTypesPresenter";
import TagPresenter from "presenters/TagPresenter";
import MakeMessagePresenter from "presenters/MakeMessagePresenter";

export default new Presenter(
  {
    id: PropTypes.string,
    type: PropTypes.string,
    thumbnail: PropTypes.string,
    castings: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        type: PropTypes.string,
        roleRank: PropTypes.number,
        headAsset: PropTypes.string,
        jawSvg: PropTypes.string,
      }),
    ),
    userStatus: PropTypes.string,
    makeMessage: MakeMessagePresenter.shape(),
    tags: PropTypes.string,
    status: PropTypes.string.isRequired,
    templateGroupActive: PropTypes.bool,
  },
  {
    template(m, tg) {
      const template = m?.template;

      if (template && tg?.templates) {
        const filtered = filter((t) => t.id === template.id, tg.templates);
        if (filtered.length) return filtered[0];
      }

      return template;
    },
    templateGroupId(m, tg) {
      return this.template(m, tg)?.templateGroupSlug;
    },
    webglPositionData(m, tg) {
      return this.template(m, tg)?.webglPositionData;
    },
    webglFeatureVideo(m, tg) {
      return this.template(m, tg)?.webglFeatureVideo;
    },
    positionData(m, tg) {
      return this.template(m, tg)?.positionData;
    },
    manifest(m, tg) {
      return this.template(m, tg)?.manifest;
    },
    isStreamable(tg) {
      return tg?.streamable;
    },
    hasStreamingAssets(m, tg) {
      return this.isStreamable(tg) && isPresent(this.manifest(m, tg)) && isPresent(this.positionData(m, tg));
    },
    isOwner(m) {
      return this.userStatus(m) === "owner";
    },
    isRecipient(m) {
      return this.userStatus(m) === "recipient";
    },
    isDraft(m) {
      return this.status(m) === "draft";
    },
    isLive(m) {
      return this.status(m) === "live";
    },
    notOwnerButtonText(m) {
      const tags = this.tags(m);
      const primaryTag = getPrimaryTag(tags);
      return TagPresenter.notOwnerButtonText(primaryTag);
    },
    notOwnerButtonPath(m, templateGroupId) {
      const tags = this.tags(m);
      const primaryTag = getPrimaryTag(tags);
      return TagPresenter.notOwnerButtonPath(primaryTag, templateGroupId);
    },
  },
);
