import R from "ramda";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";

import { useFacesActions } from "store/FacesSlice";
import useSettings from "hooks/useSettings";
import useUsers from "hooks/useUsers";
import useAssetUpload from "hooks/useAssetUpload";
import FacePresenter from "presenters/FacePresenter";
import AWSRepository from "repositories/AWSRepository";
import { faceCreateAttributes, faceUpdateAttributes, faceUpdateJawAttributes } from "utils/faceUtils";
import { trackCordialHeadCreationEvent } from "utils/cordialUtils";

const useFaces = () => {
  const {
    loadFaces,
    loadAdditionalFaces,
    deleteFace,
    openModal,
    closeModal,
    setPhoto,
    clearPhoto,
    detectFaces,
    createFace,
    updateFace,
    resetAdded,
    saveTransform,
    resetTransforms,
    clearFoundFaces,
    setMode,
    setPreviousMode,
    resetFaces,
    setRelationToCreate,
    clearRelationToCreate,
    setPersonToAddHead,
    clearPersonToAddHead,
    setFacesToAddPerson,
    clearFacesToAddPerson,
    setFaceToAddJaw,
    clearFaceToAddJaw,
    setIsCasting,
    storePersonByFaceId,
    convertToOrphanFace,
  } = useFacesActions();

  const { uploadHeadAsset } = useAssetUpload();
  const { ensureCurrentUser } = useUsers();

  const {
    settings: { googleVisionKey },
  } = useSettings();

  const {
    faces,
    modalOpened,
    previousMode,
    photo,
    newFaces,
    addedFacesIds,
    transforms,
    mode,
    meta,
    relationToCreate,
    personToAddHead,
    facesToAddPerson,
    faceToAddJaw,
    isCasting,
  } = useSelector((state) => state.FacesSlice);
  const addedFaces = faces && faces.filter(({ id }) => addedFacesIds.includes(id));

  const getGoogleVisionParams = () => ({
    requests: [
      {
        image: { content: photo.split(",")[1] },
        features: [{ type: "FACE_DETECTION" }],
      },
    ],
  });

  const getTransform = (id) => transforms[id] || { face: null, jaw: null };

  const getFaces = (state) => state.FacesSlice.faces;

  const getOrphanFaces = createSelector(getFaces, (storeFaces) => {
    return storeFaces.filter((face) => {
      return !FacePresenter.person(face);
    });
  });

  const saveFace = (faceBlob, svg, person = null) =>
    ensureCurrentUser()
      .then(() => {
        return uploadHeadAsset(AWSRepository.presignedUploadToS3({ method: "heads" }, faceBlob)).then((key) => {
          const attrs = faceCreateAttributes(key, svg);
          return createFace(attrs, person);
        });
      })
      .then((newFace) => {
        trackCordialHeadCreationEvent(newFace.asset);
        if (!person?.id) return newFace;
        const attrs = faceUpdateAttributes(person.id, newFace.id);
        return updateFace(newFace.id, attrs);
      })
      .then((newFace) => newFace);

  const addJawToFace = (face, jawSvg) => {
    const attrs = faceUpdateJawAttributes(face, jawSvg);
    return updateFace(face.id, attrs).then((newFace) => setFaceToAddJaw(newFace));
  };

  const addFaceToPerson = (faceToAddPerson, personSlug) =>
    ensureCurrentUser()
      .then(() => {
        const attrs = faceUpdateAttributes(personSlug, faceToAddPerson.id);
        return updateFace(faceToAddPerson.id, attrs);
      })
      .then((face) => face);

  const updateTransform = (id, changes) => {
    const oldTransform = getTransform(id);
    const newTransform = { ...oldTransform, ...changes };
    saveTransform(id, newTransform);
  };

  const orphanFaces = useSelector(getOrphanFaces);

  return {
    loadFaces,
    loadAdditionalFaces,
    faces,
    orphanFaces,
    meta,
    deleteFace,
    openModal,
    closeModal: () => closeModal(),
    setRelationToCreate,
    clearRelationToCreate,
    relationToCreate,
    modalOpened,
    previousMode,
    photo,
    newFaces,
    setPhoto,
    clearPhoto,
    detectFaces: () => detectFaces(googleVisionKey, getGoogleVisionParams()),
    saveFace,
    resetAdded,
    addedFaces,
    addedFacesIds,
    saveTransform: updateTransform,
    resetTransforms,
    getTransform,
    clearFoundFaces,
    mode,
    setMode,
    setPreviousMode,
    totalFacesCount: R.propOr(faces.count, "totalCount", meta),
    resetFaces,
    setPersonToAddHead,
    clearPersonToAddHead,
    personToAddHead,
    facesToAddPerson,
    setFacesToAddPerson,
    clearFacesToAddPerson,
    addFaceToPerson,
    faceToAddJaw,
    setFaceToAddJaw,
    clearFaceToAddJaw,
    addJawToFace,
    isCasting,
    setIsCasting,
    storePersonByFaceId,
    convertToOrphanFace,
  };
};

export default useFaces;
