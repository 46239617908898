import { breakpointsValues } from "layouts/ThemeProvider/theme";

const capitalize = ([head, ...tail]) => head.toUpperCase() + tail.join("");
const capitalizeWord = ([head, ...tail]) => head.toUpperCase() + tail.join("").toLowerCase();

const replaceFontName = (styles) => {
  if (!styles?.includes("font-family")) return styles;

  const regex = /font-family:\s*"([^"]+)"/;

  const getFontFamily = (css) => {
    const match = css.match(regex);

    return match ? match[1] : null;
  };

  const convertFontName = (fontName) => {
    const name = fontName.split("-")[0];
    return name.replace(/([a-z])([A-Z])/g, "$1 $2");
  };

  return styles?.replace(regex, `font-family: "${convertFontName(getFontFamily(styles))}"`);
};

const createCSS = (className, css) => {
  return `.${className} { ${replaceFontName(css)} }`;
};

const createMobileCSS = (className, css) => {
  return `@media only screen and (max-width: ${breakpointsValues.mobile}px) { ${createCSS(className, css)} }`;
};

export { capitalize, capitalizeWord, createCSS, createMobileCSS };
