const ZEROFACE = {
  isDefault: true,
  scale: 1.0,
  angle: 0.0,
  offset: { x: 0, y: 0 },
  rotation: 0,
};

const HEAD = {
  width: 400,
  height: 600,
};

const HEAD_CUT = {
  width: 800,
  height: 600,
};

const SCALE_HEAD_CUT = 0.7;
const SCALE_FACE_BOX = 1.2;

const FACE_DEFAULT_RELATION = "none";

Object.freeze(ZEROFACE);
Object.freeze(HEAD);
Object.freeze(HEAD_CUT);
Object.freeze(SCALE_HEAD_CUT);
Object.freeze(SCALE_FACE_BOX);
Object.freeze(FACE_DEFAULT_RELATION);

export { ZEROFACE, HEAD, HEAD_CUT, SCALE_HEAD_CUT, SCALE_FACE_BOX, FACE_DEFAULT_RELATION };
