import { useEffect, useState, useRef } from "react";

const useHover = () => {
  const [value, setValue] = useState(false);

  const handleMouseOver = () => setValue(true);
  const handleMouseOut = () => setValue(false);

  const ref = useRef(null);

  useEffect(() => {
    if (ref && ref.current) {
      ref.current.addEventListener("mouseover", handleMouseOver);
      ref.current.addEventListener("mouseout", handleMouseOut);
    }

    return () => {
      if (ref && ref.current) {
        ref.current.removeEventListener("mouseover", handleMouseOver);
        ref.current.removeEventListener("mouseout", handleMouseOut);
      }
    };
  }, []);

  return [ref, value];
};

export default useHover;
