import React from "react";
import { Helmet } from "react-helmet";

const MetaRobots = () => {
  const { origin, pathname } = window.location;
  const matchOrigin = origin === "https://www.jibjab.com";
  const matchPath = pathname.match(/^\/create\/|^\/account\//);
  const setNoindex = !matchOrigin || matchPath;

  return (
    setNoindex && (
      <Helmet>
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
    )
  );
};

export default MetaRobots;
