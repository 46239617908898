import PropTypes from "prop-types";

import Presenter from "utils/PropTypesPresenter";

export default new Presenter({
  id: PropTypes.string,
  recipient: PropTypes.string,
  message: PropTypes.string,
  sender: PropTypes.string,
});
