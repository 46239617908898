import axios from "axios";

import { externalRoutes } from "routes";

export default {
  detectFaces(key, params) {
    const url = externalRoutes.googleVisionPath(key);
    return axios.post(url, params, { timeout: 20000 });
  },
};
