import dayjs from "dayjs";
import DateHolidays from "date-holidays";
import { sortBy, find, propEq } from "ramda";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";

import Passover from "assets/images/category/Passover@2x.png";
import NewYear from "assets/images/category/NewYear@2x.png";
import ValentinesDay from "assets/images/category/ValentinesDay@2x.png";
import StPatrickssDay from "assets/images/category/StPatrickssDay@2x.png";
import Easter from "assets/images/category/Easter@2x.png";
import MothersDay from "assets/images/category/MothersDay@2x.png";
import FathersDay from "assets/images/category/FathersDay@2x.png";
import IndepdenceDay from "assets/images/category/IndepdenceDay@2x.png";
import Halloween from "assets/images/category/Halloween@2x.png";
import Thanksgiving from "assets/images/category/Thanksgiving@2x.png";
import Christmas from "assets/images/category/Christmas@2x.png";
import Hanukkah from "assets/images/category/Hanukkah@2x.png";
import Birthday from "assets/images/category/Birthday@2x.png";
import SpanishBirthday from "assets/images/category/SpanishBday.png";
import MusicVideos from "assets/images/category/MusicVideos@2x.png";
import Dances from "assets/images/category/Dances@2x.png";
import Love from "assets/images/category/Love@2x.png";
import Thanks from "assets/images/category/Thanks@2x.png";
import Anniversary from "assets/images/category/Anniversary@2x.png";
import Graduation from "assets/images/category/Graduation@2x.png";
import Weddings from "assets/images/category/Weddings@2x.png";
import Baby from "assets/images/category/Baby@2x.png";
import Congrats from "assets/images/category/Congrats@2x.png";
import GetWell from "assets/images/category/GetWell@2x.png";
import CheerUp from "assets/images/category/CheerUp@2x.png";
import GoodLuck from "assets/images/category/GoodLuck@2x.png";
import Sorry from "assets/images/category/Sorry@2x.png";
import Sports from "assets/images/category/Sports@2x.png";
import YouRock from "assets/images/category/YouRock@2x.png";
import OfficeFun from "assets/images/category/OfficeFun@2x.png";
import Random from "assets/images/category/Random@2x.png";
import StarringYou from "assets/images/category/StarringYou@2x.png";
import FreeEcards from "assets/images/category/FreeEcards@2x.png";
import { DATE_TYPE } from "enums";
import { capitalize } from "utils/stringUtils";

const USHolidays = new DateHolidays("US");
const currentYear = (dateSelected) => dayjs(dateSelected).year();
const yearUSHolidays = (dateSelected) => USHolidays.getHolidays(currentYear(dateSelected));

const CATEGORY_HOLIDAYS_REMAIN_AT_TOP_IN_DAYS = 45;

export const holidays = (dateSelected) => [
  {
    name: "New Years",
    slug: "new-years-ecards",
    date: dayjs(`1/1/${currentYear(dateSelected)}`, "M/D/Y"),
    img: {
      alt: "New Years eCards",
      title: "New Years",
      src: NewYear,
    },
  },
  {
    name: "Valentine's Day",
    slug: "valentines-ecards",
    date: dayjs(`2/14/${currentYear(dateSelected)}`, "M/D/Y"),
    img: {
      alt: "Valentine's Day eCards",
      title: "Valentine's Day",
      src: ValentinesDay,
    },
  },
  {
    name: "St. Patrick's Day",
    slug: "st-patricks-day-ecards",
    date: dayjs(`3/17/${currentYear(dateSelected)}`, "M/D/Y"),
    img: {
      alt: "St. Patrick's Day eCards",
      title: "St. Patrick's Day",
      src: StPatrickssDay,
    },
  },
  {
    name: "Easter",
    slug: "easter-ecards",
    date: dayjs(yearUSHolidays(dateSelected).find(({ name }) => name === "Easter Sunday").date).set(
      "year",
      currentYear(dateSelected),
    ),
    img: {
      alt: "Easter eCards",
      title: "Easter",
      src: Easter,
    },
  },
  {
    name: "Passover",
    slug: "passover-ecards",
    date: dayjs(`04/27/${currentYear(dateSelected)}`, "M/D/Y"),
    img: {
      alt: "Passover eCards",
      title: "Passover",
      src: Passover,
    },
  },
  {
    name: "Mother's Day",
    slug: "mothers-day-ecards",
    date: dayjs(yearUSHolidays(dateSelected).find(({ name }) => name === "Mother's Day").date).set(
      "year",
      currentYear(dateSelected),
    ),
    img: {
      alt: "Mother's Day eCards",
      title: "Mother's Day",
      src: MothersDay,
    },
  },
  {
    name: "Father's Day",
    slug: "fathers-day-ecards",
    date: dayjs(yearUSHolidays(dateSelected).find(({ name }) => name === "Father's Day").date).set(
      "year",
      currentYear(dateSelected),
    ),
    img: {
      alt: "Father's Day eCards",
      title: "Father's Day",
      src: FathersDay,
    },
  },
  {
    name: "Independence Day",
    slug: "independence-day-ecards",
    date: dayjs(`7/4/${currentYear(dateSelected)}`, "M/D/Y"),
    img: {
      alt: "Independence Day eCards",
      title: "Independence Day",
      src: IndepdenceDay,
    },
  },
  {
    name: "Halloween",
    slug: "halloween-ecards",
    date: dayjs(`10/31/${currentYear(dateSelected)}`, "M/D/Y"),
    img: {
      alt: "Halloween eCards",
      title: "Halloween",
      src: Halloween,
    },
  },
  {
    name: "Thanksgiving",
    slug: "thanksgiving-ecards",
    date: dayjs(yearUSHolidays(dateSelected).find(({ name }) => name === "Thanksgiving Day").date).set(
      "year",
      currentYear(dateSelected),
    ),
    img: {
      alt: "Thanksgiving eCards",
      title: "Thanksgiving",
      src: Thanksgiving,
    },
  },
  {
    name: "Christmas",
    slug: "holiday-ecards",
    date: dayjs(`12/25/${currentYear(dateSelected)}`, "M/D/Y"),
    img: {
      alt: "Christmas eCards",
      title: "Christmas",
      src: Christmas,
    },
  },
  {
    name: "Hanukkah",
    slug: "hanukkah-ecards",
    date: dayjs(`12/25/${currentYear(dateSelected)}`, "M/D/Y"),
    img: {
      alt: "Hanukkah eCards",
      title: "Hanukkah",
      src: Hanukkah,
    },
  },
];

export const nonHolidays = [
  {
    name: "Birthdays",
    slug: "birthday-ecards",
    img: {
      alt: "Birthdays eCards",
      title: "Birthdays",
      src: Birthday,
    },
  },
  {
    name: "Cumpleaños",
    slug: "cumpleanos",
    img: {
      alt: "Tarjetas de Feliz Cumpleaños",
      title: "Cumpleaños",
      src: SpanishBirthday,
    },
  },
  {
    name: "Music Videos",
    slug: "music-video-ecards",
    img: {
      alt: "Music Videos eCards",
      title: "Music Videos",
      src: MusicVideos,
    },
  },
  {
    name: "Dances",
    slug: "dance-ecards",
    img: {
      alt: "Dances eCards",
      title: "Dances",
      src: Dances,
    },
  },
  {
    name: "Love",
    slug: "love-ecards",
    img: {
      alt: "Love eCards",
      title: "Love",
      src: Love,
    },
  },
  {
    name: "Thanks",
    slug: "thank-you-ecards",
    img: {
      alt: "Thanks eCards",
      title: "Thanks",
      src: Thanks,
    },
  },
  {
    name: "Anniversary",
    slug: "anniversary-ecards",
    img: {
      alt: "Anniversary eCards",
      title: "Anniversary",
      src: Anniversary,
    },
  },
  {
    name: "Weddings",
    slug: "wedding-ecards",
    img: {
      alt: "Wedding & Engagement eCards",
      title: "Wedding &amp;<br>Engagement",
      src: Weddings,
    },
  },
  {
    name: "Graduation",
    slug: "graduation-ecards",
    img: {
      alt: "Graduation eCards",
      title: "Graduation",
      src: Graduation,
    },
  },
  {
    name: "Baby",
    slug: "baby-ecards",
    img: {
      alt: "Baby eCards",
      title: "Baby",
      src: Baby,
    },
  },
  {
    name: "You Rock",
    slug: "you-rock-ecards",
    img: {
      alt: "You Rock eCards",
      title: "You Rock",
      src: YouRock,
    },
  },
  {
    name: "Get well",
    slug: "get-well-ecards",
    img: {
      alt: "Get Well eCards",
      title: "Get Well",
      src: GetWell,
    },
  },
  {
    name: "Congrats",
    slug: "congrats-ecards",
    img: {
      alt: "Congrats eCards",
      title: "Congrats",
      src: Congrats,
    },
  },
  {
    name: "Cheer Up",
    slug: "cheer-up-ecards",
    img: {
      alt: "Cheer Up eCards",
      title: "Cheer Up",
      src: CheerUp,
    },
  },
  {
    name: "Good Luck",
    slug: "good-luck-ecards",
    img: {
      alt: "Good Luck eCards",
      title: "Good Luck",
      src: GoodLuck,
    },
  },
  {
    name: "Sorry",
    slug: "sorry-ecards",
    img: {
      alt: "Sorry eCards",
      title: "Sorry",
      src: Sorry,
    },
  },
  {
    name: "Sports",
    slug: "sports-ecards",
    img: {
      alt: "Sports eCards",
      title: "Sports",
      src: Sports,
    },
  },
  {
    name: "Office Fun",
    slug: "office-ecards",
    img: {
      alt: "Office Fun eCards",
      title: "Office Fun",
      src: OfficeFun,
    },
  },
  {
    name: "Random",
    slug: "random-ecards",
    img: {
      alt: "Random eCards",
      title: "Random",
      src: Random,
    },
  },
  {
    name: "Starring You",
    slug: "starring-you-ecards",
    img: {
      alt: "Starring You eCards",
      title: "Starring You",
      src: StarringYou,
    },
  },
  {
    name: "Free",
    slug: "free-ecards",
    img: {
      alt: "Free Ecards",
      title: "Free",
      src: FreeEcards,
    },
  },
];

export const getRelationsOccasionDay = () => {
  dayjs.extend(isSameOrBefore);
  const currentYearHolidays = holidays(dayjs());
  const valentinesDayCurrentYear = find(propEq("name", capitalize(DATE_TYPE.valentines)))(currentYearHolidays).date;
  const mothersDayCurrentYear = find(propEq("name", capitalize(DATE_TYPE.mothersDay)))(currentYearHolidays).date;
  const fathersDayCurrentYear = find(propEq("name", capitalize(DATE_TYPE.fathersDay)))(currentYearHolidays).date;

  const nextYearHolidays = holidays(dayjs().add(1, "year"));
  const mothersDayNextYear = find(propEq("name", capitalize(DATE_TYPE.mothersDay)))(nextYearHolidays).date;
  const fathersDayNextYear = find(propEq("name", capitalize(DATE_TYPE.fathersDay)))(nextYearHolidays).date;

  const isSameOrBeforeMothersDay = dayjs().isSameOrBefore(mothersDayCurrentYear);
  const isSameOrBeforeFathersDay = dayjs().isSameOrBefore(fathersDayCurrentYear);

  return {
    valentinesDay: valentinesDayCurrentYear,
    mothersDay: isSameOrBeforeMothersDay ? mothersDayCurrentYear : mothersDayNextYear,
    fathersDay: isSameOrBeforeFathersDay ? fathersDayCurrentYear : fathersDayNextYear,
  };
};

export const sortHolidays = (dateSelected = dayjs()) => {
  const fn = sortBy((holiday, startDate = dayjs(dateSelected)) => {
    const diff = dayjs(holiday.date, "MM/DD/YYYY")
      .startOf("day")
      .add(1, "day")
      .diff(startDate.startOf("day"), "days");
    return diff < 0 ? diff + 365 : diff;
  });

  return fn(holidays(dateSelected));
};

export const categoriesPage = (dateSelected) => {
  const categories = [];
  const sortedHolidays = sortHolidays(dateSelected);
  const totalHolidays = sortedHolidays.length;
  const today = dayjs(dateSelected);
  let next = 0;

  while (
    next < totalHolidays &&
    sortedHolidays[next].date.diff(today, "days") < CATEGORY_HOLIDAYS_REMAIN_AT_TOP_IN_DAYS
  ) {
    categories.push(sortedHolidays[next]);
    next += 1;
  }

  return [...categories, ...nonHolidays, ...sortedHolidays.slice(next)];
};
