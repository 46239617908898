import { contentfulRoutes } from "routes";
import FetchHelpers from "utils/FetchHelpers";

export default {
  show(slug) {
    const url = contentfulRoutes.page(slug);
    const headers = { Accept: "application/vnd.api+json" };
    return FetchHelpers.get(url, {}, headers);
  },
};
