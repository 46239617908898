import IconButton from "@mui/material/IconButton";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import React from "react";
import PropTypes from "prop-types";

import useStyles from "./useStyles";

const Avatar = ({ onClick }) => {
  const classes = useStyles();

  return (
    <IconButton alt="Account management menu" onClick={onClick} className={classes.avatar}>
      <AccountCircleOutlinedIcon />
    </IconButton>
  );
};

Avatar.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default Avatar;
