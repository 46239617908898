import { useState, useEffect } from "react";
import R from "ramda";

import overlay from "assets/images/share-overlay-logo.png";
import placeholder from "assets/images/placeholder-headcut.png";
import FacePresenter from "presenters/FacePresenter";
import TemplateGroupPresenter from "presenters/TemplateGroupPresenter";
import { resetCacheKey } from "utils/imageUtils";
import { jjLogger } from "utils/logUtils";
import localStorage from "utils/localStorage";
import { setCastingOrder } from "utils/castingUtils";
import useFaces from "hooks/useFaces";
import useCalendar from "hooks/useCalendar";
import usePeople from "hooks/usePeople";
import useWebGLRenderLibrary from "hooks/useWebGLRenderLibrary";
import { filterFirstPersonByRelation } from "utils/relativeUtils";
import { FACE_RELATIONS_TAGS, UNIQUE_RELATIONS_KEYS, FACE_RELATIONS } from "enums";

const CONSTANTS = {
  castingCardId: "castingCardID",
  canvasId: "casting-canvas",
};

const faceToCast = (face, role = null) => {
  const headID = FacePresenter.id(face);
  const imageURL = resetCacheKey(FacePresenter.asset(face));
  const toCast = {
    headID,
    imageURL,
    role,
  };

  if (R.isEmpty(headID) || R.isEmpty(imageURL)) {
    jjLogger.log(
      `AutoCast: useCasting.js - faceToCast - Error to get data from Face, Face: ${JSON.stringify(
        face,
      )} - toCast: ${JSON.stringify(toCast)}`,
    );
  }

  jjLogger.logDebug("AutoCast: useCasting.js - faceToCast - toCast with all data: ");
  jjLogger.logDebug(toCast);

  return toCast;
};

const getCastingParams = (url) => {
  return {
    feature: url,
    placeHolderImage: placeholder,
    target: CONSTANTS.canvasId,
    id: CONSTANTS.castingCardID,
    shareThumb: {
      width: 460,
      height: 240,
      overlayImage: overlay,
    },
  };
};

const castToRoles = (cast) => {
  const roles = {};
  cast.forEach((head) => {
    roles[head.role] = head;
  });
  return roles;
};

const setNewOrder = (order, castingCount) => {
  if (!order || R.isEmpty(order)) {
    return R.range(1, R.inc(castingCount)).reverse();
  }
  const newOrder = order.filter((x) => x <= castingCount);
  if (newOrder.length < castingCount) {
    for (let i = Math.max(...newOrder) + 1; i <= castingCount; i += 1) {
      newOrder.unshift(i);
    }
  }
  return newOrder;
};

export const deleteHeadFromCastingData = (headID) => {
  const castingData = localStorage.getItem("castingData");
  if (!castingData) return undefined;
  const { roles, order } = castingData;
  const deletedRoles = [];
  R.map((role) => (role.headID === headID ? deletedRoles.push(role.role) : role), roles);
  deletedRoles.forEach((role) => delete roles[role]);
  return localStorage.setItem("castingData", { roles, order });
};

const setCastingData = (card, order = null) => {
  if (R.isNil(card)) {
    jjLogger.log(`AutoCast: setCastingData - Card is Nil while trying to cast the data: ${JSON.stringify(card)}`);
    return;
  }

  if (R.isEmpty(card)) {
    jjLogger.log(`AutoCast: setCastingData - Card is Empty while trying to cast the data: ${JSON.stringify(card)}`);
    return;
  }

  const { actors } = card;
  const headsWithoutPlaceholders = R.values(actors).filter((actor) => actor.headID);
  const newRoles = castToRoles(headsWithoutPlaceholders);

  Object.entries(newRoles).forEach((newRole) => {
    if (R.isEmpty(newRole.headID) || R.isEmpty(newRole.imageURL)) {
      jjLogger.log(
        `AutoCast: setCastingData - Head id or imageURL not present on newRoles object: ${JSON.stringify(newRole)}`,
      );
    }
  });

  if (order) {
    localStorage.setItem("castingData", { roles: newRoles, order });
    return;
  }

  const currentCastingData = localStorage.getItem("castingData");
  if (!currentCastingData) {
    const newOrder = R.keys(newRoles)
      .sort()
      .reverse();
    const castingData = { order: newOrder, roles: newRoles };
    localStorage.setItem("castingData", castingData);
    return;
  }
  const { roles: currenrRoles, order: currentOrder } = currentCastingData;
  const changedRole = R.difference(R.keys(newRoles), R.keys(currenrRoles))
    .map((cast) => cast.role)
    .pop();
  const newOrder = R.includes(changedRole, currentOrder)
    ? currentOrder.sort((role) => {
        return role === changedRole ? -1 : 0;
      })
    : R.prepend(changedRole, currentOrder);

  const castingData = {
    order: changedRole ? newOrder : currentOrder,
    roles: newRoles,
  };
  localStorage.setItem("castingData", castingData);
};

const useCasting = () => {
  const [card, setCard] = useState(null);
  const [faces, setFace] = useState(null);
  const [peopleCalendarBirthday, setPeopleCalendarBirthday] = useState([]);
  const [people, setPeople] = useState([]);
  const [templateGroup, setTemplateGroup] = useState(null);
  const { faces: userFaces, addedFaces, resetAdded } = useFaces();
  const { personWithBirthdaysInThrityDays } = useCalendar();
  const { peopleToCast, resetPeopleToCast } = usePeople();
  const { load: loadRenderLib, renderLib } = useWebGLRenderLibrary();
  const castingCount = TemplateGroupPresenter.castsCount(templateGroup);
  const [roleIndex, setRoleIndex] = useState(null);
  const [loadedFacesCount, setLoadedFacesCount] = useState(null);

  useEffect(() => {
    loadRenderLib();
  }, []);

  useEffect(() => {
    if (R.isNil(renderLib) || R.isNil(templateGroup) || R.isEmpty(templateGroup)) return;

    if (window.castingCard) {
      setRoleIndex(window.castingCard.roleIndex < window.castingCard.roles - 1 ? window.castingCard.roleIndex + 1 : 0);
    }
    const { CastingCard } = renderLib;
    const parameters = getCastingParams(TemplateGroupPresenter.castingCardThumb(templateGroup));
    const castingCard = new CastingCard(parameters);
    window.castingCard = castingCard;
    castingCard.init().then(() => {
      setCard(castingCard);
    });
  }, [renderLib, templateGroup]);

  const init = (face, tg, peopleCalendarBday, peopleRelated) => {
    setLoadedFacesCount(face.length);
    setFace(face);
    setTemplateGroup(tg);
    setPeopleCalendarBirthday(peopleCalendarBday);
    setPeople(peopleRelated);
  };

  const cleanup = () => {
    if (card) {
      card.destroy();
      setCard(null);
    }
  };

  const swap = () => {
    try {
      if (castingCount === 2) {
        const actorsKeys = Object.keys(card?.actors) || [];
        const newActorsOrder = actorsKeys.map((key, index) => {
          return {
            ...card.actors[key],
            role: index === 0 ? 2 : 1,
          };
        });
        card.addCast(newActorsOrder).then(() => {
          card.update();
          setCastingData(card);
        });
      }
    } catch (error) {
      jjLogger.log(
        `AutoCast: useCasting.js - swap() - Empty newActorsOrder array passed to CastingCard or actors is null - ${error}`,
      );
    }
  };

  const castFace = (face, role = null) => {
    const cast = faceToCast(face, role);

    jjLogger.logDebug("useCasting.js - castFace():");
    jjLogger.logDebug(cast);

    try {
      return card.addCastMember(cast).then(() => {
        card.update();
        setCastingOrder();
        return setCastingData(card);
      });
    } catch (error) {
      return jjLogger.logError(`useCasting.js - castFace() - Error: ${error}`);
    }
  };

  const getRolesForBirthdays = (birthdayPerson) => {
    const faceOfPersonWithBirthday = birthdayPerson?.defaultHead;
    if (castingCount === 1) {
      return [faceOfPersonWithBirthday];
    }
    const personMe = filterFirstPersonByRelation(UNIQUE_RELATIONS_KEYS[0], people) || null;
    const toMapCasting = personMe ? [faceOfPersonWithBirthday, personMe?.defaultHead] : [faceOfPersonWithBirthday];
    return toMapCasting;
  };

  const smartCastRelation = () => {
    let peopleWithRelationToCast = [];

    const getPeopleWithRelationToCast = (relationToFilter) => {
      const filteredPersonByRelation = filterFirstPersonByRelation(relationToFilter, people);
      if (filteredPersonByRelation) {
        if (castingCount === 1) return [filteredPersonByRelation];
        const filterMe = filterFirstPersonByRelation(FACE_RELATIONS.me.toLowerCase(), people);
        return filterMe ? [filteredPersonByRelation, filterMe] : [filteredPersonByRelation];
      }
      return [];
    };

    switch (true) {
      case TemplateGroupPresenter.hasTag(templateGroup, FACE_RELATIONS_TAGS.mother): {
        peopleWithRelationToCast = getPeopleWithRelationToCast(FACE_RELATIONS_TAGS.mother);
        break;
      }
      case TemplateGroupPresenter.hasTag(templateGroup, FACE_RELATIONS_TAGS.father): {
        peopleWithRelationToCast = getPeopleWithRelationToCast(FACE_RELATIONS_TAGS.father);
        break;
      }
      case TemplateGroupPresenter.hasTag(templateGroup, "valentines", "anniversary"): {
        peopleWithRelationToCast = getPeopleWithRelationToCast(FACE_RELATIONS.partner.toLowerCase());
        break;
      }
      default:
    }

    return peopleWithRelationToCast;
  };

  const validateActors = (actors) => {
    actors.forEach((actor) => {
      if (!actor.headID || !actor.imageURL) {
        jjLogger.log(
          `useCasting.js - validateActors() - Missing head from castingCard.addCast - actor : ${JSON.stringify(actor)}`,
        );

        const uncastedFaces = userFaces.filter(
          (face) =>
            !actors
              .filter((actorToCheck) => {
                return typeof actorToCheck.headID !== "undefined";
              })
              .map((actorToCheck) => actorToCheck.headID)
              .includes(face.id),
        );

        if (!R.isEmpty(uncastedFaces)) {
          jjLogger.log("useCasting.js - validateActors() - Calling castFace()");
          castFace(uncastedFaces[0], actor.role);
          jjLogger.log(
            `useCasting.js - validateActors() - card.addCast - face ${JSON.stringify(
              uncastedFaces[0],
            )} added to missing role: ${actor.role}`,
          );
        }
      }
    });
  };

  const autoCast = async () => {
    const facesFromPeopleToOmit = [];

    if (peopleToCast.length > 0) {
      const castings = peopleToCast.map((person, index) => faceToCast(person.defaultHead, index + 1));
      resetPeopleToCast();
      return card
        .addCast(castings)
        .then(() => {
          card.update();
          setCastingOrder();
          setCastingData(card);
        })
        .catch((error) => {
          jjLogger.log(`AutoCast: useCasting.js - autocast() - Data from peopleToCast is invalid - ${error}`);
        });
    }

    const peopleWithRelationToCast = smartCastRelation();
    if (peopleWithRelationToCast.length > 0) {
      facesFromPeopleToOmit.push(...peopleWithRelationToCast.map((person) => person.id));
      const castings = peopleWithRelationToCast.map((person, index) => faceToCast(person.defaultHead, index + 1));

      await card
        .addCast(castings)
        .then(() => {
          card.update();
          setCastingOrder();
          setCastingData(card);
        })
        .catch((error) => {
          jjLogger.log(`AutoCast: useCasting.js - autocast() - Data from smartCastRelation() is invalid - ${error}`);
        });
    } else if (TemplateGroupPresenter.hasTag(templateGroup, "birthday") && R.isEmpty(addedFaces)) {
      const birthdayPerson = personWithBirthdaysInThrityDays(peopleCalendarBirthday);
      if (!R.isNil(birthdayPerson)) {
        const toMapCasting = getRolesForBirthdays(birthdayPerson);
        facesFromPeopleToOmit.push(...toMapCasting.map((face) => face?.person?.id));
        const castings = toMapCasting.map((face, index) => faceToCast(face, index + 1));
        await card
          .addCast(castings)
          .then(() => {
            card.update();
            setCastingData(card);
            setCastingOrder();
          })
          .catch((error) => {
            jjLogger.log(
              `AutoCast: useCasting.js - autocast() - smartCastBirthday - Empty castings object passed to addCast or this value is null - ${error}`,
            );
          });
      }
    }

    const castingData = localStorage.getItem("castingData");
    const facesWithJaws = faces.filter(FacePresenter.jaw);

    if (!castingData) {
      jjLogger.logDebug("AutoCast: useCasting.js - autocast() - no casting data");
      const castings = facesWithJaws.slice(0, castingCount).map((face, index) => faceToCast(face, R.inc(index)));
      resetAdded();

      jjLogger.logDebug("AutoCast: useCasting.js - autocast() - add casting data ");
      jjLogger.logDebug(castings);

      return card
        .addCast(castings)
        .then(() => {
          card.update();
          setCastingData(card);
        })
        .catch((error) => {
          jjLogger.logError(`useCasting.js - autocast() - Error: ${error}`);
        });
    }

    const { roles, order } = castingData;
    const newOrder = setNewOrder(order, castingCount);
    if (!R.isEmpty(addedFaces)) {
      jjLogger.logDebug("AutoCast: useCasting.js - autocast() - Added faces not empty");
      const currentEmptyRoles = newOrder.filter(
        (el) => !R.values(roles).find((head) => Number(head.role) === Number(el)),
      );
      const castingAddedFaces = addedFaces.map((face) => {
        const role = R.isEmpty(currentEmptyRoles) ? R.last(newOrder) : currentEmptyRoles.pop();
        jjLogger.logDebug("AutoCast: useCasting.js - autocast() - casting added faces to empty role:");
        jjLogger.logDebug(role);
        newOrder.forEach((item, i) => {
          if (item === role) {
            newOrder.splice(i, 1);
            newOrder.unshift(item);
          }
        });

        jjLogger.logDebug("AutoCast: useCasting.js - autocast() - face to cast:");
        jjLogger.logDebug(face);
        const toCast = faceToCast(face, Number(role));
        jjLogger.logDebug("AutoCast: useCasting.js - autocast() - toCast:");
        jjLogger.logDebug(toCast);

        return toCast;
      });
      const addedRoles = castToRoles(castingAddedFaces);

      const emptyRoles = newOrder.filter(
        (el) => !R.concat(castingAddedFaces, R.values(roles)).find((head) => Number(head.role) === Number(el)),
      );
      const freeFaces = facesWithJaws.filter(
        (el) => !R.concat(castingAddedFaces, R.values(roles)).find((head) => head.headID === el.id),
      );
      const castingFillerHeads = freeFaces.slice(0, emptyRoles.length).map((face) => {
        const role = emptyRoles.pop();
        return faceToCast(face, role);
      });
      const rolesFillers = castToRoles(castingFillerHeads);

      const updatedRoles = { ...roles, ...addedRoles, ...rolesFillers };

      resetAdded();
      const toCastSlice = R.slice(0, castingCount, R.values(updatedRoles));
      jjLogger.logDebug("AutoCast: useCasting.js - autocast() - add cast no castingData:");
      jjLogger.logDebug(toCastSlice);

      return card.addCast(toCastSlice).then((actors) => {
        validateActors(actors);

        if (!R.isNil(roleIndex)) {
          card.roleIndex = roleIndex;
          card.update();
          setCastingOrder();
        }

        return setCastingData(card, newOrder);
      });
    }
    const emptyRoles = newOrder.filter((el) => !R.values(roles).find((head) => Number(head.role) === Number(el)));
    const freeFaces = facesWithJaws.filter((el) => !R.values(roles).find((head) => head.headID === el.id));
    const freeFacesWithOmit = freeFaces.filter((face) => !R.includes(face?.person?.id || null, facesFromPeopleToOmit));
    if (emptyRoles.length > freeFacesWithOmit?.length) {
      setLoadedFacesCount(emptyRoles.length - freeFacesWithOmit?.length);
    }
    const castingFillerHeads = freeFacesWithOmit.slice(0, emptyRoles.length).map((face) => {
      const role = emptyRoles.pop();
      const toCast = faceToCast(face, Number(role));

      jjLogger.logDebug("useCasting.js - autocast() - delete face data to cast - original toCast:");
      jjLogger.logDebug(toCast);

      return toCast;
    });
    const rolesFillers = castToRoles(castingFillerHeads);
    const updatedRoles = { ...roles, ...rolesFillers };
    const toCastSliceUpdateRoles = R.slice(0, castingCount, R.values(updatedRoles));
    jjLogger.logDebug("AutoCast: useCasting.js - autocast() - toCastSliceUpdateRoles:");
    jjLogger.logDebug(toCastSliceUpdateRoles);
    return card
      .addCast(toCastSliceUpdateRoles)
      .then((actors) => {
        validateActors(actors);
        card.update();
        setCastingOrder();
        return setCastingData(card);
      })
      .catch((error) => jjLogger.log(error));
  };

  const getRoles = () => {
    if (!card) return null;

    const roles = R.values(card.actors).map(({ role, headID }) => ({ roleRank: role, headID }));

    roles.forEach((role, index) => {
      if (!role.headID) {
        jjLogger.logDebug("AutoCast: useCasting.js - getRoles() - role without headID - role:");
        jjLogger.logDebug(role);
        const uncastedFaces = userFaces.filter(
          (face) =>
            !roles
              .filter((roleToCheck) => {
                return typeof roleToCheck.headID !== "undefined";
              })
              .map((roleToCheck) => roleToCheck.headID)
              .includes(face.id),
        );

        if (!R.isEmpty(uncastedFaces)) {
          roles[index] = { roleRank: role.roleRank, headID: uncastedFaces[0].id };
          jjLogger.logDebug("AutoCast: useCasting.js - getRoles() - oles fixed - role:");
          jjLogger.logDebug(roles[index]);
        }
      }
    });

    return roles;
  };

  const getData = () => {
    return {
      roles: getRoles(),
      makeThumbUrl: card.getThumbnail(),
      shareThumbUrl: card.getSharedThumbnail(),
      templateId: TemplateGroupPresenter.template(templateGroup).id,
    };
  };

  return { CONSTANTS, card, autoCast, init, cleanup, castFace, getData, castingCount, swap, loadedFacesCount };
};

export default useCasting;
