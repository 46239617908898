import { apiRoutes } from "routes";
import FetchHelpers from "utils/FetchHelpers";

export default {
  create(params) {
    const url = apiRoutes.paypalExpressTokensPath(1);
    return FetchHelpers.post(url, params);
  },
  show(id) {
    const url = apiRoutes.paypalExpressTokenPath(1, id);
    return FetchHelpers.get(url);
  },
};
