import { useParams, useLocation, useHistory, useRouteMatch, matchPath } from "react-router-dom";
import { useMemo } from "react";
import qs from "qs";

import { camelize } from "utils/keysConverter";
import appRoutes from "routes/appRoutes";

const useRouter = () => {
  const params = useParams();
  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch;

  return useMemo(() => {
    const camelizedQuery = camelize({
      ...qs.parse(location.search, { ignoreQueryPrefix: true }),
      ...params,
    });
    return {
      push: history.push,
      replace: history.replace,
      pathname: location.pathname,
      query: {
        ...qs.parse(location.search, { ignoreQueryPrefix: true }),
        ...params,
      },
      camelizedQuery,
      match,
      matchPath,
      location,
      history,
      pushWithSave: (newPathname) => {
        const { backUrl } = camelizedQuery;
        const newSearch = qs.stringify({ backUrl: backUrl || location.pathname });
        history.push({ pathname: newPathname, search: newSearch });
      },
      replaceWithSave: (newPathname) => {
        const { backUrl } = camelizedQuery;
        const newSearch = qs.stringify({ backUrl: backUrl || location.pathname });
        history.replace({ pathname: newPathname, search: newSearch });
      },
      pushWithRestore: () => {
        const { backUrl } = camelize({ ...qs.parse(location.search, { ignoreQueryPrefix: true }), ...params });
        if (backUrl) {
          history.replace(backUrl);
        }
      },
      push404: (referrer = location.pathname) => {
        history.push({ pathname: appRoutes.page404Path(), state: { referrer } });
      },
      pushLogin: (referrer = location.pathname) => {
        history.push({ pathname: appRoutes.loginPath(), state: { referrer } });
      },
    };
  }, [params, match, location, history]);
};

export default useRouter;
