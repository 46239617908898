import PropTypes from "prop-types";

import { createCSS, createMobileCSS } from "utils/stringUtils";
import Presenter from "utils/PropTypesPresenter";
import { DEFAULT_INVITE_THEME } from "enums";

export default new Presenter(
  {
    id: PropTypes.string,
    name: PropTypes.string,
    googleFontsLink: PropTypes.string,
    titleDesktop: PropTypes.string,
    titleMobile: PropTypes.string,
    hostLabelDesktop: PropTypes.string,
    hostLabelMobile: PropTypes.string,
    hostDesktop: PropTypes.string,
    hostMobile: PropTypes.string,
    descriptionDesktop: PropTypes.string,
    descriptionMobile: PropTypes.string,
    locationLabelDesktop: PropTypes.string,
    locationLabelMobile: PropTypes.string,
    locationDesktop: PropTypes.string,
    locationMobile: PropTypes.string,
    dateLabelDesktop: PropTypes.string,
    dateLabelMobile: PropTypes.string,
    dateDesktop: PropTypes.string,
    dateMobile: PropTypes.string,
    detailsDesktop: PropTypes.string,
    detailsMobile: PropTypes.string,
    guestListDesktop: PropTypes.string,
    guestListMobile: PropTypes.string,
    invitationBackgroundColor: PropTypes.string,
    guestListBackgroundColor: PropTypes.string,
    eventDetailsBackgroundColor: PropTypes.string,
    eventBlock2BackgroundColor: PropTypes.string,
    invitationBorderColor: PropTypes.string,
  },
  {
    toCSS(theme) {
      let themeCSS = "";
      const classes = [
        "title",
        "hostLabel",
        "host",
        "description",
        "dateLabel",
        "date",
        "locationLabel",
        "location",
        "details",
        "guestList",
        "guestListTitle",
      ];

      classes.forEach((className) => {
        const desktop = createCSS(className, theme[`${className}Desktop`]);
        const mobile = createMobileCSS(className, theme[`${className}Mobile`]);

        themeCSS += `${desktop} ${mobile}`;
      });

      return themeCSS;
    },
    fontsLink(theme) {
      const regex = /href="(.*?)"/g;
      const matches = this.googleFontsLink(theme)?.match(regex);

      return matches ? matches[matches.length - 1].slice(6, -1) : null;
    },
    normalizeTheme(theme) {
      return { ...DEFAULT_INVITE_THEME, ...theme };
    },
  },
);
