/* global dataLayer */

import { isNil } from "ramda";
import { browserName, deviceType, osName } from "react-device-detect";

import { jjLogger } from "utils/logUtils";
import UserPresenter from "presenters/UserPresenter";
import OfferPresenter from "presenters/OfferPresenter";
import TemplatePresenter from "presenters/TemplatePresenter";

const userData = (user) => {
  return {
    user: {
      id: UserPresenter.id(user),
      email: UserPresenter.email(user),
      name: UserPresenter.firstName(user),
      "logged-in": UserPresenter.isRegistered(user) ? "yes" : "no",
      member: UserPresenter.isPaid(user) ? "yes" : "no",
      browser: browserName,
      os: osName,
      device: deviceType === "browser" ? "desktop" : deviceType,
    },
  };
};

const sendUserData = (user) => {
  const dataObj = userData(user);
  jjLogger.log(`gtmUtils.js: sendUserData: ${JSON.stringify(dataObj)}`);
  dataLayer.push(dataObj);
};

export const sendRegistrationSuccessEvent = (user) => {
  sendUserData(user);
  dataLayer.push({
    event: "registrationSuccess",
  });
};

export const sendPaymentSuccessEvent = (user, type, offer, subscriptionId, template) => {
  const dataObj = {
    event: "paymentSuccess",
    "offer-name": OfferPresenter.name(offer),
    "offer-duration": `${OfferPresenter.duration(offer)} ${OfferPresenter.durationUnit(offer)}`,
    revenue: OfferPresenter.price(offer),
    "payment-method": type,
    "subscription-slug": subscriptionId,
    "in-flow": "no",
  };

  if (typeof template !== "undefined") {
    dataObj["in-flow"] = "yes";
    dataObj["template-id"] = TemplatePresenter.id(template);
    dataObj["template-short-name"] = TemplatePresenter.name(template);
    dataObj.product = {
      "template-group-slug": TemplatePresenter.templateGroupSlug(template),
      "template-slug": TemplatePresenter.id(template),
      "template-cast-size": TemplatePresenter.castNum(template),
    };
  }

  jjLogger.log(`gtmUtils.js: sendPaymentSuccessEvent: ${JSON.stringify(dataObj)}`);
  dataLayer.push(dataObj);
  sendUserData(user);
};

export const sendTrackVirtualPageView = (user, payload) => {
  const gtmData = isNil(payload) ? {} : payload;

  const userInfo = userData(user);
  const pageInfo = {
    title: document.title,
    url: window.location.href,
  };

  if ("in-flow" in gtmData) {
    gtmData["in-flow"] = gtmData["in-flow"] ? "yes" : "no";
  }

  const dataObj = {
    event: "virtualPageView",
    page: { ...pageInfo, ...gtmData },
    ...userInfo,
  };

  dataLayer.push(dataObj);
};
