import React, { useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Fade from "@mui/material/Fade";
import { NavLink } from "react-router-dom";
import Link from "@mui/material/Link";

import { externalRoutes } from "routes";
import { useUsers } from "hooks";
import { AUTH_LINKS } from "enums";

import useStyles from "./useStyles";
import Avatar from "./components/Avatar";

const AccountMenu = () => {
  const classes = useStyles();
  const { signOut } = useUsers();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const renderAuthItem = (link) => {
    const { to: pathname, name, id } = link;
    const key = `account-menu-${id}`;

    return (
      <MenuItem id={key} key={key} component={NavLink} onClick={handleClose} to={pathname}>
        {name}
      </MenuItem>
    );
  };

  return (
    <>
      <Avatar onClick={handleClick} />
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        hideBackdrop
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        className={classes.menu}
        TransitionComponent={Fade}
      >
        {AUTH_LINKS.map((link) => renderAuthItem(link))}
        <MenuItem
          id="account-menu-help"
          component={Link}
          href={externalRoutes.helpPath()}
          onClick={handleClose}
          target="_blank"
          rel="noreferrer"
        >
          Help Center
        </MenuItem>
        <MenuItem id="account-menu-logout" onClick={() => signOut()}>
          Log Out
        </MenuItem>
      </Menu>
    </>
  );
};

export default AccountMenu;
