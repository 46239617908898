import React from "react";
import { Divider, Grid } from "@mui/material";
import PropTypes from "prop-types";

import MegaMenuItemPresenter from "presenters/MegaMenuItemPresenter";

import LinkList from "./components/LinkList";
import useStyles from "./useStyles";

const LinkListContainer = ({ links }) => {
  const clasess = useStyles();

  const LIST_SIZE = 8;

  const createLists = () => {
    if (links.length === 0) return [];

    const newList = [];
    for (let i = 0; i < links.length; i += LIST_SIZE) {
      const list = links.slice(i, i + LIST_SIZE);
      newList.push(list);
    }

    return newList;
  };

  const lists = createLists();

  return (
    <Grid container>
      <Grid>
        <Grid className={clasess.listsContainer}>
          {lists.map((list, index) => (
            <React.Fragment key={index}>
              <LinkList list={list} />
              {index !== lists.length - 1 && <Divider className={clasess.divider} orientation="vertical" />}
            </React.Fragment>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

LinkListContainer.propTypes = {
  links: PropTypes.arrayOf(MegaMenuItemPresenter.shape()),
};

export default LinkListContainer;
