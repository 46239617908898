import { createSlice } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { unionWith, eqBy, prop } from "ramda";

import { PAGE_TYPES } from "enums";

const initialState = {
  initialized: false,
  modalOpen: sessionStorage.getItem("facebookModal") === "true",
  mode: PAGE_TYPES.LOGIN,
  albums: [],
  photos: [],
  selectedPhoto: null,
  status: null,
  loading: true,
  showNoticeModal: false,
};

const facebookSlice = createSlice({
  name: "facebookSlice",
  initialState,
  reducers: {
    loadAlbumsSuccess(state, { payload }) {
      state.albums = payload;
    },
    changeInitializedSuccess(state, { payload }) {
      state.initialized = payload;
    },
    loadPhotosSuccess(state, { payload }) {
      state.photos = payload;
    },
    loadAdditionalPhotosSuccess(state, { payload }) {
      const photos = unionWith(eqBy(prop("id")), state.photos, payload);
      state.photos = photos;
    },
    loadAdditionalAlbumsSuccess(state, { payload }) {
      const albums = unionWith(eqBy(prop("id")), state.albums, payload);
      state.albums = albums;
    },
    changeModalOpenSuccess(state, { payload }) {
      state.modalOpen = payload;
    },
    changeModeSuccess(state, { payload }) {
      state.mode = payload;
    },
    changeStatusSuccess(state, { payload }) {
      state.status = payload;
    },
    changeSelectedPhotoSuccess(state, { payload }) {
      state.selectedPhoto = payload;
    },
    changeLoadingSuccess(state, { payload }) {
      state.loading = payload;
    },
    resetModeSuccess(state) {
      state.mode = PAGE_TYPES.LOGIN;
    },
    changeNoticeModal(state, { payload }) {
      state.showNoticeModal = payload;
    },
    clearAlbumsSuccess(state) {
      state.albums = [];
    },
    clearPhotosSuccess(state) {
      state.photos = [];
    },
    clearStatusSuccess(state) {
      state.status = null;
    },
    clearSelectedPhotoSuccess(state) {
      state.selectedPhoto = null;
    },
  },
});

export const { actions } = facebookSlice;
export default facebookSlice.reducer;

export const useFacebookActions = () => {
  const dispatch = useDispatch();

  const loadAlbums = (albums) => {
    dispatch(facebookSlice.actions.loadAlbumsSuccess(albums));
  };

  const loadPhotos = (photos) => {
    dispatch(facebookSlice.actions.loadPhotosSuccess(photos));
  };

  const loadAdditionalPhotos = (photos) => {
    dispatch(facebookSlice.actions.loadAdditionalPhotosSuccess(photos));
  };

  const loadAdditionalAlbums = (albums) => {
    dispatch(facebookSlice.actions.loadAdditionalAlbumsSuccess(albums));
  };

  const changeMode = (mode) => {
    dispatch(facebookSlice.actions.changeModeSuccess(mode));
  };

  const changeSelectedPhoto = (photo) => {
    dispatch(facebookSlice.actions.changeSelectedPhotoSuccess(photo));
  };

  const changeModalOpen = (isOpen) => {
    dispatch(facebookSlice.actions.changeModalOpenSuccess(isOpen));
  };

  const changeStatus = (status) => {
    dispatch(facebookSlice.actions.changeStatusSuccess(status));
  };

  const changeInitialized = (init) => {
    dispatch(facebookSlice.actions.changeInitializedSuccess(init));
  };

  const clearSelectedPhoto = () => {
    dispatch(facebookSlice.actions.clearSelectedPhotoSuccess());
  };

  const clearPhotos = () => {
    dispatch(facebookSlice.actions.clearPhotosSuccess());
  };

  const resetMode = () => {
    dispatch(facebookSlice.actions.resetModeSuccess());
  };

  const clearAlbums = () => {
    dispatch(facebookSlice.actions.clearAlbumsSuccess());
  };

  const clearStatus = () => {
    dispatch(facebookSlice.actions.clearStatusSuccess());
  };

  const changeLoading = (loading) => {
    dispatch(facebookSlice.actions.changeLoadingSuccess(loading));
  };

  const changeNoticeModal = (value) => {
    dispatch(facebookSlice.actions.changeNoticeModal(value));
  };

  return {
    loadAlbums,
    changeMode,
    loadPhotos,
    loadAdditionalPhotos,
    loadAdditionalAlbums,
    changeModalOpen,
    changeSelectedPhoto,
    changeStatus,
    changeInitialized,
    resetMode,
    clearAlbums,
    clearPhotos,
    clearSelectedPhoto,
    clearStatus,
    changeLoading,
    changeNoticeModal,
  };
};
