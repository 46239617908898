import R from "ramda";

const TAG_TYPES = [
  {
    tag: "christmas",
    type: "template",
    animation: "snow",
    playerAnimation: "PostCardFlip",
    category: "holiday-ecards",
  },
  {
    tag: "valentines",
    type: "template",
    animation: "hearts",
    playerAnimation: "PostCardFlip",
    category: "valentines-ecards",
  },
  { tag: "mother", type: "thanks" },
  { tag: "thanksgiving", type: "template", playerAnimation: "PostCardFlip", category: "thanksgiving-ecards" },
  { tag: "newyear", type: "template", playerAnimation: "PostCardFlip", category: "new-years-ecards" },
  { tag: "halloween", type: "template", animation: "ghosts", playerAnimation: "PlayerEntrance" },
  { tag: "musicvideos", type: "template", animation: "music-notes", playerAnimation: "PlayerEntrance" },
  { tag: "birthday", type: "thanks", animation: "balloons", playerAnimation: "PostCardFlip" },
  { tag: "dance", type: "thanks", animation: "music-notes", playerAnimation: "PlayerEntrance" },
  {
    tag: "love",
    type: "template",
    animation: "hearts",
    playerAnimation: "PostCardFlip",
    category: "love-ecards",
    ctaText: "Send a Love Note",
  },
  {
    tag: "weddings",
    type: "template",
    animation: "hearts",
    playerAnimation: "PostCardFlip",
    category: "love-ecards",
    ctaText: "Send a Love Note",
  },
  {
    tag: "anniversary",
    type: "template",
    animation: "hearts",
    playerAnimation: "PostCardFlip",
    category: "love-ecards",
    ctaText: "Send a Love Note",
  },
];

const getPrimaryTag = (rawTags) => {
  if (R.isNil(rawTags)) {
    return null;
  }

  const tags = R.uniq(rawTags.replace(/\s/g, "").split(","));
  const primaryTag = TAG_TYPES.find(({ tag }) => tags.includes(tag));

  return primaryTag;
};

export { getPrimaryTag };
