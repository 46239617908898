import R from "ramda";
import Jsona from "jsona";

export const isNumber = R.is(Number);
export const isString = R.is(String);

export const isObject = (obj) => R.is(Object, obj);
export const isFunc = (obj) => R.is(Function, obj);

export const isFile = (obj) => obj instanceof File;

export const isArray = (obj) => obj instanceof Array;

export const isNullOrEmpty = R.anyPass([R.isNil, R.isEmpty]);
export const isPresent = (value) => !isNullOrEmpty(value);

const reduceWithMapRelations = (object, func) => {
  if (isArray(object)) return object.map((item) => reduceWithMapRelations(item, func));
  if (!object || !object.relationshipNames) return object;
  return object.relationshipNames.reduce(
    (acc, key) => ({
      ...acc,
      [key]: func(object, key),
    }),
    object,
  );
};

const buildRelation = (object) =>
  reduceWithMapRelations(object, (obj, key) => (obj[key] && obj[key].id ? obj[key].id : null));
const removeCycleLinks = (object) => reduceWithMapRelations(object, (obj, key) => buildRelation(obj[key]));

export const serializeFunction = (func) => func.toString();
// eslint-disable-next-line no-new-func
export const deserializeFunction = (funcString) => new Function(`return ${funcString}`)();

export const deserialize = (params) => {
  const dataConverter = new Jsona();
  const data = dataConverter.deserialize(params);
  return removeCycleLinks(data);
};

export const deserializeErrors = (error) => {
  if (!error) return null;

  if (error.response?.data?.errors) {
    const errors = error.response.data.errors.map((err) => {
      const attr = err.source.pointer.split("/").pop();
      const message = err.detail;

      return `${attr}: ${message}`;
    });

    return errors;
  }

  if (error.response?.status) {
    return [`${error.response.status}: ${error.response.statusText}`];
  }

  return null;
};

const rejectById = (id) => R.reject(R.propEq("id", id));
const mergeIfSameId = (mergeId, mergeAttrs, original) =>
  original.map((el) => (el.id !== mergeId ? el : { ...el, ...mergeAttrs }));

export const change = (object, path, func) => {
  const lens = R.lensPath(path);
  return R.over(lens, func, object);
};

export const mergeById = (object, path, id, attrs) => {
  const lens = R.lensPath(path);
  return R.over(lens, mergeIfSameId.bind(null, id, attrs), object);
};

export const set = (object, path, value) => {
  const lens = R.lensPath(path);
  return R.set(lens, value, object);
};

export const merge = (object, path, attrs) => {
  const lens = R.lensPath(path);
  return R.over(lens, (value) => ({ ...value, ...attrs }), object);
};

export const removeById = (object, path, id) => {
  const lens = R.lensPath(path);
  return R.over(lens, rejectById(id), object);
};

export const removeByIndex = (object, path, index) => {
  const lens = R.lensPath(path);
  return R.over(lens, R.remove(index, 1), object);
};

export const append = (object, path, element) => {
  const lens = R.lensPath(path);
  return R.over(lens, R.append(element), object);
};

export const prepend = (object, path, element) => {
  const lens = R.lensPath(path);
  return R.over(lens, R.prepend(element), object);
};

export const removeFromArray = (object, path, element) => {
  const lens = R.lensPath(path);
  return R.over(
    lens,
    R.reject((e) => e === element),
    object,
  );
};

export const toggleListItem = R.curry((value, list) =>
  R.ifElse(R.includes(value), R.without(value), R.append(value))(list),
);

export const isBlank = (value) => R.isNil(value) || R.isEmpty(value);
