import { useSnackbar } from "notistack";

const useNotifications = () => {
  const { enqueueSnackbar } = useSnackbar();

  const defaultErrorMessage = "Oops! Something went wrong!";

  const config = {
    anchorOrigin: {
      vertical: "top",
      horizontal: "center",
      preventDuplicate: true,
    },
  };

  const addSuccessNotification = (message) => enqueueSnackbar(message, { ...config, variant: "success" });
  const addErrorNotification = (message = defaultErrorMessage) =>
    enqueueSnackbar(message, { ...config, variant: "error" });

  return {
    addSuccessNotification,
    addErrorNotification,
  };
};

export default useNotifications;
