import { createSlice } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";

const CookieBannerKey = "CookieBanner";

const initialState = {
  dismissCookieBanner: sessionStorage.getItem(CookieBannerKey) === "true",
};

const CookieBannerSlice = createSlice({
  name: "CookieBannerSlice",
  initialState,
  reducers: {
    enableCookieBanner(state, { payload }) {
      state.dismissCookieBanner = payload;
    },
  },
});

export const { actions } = CookieBannerSlice;
export default CookieBannerSlice.reducer;

export const useCookieBannerActions = () => {
  const dispatch = useDispatch();

  const changeValue = (data) => {
    dispatch(CookieBannerSlice.actions.enableCookieBanner(data));
    sessionStorage.setItem(CookieBannerKey, data);
  };

  return { changeValue };
};
