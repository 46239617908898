import { useEffect } from "react";

import useUsers from "hooks/useUsers";

import { sendTrackVirtualPageView } from "../utils/gtmUtils";

const useGtm = () => {
  const { currentUser } = useUsers();
  const templateId = (backUrl) => backUrl.split("/").pop();
  const trackVirtualPageView = (payload) => sendTrackVirtualPageView(currentUser, payload);

  const trackVirtualPageManualHeadCut = (slug, backUrl) => {
    const url = `${window.location.origin}/create/head`;

    trackVirtualPageView({
      name: slug,
      url: `${url}/${slug}/${templateId(backUrl)}`,
    });
  };

  const trackVirtualPageViewFacesFound = (faceIndex, backUrl) => {
    trackVirtualPageView({
      name: "Face Found",
      url: `/create/head/auto/faces-found/${faceIndex}/${templateId(backUrl)}`,
    });
  };

  const trackVirtualPageViewGotEveryOne = (backUrl) => {
    trackVirtualPageView({
      name: "Miss Anyone",
      url: `/create/head/auto/goteveryone/${templateId(backUrl)}`,
    });
  };

  const trackVirtualPageViewAutoHeadCut = (backUrl) => {
    trackVirtualPageView({
      name: "Finding Faces",
      url: `/create/head/auto/loading/${templateId(backUrl)}`,
    });
  };

  const trackVirtualPageViewHeadCut = (backUrl) => trackVirtualPageManualHeadCut("head-cut", backUrl);

  const trackVirtualPageViewJawCut = (backUrl) => trackVirtualPageManualHeadCut("jaw-cut", backUrl);

  return {
    trackVirtualPageView,
    trackVirtualPageViewHeadCut,
    trackVirtualPageViewJawCut,
    trackVirtualPageViewFacesFound,
    trackVirtualPageViewGotEveryOne,
    trackVirtualPageViewAutoHeadCut,
  };
};

const useGtmTrackAutomatically = (payload) => {
  const { currentUser } = useUsers();

  return useEffect(() => {
    sendTrackVirtualPageView(currentUser, payload);
  }, []);
};

export { useGtm, useGtmTrackAutomatically };
