import makeStyles from "@mui/styles/makeStyles";

export default makeStyles(
  () => ({
    listsContainer: {
      display: "flex",
      flexWrap: "nowrap",
    },
    divider: {
      height: "auto",
    },
  }),
  { name: "LinkListContainer" },
);
