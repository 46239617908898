import { createSlice } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";

import { deserialize, mergeById } from "utils/storeUtils";
import UserRepository from "repositories/UserRepository";
import SubscriptionsRepository from "repositories/SubscriptionsRepository";
import { jjLogger } from "utils/logUtils";

const initialState = {
  currentUser: null,
  currentUserLoadingStatus: null,
  errors: null,
  settings: {
    contentful: {
      accessToken: process.env.CONTENTFUL_ACCESS_TOKEN,
      previewAccessToken: process.env.CONTENTFUL_PREVIEW_ACCESS_TOKEN,
      space: process.env.CONTENTFUL_SPACE,
    },
    googleAnalytics4Key: process.env.GOOGLE_ANALYTICS_4_KEY,
    googleVisionKey: process.env.GOOGLE_VISION_KEY,
    fbAppId: process.env.FACEBOOK_APP_ID,
    recurlyApiKey: process.env.RECURLY_PUBLIC_API_KEY,
    paypal: {
      clientId: process.env.PAYPAL_CLIENT_ID,
    },
    recaptcha: {
      enabled: process.env.RECAPTCHA_V3_ENABLED === "true",
      subscription_enabled: process.env.RECAPTCHA_V3_SUBSCRIPTION_ENABLED === "true",
      login_enabled: process.env.RECAPTCHA_V3_LOGIN_ENABLED === "true",
      key: process.env.RECAPTCHA_V3_SITE_KEY,
    },
    algolia: {
      id: process.env.ALGOLIA_APP_ID,
      searchKey: process.env.ALGOLIA_API_SEARCH_KEY,
      indexName: `TemplateGroup_${process.env.DEPLOY_ENV}`,
    },
  },
};

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    loadCurrentUserSuccess(state, { payload }) {
      state.currentUser = deserialize(payload);
    },

    setCurrentUserLoadingStatus(state, { payload }) {
      state.currentUserLoadingStatus = payload;
    },

    updateUserSuccess(state, { payload }) {
      state.currentUser = deserialize(payload);
    },

    updateUserFail(state, { payload }) {
      state.errors = payload;
    },

    reactivateSubscriptionFail(state, { payload }) {
      state.errors = payload;
    },

    reactivateSubscriptionSuccess(state, { payload }) {
      const updatedSubscriptions = mergeById(state.currentUser.subscriptions, [], payload, { status: "paid" });
      state.currentUser.subscriptions = updatedSubscriptions;
    },

    deactivateSubscriptionFail(state, { payload }) {
      state.errors = payload;
    },

    deactivateSubscriptionSuccess(state, { payload }) {
      const updatedSubscriptions = mergeById(state.currentUser.subscriptions, [], payload, { status: "canceled" });
      state.currentUser.subscriptions = updatedSubscriptions;
    },

    clearErrors(state) {
      state.errors = null;
    },
  },
});

export const { actions } = appSlice;
export default appSlice.reducer;

export const useAppActions = () => {
  const dispatch = useDispatch();

  const loadCurrentUser = () => {
    return UserRepository.show()
      .then(({ data }) => {
        dispatch(appSlice.actions.loadCurrentUserSuccess(data));
        const userData = deserialize(data);
        window.currentUser = { id: userData.id, role: userData.role };
        return userData;
      })
      .catch((error) => {
        jjLogger.logError(`AppSlice.js:loadCurrentUser Error ${error.message}`);
      });
  };

  const setCurrentUserLoadingStatus = (status) => {
    dispatch(appSlice.actions.setCurrentUserLoadingStatus(status));
  };

  const createUser = (attrs) => {
    return UserRepository.create(attrs).then(({ data }) => {
      return data.data;
    });
  };

  const updateUser = (attrs) => {
    return UserRepository.update(attrs)
      .then(({ data }) => {
        dispatch(appSlice.actions.updateUserSuccess(data));
        return deserialize(data);
      })
      .catch((error) => {
        dispatch(appSlice.actions.updateUserFail(error));
        return Promise.reject(error);
      });
  };

  const clearErrors = () => dispatch(appSlice.actions.clearErrors());

  const reactivateSubscription = (id, params) => {
    return SubscriptionsRepository.reactivate(id, params)
      .then(() => {
        dispatch(appSlice.actions.reactivateSubscriptionSuccess(id));
        Promise.resolve(id);
      })
      .catch((error) => {
        dispatch(appSlice.actions.reactivateSubscriptionFail(error.response.statusText));
        throw new Error(error);
      });
  };

  const deactivateSubscription = (id, type, params) => {
    if (type === "paypal") {
      return SubscriptionsRepository.pause(id, params)
        .then(() => {
          dispatch(appSlice.actions.deactivateSubscriptionSuccess(id));
          return Promise.resolve(id);
        })
        .catch((error) => {
          dispatch(appSlice.actions.deactivateSubscriptionFail(error.response.statusText));
          throw new Error(error);
        });
    }

    return SubscriptionsRepository.cancel(id, params)
      .then(() => {
        dispatch(appSlice.actions.deactivateSubscriptionSuccess(id));
        return Promise.resolve(id);
      })
      .catch((error) => {
        dispatch(appSlice.actions.deactivateSubscriptionFail(error.response.statusText));
        throw new Error(error);
      });
  };

  return {
    loadCurrentUser,
    setCurrentUserLoadingStatus,
    createUser,
    updateUser,
    reactivateSubscription,
    deactivateSubscription,
    clearErrors,
  };
};
