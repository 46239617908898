/* global ga */
/* global newrelic */

import React, { lazy, Suspense, useEffect, useState } from "react";
import Cookies from "js-cookie";
import { compose } from "redux";
import { hot } from "react-hot-loader/root";
import { Provider } from "react-redux";
import { Redirect, Router, Switch, Route } from "react-router-dom";
import { SnackbarProvider } from "notistack";

import { FaceOrgProvider } from "contexts/faceorg-context";
import { ExperimentProvider } from "contexts/experiment-context";
import "./global.css";
import UserLayout from "layouts/UserLayout";
import NoLayout from "layouts/NoLayout";
import {
  MakeView,
  MakePreview,
  MorphablePackGroup,
  InvitesPreview,
  TemplateGroup,
  Categories,
  Category,
  Home,
  Login,
  NewCast,
  People,
  MyEcards,
  MyInvites,
  Overview,
  ChangeEmail,
  ChangeName,
  ChangePassword,
  Receipts,
  Receipt,
  SubscriptionDetails,
  EditRenewal,
  UpdateCreditCard,
  Legal,
  AboutUs,
  Privacy,
  TermsOfSale,
  TermsOfService,
  SignUp,
  ResetPassword,
  ResetPasswordReturn,
  LoginHelp,
  Payment,
  PaypalConfirmation,
  PurchaseConfirmation,
  Face,
  Search,
  Page404,
  Unauthorized,
  OauthValidation,
  InviteCategory,
  InvitesCasting,
  InviteMakePreview,
  InviteGuestView,
  ManageInvite,
  InvitePublicView,
  InvitesLandingPage,
  StarringYouAILandingPage,
  SYAIPhotoConfirmation,
  SYAIPackPreview,
  SYAIPackView,
  SYAIMyPacks,
} from "pages";
import ThemeProvider from "layouts/ThemeProvider";
import LoaderBricks from "components/LoaderBricks";
import RenderModal from "components/RenderModal";
import FeatureFlags from "components/FeatureFlags";
import history from "utils/history";
import localStorage, { LOCAL_STORAGE_AUTH_KEY, RECIPIENT_SESSION_KEY } from "utils/localStorage";
import { initGAData, trackPageView, trackPageViewRegister, trackPageViewCast } from "utils/gAnalyticsUtils";
import { pageViewEvent, setupTrackingId, getTrackingId } from "tracking/jibjab/tracking";
import store from "store";
import { appRoutes } from "routes";
import {
  useUsers,
  useFacesCount,
  useOffers,
  useRouter,
  useScrollToTopOnPathnameChange,
  useLoading,
  useDetectAdblock,
  usePageUpdated,
  useUtmsParams,
  useGA4,
  useRefreshAuthToken,
  useInFlowManager,
} from "hooks";
import {
  storeTestEventCode,
  trackViewContent,
  trackPageView as trackFacebookPageView,
} from "tracking/facebook/conversion";

import { jjLogger, MOBILE_DEBUG_COOKIE_NAME } from "./utils/logUtils";
import { registerServiceWorker } from "./serviceWorkerRegistration";

const Routes = () => {
  const [trackVisit, setTrackVisit] = useState(true);
  const [trackingID, setTrackingID] = useState(null);
  const { setCurrentOffer } = useOffers();
  const { currentUser, signOut, loadCurrentUser, setCurrentUserLoadingStatus } = useUsers();
  const { initializeValue, clearValue, initialValue } = useDetectAdblock();
  const {
    query: { mobileDebug = null },
    location,
  } = useRouter();
  const { totalFacesCount } = useFacesCount();
  const { func: loadUserSession, status: currentUserStatus, isFinished: isUserSessionLoaded } = useLoading(
    loadCurrentUser,
  );
  const { handleUtms } = useUtmsParams();

  const syncLoginAcrossTabs = (e) => {
    if (window.forceRefresh) return;

    if (e.key === LOCAL_STORAGE_AUTH_KEY) {
      if (e.oldValue && !e.newValue) {
        jjLogger.logDebug("UserApp.js: LocalStorage Change Event: Signing Out");
        signOut();
      } else {
        jjLogger.logDebug("UserApp.js: LocalStorage Change Event: Refreshing Page");
        window.forceRefresh = true;
        window.location.reload();
      }
    }
  };

  const logErrors = (e) => {
    jjLogger.logError(e.message);
  };

  const logUnhandledRejection = (e) => {
    jjLogger.logError(`Unhandled Promise Rejection: ${e.reason}`);
  };

  const flushLogs = () => {
    jjLogger.flushLogs();
  };

  useScrollToTopOnPathnameChange();
  useGA4();
  useInFlowManager();

  useEffect(() => {
    window.addEventListener("storage", syncLoginAcrossTabs);
    window.addEventListener("error", logErrors);
    window.addEventListener("unhandledrejection", logUnhandledRejection);
    window.addEventListener("beforeunload", flushLogs);

    return () => {
      window.removeEventListener("storage", syncLoginAcrossTabs);
      window.removeEventListener("error", logErrors);
      window.removeEventListener("unhandledrejection", logUnhandledRejection);
      window.removeEventListener("beforeunload", flushLogs);
    };
  }, []);

  useEffect(() => {
    storeTestEventCode();
    loadUserSession();
    setupTrackingId();
    setTrackingID(getTrackingId());
  }, []);

  useEffect(() => {
    initializeValue();
    return () => {
      clearValue();
    };
  }, [initialValue]);

  useEffect(() => {
    ga((tracker) => {
      const clientId = tracker.get("clientId");
      const randomSessionId = `${new Date().getTime()}.${Math.random()
        .toString(36)
        .substring(5)}`;
      initGAData(clientId, randomSessionId);
    });
  }, []);

  useEffect(() => {
    setCurrentOffer();
    handleUtms();
  }, []);

  useEffect(() => {
    const path = location.pathname + location.search;
    const isViewMakePage = /^\/view\/make\/*\/*\//.test(path);
    if (!localStorage.hasItem(RECIPIENT_SESSION_KEY) && !isViewMakePage) {
      localStorage.setItem(RECIPIENT_SESSION_KEY, "no");
    }
  }, []);

  useEffect(() => {
    if (mobileDebug === "on") {
      Cookies.set(MOBILE_DEBUG_COOKIE_NAME, true);
    } else if (mobileDebug === "off") {
      Cookies.remove(MOBILE_DEBUG_COOKIE_NAME);
    }
  }, [mobileDebug]);

  useEffect(() => {
    setCurrentUserLoadingStatus(currentUserStatus);
  }, [currentUserStatus]);

  useEffect(() => {
    if (trackVisit && isUserSessionLoaded) {
      setTrackVisit(false);
      pageViewEvent(currentUser);
    }
  }, [isUserSessionLoaded]);

  useEffect(() => {
    if (currentUser && typeof newrelic !== "undefined") {
      newrelic.setCustomAttribute("userId", currentUser.id);
    }
  }, [currentUser]);

  useEffect(() => {
    if (!isUserSessionLoaded) return;

    const path = location.pathname + location.search;
    const isHomePage = path === "/";
    const isCategoryPage = /^\/browse\/.+$/.test(path);
    const isTemplateGroupPage = /^\/(view\/template|invites\/view\/template)\/.+$/.test(path);
    const skipPath =
      isHomePage ||
      /^\/not-found/.test(path) ||
      isTemplateGroupPage ||
      /^\/view\/make\/*\/*\//.test(path) ||
      /^\/view\/make-preview\/*\/*\//.test(path) ||
      /^\/face/.test(path) ||
      /^\/search(\/)?(\?.+)+/.test(path);

    if (!isHomePage && !isCategoryPage && !isTemplateGroupPage) window.prerenderReady = true;

    trackFacebookPageView(currentUser);

    if (skipPath) return;

    if (/^\/create\/account/.test(path)) {
      trackPageViewRegister(currentUser);
      trackViewContent(currentUser, { content_name: "No Account Creation" });
    } else if (/^\/create\/cast\/.+$/.test(path)) {
      trackPageViewCast(currentUser, totalFacesCount);
      trackViewContent(currentUser, { content_name: "Cast More Faces" });
    } else {
      trackPageView(currentUser);
    }
  }, [location, isUserSessionLoaded]);

  return (
    <Suspense fallback={<LoaderBricks />}>
      <FeatureFlags trackingID={trackingID}>
        <Switch>
          <Route exact path={[appRoutes.searchPath()]}>
            <NoLayout>
              <Switch>
                <Route path={appRoutes.searchPath()} component={Search} />
              </Switch>
            </NoLayout>
          </Route>
          <Route path=":path?">
            <UserLayout>
              <Switch>
                <Route exact path={appRoutes.rootPath()}>
                  <Home />
                </Route>
                <Route exact path={appRoutes.categoriesPath()} component={Categories} />
                <Route exact path={appRoutes.categoryPath(":slug")} component={Category} />
                <Route exact path={appRoutes.invitesPath()} component={InvitesLandingPage} />
                <Route exact path={appRoutes.starringYouAIPath()} component={StarringYouAILandingPage} />
                <Route exact path={appRoutes.invitePath(":slug")} component={InviteCategory} />
                <Route
                  exact
                  path={appRoutes.inviteSubcategoryPath(":slug", ":subcategory")}
                  component={InviteCategory}
                />
                <Route path={appRoutes.templateGroupPath(":slug")} component={TemplateGroup} />
                <Route path={appRoutes.newCastPath(":slug")} component={NewCast} />
                <Route path={appRoutes.facePath()} component={Face} />
                <Route exact path={appRoutes.termsOfSalePath()} component={TermsOfSale} />
                <Route exact path={appRoutes.termsOfServicePath()} component={TermsOfService} />
                <Route exact path={appRoutes.privacyPath()} component={Privacy} />
                <Route exact path={appRoutes.legalPath()} component={Legal} />
                <Route exact path={appRoutes.aboutUsPath()} component={AboutUs} />
                <Route path={appRoutes.page404Path()} component={Page404} />
                <Route path={appRoutes.makeViewPath(":slug", ":id")} component={MakeView} />
                <Route path={appRoutes.makePreviewPath(":slug", ":id")} component={MakePreview} />
                <Route exact path={appRoutes.unauthorizedPath()} component={Unauthorized} />
                <Route path={appRoutes.loginPath()} component={Login} />
                <Route exact path={appRoutes.signUpPath()} component={SignUp} />
                <Route path={appRoutes.resetPasswordPath()} component={ResetPassword} />
                <Route path={appRoutes.resetPasswordReturnPath(":token")} component={ResetPasswordReturn} />
                <Route path={appRoutes.loginHelpPath()} component={LoginHelp} />
                <Route path={appRoutes.myAccountPath()} component={Overview} />
                <Route path={appRoutes.changeNamePath()} component={ChangeName} />
                <Route path={appRoutes.changeEmailPath()} component={ChangeEmail} />
                <Route path={appRoutes.changePasswordPath()} component={ChangePassword} />
                <Route path={appRoutes.receiptsPath()} component={Receipts} />
                <Route path={appRoutes.receiptPath(":id")} component={Receipt} />
                <Route path={appRoutes.subscriptionDetailsPath()} component={SubscriptionDetails} />
                <Route path={appRoutes.editRenewalPath()} component={EditRenewal} />
                <Route path={appRoutes.myEcardsPath()} component={MyEcards} />
                <Route path={appRoutes.manageInvitePath(":slug")} component={ManageInvite} />
                <Route path={appRoutes.myInvitesPath()} component={MyInvites} />
                <Route path={appRoutes.peoplePath()} component={People} />
                <Route path={appRoutes.paypalConfirmationPath()} component={PaypalConfirmation} />
                <Route path={appRoutes.updateCreditCardPath()} component={UpdateCreditCard} />
                <Route path={appRoutes.purchaseConfirmationPath()} component={PurchaseConfirmation} />
                <Route path={appRoutes.paymentPath()} component={Payment} />
                <Route path={appRoutes.oAuthRedirectPath()} component={OauthValidation} />
                <Route path={appRoutes.morphablePackGroupPath(":slug")} component={MorphablePackGroup} />
                <Route path={appRoutes.invitesPreviewPath(":slug")} component={InvitesPreview} />
                <Route path={appRoutes.invitesCastingPath(":slug")} component={InvitesCasting} />
                <Route path={appRoutes.inviteMakePreviewPath(":slug")} component={InviteMakePreview} />
                <Route path={appRoutes.inviteGuestViewPath(":tg", ":invite", ":guest")} component={InviteGuestView} />
                <Route path={appRoutes.invitePublicViewPath(":invite")} component={InvitePublicView} />
                <Route path={appRoutes.syaiPackPreviewPath(":slug")} component={SYAIPackPreview} />
                <Route path={appRoutes.syaiPhotoConfirmationPath()} component={SYAIPhotoConfirmation} />
                <Route path={appRoutes.syaiPackViewPath(":slug")} component={SYAIPackView} />
                <Route path={appRoutes.syaiMyPacksPath()} component={SYAIMyPacks} />
                <Redirect to={{ pathname: appRoutes.page404Path(), state: { referrer: location.pathname } }} />
              </Switch>
            </UserLayout>
          </Route>
        </Switch>
      </FeatureFlags>
    </Suspense>
  );
};

const CookieBanner = lazy(() => import("components/CookieBanner"));

const UserApp = () => {
  useRefreshAuthToken();

  const { changeValue, changeLoading, removeValue } = usePageUpdated();

  useEffect(() => {
    registerServiceWorker(
      () => changeValue(true),
      () => changeLoading(true),
      () => removeValue(),
    );
  }, []);

  return (
    <SnackbarProvider maxSnack={3}>
      <ThemeProvider>
        <ExperimentProvider>
          <FaceOrgProvider>
            <Router history={history}>
              <Suspense fallback={<span />}>
                <RenderModal />
                <CookieBanner />
              </Suspense>
              <Routes />
            </Router>
          </FaceOrgProvider>
        </ExperimentProvider>
      </ThemeProvider>
    </SnackbarProvider>
  );
};

const addStore = (ProxyComponent) => (props) => (
  <Provider store={store}>
    <ProxyComponent {...props} />
  </Provider>
);

UserApp.propTypes = {};

Routes.propTypes = {};

export default compose(hot, addStore)(UserApp);
