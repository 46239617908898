import { is, isNil, map, unnest, flatten } from "ramda";
import axios from "axios";
import qs from "qs";
import FileSaver from "file-saver";

import { apiRoutes, apiRoutesV2 } from "routes";
import { getAuthFromLocalStorage } from "utils/authUtils";
import { jjLogger } from "utils/logUtils";

import { camelize, decamelize } from "./keysConverter";
import { extractFileName } from "./fileUtils";
import { trackEvent404Error } from "./gAnalyticsUtils";

const getToken = () => {
  const auth = getAuthFromLocalStorage();
  return auth.accessToken;
};

const logRequest = (config) => {
  const { method, url } = config;
  jjLogger.log(`FetchHelpers.js: Request: ${method} ${url}`);
};

const logResponseError = (error) => {
  const { config, response } = error;
  jjLogger.log(
    `FetchHelpers.js: Error for Request: ${config?.method} ${config?.url} Response Status: ${response?.status}`,
  );
};

const instance = axios.create();

instance.defaults.headers.post["Content-Type"] = "application/json";

instance.interceptors.request.use((config) => {
  logRequest(config);

  const token = getToken();
  const { headers, url } = config;
  const isApiPath = url.startsWith(apiRoutes.rootPath()) || url.startsWith(apiRoutesV2.rootPath());

  return { ...config, headers: { ...(isApiPath && token && { Authorization: `Bearer ${token}` }), ...headers } };
});

instance.interceptors.response.use(null, (error) => {
  logResponseError(error);

  if (error.response?.status === 404) {
    trackEvent404Error(error.config.url);
  }

  return Promise.reject(error);
});

function headersMultipartFormData() {
  return {
    Accept: "*/*",
  };
}

function toFormDataQuery(key, value, lastKey = null) {
  if ((!is(Object, value) && !isNil(value)) || value instanceof File) {
    return { key, value };
  }

  if (is(Array, value)) {
    return unnest(
      value.map((arrayValue, i) => {
        const arKey = is(Object, arrayValue) && lastKey.includes("_attributes") ? `${key}[${i}]` : `${key}[]`;
        return toFormDataQuery(arKey, arrayValue, lastKey);
      }),
    );
  }
  return map((nestedValue, nestedKey) => toFormDataQuery(`${key}[${nestedKey}]`, nestedValue, nestedKey), value);
}

export default {
  get(url, params = {}, headers = {}) {
    return instance
      .get(url, {
        params: decamelize(params),
        headers,
        paramsSerializer: (parameters) => qs.stringify(parameters, { encode: false, arrayFormat: "brackets" }),
      })
      .then(camelize);
  },

  post(url, json, separator, params = {}) {
    const body = decamelize(json, separator);

    return instance.post(url, body, { params: decamelize(params) }).then(camelize);
  },

  put(url, json) {
    const body = decamelize(json);

    return instance.put(url, body).then(camelize);
  },

  patch(url, json, params = {}) {
    const body = decamelize(json);

    return instance.patch(url, body, { params: decamelize(params) }).then(camelize);
  },

  delete(url, json) {
    const body = decamelize(json);

    return instance.delete(url, { data: body }).then(camelize);
  },

  download(url, params = {}) {
    return instance
      .get(url, {
        params: decamelize(params),
        paramsSerializer: (parameters) => qs.stringify(parameters, { encode: false }),
        responseType: "blob",
      })
      .then((response) => {
        const fileName = extractFileName(response);
        FileSaver.saveAs(new Blob([response.data]), fileName);
      });
  },

  toFormData(model, attributes) {
    const formData = new FormData();
    const convertedParams = toFormDataQuery(model, attributes);
    flatten(convertedParams).forEach(({ key, value }) => {
      formData.append(key, value);
    });
    return formData;
  },

  postMultipartFormData(url, formData, options = {}) {
    return instance
      .post(url, formData, {
        headers: headersMultipartFormData(),
        ...options,
      })
      .then((response) => camelize(response.data));
  },

  putMultipartFormData(url, formData) {
    return instance
      .put(url, formData, {
        headers: headersMultipartFormData(),
      })
      .then((response) => camelize(response.data));
  },

  toCDN(url) {
    return url.replace("https://origin", "https://cf-api");
  },

  toSameOrigin(url) {
    const { location } = window;

    if (!url) return "";
    // Frequent jibjab mobile IP addresses. Used for mobile dev
    if (location.hostname.indexOf("10.10") !== -1) return url;
    if (location.hostname.indexOf("localhost") !== -1) return url;

    const patt = /((\/image|\/video)+_assets)|\/heads|\/makes|\/contentful/gi;
    const match = patt.exec(url);

    if (match === null) return url;

    const assetIndex = match.index;
    const { host, protocol } = location;
    const subURL = url.substring(assetIndex, url.length);

    return `${protocol}//${host}${subURL}`;
  },
  isContentfulPreview() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const includeDraft = urlParams.get("includeDraft");
    return !isNil(includeDraft) && includeDraft === "true";
  },
};
