import { createSlice } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";

import { deserialize, deserializeErrors } from "utils/storeUtils";
import SourcePhotoRepository from "repositories/SourcePhotoRepository";
import useActions from "hooks/useActions";
import { jjLogger } from "utils/logUtils";

const initialState = {
  sourcePhoto: null,
  modalOpened: false,
};

const sourcePhotoSlice = createSlice({
  name: "sourcePhoto",
  initialState,
  reducers: {
    setSourcePhoto(state, { payload }) {
      state.sourcePhoto = payload;
    },
    openModal(state) {
      state.modalOpened = true;
    },
    closeModal(state) {
      state.modalOpened = false;
    },
  },
});

export const { actions } = sourcePhotoSlice;
export default sourcePhotoSlice.reducer;

export const useSourcePhotoActions = () => {
  const { openModal, closeModal } = useActions(actions);
  const dispatch = useDispatch();

  const createSourcePhoto = (params) => {
    return SourcePhotoRepository.create(params)
      .then(({ data }) => {
        const sourcePhoto = deserialize(data);
        dispatch(sourcePhotoSlice.actions.setSourcePhoto(sourcePhoto));
        return sourcePhoto;
      })
      .catch((error) => {
        jjLogger.logError(`SourcePhotoSlice.js: createSourcePhoto() error: ${JSON.stringify(error)}`);
        return Promise.reject(deserializeErrors(error));
      });
  };

  return {
    createSourcePhoto,
    openModal,
    closeModal,
  };
};
