import { createSlice } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";

import { deserialize } from "utils/storeUtils";
import PageRepository from "repositories/PageRepository";
import { jjLogger } from "utils/logUtils";
import { PAGE_MODULES } from "enums/pageModules";

const initialState = {
  page: null,
};

const pageSlice = createSlice({
  name: "page",
  initialState,
  reducers: {
    setPage(state, { payload }) {
      state.page = payload;
    },
  },
});

export const { actions } = pageSlice;
export default pageSlice.reducer;

export const usePageActions = () => {
  const dispatch = useDispatch();

  const loadPage = (slug) => {
    return PageRepository.show(slug)
      .then(({ data }) => {
        const response = deserialize(data);
        const modules = response?.pageModules.map((module) => {
          const deserializedModule = deserialize({ data: module });

          if (module.type === PAGE_MODULES.ALGOLIA_CARDS) {
            return {
              ...deserializedModule,
              internalType: deserializedModule.type,
              type: PAGE_MODULES.ALGOLIA_CARDS,
            };
          }

          if (module.type === PAGE_MODULES.CATEGORY_COLLECTIONS) {
            return {
              ...deserializedModule,
              categories: deserializedModule.categories.map((category) => deserialize(category)),
            };
          }

          return deserializedModule;
        });

        response.pageModules = modules;
        dispatch(pageSlice.actions.setPage(response));
        return response;
      })
      .catch((error) => jjLogger.logError(error));
  };

  return {
    loadPage,
  };
};
