import { createSlice } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";

import { deserialize } from "utils/storeUtils";
import PillsRepository from "repositories/PillsRepository";

const initialState = {
  pills: null,
};

const pillsSlice = createSlice({
  name: "pills",
  initialState,
  reducers: {
    loadPillsSuccess(state, { payload }) {
      state.pills = deserialize(payload);
    },
  },
});

export const { actions } = pillsSlice;
export default pillsSlice.reducer;

export const usePillsActions = () => {
  const dispatch = useDispatch();

  const loadPills = () => {
    return PillsRepository.index().then(({ data }) => {
      dispatch(pillsSlice.actions.loadPillsSuccess(data));
      return data.data;
    });
  };

  return {
    loadPills,
  };
};
