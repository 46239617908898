import { apiRoutes } from "routes";
import FetchHelpers from "utils/FetchHelpers";

export default {
  create(slug, params) {
    const url = apiRoutes.makeMakeMessagePath(1, slug);
    return FetchHelpers.post(url, params);
  },
  update(slug, params) {
    const url = apiRoutes.makeMakeMessagePath(1, slug);
    return FetchHelpers.patch(url, params);
  },
  delete(slug) {
    const url = apiRoutes.makeMakeMessagePath(1, slug);
    return FetchHelpers.delete(url);
  },
};
