import PropTypes from "prop-types";

import Presenter from "utils/PropTypesPresenter";

export default new Presenter(
  {
    currency: PropTypes.string,
    description: PropTypes.string.isRequired,
    displayName: PropTypes.string,
    duration: PropTypes.number,
    durationUnit: PropTypes.string,
    id: PropTypes.string,
    image: PropTypes.string.isRequired,
    name: PropTypes.string,
    price: PropTypes.string,
    status: PropTypes.string,
    type: PropTypes.string,
  },
  {
    priceInCents(o) {
      return o.price * 100;
    },
    displayOfferDuration(o) {
      return o?.durationUnit?.charAt(0).toUpperCase() + o?.durationUnit?.slice(1);
    },
  },
);
