/* global DetectUserAgent */
import { Workbox } from "workbox-window";

export function registerServiceWorker(onPageRefresh, onLoading, onSuccess) {
  if (!DetectUserAgent.isPrerender() && "serviceWorker" in navigator) {
    const wb = new Workbox("/service-worker.js");

    const showSkipWaitingPrompt = () => {
      onPageRefresh();
      window.onPageRefresh = () => {
        onLoading();
        wb.messageSkipWaiting();
      };
      wb.addEventListener("controlling", () => {
        window.location.reload();
        onSuccess();
      });
    };

    wb.addEventListener("waiting", showSkipWaitingPrompt);

    wb.register();
  }
}
