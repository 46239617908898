import makeStyles from "@mui/styles/makeStyles";

export default makeStyles(
  () => ({
    menu: {
      padding: [26, 28],
    },
    close: {
      marginBottom: 20,
    },
  }),
  { name: "MenuDrawer" },
);
