import makeStyles from "@mui/styles/makeStyles";

export default makeStyles(
  (theme) => ({
    menu: {
      "& .MuiPaper-root": {
        width: 220,
        marginTop: 8,
        boxShadow: [2, 2, 5, 0, "#00000030"],
        borderRadius: 4,
      },
      "& .MuiList-root": {
        padding: [24, 0],
      },
      "& .MuiMenuItem-root:hover": {
        color: theme.palette.primary.main,
        background: "transparent",
      },
      "& .MuiMenuItem-root:not(:last-child)": {
        padding: [10, 16],
      },
    },
  }),
  { name: "AccountMenu" },
);
