import React, { useEffect } from "react";
import { isNil, isEmpty } from "ramda";
import { useLocalStorage } from "react-use";

import { useUsers } from "hooks";
import { externalRoutes } from "routes";
import UserPresenter from "presenters/UserPresenter";
import LocationInfoRepository from "repositories/LocationInfoRepository";

import useStyles from "./useStyles";

const NotSellMyDataLink = () => {
  const classes = useStyles();
  const { currentUser } = useUsers();
  const [locationInfo, setLocationInfo] = useLocalStorage("userLocationInfo", {});
  const listStates = {
    US: ["CA", "CO", "UT", "CT", "OR", "TX", "MT"],
  };
  const listCountries = [
    "AT",
    "BE",
    "BG",
    "HR",
    "CY",
    "CZ",
    "DK",
    "EE",
    "FI",
    "FR",
    "DE",
    "GR",
    "HU",
    "IE",
    "IT",
    "LV",
    "LT",
    "LU",
    "MT",
    "NL",
    "PL",
    "PT",
    "RO",
    "SK",
    "SI",
    "ES",
    "SE",
    "IS",
    "LI",
    "NO",
    "CH",
    "GB",
  ];

  const showNotSellMyData = (location) => {
    if (isNil(location?.country)) return false;

    if (location.country === "US" && listStates.US.includes(location.subdivision)) return true;

    if (listCountries.includes(location.country)) return true;

    return false;
  };

  useEffect(() => {
    if (isEmpty(locationInfo)) {
      LocationInfoRepository.get().then((data) => {
        setLocationInfo(data.data);
      });
    }
  }, []);

  return showNotSellMyData(locationInfo) ? (
    <div className={classes.notSellMyDataLink}>
      <a
        href={
          UserPresenter.isRegistered(currentUser)
            ? externalRoutes.notSellMyDataLinkPath(currentUser.email)
            : externalRoutes.notSellMyDataLinkPath()
        }
        target="_blank"
        rel="noreferrer"
      >
        Do Not Sell or Share My Data
      </a>
    </div>
  ) : (
    <></>
  );
};

export default NotSellMyDataLink;
