import { useEffect } from "react";
import { useSelector } from "react-redux";

import { FETCH_STATUSES } from "enums";
import { useLoadingActions } from "store/LoadingSlice";
import { serializeFunction, isFunc } from "utils/storeUtils";

import useFetchStatus from "./useFetchStatus";

const useGlobalLoading = (func = null, { labelFunction = null, crossPage = false } = {}) => {
  const { status, progress, error, labelFunc } = useSelector((state) => state.LoadingSlice);
  const { setError, reset, setStatus, setLabelFunc, clearLabelFunc } = useLoadingActions();

  useEffect(() => {
    if (!isFunc(labelFunction)) return undefined;
    setLabelFunc(serializeFunction(labelFunction));
    return () => {
      if (!crossPage) clearLabelFunc();
    };
  }, [isFunc(labelFunction)]);

  const funcWithLoading = (...params) => {
    setError(null);
    setStatus(FETCH_STATUSES.pending);
    return func(...params)
      .then((result) => {
        setStatus(FETCH_STATUSES.fulfilled);
        return result;
      })
      .catch((err) => {
        setStatus(FETCH_STATUSES.failed);
        setError(err);
        throw err;
      });
  };

  const { isIdle, isPending, isFulfilled, isFailed } = useFetchStatus(status);

  return {
    func: funcWithLoading,
    status,
    setStatus,
    error,
    reset,
    isIdle,
    isPending,
    isFulfilled,
    isFailed,
    isNotFinished: isIdle || isPending,
    isFinished: isFulfilled || isFailed,
    labelFunc,
    progress,
  };
};

export default useGlobalLoading;
