import { useState } from "react";
import { useInterval } from "react-use";

import { jjLogger } from "utils/logUtils";

const usePolling = (callback, onLimitReached, pollingInterval = 2000, maxIntervals = 60) => {
  const [polling, setPolling] = useState(false);
  const [intervalCount, setIntervalCount] = useState(0);

  const stopPolling = () => {
    setPolling(false);
    setIntervalCount(0);
  };

  const handlePolling = async () => {
    try {
      if (intervalCount < maxIntervals) {
        await callback();
        setIntervalCount((prevCount) => prevCount + 1);
      } else {
        onLimitReached();
        stopPolling();
      }
    } catch (error) {
      jjLogger.logError(`usePolling.js: handlePolling() Error polling: ${error}`);
      stopPolling();
    }
  };

  useInterval(
    () => {
      if (polling) {
        handlePolling();
      }
    },
    polling ? pollingInterval : null,
  );

  return { polling, intervalCount, startPolling: () => setPolling(true), stopPolling, handlePolling };
};

export default usePolling;
