import { useSelector } from "react-redux";

import { useJoinModalActions } from "store/JoinModalSlice";

const JOIN_MODAL_TIMEOUT = 5;

const useJoinModal = () => {
  const { openJoinModal, closeJoinModal } = useJoinModalActions();
  const { visible: joinModalVisible } = useSelector((state) => state.JoinModalSlice);

  return { JOIN_MODAL_TIMEOUT, openJoinModal, closeJoinModal, joinModalVisible };
};

export default useJoinModal;
