const baseContentfulPath = "/v1/contentful";

const buildContentfulPath = (subPath, param) => {
  let path = `${subPath}`;
  if (param && param !== "") path = `${subPath}/${param}`;
  return baseContentfulPath + path;
};

export default {
  menu: () => buildContentfulPath("/menu"),
  templateGroups: () => buildContentfulPath("/template-groups"),
  templateGroup: (slug) => buildContentfulPath("/template-groups", slug),
  morphablePackGroup: (slug) => buildContentfulPath("/morphable-pack-groups", slug),
  category: (slug) => buildContentfulPath("/categories", slug),
  page: (slug) => buildContentfulPath("/page", slug),
};
