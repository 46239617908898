import { useSelector } from "react-redux";

import { usePageUpdatedActions } from "store/PageUpdatedSlice";

const usePageUpdated = (isNeededUpdating = false, isNeededLoading = false) => {
  const isUpdating = useSelector((state) => (isNeededUpdating ? state.PageUpdatedSlice.pageUpdated : false));
  const loading = useSelector((state) => (isNeededLoading ? state.PageUpdatedSlice.loading : false));
  const { changeValue, removeValue, changeLoading } = usePageUpdatedActions();

  return { changeValue, isUpdating, removeValue, changeLoading, loading };
};

export default usePageUpdated;
