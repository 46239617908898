import { symmetricDifference } from "ramda";

import { PERSON_TO_UPDATE_PERSIST_KEY, getPersonToUpdate, clearPersonToUpdatePersist } from "utils/peopleUtils";

const usePersistPersonToUpdate = () => {
  const setValue = (value) => {
    const actualValue = getPersonToUpdate();
    const hasDiference = symmetricDifference(value, actualValue)?.length > 0;
    if (hasDiference) {
      sessionStorage.setItem(PERSON_TO_UPDATE_PERSIST_KEY, JSON.stringify(value));
    }
  };

  return { setValue, clearPersonToUpdatePersist };
};

export default usePersistPersonToUpdate;
