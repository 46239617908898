import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Grid, Link, Collapse } from "@mui/material";
import { NavLink } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ExpandLess } from "@mui/icons-material";

import withSYAIMenuItemTest from "layouts/UserLayout/components/Header/components/withSYAIMenuItemTest";
import MegaMenuItemPresenter from "presenters/MegaMenuItemPresenter";

import useStyles from "./useStyles";

const MenuItem = ({ menuItem, closeMenu }) => {
  const classes = useStyles();
  const [collapseSubMenu, setCollapseSubMenu] = useState(false);

  const name = MegaMenuItemPresenter.name(menuItem);
  const subMenuItmes = MegaMenuItemPresenter.childrenMenu(menuItem);

  const renderSimpleLink = () => {
    return MegaMenuItemPresenter.isExternalLinkTo(menuItem) ? (
      <Link
        id={MegaMenuItemPresenter.createComponentId(menuItem)}
        href={MegaMenuItemPresenter.linkTo(menuItem)}
        className={classes.menuItem}
        onClick={closeMenu}
      >
        {name}
      </Link>
    ) : (
      <Link
        id={MegaMenuItemPresenter.createComponentId(menuItem)}
        to={MegaMenuItemPresenter.linkTo(menuItem)}
        className={classes.menuItem}
        component={NavLink}
        onClick={closeMenu}
      >
        {name}
      </Link>
    );
  };

  const renderCollapsableLink = () => {
    return (
      <>
        <Grid className={classes.menuItem} onClick={() => setCollapseSubMenu(!collapseSubMenu)}>
          <Button
            id={`button-${MegaMenuItemPresenter.createComponentId(menuItem)}`}
            variant="link"
            endIcon={collapseSubMenu ? <ExpandMoreIcon /> : <ExpandLess />}
          >
            {name}
          </Button>
        </Grid>

        <Collapse
          hidden={!collapseSubMenu}
          in={collapseSubMenu}
          className={classes.subMenu}
          onClick={() => setCollapseSubMenu(!collapseSubMenu)}
        >
          <Grid className={classes.linkList}>
            {subMenuItmes.map((subMenu) => (
              <Link
                id={MegaMenuItemPresenter.createComponentId(subMenu)}
                className={classes.linkInList}
                key={MegaMenuItemPresenter.name(subMenu)}
                to={MegaMenuItemPresenter.linkTo(subMenu)}
                component={NavLink}
                onClick={closeMenu}
              >
                {subMenu.linkText}
              </Link>
            ))}
          </Grid>
        </Collapse>
      </>
    );
  };

  return subMenuItmes && subMenuItmes.length === 0 ? renderSimpleLink() : renderCollapsableLink();
};

MenuItem.propTypes = {
  menuItem: MegaMenuItemPresenter.shape(),
  closeMenu: PropTypes.func,
};

export default withSYAIMenuItemTest(MenuItem);
