/* global DetectUserAgent */
import { isNil } from "ramda";

import { externalRoutes } from "routes";
import UserPresenter from "presenters/UserPresenter";
import { METRICS } from "enums/tracking";
import { jjLogger } from "utils/logUtils";
import MorphedPackPresenter from "presenters/MorphedPackPresenter";

const METRIC = METRICS.FACESWAP;

export const getUrl = () => externalRoutes.jjTracking();

export const trackEvent = (eventData) => {
  if (!getUrl() || DetectUserAgent.isPrerender() || !eventData) return;

  navigator.sendBeacon(getUrl(), JSON.stringify(eventData));
};

export const isValidData = (data) => {
  return !isNil(data) && !isNil(data?.user) && !isNil(data?.morphedPack);
};

export const validateAdditionalData = (eventName, requiredData, additionalEventData) => {
  let isValid = true;

  requiredData.forEach((key) => {
    if (!additionalEventData[key]) {
      jjLogger.logError(`${eventName}: - Missing required data - key: ${key}`);
      isValid = false;
    }
  });

  return isValid;
};

export const baseEventData = (event, data) => {
  return {
    event,
    user_id: UserPresenter.id(data?.user),
    user_status: UserPresenter.analyticsStatus(data?.user),
    platform: "web",
    env: process.env.DEPLOY_ENV,
    morphed_pack_id: MorphedPackPresenter.id(data?.morphedPack),
    metric: METRIC,
  };
};

export const buildEventData = (event, data, additionalEventData = {}) => {
  if (!isValidData(data)) {
    jjLogger.logError("buildParams: - Invalid data provided");
    return null;
  }

  const eventData = baseEventData(event, data);

  return { ...eventData, ...additionalEventData };
};
