import localStorage from "localStorage";
import { not, isNil } from "ramda";

export const LOCAL_STORAGE_AUTH_KEY = "authInfo";

export const RECIPIENT_SESSION_KEY = "sessionIsRecipient";

export const CASTING_DATA = "castingData";

export const CURRENT_MAKE = "currentMake";

export const DATA_VOLUME = "dataVolume";

export const UPDATED_PAGE_KEY = "updated_page_key";

export const TEMPLATE_GROUP = "templateGroup";

export const MORPHABLE_PACK_GROUP = "morphablePackGroup";

export const ECARD = "ecard";

export const IN_FLOW = "in_flow";

export const SOURCE_PHOTO = "source_photo";

export const INVITE_SHARE_TYPE = "invite_share_type";

export const INVITE_SHOW_SHARE_CONFIRMATION = "invite_show_share_confirmation";

export const JIBJAB_TRACKING_ID = "jibjab_tracking_id";

export default {
  hasItem(key) {
    return not(isNil(localStorage.getItem(key)));
  },

  getItem(key) {
    const value = localStorage.getItem(key);
    return typeof value === "undefined" ? null : JSON.parse(value);
  },

  setItem(key, value) {
    localStorage.setItem(key, JSON.stringify(value));
  },

  removeItem(key) {
    localStorage.removeItem(key);
  },

  clearForSignOut() {
    localStorage.removeItem(CASTING_DATA);
    localStorage.removeItem(IN_FLOW);
    localStorage.removeItem(ECARD);
    localStorage.removeItem(TEMPLATE_GROUP);
    localStorage.removeItem(SOURCE_PHOTO);
    localStorage.removeItem(MORPHABLE_PACK_GROUP);
    localStorage.removeItem(INVITE_SHARE_TYPE);
    localStorage.removeItem(INVITE_SHOW_SHARE_CONFIRMATION);
  },
};
