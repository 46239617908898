import { contentfulRoutes } from "routes";

import ContentfulRepository from "./ContentfulRepository";

export default {
  index(slugs) {
    const url = contentfulRoutes.templateGroups();
    const params = { filter: { slugs } };
    return ContentfulRepository.get(url, params);
  },
  show(slug, includeTheme = false) {
    const url = contentfulRoutes.templateGroup(slug);
    const headers = { Accept: "application/vnd.api+json" };
    const params = { includes: includeTheme ? "themes" : undefined };
    if (includeTheme) params.includes = "theme";
    return ContentfulRepository.get(url, params, headers);
  },
};
