import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { helmetJsonLdProp } from "react-schemaorg";

const LDJsonSchema = ({ schema }) => {
  return <Helmet script={[helmetJsonLdProp(schema)]} />;
};

LDJsonSchema.propTypes = {
  schema: PropTypes.shape({
    "@context": PropTypes.string.isRequired,
    "@type": PropTypes.string.isRequired,
  }).isRequired,
};

export default LDJsonSchema;
