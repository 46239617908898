import { contentfulRoutes } from "routes";

import ContentfulRepository from "./ContentfulRepository";

export default {
  show(slug) {
    const url = contentfulRoutes.morphablePackGroup(slug);
    return ContentfulRepository.get(url);
  },
};
