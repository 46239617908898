import { useState } from "react";

import { FETCH_STATUSES } from "enums";

import useFetchStatus from "./useFetchStatus";

const useLoading = (func) => {
  const [status, setStatus] = useState(FETCH_STATUSES.idle);
  const [error, setError] = useState(null);

  const reset = () => {
    setStatus(FETCH_STATUSES.idle);
    setError(null);
  };

  const funcWithLoading = (...params) => {
    setError(null);
    setStatus(FETCH_STATUSES.pending);
    return func(...params)
      .then((result) => {
        setStatus(FETCH_STATUSES.fulfilled);
        return result;
      })
      .catch((err) => {
        setStatus(FETCH_STATUSES.failed);
        setError(err);
        throw err;
      });
  };

  const { isIdle, isPending, isFulfilled, isFailed } = useFetchStatus(status);

  return {
    func: funcWithLoading,
    status,
    error,
    reset,
    isIdle,
    isPending,
    isFulfilled,
    isFailed,
    isNotFinished: isIdle || isPending,
    isFinished: isFulfilled || isFailed,
  };
};

export default useLoading;
