import PropTypes from "prop-types";

import Presenter from "utils/PropTypesPresenter";

export default new Presenter(
  {
    id: PropTypes.string,
    type: PropTypes.string,
    url: PropTypes.string,
    morphableTargetPhotoSlug: PropTypes.string,
  },
  {
    fileName(photo) {
      return `${this.id(photo)}.jpg`;
    },
  },
);
