import React from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";

const ResponsiveImage = ({ src, alt, width, widthMobile, maxScale, ...props }) => {
  const theme = useTheme();
  const { mobile: mobileBreakpoint } = theme.breakpoints.values;
  const contentfulHost = process.env.CONTENTFUL_IMAGE_HANDLER_HOST;
  const imagehandlerHost = process.env.IMAGE_HANDLER_HOST;
  const bucketList = {
    packs: process.env.WEBPACK_ASSETS_AWS_BUCKET,
    image_assets: process.env.UGC_ASSETS_AWS_BUCKET,
    contentful: process.env.CONTENTFUL_UGC_ASSETS_AWS_BUCKET,
    pills: process.env.UGC_ASSETS_AWS_BUCKET,
    heads: process.env.UGC_ASSETS_AWS_BUCKET,
  };

  const key = () => {
    if (!src) return "";

    let path = src;
    if (src.includes("localhost")) {
      path = src.split("localhost:3000/").pop();
    } else if (src.includes("assets.jibjab.dev")) {
      path = src.split("assets.jibjab.dev/").pop();
    } else if (src.includes("jibjab.com/")) {
      path = src.split("jibjab.com/").pop();
    }

    return path[0] === "/" ? path.substring(1) : path;
  };

  const bucket = () => {
    const index = key().split("/");

    if (!index[0]) return null;

    return bucketList[index[0]];
  };

  const getImageUrl = (newWidth, newHeight) => {
    if (!key() || !bucket()) return src;

    const data = {
      bucket: bucket(),
      key: key(),
      edits: {
        resize: {
          fit: "cover",
          withoutEnlargement: true,
        },
      },
    };

    if (newWidth) {
      data.edits.resize.width = newWidth;
    }

    if (newHeight) {
      data.edits.resize.height = newHeight;
    }

    const isContentful = data.key.split("/")[0] === "contentful";
    const newPath = window.btoa(unescape(encodeURIComponent(JSON.stringify(data))));

    return isContentful ? `${contentfulHost}/${newPath}` : `${imagehandlerHost}${newPath}`;
  };

  const createSrcSet = (newWidth) => {
    let srcSet = "";

    for (let i = 1; i <= maxScale; i += 1) {
      const newSize = i * newWidth;
      srcSet += `${getImageUrl(newSize)} ${newSize}w,`;
    }

    return srcSet;
  };

  const srcSet = `${createSrcSet(widthMobile)} ${createSrcSet(width)}`;
  const sizes = `(max-width: ${mobileBreakpoint}px) ${widthMobile}px, ${width}px`;

  return <img src={getImageUrl(width)} srcSet={srcSet} sizes={sizes} alt={alt} width={width} {...props} />;
};

ResponsiveImage.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  widthMobile: PropTypes.number.isRequired,
  maxScale: PropTypes.number,
};

ResponsiveImage.defaultProps = {
  maxScale: 3,
};

export default ResponsiveImage;
