import { useEffect } from "react";
import duration from "dayjs/plugin/duration";
import dayjs from "dayjs";

import { useAuthActions } from "store/AuthSlice";
import { getAuthFromLocalStorage } from "utils/authUtils";
import { jjLogger } from "utils/logUtils";

import useUsers from "./useUsers";

const useRefreshAuthToken = () => {
  const { refreshAuthData } = useAuthActions();
  const { signOut, needsRefreshScheduled, scheduleRefresh } = useUsers();
  let timeoutID;

  dayjs.extend(duration);

  const refreshTime = (authData) => {
    const offset = 60;
    const now = dayjs();
    const expiresAt = dayjs.unix(authData.createdAt + authData.expiresIn - offset);
    const seconds = dayjs.duration(expiresAt.diff(now)).asSeconds();

    return seconds < 0 ? 0 : seconds * 1000;
  };

  const refreshData = (seconds) => {
    timeoutID = setTimeout(() => {
      jjLogger.logDebug("RefreshAuthToken.js: refreshData: Refreshing Auth Token");
      const authData = getAuthFromLocalStorage();
      const { refreshToken } = authData;

      refreshAuthData(refreshToken)
        .then((newAuthData) => {
          refreshData(refreshTime(newAuthData));
        })
        .catch((error) => {
          jjLogger.logError(
            `RefreshAuthToken.js: refreshData: Error refreshing auth token, signing user out: ${error}`,
          );
          signOut();
        });
    }, seconds);
  };

  useEffect(() => {
    if (!needsRefreshScheduled) return;

    const authData = getAuthFromLocalStorage();
    scheduleRefresh(false);
    clearTimeout(timeoutID);

    if (authData?.refreshToken) {
      jjLogger.logDebug("RefreshAuthToken.js: useEffect: Scheduling Token Refresh");
      refreshData(refreshTime(authData));
    }
  }, [needsRefreshScheduled]);
};

export default useRefreshAuthToken;
