import { createTheme } from "@mui/material/styles";

import palette from "./palette";
import components from "./components";

const htmlFontSize = 16;
export const breakpointsValues = {
  xs: 0,
  sm: 780,
  md: 960,
  lg: 1280,
  xl: 1920,
  mobile: 780,
  tablet: 1280,
};

const theme = createTheme({
  palette,
  components,
  typography: {
    fontFamily: ["Inter"],
    htmlFontSize,
    h1: {
      fontSize: 20,
      fontWeight: "bold",
      [`@media (min-width:${breakpointsValues.mobile}px)`]: {
        fontSize: 24,
      },
      color: palette.text.main,
    },
    h2: {
      fontSize: 20,
      fontWeight: "bold",
      [`@media (min-width:${breakpointsValues.mobile}px)`]: {
        fontSize: 24,
      },
      color: palette.text.main,
    },
    h3: {
      fontSize: 18,
      fontWeight: "bold",
      [`@media (min-width:${breakpointsValues.mobile}px)`]: {
        fontSize: 20,
      },
      color: palette.text.main,
    },
    h4: {
      fontSize: htmlFontSize,
      fontWeight: "bold",
      [`@media (min-width:${breakpointsValues.mobile}px)`]: {
        fontSize: 18,
        fontWeight: 600,
      },
      color: palette.text.main,
    },
    h5: {
      fontSize: 14,
      fontWeight: 600,
      [`@media (min-width:${breakpointsValues.mobile}px)`]: {
        fontSize: htmlFontSize,
      },
      color: palette.text.main,
    },
    h6: {
      fontSize: 12,
      fontWeight: 600,
      [`@media (min-width:${breakpointsValues.mobile}px)`]: {
        fontSize: 14,
      },
      color: palette.text.main,
    },
    "x-large": {
      fontSize: 24,
      fontWeight: "600",
      [`@media (min-width:${breakpointsValues.mobile}px)`]: {
        fontSize: 32,
      },
      color: palette.text.main,
    },
    large: {
      fontSize: htmlFontSize,
      [`@media (min-width:${breakpointsValues.mobile}px)`]: {
        fontSize: 18,
      },
    },
    regular: {
      fontSize: 14,
      [`@media (min-width:${breakpointsValues.mobile}px)`]: {
        fontSize: htmlFontSize,
      },
    },
    small: {
      fontSize: 12,
      [`@media (min-width:${breakpointsValues.mobile}px)`]: {
        fontSize: 14,
      },
    },
    caption: {
      fontSize: 10,
      [`@media (min-width:${breakpointsValues.mobile}px)`]: {
        fontSize: 12,
      },
    },
    homeHeroMain: {
      fontSize: 37,
      lineHeight: 1.2,
      [`@media (min-width:${breakpointsValues.tablet}px)`]: {
        fontSize: 60,
      },
    },
    homeHeroSecondary: {
      fontSize: 19,
      [`@media (min-width:${breakpointsValues.tablet}px)`]: {
        fontSize: 32,
      },
    },
  },
  breakpoints: {
    values: breakpointsValues,
  },
  zIndex: {
    loadingModal: 1400,
    faceModal: 1400,
  },
  pxToRem: (px) => `${px / htmlFontSize}rem`,
  sizes: {
    desktop: {
      header: {
        height: 90,
      },
    },
    mobile: {
      header: {
        height: 100,
      },
    },
  },
});

export default theme;
