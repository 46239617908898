import React from "react";
import { Button, Link, IconButton } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { NavLink } from "react-router-dom";

import { useUsers } from "hooks";
import { appRoutes } from "routes";
import Logo from "assets/images/Logo.svg";
import Navigation from "layouts/UserLayout/components/Header/components/Navigation";
import UserPresenter from "presenters/UserPresenter";
import { headerSelector } from "tests/selectors/index.js";

import AccountMenu from "../AccountMenu/AccountMenu";

import useStyles from "./useStyles";

const DesktopHeader = (props) => {
  const classes = useStyles();
  const { currentUser } = useUsers();

  return (
    <div className={classes.root}>
      <Link id="header-logo-desktop" to={appRoutes.rootPath()} component={NavLink}>
        <img src={Logo} width="120px" height="36px" className={classes.logo} alt="JibJab" />
      </Link>

      <div className={classes.nav}>
        <Navigation {...props} />
      </div>

      <div className={classes.actions}>
        <div className={classes.action}>
          <IconButton
            alt="Search"
            component={NavLink}
            id="header-search-desktop"
            aria-label="search"
            to={appRoutes.searchPath()}
            color="text.light"
          >
            <SearchIcon style={{ position: "relative", top: 1 }} />
          </IconButton>
        </div>

        {UserPresenter.isRegistered(currentUser) && !UserPresenter.isPaid(currentUser) && (
          <div className={classes.action}>
            <Button
              id="header-upgrade-desktop"
              to={appRoutes.paymentPath()}
              data-testid={headerSelector.buttons.upgrade}
              component={NavLink}
              variant="contained"
              color="primary"
            >
              Upgrade
            </Button>
          </div>
        )}

        {UserPresenter.isRegistered(currentUser) && (
          <div className={classes.action}>
            <AccountMenu />
          </div>
        )}

        {!UserPresenter.isRegistered(currentUser) && (
          <div className={classes.action}>
            <Link id="header-login-desktop" to={appRoutes.loginPath()} component={NavLink} color="text.light">
              Log in
            </Link>
          </div>
        )}

        {!UserPresenter.isRegistered(currentUser) && (
          <div className={classes.action}>
            <Button
              id="header-sign-up-desktop"
              to={appRoutes.signUpPath()}
              data-testid={headerSelector.buttons.signup}
              component={NavLink}
              variant="contained"
              color="primary"
            >
              Sign Up
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default DesktopHeader;
