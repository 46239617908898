import makeStyles from "@mui/styles/makeStyles";

export default makeStyles(
  (theme) => ({
    root: {
      minHeight: "100vh",
      display: "grid",
      gridTemplateColumns: "1fr",
      gridTemplateRows: "auto 1fr auto",
    },
    "@global *": {
      minWidth: 0,
      minHeight: 0,
    },
    "@global #simple-popper": {
      zIndex: theme.zIndex.tooltip,
    },
  }),
  { name: "UserLayout" },
);
