const host = process.env.JIBJAB_API_HOST ? `https://${process.env.JIBJAB_API_HOST}` : "";

export default {
  rootPath: () => `${host}/v1`,
  invites: (slug) => (slug ? `${host}/v1/invites/${slug}` : `${host}/v1/invites`),
  morphedPacks: (slug) => (slug ? `${host}/v1/morphed_packs/${slug}` : `${host}/v1/morphed_packs`),
  sourcePhotos: () => `${host}/v1/source_photos`,
  inviteEvents: () => `${host}/v1/invite_events`,
  inviteGuests: (inviteSlug, guestSlug) =>
    guestSlug ? `${host}/v1/invites/${inviteSlug}/guests/${guestSlug}` : `${host}/v1/invites/${inviteSlug}/guests`,
};
