import PropTypes from "prop-types";

import Presenter from "utils/PropTypesPresenter";

export default new Presenter(
  {
    id: PropTypes.string,
    type: PropTypes.string,
    name: PropTypes.string,
    query: PropTypes.string,
    title: PropTypes.string,
    metaDescription: PropTypes.string,
    metaKeywords: PropTypes.string,
    indexable: PropTypes.string,
  },
  {
    pageModules(p) {
      return p?.pageModules;
    },

    firstCarousel(p) {
      const carousels = this.pageModules(p).filter(
        (module) => module?.type === "algolia-cards" && module?.attributes?.type === "Carousel",
      );
      return carousels[0];
    },

    firstList(p) {
      const lists = this.pageModules(p).filter(
        (module) => module?.type === "algolia-cards" && module?.attributes?.type === "List",
      );
      return lists[0];
    },
  },
);
