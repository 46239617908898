import PropTypes from "prop-types";

import Presenter from "utils/PropTypesPresenter";

export default new Presenter(
  {
    id: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
    mainImage: PropTypes.string,
    backgroundImage: PropTypes.string,
    backgroundColor: PropTypes.string,
    altText: PropTypes.string,
    link: PropTypes.string,
  },
  {},
);
