import { useSelector } from "react-redux";

import { useMakesActions } from "store/MakesSlice";
import { makeAttributes } from "utils/makeUtils";

import useLastEcard from "./useLastEcard";

const useMakes = () => {
  const { setLastEcard } = useLastEcard();
  const {
    createMake,
    loadMake,
    clearMake,
    patchMake,
    loadMakes,
    clearMakes,
    loadMoreMakes,
    deleteMake,
    updateMake,
    setCurrentMake,
  } = useMakesActions();

  const { make, makes, meta } = useSelector((state) => state.MakesSlice);

  const saveMake = ({ roles, templateId, templateGroup, makeThumb, shareThumb }) => {
    const attrs = makeAttributes({ makeThumb, shareThumb, roles, templateId });

    return createMake(attrs).then((_make) => {
      setLastEcard(_make, templateGroup);
      return _make;
    });
  };

  return {
    saveMake,
    loadMake,
    make,
    clearMake,
    patchMake,
    loadMakes,
    loadMoreMakes,
    clearMakes,
    makes,
    meta,
    deleteMake,
    updateMake,
    setCurrentMake,
  };
};

export default useMakes;
