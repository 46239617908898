import { v4 as uuidv4 } from "uuid";

import MakePresenter from "presenters/MakePresenter";
import OfferPresenter from "presenters/OfferPresenter";
import { appRoutes } from "routes";
import { CORDIAL_EVENTS } from "enums";

const trackCordialEvent = (eventName, properties) => {
  if (window?.crdl) {
    window.crdl("event", eventName, properties);
  }
};

const trackCordialContact = (authData, contactData) => {
  if (window?.crdl) {
    window.crdl("contact", authData, contactData);
  }
};

export const trackCordialLoginRegister = (email) => {
  trackCordialContact({ email }, {});
};

export const trackCordialMakeCreated = (make, templateGroup) => {
  const { origin } = window.location;
  const isLive = MakePresenter.isLive(make);
  const makeViewPath = appRoutes.makeViewPath(templateGroup?.id, MakePresenter.id(make));
  const makePreviewPath = appRoutes.makePreviewPath(templateGroup?.id, MakePresenter.id(make));
  const makeThumbUrl = MakePresenter.thumbnail(make);
  const eventName = isLive ? CORDIAL_EVENTS.LIVE_MAKE : CORDIAL_EVENTS.DRAFT_MAKE;
  const properties = {
    [isLive ? "live_make_url" : "draft_make_url"]: `${origin}${isLive ? makeViewPath : makePreviewPath}`,
    make_thumbnail_url: makeThumbUrl,
  };
  return trackCordialEvent(eventName, properties);
};

export const trackCordialHeadCreationEvent = (headUrl) => {
  const properties = { head_url: headUrl };
  trackCordialEvent(CORDIAL_EVENTS.HEAD_CREATED, properties);
};

export const trackCordialSubscriptionEvent = (currentOffer) => {
  const properties = {
    name: OfferPresenter.name(currentOffer),
    duration: `${OfferPresenter.duration(currentOffer)} ${OfferPresenter.durationUnit(currentOffer)}`,
    price: OfferPresenter.price(currentOffer),
  };
  trackCordialEvent(CORDIAL_EVENTS.SUBSCRIPTION_PURCHASED, properties);
};

export const trackCordialOrder = (currentOffer) => {
  const uniqueId = uuidv4();
  const orderData = {
    orderID: `jibjab_${uniqueId}`,
    items: [
      {
        productID: `${OfferPresenter.name(currentOffer)}_${uniqueId}`,
        sku: OfferPresenter.id(currentOffer),
        category: "subscription",
        name: OfferPresenter.name(currentOffer),
        qty: 1,
        itemPrice: OfferPresenter.price(currentOffer),
      },
    ],
  };

  if (window?.crdl) {
    window.crdl([
      ["cart", "clear"],
      ["order", "add", orderData],
      ["event", "order"],
    ]);
  }
};
