import { createSlice } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";

import { deserialize } from "utils/storeUtils";
import FeaturedEcardsRepository from "repositories/FeaturedEcardsRepository";

const initialState = {
  featuredEcards: null,
};

const featuredEcardsSlice = createSlice({
  name: "featuredEcards",
  initialState,
  reducers: {
    loadFeaturedEcardsSuccess(state, { payload }) {
      state.featuredEcards = deserialize(payload);
    },
  },
});

export const { actions } = featuredEcardsSlice;
export default featuredEcardsSlice.reducer;

export const useFeaturedEcardsActions = () => {
  const dispatch = useDispatch();

  const loadFeaturedEcards = () => {
    return FeaturedEcardsRepository.index().then(({ data }) => {
      dispatch(featuredEcardsSlice.actions.loadFeaturedEcardsSuccess(data));
      return data.included;
    });
  };

  return {
    loadFeaturedEcards,
  };
};
