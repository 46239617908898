import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import R from "ramda";

import { useSearchActions } from "store/SearchSlice";

const getSearchResults = (state) => state.SearchSlice.searchResults;

const getStarringYouClipCards = createSelector(getSearchResults, (ecards) => {
  if (R.isNil(ecards)) {
    return null;
  }
  return ecards.filter((ecard) => ecard.templateGroupType === "starring_you_clip" && ecard.thumbnail);
});

const getStarringYouCards = createSelector(getSearchResults, (ecards) => {
  if (R.isNil(ecards)) {
    return null;
  }
  return ecards.filter((ecard) => ecard.templateGroupType === "starring_you");
});

const getStraightUpCards = createSelector(getSearchResults, (ecards) => {
  if (R.isNil(ecards)) {
    return null;
  }
  return ecards.filter((ecard) => ecard.templateGroupType === "straight_up");
});

const useSearch = () => {
  const { loadSearchResults, resetSearchResults } = useSearchActions();
  const starringYouClipCards = useSelector(getStarringYouClipCards);
  const starringYouCards = useSelector(getStarringYouCards);
  const straightUpCards = useSelector(getStraightUpCards);

  return { loadSearchResults, resetSearchResults, starringYouClipCards, starringYouCards, straightUpCards };
};

export default useSearch;
