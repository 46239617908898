import { jjLogger } from "utils/logUtils";
import useSettings from "hooks/useSettings";
import { loadScriptByURL } from "utils/scriptsUtils";

const useRecaptcha = () => {
  const {
    settings: {
      recaptcha: {
        enabled: recaptchaEnabled,
        subscription_enabled: recaptchaSubscriptionEnabled,
        login_enabled: recaptchaLoginEnabled,
        key: recaptchaKey,
      },
    },
  } = useSettings();

  const actionEnabled = (actionName) => {
    switch (actionName) {
      case "subscription":
        return recaptchaEnabled && recaptchaSubscriptionEnabled;
      case "login":
        return recaptchaEnabled && recaptchaLoginEnabled;
      default:
        return false;
    }
  };

  const requestRecapchaToken = (settings) => {
    return new Promise((resolve) => {
      return window.grecaptcha.ready(async () => {
        const token = await window.grecaptcha.execute(recaptchaKey, { action: settings.actionName });
        resolve(token);
      });
    });
  };

  const recaptcha = async (settings) => {
    try {
      if (!actionEnabled(settings.actionName)) return null;

      await loadScriptByURL(recaptchaKey, `https://www.google.com/recaptcha/api.js?render=${recaptchaKey}`);
      return await requestRecapchaToken(settings);
    } catch (error) {
      const errorMessage = `useRecaptcha.js: recaptcha() ${error}`;

      jjLogger.logError(errorMessage);
      return null;
    }
  };

  return {
    recaptcha,
    actionEnabled,
  };
};

export default useRecaptcha;
