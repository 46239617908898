import { createSlice } from "@reduxjs/toolkit";

import SubscriptionsRepository from "repositories/SubscriptionsRepository";
import { deserialize } from "utils/storeUtils";

const initialState = {
  errors: null,
};

const subscriptionsSlice = createSlice({
  name: "subscriptions",
  initialState,
  reducers: {},
});

export const { actions } = subscriptionsSlice;
export default subscriptionsSlice.reducer;

export const useSubscriptionsActions = () => {
  const createSubscription = (params) => {
    return SubscriptionsRepository.create(params);
  };

  const getBillingInfo = () => {
    return SubscriptionsRepository.getBillingInfo().then(({ data }) => deserialize(data));
  };

  const updateBillingInfo = (params) => {
    return SubscriptionsRepository.updateBillingInfo(params);
  };

  return {
    createSubscription,
    getBillingInfo,
    updateBillingInfo,
  };
};
