import PropTypes from "prop-types";

import Presenter from "utils/PropTypesPresenter";

export default new Presenter(
  {
    id: PropTypes.string,
    type: PropTypes.string,
    date: PropTypes.string,
    status: PropTypes.string,
    url: PropTypes.string,
  },
  {},
);
