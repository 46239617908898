import { createSlice } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";

import { FETCH_STATUSES } from "enums";

const initialState = {
  status: FETCH_STATUSES.idle,
  progress: null,
  error: null,
  labelFunc: null,
};

const loadingSlice = createSlice({
  name: "loading",
  initialState,
  reducers: {
    setStatus(state, { payload }) {
      state.status = payload;
    },
    setProgress(state, { payload }) {
      state.progress = payload;
    },
    setError(state, { payload }) {
      state.error = payload;
    },
    setLabelFunc(state, { payload }) {
      state.labelFunc = payload;
    },
    clearLabelFunc(state) {
      state.labelFunc = null;
    },
    reset(state) {
      const { error, status, progress } = initialState;
      state.error = error;
      state.status = status;
      state.progress = progress;
    },
  },
});

export const { actions } = loadingSlice;
export default loadingSlice.reducer;

export const useLoadingActions = () => {
  const dispatch = useDispatch();

  const reset = () => dispatch(loadingSlice.actions.reset());
  const setError = (error) => dispatch(loadingSlice.actions.setError(error));
  const setStatus = (status) => dispatch(loadingSlice.actions.setStatus(status));
  const setProgress = (progress) => dispatch(loadingSlice.actions.setProgress(progress));
  const setLabelFunc = (func) => dispatch(loadingSlice.actions.setLabelFunc(func));
  const clearLabelFunc = () => dispatch(loadingSlice.actions.clearLabelFunc());

  return {
    reset,
    setError,
    setStatus,
    setProgress,
    setLabelFunc,
    clearLabelFunc,
  };
};
