import { createSlice } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { concat } from "ramda";

import { deserialize, deserializeErrors } from "utils/storeUtils";
import MorphedPackRepository from "repositories/MorphedPackRepository";
import { jjLogger } from "utils/logUtils";

const initialState = {
  morphedPack: null,
  morphedPacks: null,
  meta: null,
};

const morphedPackSlice = createSlice({
  name: "morphedPack",
  initialState,
  reducers: {
    setMorphedPack(state, { payload }) {
      state.morphedPack = payload;
    },
    setMorphedPacks(state, { payload }) {
      state.morphedPacks = payload.morphedPacks;
      state.meta = payload.meta;
    },
    setMoreMorphedPacks(state, { payload }) {
      state.morphedPacks = concat(state.morphedPacks, payload);
    },
  },
});

export const { actions } = morphedPackSlice;
export default morphedPackSlice.reducer;

export const useMorphedPackActions = () => {
  const dispatch = useDispatch();

  const loadMorphedPack = (slug) => {
    return MorphedPackRepository.show(slug).then(({ data }) => {
      const morphedPack = deserialize(data);
      dispatch(morphedPackSlice.actions.setMorphedPack(morphedPack));
      return morphedPack;
    });
  };

  const loadMoreMorphedPacks = (params) => {
    return MorphedPackRepository.index(params)
      .then((response) => {
        const morphedPacks = deserialize(response.data);
        dispatch(morphedPackSlice.actions.setMoreMorphedPacks(morphedPacks));
        return morphedPacks;
      })
      .catch((error) => {
        jjLogger.logError(`MorphedPackSlice.js: loadMoreMorphedPacks() error: ${JSON.stringify(error)}`);
        return Promise.reject(deserializeErrors(error));
      });
  };

  const createMorphedPack = (params) => {
    return MorphedPackRepository.create(params).then(({ data }) => {
      const morphedPack = deserialize(data);
      dispatch(morphedPackSlice.actions.setMorphedPack(morphedPack));
      return morphedPack;
    });
  };

  const generatePreviewPhoto = (slug) => {
    return MorphedPackRepository.previewPhoto(slug).then(({ data }) => {
      return data;
    });
  };

  const generateStandardPhotos = (slug) => {
    return MorphedPackRepository.standardPhotos(slug).then(({ data }) => {
      return data;
    });
  };

  const getStatus = (slug) => {
    return MorphedPackRepository.status(slug).then(({ data }) => {
      return data;
    });
  };

  const loadMorphedPacks = (params) => {
    return MorphedPackRepository.index(params)
      .then(({ data }) => {
        const morphedPacks = deserialize(data);
        dispatch(morphedPackSlice.actions.setMorphedPacks({ morphedPacks, meta: data.meta }));
        return morphedPacks;
      })
      .catch((error) => {
        jjLogger.logError(`MorphedPackSlice.js: loadMorphedPacks() error: ${JSON.stringify(error)}`);
        return Promise.reject(deserializeErrors(error));
      });
  };

  return {
    loadMorphedPack,
    createMorphedPack,
    generatePreviewPhoto,
    generateStandardPhotos,
    getStatus,
    loadMorphedPacks,
    loadMoreMorphedPacks,
  };
};
