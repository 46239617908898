import { createSlice } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";

import { deserialize } from "utils/storeUtils";
import TransactionRecordsRepository from "repositories/TransactionRecordsRepository";

const initialState = {
  transactionRecords: null,
  transactionRecord: null,
};

const transactionRecordsSlice = createSlice({
  name: "transactionRecords",
  initialState,
  reducers: {
    loadTransactionRecordsSuccess(state, { payload }) {
      state.transactionRecords = deserialize(payload);
    },
    loadTransactionRecordSuccess(state, { payload }) {
      state.transactionRecord = deserialize(payload);
    },
  },
});

export const { actions } = transactionRecordsSlice;
export default transactionRecordsSlice.reducer;

export const useTransactionRecordsActions = () => {
  const dispatch = useDispatch();

  const loadTransactionRecords = () => {
    return TransactionRecordsRepository.index().then(({ data }) => {
      dispatch(transactionRecordsSlice.actions.loadTransactionRecordsSuccess(data));
      return data;
    });
  };

  const loadTransactionRecord = (id) => {
    return TransactionRecordsRepository.show(id).then(({ data }) => {
      dispatch(transactionRecordsSlice.actions.loadTransactionRecordSuccess(data));
      return data;
    });
  };

  return {
    loadTransactionRecords,
    loadTransactionRecord,
  };
};
