import { useEffect } from "react";

import useRouter from "hooks/useRouter";
import useLastEcard from "hooks/useLastEcard";
import { FLOW_START_PAGES, FLOW_WHITELIST_PAGES } from "enums";

const useInFlowManager = () => {
  const { location } = useRouter();
  const { inFlow, setInFlow } = useLastEcard();

  const startPages = FLOW_START_PAGES.map((appRoute) => appRoute("([^/]+)", "([^/]+)"));
  const whitelistPages = FLOW_WHITELIST_PAGES;

  const matchesPath = (appRoute, path) => new RegExp(`^${appRoute}`).test(path);
  const isStartPage = (path) => startPages.some((appRoute) => matchesPath(appRoute, path));
  const isWhitelistPage = (path) => whitelistPages.some((appRoute) => matchesPath(appRoute, path));

  useEffect(() => {
    const path = location.pathname;
    const startFlow = isStartPage(path);
    const isflowPage = startFlow || isWhitelistPage(path);

    if (startFlow) {
      setInFlow(true);
    } else if (inFlow) {
      setInFlow(isflowPage);
    }
  }, [location]);
};

export default useInFlowManager;
