import { apiRoutes } from "routes";
import FetchHelpers from "utils/FetchHelpers";
import { newRelic, jjLogger } from "utils/logUtils";

const sameOriginAssets = (response) => {
  const newResponse = response;
  try {
    for (let i = 0; i < newResponse.data.data.length; i += 1) {
      const { asset } = newResponse.data.data[i].attributes;
      if (asset) {
        newResponse.data.data[i].attributes.asset = FetchHelpers.toSameOrigin(asset);
      }
    }
  } catch (error) {
    newRelic(`FaceRepository Error: ${error.message}`, error);
    jjLogger.log(error.message);
  }

  return newResponse;
};

const sameOriginAsset = (response) => {
  const newResponse = response;
  try {
    const { asset } = newResponse.data.data.attributes;
    if (asset) {
      newResponse.data.data.attributes.asset = FetchHelpers.toSameOrigin(asset);
    }
  } catch (error) {
    newRelic(`FaceRepository Error: ${error.message}`, error);
    jjLogger.log(error.message);
  }

  return newResponse;
};

export default {
  index(params) {
    const url = apiRoutes.headsPath(1, params);
    return FetchHelpers.get(url).then(sameOriginAssets);
  },
  delete(slug) {
    const url = apiRoutes.headPath(1, slug);
    return FetchHelpers.delete(url);
  },
  create(params) {
    const url = apiRoutes.headsPath(1);
    return FetchHelpers.post(url, params).then(sameOriginAsset);
  },
  update(slug, params) {
    const url = apiRoutes.headPath(1, slug);
    return FetchHelpers.put(url, params).then(sameOriginAsset);
  },
  partialUpdate(slug, params) {
    const url = apiRoutes.headPath(1, slug);
    return FetchHelpers.patch(url, params).then(sameOriginAsset);
  },
};
