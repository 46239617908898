import makeStyles from "@mui/styles/makeStyles";

export default makeStyles(
  (theme) => ({
    root: {
      padding: [12, 16],
      [theme.breakpoints.up("mobile")]: {
        padding: [12, 22],
      },
    },
    menuItem: {
      margin: [16, 0],
      display: "block",
    },
    button: {
      margin: [16, 0],
    },
    message: {
      maxWidth: 470,
      margin: [16, 0],
    },
    action: {
      marginRight: 10,
      [theme.breakpoints.up("mobile")]: {
        marginRight: 32,
      },
    },
  }),
  { name: "MobileHeader" },
);
