/* eslint-disable no-param-reassign */

export const canvasToImage = (canvas, type = "image/jpeg") => canvas.toDataURL(type, 0.9);

export const canvasToBlob = (canvas) =>
  new Promise((resolve) => {
    canvas.toBlob((blob) => {
      resolve(blob);
    });
  });

export const initCanvas = ({ width, height }) => {
  const canvas = document.createElement("canvas");
  canvas.width = width;
  canvas.height = height;

  const context = canvas.getContext("2d");
  return [canvas, context];
};

export const transformContext = (ctx, { offset, scale, angle }) => {
  const { width, height } = ctx.canvas;

  if (offset) ctx.translate(offset.x, offset.y);
  ctx.translate(width / 2, height / 2);
  if (scale) ctx.scale(scale, scale);
  if (angle) ctx.rotate(angle);
  ctx.translate(-width / 2, -height / 2);
};

export const matrixFor = ({ rotation, center }) => {
  const [, context] = initCanvas({ width: 1, height: 1 });
  context.translate(center.x, center.y);
  context.rotate(-rotation);
  context.translate(-center.x, -center.y);
  return context.getTransform();
};

const drawSvgCommand = (ctx, command) => {
  const [action, ...args] = command;
  switch (true) {
    case action === "M":
      return ctx.moveTo(...args);
    case action === "L":
      return ctx.lineTo(...args);
    case action === "C":
      return ctx.bezierCurveTo(...args);
    case action === "Q":
      return ctx.quadraticCurveTo(...args);
    default:
      return null;
  }
};

export const drawSvgPath = (ctx, svgPath, { color }) => {
  const { path: commands } = svgPath;

  ctx.save();

  ctx.strokeStyle = color;
  ctx.beginPath();
  ctx.lineWidth = 4.0;
  commands.map((command) => drawSvgCommand(ctx, command));
  ctx.closePath();
  ctx.stroke();

  ctx.restore();
};
