import { useSelector } from "react-redux";

import { useMenuActions } from "../store/MenuSlice";

const useMenu = () => {
  const { loadMenu } = useMenuActions();

  const { menuItems } = useSelector((state) => state.MenuSlice);

  return {
    loadMenu,
    menuItems,
  };
};

export default useMenu;
