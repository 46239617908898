import makeStyles from "@mui/styles/makeStyles";

export default makeStyles(
  () => ({
    root: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: [12, 40, 12, 47],
      width: "100%",
      height: 60,
    },
    logo: {
      display: "inline",
      marginRight: 47,
      fontSize: 0,
      verticalAlign: "middle",
    },
    nav: {
      flex: 1,
    },
    actions: {
      display: "flex",
      alignItems: "center",
    },
    action: {
      marginLeft: 20,
    },
  }),
  { name: "DesktopHeader" },
);
