import { isNil } from "ramda";

import localStorage from "utils/localStorage";

export const setCastingOrder = () => {
  const { roleIndex: selectedIndex, roles: orderLength } = window.castingCard;
  const newOrder = [...Array(orderLength).keys()];
  let counter = 1;
  let negCounter = 0;
  for (let index = 0; index < orderLength; index += 1) {
    if (index === selectedIndex) {
      newOrder[index] = orderLength;
    } else if (index > selectedIndex) {
      newOrder[index] = orderLength - counter;
      counter += 1;
    } else {
      newOrder[index] = selectedIndex - negCounter;
      negCounter += 1;
    }
  }
  if (!isNil(localStorage.getItem("castingData"))) {
    const { roles } = localStorage.getItem("castingData");
    return localStorage.setItem("castingData", { roles, order: newOrder });
  }
  return localStorage.setItem("castingData", { order: newOrder });
};
