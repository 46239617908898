import dayjs from "dayjs";
import { useSelector } from "react-redux";

import { useSharesActions } from "store/SharesSlice";

const useShares = () => {
  const { createShare, setDateToShare, resetDateToShare } = useSharesActions();

  const { dateToShare: date } = useSelector((state) => state.SharesSlice);

  const dateToShare = dayjs(date);

  return { createShare, setDateToShare, dateToShare, resetDateToShare };
};

export default useShares;
