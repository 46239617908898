import PropTypes from "prop-types";

import Presenter from "utils/PropTypesPresenter";

export default new Presenter(
  {
    castNum: PropTypes.number,
    castingCardThumb: PropTypes.string,
    id: PropTypes.string,
    mobileMp4Video: PropTypes.string,
    mp4Video: PropTypes.string,
    name: PropTypes.string,
    status: PropTypes.string,
    swfVideo: PropTypes.string,
    templateGroupName: PropTypes.string,
    templateGroupSlug: PropTypes.string,
    type: PropTypes.string,
    webglFeatureVideo: PropTypes.string,
    webglPositionData: PropTypes.string,
    manifest: PropTypes.string,
    positionData: PropTypes.string,
  },
  {
    isLive(t) {
      return this.status(t)?.toLowerCase() === "live";
    },
    isHidden(t) {
      return this.status(t)?.toLowerCase() === "hidden";
    },
    isDeleted(t) {
      return this.status(t)?.toLowerCase() === "deleted";
    },
  },
);
