import palette from "layouts/ThemeProvider/palette";

const DEFAULT_INVITE_THEME = {
  id: "Default Theme",
  name: "Default Theme",
  titleDesktop: `font-family: "Inter"; color: ${palette.text.main};`,
  titleMobile: `font-family: "Inter"; color: ${palette.text.main};`,
  hostLabelDesktop: `font-family: "Inter"; color: ${palette.primary.main};`,
  hostLabelMobile: `font-family: "Inter"; color: ${palette.primary.main};`,
  hostDesktop: `font-family: "Inter"; color: ${palette.text.main};`,
  hostMobile: `font-family: "Inter"; color: ${palette.text.main};`,
  descriptionDesktop: `font-family: "Inter"; color: ${palette.text.main};`,
  descriptionMobile: `font-family: "Inter"; color: ${palette.text.main};`,
  locationLabelDesktop: `font-family: "Inter"; color: ${palette.primary.main};`,
  locationLabelMobile: `font-family: "Inter"; color: ${palette.primary.main};`,
  locationDesktop: `font-family: "Inter"; color: ${palette.text.main};`,
  locationMobile: `font-family: "Inter"; color: ${palette.text.main};`,
  dateLabelDesktop: `font-family: "Inter"; color: ${palette.primary.main};`,
  dateLabelMobile: `font-family: "Inter"; color: ${palette.primary.main};`,
  dateDesktop: `font-family: "Inter"; color: ${palette.text.main};`,
  dateMobile: `font-family: "Inter"; color: ${palette.text.main};`,
  detailsDesktop: `font-family: "Inter"; color: ${palette.text.main};`,
  detailsMobile: `font-family: "Inter"; color: ${palette.text.main};`,
  guestListDesktop: `font-family: "Inter"; color: ${palette.text.main};`,
  guestListMobile: `font-family: "Inter"; color: ${palette.text.main};`,
  guestListTitleDesktop: `font-family: "Inter"; color: ${palette.text.main};`,
  guestListTitleMobile: `font-family: "Inter"; color: ${palette.text.main};`,
  invitationBackgroundColor: palette.botticelli,
  guestListBackgroundColor: palette.wildSand,
  eventDetailsBackgroundColor: palette.porcelain,
  eventBlock2BackgroundColor: palette.porcelain,
  invitationBorderColor: palette.quillGray,
};

Object.freeze(DEFAULT_INVITE_THEME);

export { DEFAULT_INVITE_THEME };
