const CORDIAL_EVENTS = {
  LIVE_MAKE: "live_make_created",
  DRAFT_MAKE: "draft_make_created",
  HEAD_CREATED: "head_created",
  SUBSCRIPTION_PURCHASED: "subscription_purchased",
};

Object.freeze(CORDIAL_EVENTS);

export { CORDIAL_EVENTS };
