import InviteEventsRepository from "repositories/InviteEventsRepository";
import InvitePresenter from "presenters/InvitePresenter";

export const INVITE_PREVIEW_VIEW = "invite-preview-view";
export const IN_FLOW_REGISTRATION = "in-flow-registration";
export const IN_FLOW_SUBSCRIPTION_PURCHASE = "in-flow-subscription-purchase";
export const INVITE_OWNER_VIEW = "invite-owner-view";
export const INVITE_RECIPIENT_VIEW = "invite-recipient-view";
export const INVITE_SHARE_LINK = "invite-share-link";
export const INVITE_SHARE_EMAIL = "invite-share-email";

export const inviteEventParams = (inviteId, eventType, guestId = null) => {
  const payload = {
    data: {
      type: "invite-events",
      relationships: {
        "invite-event-type": {
          data: {
            type: "invite-event-types",
            id: eventType,
          },
        },
        invite: {
          data: {
            type: "invites",
            id: inviteId,
          },
        },
      },
    },
  };

  if (eventType === INVITE_RECIPIENT_VIEW && guestId) {
    payload.data.relationships = { ...payload.data.relationships, guest: { data: { type: "guests", id: guestId } } };
  }

  return payload;
};

export const trackInviteEvent = (invite, eventType, guestId = null) => {
  const params = inviteEventParams(InvitePresenter.id(invite), eventType, guestId);
  InviteEventsRepository.create(params);
};
