import { createSlice } from "@reduxjs/toolkit";

import PasswordRepository from "repositories/PasswordRepository";

const initialState = {
  error: {},
};

const resetPasswordSlice = createSlice({
  name: "searchResults",
  initialState,
  reducers: {},
});

export const { actions } = resetPasswordSlice;
export default resetPasswordSlice.reducer;

export const usePasswordActions = () => {
  const sendEmail = (params) => PasswordRepository.sendEmail(params);

  const update = (params) => PasswordRepository.update(params);

  return {
    sendEmail,
    update,
  };
};
