const FACE_PAGE_FLOWS = {
  NO_PHOTO: "no_photo",
  LOADING: "loading",
  FOUND: "found",
  EDIT: "edit",
  FOUND_EVERYONE: "found_everyone",
  FREE_USER_UPLOADING_FACE: "free_user_uploading_face",
  NEW_FACE_WITH_PREDIFINED_RELATION: "new_face_with_predifined_relation",
  NEW_FACE_TO_PREDIFINED_PERSON: "new_face_to_predifined_person",
  ORPHAN_FACES: "orphan_faces",
  NEW_FACES: "new_faces",
  JAW_CUT_MISSING: "jaw_cut_missing",
};

const FLOWS_MODES = {
  START: "start",
  PERSON_FORM: "person_form",
  WHO_IT_IS: "who_it_is",
  PERSON_SUMMARY: "person_summary",
};

Object.freeze(FACE_PAGE_FLOWS);
Object.freeze(FLOWS_MODES);

export { FACE_PAGE_FLOWS, FLOWS_MODES };
