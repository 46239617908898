const SHARE_METHODS = Object.freeze({
  LINK: "Link",
  EMAIL: "Email",
  FACEBOOK: "Facebook",
  BROWSER: "Browser Share",
  SMS: "sms",
  GUEST_LINK: "Copy Guest Link",
  PUBLIC_LINK: "Copy Invite Link",
});

const SHARE_ACTIONS = Object.freeze({
  ORIGINAL: "original",
  UPDATE: "update",
});

const EDIT_SUBSCRIPTION_ACTIONS = Object.freeze({
  KEEP_RENEWAL: "keep renewal",
  CANCEL_RENEWAL: "cancel renewal",
  ENABLE_RENEWAL: "enable renewal",
});

const UPLOAD_SOURCES = Object.freeze({
  FACEBOOK: "Facebook",
  WEBCAM: "Webcam",
  USE_LAST_PHOTO: "Use Last Photo",
  BROWSE_PHOTOS: "Browse Photos",
  TAKE_PHOTO: "Take Photo",
});

const FOUND_ACTIONS = Object.freeze({
  OK: "Accept",
  SKIP: "Skip",
  EDIT: "Edit",
});

const INVITE_UPDATED_ACTIONS = Object.freeze({
  EVENT_DETAILS_UPDATE: "event details update",
  GUEST_UPDATE: "guest update",
});

const LOGIN_SIGNUP_METHODS = Object.freeze({
  EMAIL: "email",
  FACEBOOK: "facebook",
});

export {
  SHARE_METHODS,
  SHARE_ACTIONS,
  EDIT_SUBSCRIPTION_ACTIONS,
  UPLOAD_SOURCES,
  FOUND_ACTIONS,
  INVITE_UPDATED_ACTIONS,
  LOGIN_SIGNUP_METHODS,
};
