import FetchHelpers from "utils/FetchHelpers";

export default {
  get(url, params = {}) {
    const includeDraft = FetchHelpers.isContentfulPreview();
    const headers = { Accept: "application/vnd.api+json" };
    const parameters = { ...params, filter: { ...params.filter, "with-draft": includeDraft } };
    return FetchHelpers.get(url, parameters, headers);
  },
};
