import { createSlice } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";

import { deserialize } from "utils/storeUtils";
import TemplateGroupRepository from "repositories/TemplateGroupRepository";

const initialState = {
  templateGroup: null,
};

const templateGroupSlice = createSlice({
  name: "templateGroup",
  initialState,
  reducers: {
    loadTemplateGroupSuccess(state, { payload }) {
      state.templateGroup = deserialize(payload);
    },
    clearTemplateGroupSuccess(state) {
      state.templateGroup = null;
    },
  },
});

export const { actions } = templateGroupSlice;
export default templateGroupSlice.reducer;

export const useTemplateGroupActions = () => {
  const dispatch = useDispatch();

  const loadTemplateGroup = (slug, includeTheme = false) => {
    return TemplateGroupRepository.show(slug, includeTheme).then(({ data }) => {
      dispatch(templateGroupSlice.actions.loadTemplateGroupSuccess(data));
      return data;
    });
  };

  const loadThemedTemplateGroup = (slug) => loadTemplateGroup(slug, true);

  const clearTemplateGroup = () => dispatch(templateGroupSlice.actions.clearTemplateGroupSuccess());

  return {
    loadTemplateGroup,
    clearTemplateGroup,
    loadThemedTemplateGroup,
  };
};
