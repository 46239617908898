import { trackEventTemplateThumbnailClick } from "utils/gAnalyticsUtils";
import { trackSelectContent } from "tracking/google/analytics";

import useUsers from "./useUsers";

const useTemplateGroupTracking = (templateGroup) => {
  const { currentUser } = useUsers();

  const trackClick = () => {
    trackEventTemplateThumbnailClick(templateGroup, currentUser);
    trackSelectContent(templateGroup);
  };

  return {
    trackClick,
  };
};

export default useTemplateGroupTracking;
