import { apiRoutes, contentfulRoutes } from "routes";
import FetchHelpers from "utils/FetchHelpers";

import ContentfulRepository from "./ContentfulRepository";

export default {
  index(params) {
    const url = FetchHelpers.toCDN(apiRoutes.categoryPath(1, params));
    return FetchHelpers.get(url);
  },

  contentfulShow(slug) {
    const url = contentfulRoutes.category(slug);
    return ContentfulRepository.get(url);
  },
};
