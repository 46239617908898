import { useSelector } from "react-redux";

import TemplateGroupPresenter from "presenters/TemplateGroupPresenter";
import { usePlayerControlsActions } from "store/PlayerControlsSlice";
import { DATA_VOLUME } from "utils/localStorage";

import useTemplateGroup from "./useTemplateGroup";

const usePlayerControls = (ref) => {
  const { setPauseVideo: _setPauseVideo, setVolume: _setVolume } = usePlayerControlsActions();
  const { pauseVideo, volume } = useSelector((state) => state.PlayerControlsSlice);
  const { templateGroup } = useTemplateGroup();

  const refVolume = () => ref?.current?.volume;

  const init = () => {
    if (refVolume() != null && volume != null) {
      const videoPlayer = ref.current;
      if (videoPlayer?.volume) {
        videoPlayer.volume = volume;
      }
    }
  };

  const setVolume = () => {
    const refTemp = refVolume();
    _setVolume(refTemp);
    sessionStorage.setItem(DATA_VOLUME, refTemp || volume);
  };

  const setPauseVideo = (pause) => {
    if (TemplateGroupPresenter.isStarringYouInviteGif(templateGroup)) return;
    _setPauseVideo(pause);
  };

  return {
    setPauseVideo,
    pauseVideo,
    setVolume,
    volume,
    init,
  };
};

export default usePlayerControls;
