/* global gtag */
import { isEmpty, isNil } from "ramda";

import { appRoutes } from "routes";
import { jjLogger } from "utils/logUtils";
import {
  LOGIN_SIGNUP_METHODS,
  SHARE_METHODS,
  SHARE_ACTIONS,
  EDIT_SUBSCRIPTION_ACTIONS,
  UPLOAD_SOURCES,
  FOUND_ACTIONS,
} from "enums";
import CategoryPresenter from "presenters/CategoryPresenter";
import HomePagePresenter from "presenters/HomePagePresenter";
import MakePresenter from "presenters/MakePresenter";
import OfferPresenter from "presenters/OfferPresenter";
import PersonPresenter from "presenters/PersonPresenter";
import SubscriptionPresenter from "presenters/SubscriptionPresenter";
import TemplateGroupPresenter from "presenters/TemplateGroupPresenter";
import TemplatePresenter from "presenters/TemplatePresenter";
import UserPresenter from "presenters/UserPresenter";
import InvitePresenter from "presenters/InvitePresenter";

const TRACK_LOGIN_AND_SIGNUP_METHODS = Object.values(LOGIN_SIGNUP_METHODS);
const TRACK_SHARE_METHODS = Object.values(SHARE_METHODS);
const TRACK_SHARE_ACTIONS = Object.values(SHARE_ACTIONS);
const TRACK_PHOTO_UPLOAD_SOURCES = Object.values(UPLOAD_SOURCES);
const TRACK_FOUND_ACTIONS = Object.values(FOUND_ACTIONS);
const TRACK_EDIT_SUBSCRIPTION_ACTIONS = Object.values(EDIT_SUBSCRIPTION_ACTIONS);

const trackEvent = (name, params) => {
  gtag("event", name, params);
};

export const trackPhotoUpload = (source, slug) => {
  if (!TRACK_PHOTO_UPLOAD_SOURCES.includes(source)) {
    jjLogger.logError(`trackPhotoUpload: Invalid source: ${source}`);
    return;
  }
  const params = { face_photo_source: source };
  if (slug) params.item_id = slug;

  trackEvent("photo_upload", params);
};

export const trackPhotoUploadSuccess = (source) => {
  if (!TRACK_PHOTO_UPLOAD_SOURCES.includes(source)) {
    jjLogger.logError(`trackPhotoUpload: Invalid source: ${source}`);
    return;
  }

  trackEvent("photo_upload_success", { face_photo_source: source });
};

export const trackFaceDetected = (faceCount) => {
  trackEvent("face_detected", { face_detected_count: faceCount });
};

export const trackFaceFoundAction = (action) => {
  if (!TRACK_FOUND_ACTIONS.includes(action)) {
    jjLogger.logError(`trackFaceFoundAction: Invalid action: ${action}`);
    return;
  }

  trackEvent("face_found_action", { face_found_action: action });
};

const trackEventGotEveryone = (gotEveryone, savedFacesCount) => {
  trackEvent("got_everyone", { got_everyone: gotEveryone, saved_faces_count: savedFacesCount });
};

export const trackEventGotEveryoneYes = (savedFacesCount) => {
  trackEventGotEveryone("yes", savedFacesCount);
};

export const trackEventGotEveryoneNo = (savedFacesCount) => {
  trackEventGotEveryone("no", savedFacesCount);
};

const trackLoginOrSignUpSuccess = (event, method, inFlow, templateGroup) => {
  if (!TRACK_LOGIN_AND_SIGNUP_METHODS.includes(method)) {
    jjLogger.logError(`trackLoginOrSignUpSuccess: Invalid method: ${method}`);
    return;
  }

  const params = { method };

  if (inFlow) {
    params.item_id = TemplateGroupPresenter.id(templateGroup);
    params.content_type = TemplateGroupPresenter.templateGroupType(templateGroup);
  }

  trackEvent(event, params);
};

export const trackLoginSuccess = (method, inFlow, templateGroup) => {
  trackLoginOrSignUpSuccess("login", method, inFlow, templateGroup);
};

export const trackSignUpSuccess = (method, inFlow, templateGroup) => {
  trackLoginOrSignUpSuccess("sign_up", method, inFlow, templateGroup);
};

export const trackPurchaseSuccess = (inFlow, offer, currentUser, template, templateGroup) => {
  const subscription = UserPresenter.activeSubscription(currentUser);
  if (isNil(subscription) || isNil(offer)) {
    jjLogger.logError(
      `trackPurchaseSuccess: Required params not defined. Subscription: ${JSON.stringify(
        subscription,
      )} Offer: ${JSON.stringify(offer)}`,
    );
    return;
  }

  if (inFlow && (isNil(template) || isNil(templateGroup))) {
    jjLogger.logError(
      `trackPurchaseSuccess: Required params not defined. Template: ${JSON.stringify(
        template,
      )} TemplateGroup: ${JSON.stringify(templateGroup)}`,
    );
    return;
  }

  let type = null;
  let item = { item_id: "out-of-flow" };

  if (inFlow) {
    type = TemplateGroupPresenter.templateGroupType(templateGroup);
    item = {
      item_id: TemplatePresenter.templateGroupSlug(template),
      item_name: TemplatePresenter.templateGroupName(template),
      item_variant: TemplatePresenter.castNum(template),
    };
  }

  const params = {
    currency: OfferPresenter.currency(offer),
    transaction_id: SubscriptionPresenter.id(subscription),
    value: OfferPresenter.price(offer),
    affiliation: SubscriptionPresenter.paymentProviderName(subscription),
    coupon: OfferPresenter.id(offer),
    content_type: type,
    items: [item],
    inFlow,
  };

  trackEvent("purchase", params);
};

export const trackSearch = (searchTerm) => {
  trackEvent("search", {
    search_term: searchTerm,
  });
};

export const trackShare = (method, templateGroup, action, count, isInviteFirstShare) => {
  if (!TRACK_SHARE_METHODS.includes(method)) {
    jjLogger.logError(`trackShare: Invalid method: ${method}`);
    return;
  }

  if (action && !TRACK_SHARE_ACTIONS.includes(action)) {
    jjLogger.logError(`trackShare: Invalid action: ${action}`);
    return;
  }

  const type = TemplateGroupPresenter.templateGroupType(templateGroup);
  const id = TemplateGroupPresenter.id(templateGroup);

  if (isNil(type) || isNil(id)) {
    jjLogger.logError(
      `trackShare: required params can't be empty. content_type: ${type}. item_id: ${id} templateGroup: ${JSON.stringify(
        templateGroup,
      )}`,
    );
    return;
  }

  const params = {
    method,
    action,
    content_type: type,
    item_id: id,
  };

  if ([SHARE_METHODS.PUBLIC_LINK, SHARE_METHODS.GUEST_LINK].includes(method) && count > 0) params.count = count;
  if (!isNil(isInviteFirstShare) && [SHARE_METHODS.EMAIL, SHARE_METHODS.PUBLIC_LINK].includes(method))
    params.first_send = isInviteFirstShare;

  trackEvent("share", params);
};

const trackInviteShare = (method, invite, templateGroup) => {
  const action = InvitePresenter.trackShareAction(invite);
  const count = InvitePresenter.guestsWithEmail(invite).length;
  const isInviteFirstShare = InvitePresenter.isDraft(invite);

  trackShare(method, templateGroup, action, count, isInviteFirstShare);
};

export const trackInvitePublicLinkShare = (invite, templateGroup) => {
  trackInviteShare(SHARE_METHODS.PUBLIC_LINK, invite, templateGroup);
};

export const trackInviteGuestLinkShare = (invite, templateGroup) => {
  trackInviteShare(SHARE_METHODS.GUEST_LINK, invite, templateGroup);
};

export const trackInviteEmailShare = (invite, templateGroup) => {
  trackInviteShare(SHARE_METHODS.EMAIL, invite, templateGroup);
};

export const trackSelectContent = (templateGroup) => {
  const type = TemplateGroupPresenter.templateGroupType(templateGroup);
  const id = TemplateGroupPresenter.id(templateGroup);

  if (isNil(type) || isNil(id)) {
    jjLogger.logError(
      `trackSelectContent: required params can't be empty. content_type: ${type}. item_id: ${id} templateGroup: ${JSON.stringify(
        templateGroup,
      )}`,
    );
    return;
  }

  trackEvent("select_content", {
    content_type: type,
    item_id: id,
  });
};

export const trackMakeCreationSuccess = (make, templateGroup) => {
  const template = MakePresenter.template(make);
  const id = TemplateGroupPresenter.id(templateGroup);
  const status = MakePresenter.status(make);
  const contentType = TemplateGroupPresenter.templateGroupType(templateGroup);

  if (isNil(id) || isNil(status) || isNil(contentType)) {
    jjLogger.logError(
      `trackMakeCreationSuccess: item_id or make status or contentType information can't be empty. template: ${JSON.stringify(
        template,
      )} make: ${JSON.stringify(make)}`,
    );
    return;
  }

  trackEvent("make_creation_success", {
    item_id: id,
    make_status: status,
    content_type: contentType,
  });
};

export const trackMakeCreationError = (error) => {
  if (isNil(error)) {
    jjLogger.logError(`trackMakeCreationError: error message can't be empty. Error: ${error}`);
    return;
  }

  trackEvent("make_creation_error", {
    make_creation_error_value: error,
  });
};

export const trackMakeViewSuccess = (rendererType) => {
  if (isNil(rendererType)) {
    jjLogger.logError(`trackMakeViewSuccess: renderer type message can't be empty.`);
    return;
  }

  trackEvent("make_view_success", {
    renderer: rendererType,
  });
};

export const trackMakeViewError = (rendererType) => {
  if (isNil(rendererType)) {
    jjLogger.logError(`trackMakeViewError: renderer type can't be empty.`);
    return;
  }

  trackEvent("make_view_error", {
    renderer: rendererType,
  });
};

export const trackTemplateView = (templateGroup) => {
  if (isNil(templateGroup)) {
    jjLogger.logError(
      `trackTemplateView: required params can't be empty. templateGroup: ${JSON.stringify(templateGroup)}`,
    );
    return;
  }

  const template = TemplateGroupPresenter.template(templateGroup);
  if (isNil(template)) {
    jjLogger.logError(
      `trackTemplateView: template group does not have template. templateGroup: ${JSON.stringify(templateGroup)}`,
    );

    return;
  }

  trackEvent("view_item", {
    content_type: TemplateGroupPresenter.templateGroupType(templateGroup),
    items: [
      {
        item_id: TemplatePresenter.templateGroupSlug(template),
        item_name: TemplatePresenter.templateGroupName(template),
        item_variant: TemplatePresenter.castNum(template),
      },
    ],
  });
};

export const trackSelectItem = (itemListInfo, item) => {
  if (isNil(itemListInfo) || isNil(item)) {
    jjLogger.logError(
      `trackSelectItem: required params can't be empty. itemListInfo: ${JSON.stringify(
        itemListInfo,
      )} item: ${JSON.stringify(item)} `,
    );
    return;
  }

  const slug = TemplateGroupPresenter.id(item);
  const name = TemplateGroupPresenter.name(item);
  const type = TemplateGroupPresenter.templateGroupType(item);

  const { itemListId, itemListName } = itemListInfo;

  trackEvent("select_item", {
    item_list_id: itemListId,
    item_list_name: itemListName,
    content_type: type,
    items: [
      {
        item_id: slug,
        item_name: name,
      },
    ],
  });
};

export const handlerSelectItemEvent = (item, category, homePage = null) => {
  let itemListInfo;
  const categoryPath = appRoutes.categoryPath(CategoryPresenter.id(category));
  const inviteCategoryPath = appRoutes.invitePath(CategoryPresenter.id(category));
  const homePath = appRoutes.rootPath();
  const searchPath = appRoutes.searchPath();
  const { pathname, search } = window.location;
  const queryParams = new URLSearchParams(search);

  switch (true) {
    case category && (categoryPath === pathname || inviteCategoryPath === pathname):
      itemListInfo = {
        itemListId: CategoryPresenter.id(category),
        itemListName: CategoryPresenter.displayName(category),
      };
      break;
    case homePage && homePath === pathname:
      itemListInfo = {
        itemListId: HomePagePresenter.id(homePage),
        itemListName: HomePagePresenter.name(homePage),
      };
      break;
    case searchPath === pathname:
      if (queryParams.has("term")) {
        const term = `term=${queryParams.get("term")}`;
        itemListInfo = {
          itemListId: term,
          itemListName: term,
        };
      }
      break;
    case appRoutes.invitesPath() === pathname:
      itemListInfo = {
        itemListId: "/invites/browse",
        itemListName: "Invites Landing Page",
      };
      break;
    default:
      break;
  }

  trackSelectItem(itemListInfo, item);
};

export const track404 = (url) => {
  if (isNil(url)) {
    jjLogger.logError(`track404: url can't be empty. url: ${url}`);
    return;
  }

  trackEvent("404", {
    404: url,
  });
};

export const trackEditSubscriptionBilling = () => {
  trackEvent("edit_subscription_billing", {
    billing_info: "billing info updated",
  });
};

export const trackEditSubscriptionSuccess = (action) => {
  if (isNil(action) || !TRACK_EDIT_SUBSCRIPTION_ACTIONS.includes(action)) {
    jjLogger.logError(`trackEditSubscriptionSuccess: Invalid keep renewal action: ${action}`);
    return;
  }

  trackEvent("edit_subscription_success", {
    keep_renewal: action,
  });
};

export const trackSubscriptionFeedback = (answer) => {
  if (isNil(answer)) {
    jjLogger.logError(`trackSubscriptionFeedback: Invalid feedback: ${answer}`);
    return;
  }

  trackEvent("feedback", {
    feedback: answer,
  });
};

export const trackCalendarHit = (occasion) => {
  if (isNil(occasion)) {
    jjLogger.logError(`trackCalendarHit: Invalid occasion: occasion should not be nil or empty`);
    return;
  }

  trackEvent("calendar_hit", {
    occasion,
  });
};

export const trackCalendarDiscovery = (action) => {
  if (isNil(action)) {
    jjLogger.logError("trackCalendarDiscovery: Invalid action: action should not be nil or empty");
    return;
  }

  trackEvent("calendar_discovery", {
    calendar_discovery: action,
  });
};

const trackPersonSuccessEvent = (functionName, eventType, person) => {
  if (isNil(person)) {
    jjLogger.logError(`${functionName}: Required params not defined. Person: ${JSON.stringify(person)}`);
    return;
  }

  const params = {};
  if (PersonPresenter.relation(person)) params.relation = PersonPresenter.relation(person);
  if (PersonPresenter.birthday(person)) params.birthday = "yes";
  if (PersonPresenter.anniversary(person)) params.anniversary = "yes";

  trackEvent(eventType, params);
};

export const trackPersonCreationSuccess = (person) => {
  trackPersonSuccessEvent("trackPersonCreationSuccess", "person_created", person);
};

export const trackPersonUpdateSuccess = (person) => {
  trackPersonSuccessEvent("trackPersonUpdateSuccess", "person_updated", person);
};

export const trackInviteUpdated = (invite, action) => {
  if (isNil(invite) || isNil(action)) {
    jjLogger.logError(
      `trackInviteUpdated: Required params not defined. invite: ${JSON.stringify(invite)} action: ${action}`,
    );
    return;
  }

  if (InvitePresenter.isDraft(invite) || InvitePresenter.isDeleted(invite)) return;

  trackEvent("invite_updated", {
    item_id: InvitePresenter.id(invite),
    action,
  });
};

export const trackInviteCompleted = (oldInvite, newInvite) => {
  if (isNil(oldInvite) || isNil(newInvite)) {
    jjLogger.logError(
      `trackInviteCompleted: Required params not defined. oldInvite: ${JSON.stringify(
        oldInvite,
      )} newInvite: ${JSON.stringify(newInvite)}`,
    );
    return;
  }

  const isOldInviteDataNull = isNil(InvitePresenter.title(oldInvite)) && isNil(InvitePresenter.eventDate(oldInvite));
  const isNewInviteDataPresent =
    !isNil(InvitePresenter.title(newInvite)) && !isNil(InvitePresenter.eventDate(newInvite));

  if (isOldInviteDataNull && isNewInviteDataPresent) trackEvent("invite_completed");
};

export const trackRsvpSuccess = ({ itemId, rsvp, rsvpCount, isPublic }) => {
  const type = isPublic ? "public" : "private";
  trackEvent("recipient_response", { item_id: itemId, rsvp, rsvp_count: rsvpCount, type });
};

export const trackGuestInfoInput = (fields) => {
  if (isNil(fields) || isEmpty(fields) || !Array.isArray(fields)) return;

  const changedFields = fields.join(", ");

  trackEvent("guest_info_input", {
    input_field: changedFields,
  });
};

export const trackInviteEventInfo = (invite) => {
  if (isNil(invite)) {
    jjLogger.logError("trackInviteUpdated: Required invite param not defined.");
    return;
  }

  trackEvent("event_info_input", {
    input_field: InvitePresenter.populatedFieldNames(invite),
  });
};

const trackVideoPlayback = (event, video, templateGroup, percentage) => {
  if (isNil(event) || isNil(video) || isNil(templateGroup) || isNil(percentage)) {
    jjLogger.logError(`trackVideoPlayback: Required params not defined. 
      event: ${JSON.stringify(event)}
      video: ${JSON.stringify(video)}
      templateGroup: ${JSON.stringify(templateGroup)}
      percentage: ${percentage}`);
    return;
  }

  const { currentTime, duration, loop } = video;
  const { pathname } = window.location;
  const params = {
    video_current_time: currentTime,
    video_duration: duration,
    video_percent: percentage,
    video_provider: TemplateGroupPresenter.templateGroupType(templateGroup),
    video_title: TemplateGroupPresenter.name(templateGroup),
    looping_video: loop,
    video_url: pathname,
  };

  trackEvent(event, params);
};

export const trackVideoProgress = (video, percentage, templateGroup) => {
  trackVideoPlayback("video_progress", video, templateGroup, percentage);
};

export const trackVideoStart = (video, templateGroup) => {
  trackVideoPlayback("video_start", video, templateGroup, 0);
};

export const trackVideoComplete = (video, templateGroup) => {
  trackVideoPlayback("video_complete", video, templateGroup, 100);
};
