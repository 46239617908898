import makeStyles from "@mui/styles/makeStyles";

export default makeStyles(
  (theme) => ({
    link: {
      padding: [5, 16, 25],
      position: "relative",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      "&:first-child": {
        paddingLeft: 0,
      },
      "&:last-child": {
        paddingRight: 0,
      },
      [theme.breakpoints.up("tablet")]: {
        padding: 0,
        marginRight: 50,
        "&:last-child": {
          marginRight: 0,
        },
      },
    },
    activelink: {
      color: theme.palette.primary.main,
    },
    subMenu: {
      position: "absolute",
      boxShadow: [0, 5, 5, "#00000030"],
      top: 44,
    },
  }),
  { name: "MenuItem" },
);
