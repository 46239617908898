import MakeEventsRepository from "repositories/MakeEventsRepository";
import MakePresenter from "presenters/MakePresenter";

export const MAKE_OWNER_VIEW = "make_owner_view";
export const MAKE_RECIPIENT_VIEW = "make_recipient_view";
export const IN_FLOW_SUBSCRIPTION_PURCHASE = "in_flow_subscription_purchase";
export const MAKE_PREVIEW_VIEW = "make_preview_view";
export const IN_FLOW_REGISTRATION = "in_flow_registration";

export const makeEventParams = (makeId, eventType) => {
  return {
    data: {
      type: "make-events",
      relationships: {
        "make-event-type": {
          data: {
            type: "make-event-types",
            id: eventType,
          },
        },
        make: {
          data: {
            type: "makes",
            id: makeId,
          },
        },
      },
    },
  };
};

export const trackMakeEvent = (make, eventType) => {
  const params = makeEventParams(MakePresenter.id(make), eventType);
  MakeEventsRepository.create(params);
};
