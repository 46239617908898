const DATE_TYPE = {
  birthday: "birthday",
  anniversary: "anniversary",
  valentines: "valentine's Day",
  mothersDay: "mother's Day",
  fathersDay: "father's Day",
};

const DATE_TYPE_QUERIES = {
  birthday: "birthday",
  anniversary: "anniversary",
  "valentine's Day": "valentine",
  "mother's Day": "mothers day",
  "father's Day": "fathers day",
};

Object.freeze(DATE_TYPE);
Object.freeze(DATE_TYPE_QUERIES);

export { DATE_TYPE, DATE_TYPE_QUERIES };
