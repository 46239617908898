import { useSelector } from "react-redux";

import { useAlgoliaTrackerActions } from "store/AlgoliaTrackerSlice";

const useAlgoliaTracker = () => {
  const { setHitSelected, clearHitSelected } = useAlgoliaTrackerActions();

  const { hitSelected } = useSelector((state) => state.AlgoliaTrackerSlice);

  return { hitSelected, setHitSelected, clearHitSelected };
};

export default useAlgoliaTracker;
