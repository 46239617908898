import R from "ramda";
import { useSelector } from "react-redux";

import { usePersonFacesActions } from "store/PersonFacesSlice";
import { useFacesActions } from "store/FacesSlice";

const usePersonFaces = () => {
  const {
    loadPersonFaces,
    resetPersonFaces,
    removePersonFace,
    deletePersonFace,
    updatePersonFaces,
  } = usePersonFacesActions();
  const { convertToOrphanFace, deleteFace } = useFacesActions();
  const { faces: personFaces, meta } = useSelector((state) => state.PersonFacesSlice);

  const removePersonFaceAttributes = (personFace) => {
    return {
      data: {
        attributes: {
          asset: personFace.asset,
          "created-at": personFace.createdAt,
          default: Boolean(personFace.default),
        },
        id: personFace.id,
        relationships: { person: { data: null } },
        type: "heads",
      },
    };
  };

  return {
    loadPersonFaces,
    updatePersonFaces,
    resetPersonFaces,
    deletePersonFace: (personFaceId) => {
      deletePersonFace(personFaceId);
      return deleteFace(personFaceId);
    },
    removePersonFace: (personFace) => {
      const params = removePersonFaceAttributes(personFace);
      removePersonFace(personFace.id, params).then(async () => {
        return convertToOrphanFace(personFace);
      });
    },
    personFaces,
    totalPersonFacesCount: R.propOr(personFaces.count, "totalCount", meta),
  };
};

export default usePersonFaces;
