import { createSlice } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import R from "ramda";

import { deserialize, removeById } from "utils/storeUtils";
import IdentityRepository from "repositories/IdentityRepository";

const initialState = {
  identities: null,
  error: null,
};

const identitiesSlice = createSlice({
  name: "identity",
  initialState,
  reducers: {
    loadIdentitiesSuccess(state, { payload }) {
      state.identities = deserialize(payload);
    },
    createIdentitySuccess(state, { payload }) {
      state.identities = R.concat(state.identities, [deserialize(payload)]);
    },
    createIdentityFail(state, { payload }) {
      state.error = payload;
    },
    deleteIdentitySuccess(state, { payload }) {
      const newIdentities = removeById(state.identities, [], payload.id);
      state.identities = newIdentities;
    },
    deleteIdentityFail(state, { payload }) {
      state.error = payload;
    },
  },
});

export const { actions } = identitiesSlice;
export default identitiesSlice.reducer;

export const useIdentityActions = () => {
  const dispatch = useDispatch();

  const loadIdentities = () => {
    return IdentityRepository.index().then(({ data }) => {
      dispatch(identitiesSlice.actions.loadIdentitiesSuccess(data));
      return Promise.resolve(data);
    });
  };

  const createIdentity = (params) => {
    return IdentityRepository.create(params)
      .then(({ data }) => {
        dispatch(identitiesSlice.actions.createIdentitySuccess(data));
        return Promise.resolve(data);
      })
      .catch((error) => {
        dispatch(
          identitiesSlice.actions.createIdentityFail(
            error.response?.data?.errors?.[0]?.detail || error.response?.statusText,
          ),
        );
        return Promise.reject(error);
      });
  };

  const deleteIdentity = (id) => {
    return IdentityRepository.delete(id)
      .then(() => {
        dispatch(identitiesSlice.actions.deleteIdentitySuccess({ id }));
        return Promise.resolve(id);
      })
      .catch((error) => {
        dispatch(identitiesSlice.actions.createIdentityFail(error.response.statusText));
        return Promise.reject(error);
      });
  };

  return {
    loadIdentities,
    createIdentity,
    deleteIdentity,
  };
};
