import React, { memo } from "react";
import { useFeatureIsOn } from "@growthbook/growthbook-react";

import MegaMenuItemPresenter from "presenters/MegaMenuItemPresenter";
import appRoutes from "routes/appRoutes";

const withSYAIMenuItemTest = (WrappedMenuItem) => {
  const SYAIMenuItemWrapper = (props) => {
    const { menuItem } = props;
    const hideSYAI = !useFeatureIsOn("syai_access_point");

    if (hideSYAI) {
      const baseURL = window.location.origin;
      const menuItemUrl = new URL(MegaMenuItemPresenter.linkTo(menuItem), baseURL).pathname;
      const starringYouAIPath = new URL(appRoutes.starringYouAIPath(), baseURL).pathname;

      if (menuItemUrl === starringYouAIPath) return null;
    }

    return <WrappedMenuItem {...props} />;
  };

  SYAIMenuItemWrapper.propTypes = {
    menuItem: MegaMenuItemPresenter.shape(),
  };

  return memo(SYAIMenuItemWrapper);
};

export default withSYAIMenuItemTest;
