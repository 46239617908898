import PropTypes from "prop-types";

import Presenter from "utils/PropTypesPresenter";

import MorphablePackGroupPresenter from "./MorphablePackGroupPresenter";
import MorphedPhotoPresenter from "./MorphedPhotoPresenter";

export default new Presenter(
  {
    id: PropTypes.string,
    type: PropTypes.string,
    photoPackSlug: PropTypes.string,
    status: PropTypes.string,
    morphedPhotos: PropTypes.arrayOf(MorphedPhotoPresenter.shape()),
    previewMorphedPhoto: MorphedPhotoPresenter.shape(),
    morphedPackGroup: MorphablePackGroupPresenter.shape(),
    morphablePack: PropTypes.shape({}),
  },
  {
    photos(morphedPack) {
      return [...morphedPack.morphedPhotos, morphedPack.previewMorphedPhoto];
    },
    morphablePackGroupName(morphedPack) {
      return MorphablePackGroupPresenter.name(morphedPack.morphedPackGroup);
    },
  },
);
