import sha256 from "crypto-js/sha256";
import { isNil } from "ramda";
import Cookies from "js-cookie";

import FetchHelpers from "utils/FetchHelpers";
import UserPresenter from "presenters/UserPresenter";

export default {
  post(currentUser, event) {
    const testEventCode = Cookies.get("test_event_code");

    if (process.env.DEPLOY_ENV !== "production" && isNil(testEventCode)) return;

    let payload = {
      action_source: "website",
      user_data: {
        client_user_agent: navigator.userAgent,
        fbp: Cookies.get("_fbp"),
      },
    };

    if (UserPresenter.isRegistered(currentUser)) {
      payload.user_data.em = sha256(currentUser.email).toString();
    }

    payload = { ...payload, ...event };

    const fbc = Cookies.get("_fbc");
    if (!isNil(fbc)) {
      payload.user_data.fbc = fbc;
    }

    if (!isNil(testEventCode)) {
      payload.test_event_code = testEventCode;
    }

    FetchHelpers.post(process.env.FACEBOOK_CONVERSION_API, payload);
  },
};
