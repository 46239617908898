import R from "ramda";
import { useSelector } from "react-redux";

const useFacesCount = () => {
  const meta = useSelector((state) => state.FacesSlice.meta);
  const faces = useSelector((state) => state.FacesSlice.faces);
  const totalFacesCount = R.propOr(faces.count, "totalCount", meta);

  return {
    totalFacesCount,
  };
};

export default useFacesCount;
