import { apiRoutes } from "routes";
import FetchHelpers from "utils/FetchHelpers";

export default {
  index() {
    const url = apiRoutes.identitiesPath(1);
    return FetchHelpers.get(url);
  },
  create(params) {
    const url = apiRoutes.identitiesPath(1, params);
    return FetchHelpers.post(url, params);
  },
  delete(id) {
    const url = apiRoutes.identityPath(1, id);
    return FetchHelpers.delete(url);
  },
};
