import PropTypes from "prop-types";

import Presenter from "utils/PropTypesPresenter";

export default new Presenter({
  id: PropTypes.string,
  type: PropTypes.string,
  asset: PropTypes.string,
  createdAt: PropTypes.number,
  default: PropTypes.bool,
});
