import { apiRoutes } from "routes";
import FetchHelpers from "utils/FetchHelpers";

export default {
  create(params) {
    const url = apiRoutes.subscriptionsPath(1);
    return FetchHelpers.post(url, params);
  },
  reactivate(id, params) {
    const url = apiRoutes.subscriptionReactivationPath(1, id);
    return FetchHelpers.post(url, params);
  },
  cancel(id, params) {
    const url = apiRoutes.subscriptionCancellationPath(1, id);
    return FetchHelpers.post(url, params);
  },
  pause(id, params) {
    const url = apiRoutes.subscriptionPausePath(1, id);
    return FetchHelpers.post(url, { ...params, ...{ data: { type: "pauses" } } });
  },
  getBillingInfo() {
    const url = apiRoutes.recurlyBillingInfoPath(1);
    return FetchHelpers.get(url);
  },
  updateBillingInfo(params) {
    const url = apiRoutes.recurlyBillingInfoPath(1);
    return FetchHelpers.patch(url, params);
  },
};
