export const loadScriptByURL = (id, url) => {
  const isScriptExist = document.getElementById(id);

  return new Promise((resolve, reject) => {
    if (isScriptExist) {
      resolve();
    } else {
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src = url;
      script.id = id;
      script.onload = () => resolve();
      script.onerror = () => reject();
      document.body.appendChild(script);
    }
  });
};
