import { createSlice } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";

import { deserialize } from "utils/storeUtils";
import PageRepository from "repositories/PageRepository";
import { jjLogger } from "utils/logUtils";

const initialState = {
  homePage: null,
  carousel: null,
  featuredEcards: null,
  viewMoreButton: null,
};

const homePageSlice = createSlice({
  name: "homePage",
  initialState,
  reducers: {
    loadHomePageSuccess(state, { payload }) {
      const response = payload;
      const modules = [...response.data?.attributes?.pageModules];
      state.homePage = deserialize(payload);
      state.carousel = deserialize({ data: modules[0] });
      state.featuredEcards = deserialize({ data: modules[1] });
      state.viewMoreButton = deserialize({ data: modules[2] });
    },
  },
});

export const { actions } = homePageSlice;
export default homePageSlice.reducer;

export const useHomePageActions = () => {
  const dispatch = useDispatch();

  const loadHomePage = () => {
    return PageRepository.show("home-page")
      .then(({ data }) => {
        dispatch(homePageSlice.actions.loadHomePageSuccess(data));
        return data;
      })
      .catch((error) => jjLogger.logError(error));
  };

  return {
    loadHomePage,
  };
};
