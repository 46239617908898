import PropTypes from "prop-types";
import dayjs from "dayjs";
import R from "ramda";

import Presenter from "utils/PropTypesPresenter";
import { resetCacheKey } from "utils/imageUtils";
import { FACE_RELATIONS, RELATIONS_WITH_ANNIVERSARY } from "enums";
import { getRelationKeyByValue } from "utils/relativeUtils";
import { destructuredDate, nullOption } from "utils/dateTimeUtils";

const GREETINGS = {
  me: [
    "Me, Myself & I",
    "Hey there good lookin'",
    "I think therefore I JibJab",
    "I Jib therefore I Jab",
    "Because you're No.1!",
  ],
  partner: [
    "#Bae",
    "The coffee to your cream",
    "The wine to your cheese",
    "The burger to your fries",
    "The pizza to your beer",
    "Your other half",
  ],
  mother: ["Because I said so. That's why.", "Yo Mama!", "Your biggest fan", "The reason you exist"],
  father: ["Who's your daddy?", "Just don't sit in his recliner", "Dad jokes, all day long"],
  friend: ["Friend"],
  family: ["Family"],
  pet: ["Part of the family"],
};

export default new Presenter(
  {
    id: PropTypes.string,
    type: PropTypes.string,
    name: PropTypes.string,
    relation: PropTypes.string,
    birthday: PropTypes.string,
    anniversary: PropTypes.string,
    totalHeads: PropTypes.number,
    defaultHead: PropTypes.shape(),
    createdAt: PropTypes.number,
  },
  {
    formattedName(p) {
      const name = this.name(p);
      if (!name) return "";
      return name;
    },
    profileName(p) {
      const relation = this.relation(p);
      const name = this.name(p);

      if (relation === getRelationKeyByValue(FACE_RELATIONS.me)) {
        return "Me";
      }

      return name;
    },
    greeting(p) {
      const relation = this.relation(p);
      const greetings = GREETINGS[relation];
      return greetings ? greetings[Math.floor(Math.random() * greetings.length)] : "";
    },
    defaultHeadAsset(p) {
      if (!p) return "";
      return resetCacheKey(this.defaultHead(p).asset);
    },
    defaultHeadId(p) {
      const { id } = p.defaultHead;
      return id;
    },
    formattedAnniversary(p) {
      const defaultAnniversary = this.anniversary(p);
      if (!defaultAnniversary) return null;
      return dayjs(defaultAnniversary).format("MMM D");
    },
    formattedBirthday(p) {
      const defaultBirthday = this.birthday(p);
      if (!defaultBirthday) return null;
      return dayjs(defaultBirthday).format("MMM D");
    },
    formattedRelation(p) {
      const defaultRelation = this.relation(p);
      if (!defaultRelation) return "";
      return FACE_RELATIONS[defaultRelation];
    },
    label(p) {
      const relation = this.relation(p);
      const name = this.name(p);
      if (["me", "mother", "father"].includes(relation)) return FACE_RELATIONS[relation];
      if (name)
        return Array.from(name)
          .slice(0, 9)
          .join("");
      if (relation) return FACE_RELATIONS[relation];
      return "Name";
    },
    hasAnniversary(p) {
      const relation = this.relation(p);
      return R.has(relation, RELATIONS_WITH_ANNIVERSARY);
    },
    isPartner(p) {
      const relation = this.relation(p);
      return relation === getRelationKeyByValue(FACE_RELATIONS.partner);
    },
    isMe(p) {
      const relation = this.relation(p);
      return relation === getRelationKeyByValue(FACE_RELATIONS.me);
    },
    structuredBirthday(p) {
      const birthday = this.birthday(p);
      return R.isNil(birthday) ? nullOption : destructuredDate(birthday);
    },
    structuredAnniversary(p) {
      const anniversary = this.anniversary(p);
      return R.isNil(anniversary) ? nullOption : destructuredDate(anniversary);
    },
  },
);
