import { COLORS } from "layouts/ThemeProvider/palette";

export default {
  MuiFormHelperText: {
    styleOverrides: {
      root: {
        textAlign: "center",
        fontSize: 12,
        "&.Mui-error": {
          color: COLORS.havelockBlue,
        },
      },
    },
  },
};
