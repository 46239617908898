import { useEffect } from "react";

import useRouter from "hooks/useRouter";
import useUsers from "hooks/useUsers";
import useFetchStatus from "hooks/useFetchStatus";

const useRedirect = (pathname, condition, referrer = null) => {
  const { push } = useRouter();
  const { currentUser, currentUserLoadingStatus } = useUsers();
  const { isFulfilled, isFailed } = useFetchStatus(currentUserLoadingStatus);
  const isCULoadingFinished = isFulfilled || isFailed;

  const redirect = () => {
    if (isCULoadingFinished) {
      if (condition(currentUser)) {
        return push({ pathname, state: { referrer } });
      }
    }
    return null;
  };

  redirect();

  useEffect(() => {
    redirect();
  }, [isCULoadingFinished]);

  return {
    redirect,
    isCULoadingFinished,
  };
};

export default useRedirect;
