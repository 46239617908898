const FETCH_STATUSES = {
  idle: "idle",
  pending: "pending",
  fulfilled: "fulfilled",
  failed: "failed",
};

Object.freeze(FETCH_STATUSES);

export { FETCH_STATUSES };
