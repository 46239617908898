import { createSlice } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";

import localStorage, { ECARD, TEMPLATE_GROUP, IN_FLOW, SOURCE_PHOTO, MORPHABLE_PACK_GROUP } from "utils/localStorage";

const initialState = {
  ecard: null,
  templateGroup: null,
  inFlow: false,
  sourcePhoto: null,
  morphablePackGroup: null,
};

const lastEcardSlice = createSlice({
  name: "lastEcardSlice",
  initialState,
  reducers: {
    setEcard(state, { payload }) {
      const { ecard } = payload;
      state.ecard = ecard;
    },
    setTemplateGroup(state, { payload }) {
      const { templateGroup } = payload;
      state.templateGroup = templateGroup;
    },
    setInFlow(state, { payload }) {
      state.inFlow = payload;
    },
    clearData(state) {
      const { ecard, templateGroup, inFlow, sourcePhoto, morphablePackGroup } = initialState;
      state.ecard = ecard;
      state.templateGroup = templateGroup;
      state.inFlow = inFlow;
      state.sourcePhoto = sourcePhoto;
      state.morphablePackGroup = morphablePackGroup;
    },
    setSourcePhoto(state, { payload }) {
      state.sourcePhoto = payload;
    },
    setMorphablePackGroup(state, { payload }) {
      state.morphablePackGroup = payload;
    },
  },
});

export const { actions } = lastEcardSlice;
export default lastEcardSlice.reducer;

export const useLastEcardActions = () => {
  const dispatch = useDispatch();

  const setLastEcard = (ecard, templateGroup) => {
    localStorage.setItem(ECARD, ecard);
    localStorage.setItem(TEMPLATE_GROUP, templateGroup);
    dispatch(lastEcardSlice.actions.setEcard({ ecard }));
    dispatch(lastEcardSlice.actions.setTemplateGroup({ templateGroup }));
  };

  const setInFlow = (inFlow) => {
    localStorage.setItem(IN_FLOW, inFlow);
    dispatch(lastEcardSlice.actions.setInFlow(inFlow));
  };

  const clearLastEcard = () => {
    localStorage.removeItem(ECARD);
    localStorage.removeItem(TEMPLATE_GROUP);
    localStorage.removeItem(IN_FLOW);
    localStorage.removeItem(SOURCE_PHOTO);
    localStorage.removeItem(MORPHABLE_PACK_GROUP);
    dispatch(lastEcardSlice.actions.clearData());
  };

  const setSourcePhoto = (sourcePhoto, morphablePackGroup) => {
    localStorage.setItem(SOURCE_PHOTO, sourcePhoto);
    localStorage.setItem(MORPHABLE_PACK_GROUP, morphablePackGroup);
    dispatch(lastEcardSlice.actions.setSourcePhoto(sourcePhoto));
    dispatch(lastEcardSlice.actions.setMorphablePackGroup(morphablePackGroup));
  };

  return {
    setLastEcard,
    setInFlow,
    clearLastEcard,
    setSourcePhoto,
  };
};
