import PropTypes from "prop-types";

import Presenter from "utils/PropTypesPresenter";

export default new Presenter(
  {
    id: PropTypes.string,
    slug: PropTypes.string,
    type: PropTypes.string,
    coverImage: PropTypes.string,
    upsellImage: PropTypes.string,
    name: PropTypes.string,
    status: PropTypes.string,
    description: PropTypes.string,
    premium: PropTypes.bool,
    platform: PropTypes.arrayOf(PropTypes.string),
    tags: PropTypes.string,
    morphablePacks: PropTypes.arrayOf(PropTypes.shape({})),
  },
  {
    keywords(mpg) {
      return `${this.tags(mpg)} ${this.name(mpg)} AI Photos`;
    },
    metaImage(mpg) {
      return this.upsellImage(mpg);
    },
    metaFomoImage(mpg) {
      return this.upsellImage(mpg);
    },
    mainTitle(mpg) {
      return `${this.name(mpg)} AI Photos`;
    },
  },
);
