import isDataUri from "is-data-uri";
import R from "ramda";
import loadImage from "blueimp-load-image";

import { initCanvas, canvasToImage } from "utils/canvasUtils";

const MAX_HEADCUT_RESOLUTION = 1680;

export const resetCacheKey = (url) => `${url}?`;

const isUrl = (string) => {
  try {
    // eslint-disable-next-line no-new
    new URL(string);
  } catch (_) {
    return false;
  }
  return true;
};

const isBlob = (object) => object instanceof Blob;

const blobToBase64 = (blob) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const urlToBase64 = (url, imageType = "image/jpeg") =>
  new Promise((resolve) => {
    const img = new Image();
    img.crossOrigin = "Anonymous";
    img.onload = () => {
      const [cnv, ctx] = initCanvas(img);
      ctx.drawImage(img, 0, 0);
      resolve(canvasToImage(cnv, imageType));
    };
    img.src = url;
  });

export const allToBase64 = (object) => {
  if (isDataUri(object)) return Promise.resolve(object);
  if (isUrl(object)) return urlToBase64(object);
  if (isBlob(object)) return blobToBase64(object);
  return Promise.reject(new Error("cant convert to base64"));
};

export const dataURItoBlob = (dataURI) => {
  const byteString = atob(dataURI.split(",")[1]);
  const mimeString = dataURI
    .split(",")[0]
    .split(":")[1]
    .split(";")[0];
  const ab = new ArrayBuffer(byteString.length);
  const dw = new DataView(ab);
  for (let i = 0; i < byteString.length; i += 1) {
    dw.setUint8(i, byteString.charCodeAt(i));
  }
  return new Blob([ab], { type: mimeString });
};

const scaleFactor = ({ width, height }, maxResolution) => {
  const max = R.max(width, height);
  if (max > maxResolution) return maxResolution / max;
  return 1;
};

export const rotateAndScale = (file) =>
  new Promise((resolve, reject) =>
    loadImage(
      file,
      (canvas) => {
        try {
          const { width, height } = canvas;
          const scaleF = scaleFactor(canvas, MAX_HEADCUT_RESOLUTION);
          const scaled = { width: width * scaleF, height: height * scaleF };
          const [cnv, ctx] = initCanvas(scaled);
          ctx.scale(scaleF, scaleF);
          ctx.drawImage(canvas, 0, 0);
          resolve(canvasToImage(cnv));
        } catch (error) {
          reject(error);
        }
      },
      { orientation: true, canvas: true },
    ),
  );
