import { useSelector } from "react-redux";
import { useEffect } from "react";

import { useCookieBannerActions } from "store/CookieBannerSlice";

import useRouter from "./useRouter";

const useCookieBanner = () => {
  const {
    query: { dismissCookieBanner },
  } = useRouter();
  const dismissCookieBannerValue = useSelector((state) => state.CookieBannerSlice.dismissCookieBanner);
  const { changeValue } = useCookieBannerActions();

  useEffect(() => {
    changeValue(dismissCookieBanner === "true" || false);
  }, []);

  return { dismissCookieBannerValue };
};

export default useCookieBanner;
