import makeStyles from "@mui/styles/makeStyles";

export default makeStyles(
  () => ({
    root: {
      backgroundColor: "white",
      minWidth: 200,
      width: "fit-content",
      paddingBottom: 28,
    },
  }),
  { name: "SubNavigation" },
);
