/* global DetectUserAgent */
import { isNil } from "ramda";
import { parse } from "qs";
import dayjs from "dayjs";

import { jjLogger } from "utils/logUtils";
import localStorage from "utils/localStorage";
import CampaignAttributionsRepository from "repositories/CampaignAttributionsRepository";

import useRouter from "./useRouter";

const useUtmsParams = () => {
  const { location } = useRouter();
  let utmsParams = null;

  const removeUtmsStorage = () => localStorage.removeItem("utmsParams");
  const checkUtmsStorage = () => localStorage.getItem("utmsParams");

  const isUtmsValuesEquals = (object1, object2) =>
    object1.utm_source === object2?.source &&
    object1.utm_medium === object2?.medium &&
    object1.utm_campaign === object2?.campaign;

  const handleUtms = () => {
    const previousUtms = checkUtmsStorage();
    const utmsParamsValues = parse(location.search, { ignoreQueryPrefix: true });

    if (
      utmsParamsValues.utm_source &&
      utmsParamsValues.utm_medium &&
      utmsParamsValues.utm_campaign &&
      !isUtmsValuesEquals(utmsParamsValues, previousUtms)
    ) {
      localStorage.setItem("utmsParams", {
        source: utmsParamsValues.utm_source,
        medium: utmsParamsValues.utm_medium,
        campaign: utmsParamsValues.utm_campaign,
        created: dayjs.utc().format(),
      });
    }
  };

  const trackCampaignAttribution = (utmParams, userId) => {
    const date = new Date(utmParams.created);
    const dateAsUnixTimestamp = Math.round(date.getTime() / 1000);
    const attributes = {
      data: {
        type: "campaign_attributions",
        attributes: {
          source: utmParams.source,
          start_time: dateAsUnixTimestamp,
          campaign: utmParams.campaign,
          medium: utmParams.medium,
          user_id: userId,
        },
      },
    };

    return CampaignAttributionsRepository.create(attributes)
      .catch((error) => {
        jjLogger.log(
          `useUtmsParams: trackCampaignAttribution - Error while creating campaign attribution with params: ${JSON.stringify(
            attributes,
          )} - Error ${error}`,
        );
      })
      .finally(() => removeUtmsStorage());
  };

  if (DetectUserAgent.isAndroid()) {
    const params = location.search
      ? parse(location.search, { ignoreQueryPrefix: true })
      : parse(location.hash.slice(1));
    if (!isNil(params.state)) {
      utmsParams = params.state;
    }
  }

  return { utmsParams, handleUtms, checkUtmsStorage, trackCampaignAttribution };
};

export default useUtmsParams;
