import React, { useState } from "react";
import PropTypes from "prop-types";
import { Box, Button, Grid, IconButton, Link, Typography } from "@mui/material";
import { NavLink } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";

import Logo from "assets/images/Logo.svg";
import { appRoutes, externalRoutes } from "routes";
import UserPresenter from "presenters/UserPresenter";
import MegaMenuItemPresenter from "presenters/MegaMenuItemPresenter";
import { headerSelector } from "tests/selectors/index.js";
import { useRouter, useUsers } from "hooks";
import { AUTH_LINKS } from "enums";

import MenuDrawer from "../MenuDrawer";
import Avatar from "../AccountMenu/components/Avatar/Avatar";

import useStyles from "./useStyles";
import MenuItem from "./components/MenuItem/MenuItem";

const MobileHeader = ({ menuItems }) => {
  const classes = useStyles();
  const theme = useTheme();
  const { currentUser, signOut } = useUsers();
  const [isMenuOpen, setMenuOpen] = useState(false);
  const openMenu = () => setMenuOpen(true);
  const closeMenu = () => setMenuOpen(false);

  const [isAccountOpen, setAccountOpen] = useState(false);
  const openAccount = () => setAccountOpen(true);
  const closeAccount = () => setAccountOpen(false);
  const { location } = useRouter();

  const renderCommonLinks = (link) => {
    const { to: pathname, name, id } = link;
    const key = `hamburger-${id}`;
    const isLoginLink = name === "Log In";
    const toParams = { pathname };

    if (isLoginLink) toParams.state = { referrer: location.pathname };

    return (
      <Link
        id={key}
        className={classes.menuItem}
        onClick={() => closeAccount()}
        to={toParams}
        key={key}
        component={NavLink}
      >
        {name}
      </Link>
    );
  };

  return (
    <Grid container spacing={3} className={classes.root}>
      <Grid item xs>
        <IconButton
          id="header-hamburger-mobile"
          data-testid={headerSelector.buttons.hamburguer}
          alt="Menu"
          className={classes.action}
          onClick={openMenu}
        >
          <MenuIcon />
        </IconButton>

        <MenuDrawer anchor="left" open={isMenuOpen} onClose={closeMenu}>
          {menuItems &&
            menuItems.map((item) => <MenuItem key={item.menu.name} menuItem={item.menu} closeMenu={closeMenu} />)}
        </MenuDrawer>

        <IconButton id="header-search-mobile" alt="Search" to={appRoutes.searchPath()} component={NavLink}>
          <SearchIcon />
        </IconButton>
      </Grid>

      <Grid item xs={6} style={{ textAlign: "center" }}>
        <IconButton id="header-logo-mobile" alt="JibJab" to={appRoutes.rootPath()} component={NavLink}>
          <img src={Logo} alt="JibJab" width="120px" height="36px" />
        </IconButton>
      </Grid>

      <Grid item xs style={{ textAlign: "right" }}>
        <Avatar onClick={openAccount} />

        <MenuDrawer anchor="right" open={isAccountOpen} onClose={closeAccount}>
          {!UserPresenter.isRegistered(currentUser) && (
            <Link
              className={classes.menuItem}
              id="header-login-mobile"
              to={appRoutes.loginPath()}
              component={NavLink}
              onClick={closeAccount}
            >
              Log In
            </Link>
          )}

          {UserPresenter.isRegistered(currentUser) && AUTH_LINKS.map((link) => renderCommonLinks(link))}

          <Link
            className={classes.menuItem}
            id="header-help-mobile"
            href={externalRoutes.helpPath()}
            onClick={closeAccount}
          >
            Help Center
          </Link>

          {UserPresenter.isRegistered(currentUser) && (
            <Link
              id="header-logout-mobile"
              className={classes.menuItem}
              to={appRoutes.rootPath()}
              component={NavLink}
              onClick={() => signOut()}
            >
              Log Out
            </Link>
          )}

          {UserPresenter.isRegistered(currentUser) && !UserPresenter.isPaid(currentUser) && (
            <Box>
              <Button
                className={classes.menuItem}
                id="header-upgrade-mobile"
                to={appRoutes.paymentPath()}
                component={NavLink}
                variant="contained"
                color="primary"
                onClick={closeAccount}
              >
                Upgrade
              </Button>
            </Box>
          )}

          {!UserPresenter.isRegistered(currentUser) && (
            <>
              <Typography color={theme.palette.text.light} className={classes.message}>
                Haven’t join the fun? Create a free account to save your faces and Ecards!
              </Typography>

              <Box>
                <Button
                  id="header-sign-up-mobile"
                  className={classes.button}
                  to={appRoutes.signUpPath()}
                  component={NavLink}
                  variant="contained"
                  color="primary"
                  onClick={closeAccount}
                >
                  Sign Up
                </Button>
              </Box>
            </>
          )}
        </MenuDrawer>
      </Grid>
    </Grid>
  );
};

MobileHeader.propTypes = {
  menuItems: PropTypes.arrayOf(MegaMenuItemPresenter.shape()),
};

export default MobileHeader;
