import { apiRoutes } from "routes";
import FetchHelpers from "utils/FetchHelpers";

const trimEmail = (params) => {
  const attrs = params;
  if (attrs?.data?.attributes?.email) attrs.data.attributes.email = attrs.data.attributes.email.trim();
};

export default {
  show() {
    const url = apiRoutes.userPath(1);
    return FetchHelpers.get(url);
  },

  create(attrs) {
    trimEmail(attrs);
    const url = apiRoutes.userPath("1.1");
    return FetchHelpers.post(url, attrs);
  },

  update(attrs) {
    trimEmail(attrs);
    const url = apiRoutes.userPath("1.1");
    return FetchHelpers.patch(url, attrs);
  },
};
