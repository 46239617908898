import palette from "layouts/ThemeProvider/palette";

export default {
  MuiBackdrop: {
    styleOverrides: {
      root: {
        backgroundColor: palette.backdrop,
        height: "120%",
      },
    },
  },
};
