import { useEffect, useState } from "react";

const useCanvas = ({ drawFn = false, animation = false } = {}) => {
  const [ref, setRef] = useState(null);

  useEffect(() => {
    if (!ref || !animation) return undefined;

    const ctx = ref.getContext("2d");
    // eslint-disable-next-line no-use-before-define
    let animationFrameId = requestAnimationFrame(renderFrame);

    function renderFrame() {
      animationFrameId = requestAnimationFrame(renderFrame);
      drawFn(ctx);
    }

    return () => cancelAnimationFrame(animationFrameId);
  }, [!!ref, drawFn, animation]);

  return { ref, setRef };
};

export default useCanvas;
