const PERSON_TO_UPDATE_PERSIST_KEY = "personToUpdatePersist";

const clearPersonToUpdatePersist = () => {
  sessionStorage.removeItem(PERSON_TO_UPDATE_PERSIST_KEY);
};

const getPersonToUpdate = () => {
  const value = JSON.parse(sessionStorage.getItem(PERSON_TO_UPDATE_PERSIST_KEY));
  if (value?.length > 0) {
    return value;
  }
  return [];
};

export { getPersonToUpdate, PERSON_TO_UPDATE_PERSIST_KEY, clearPersonToUpdatePersist };
