import { useEffect } from "react";
import { propOr } from "ramda";
import { useSelector } from "react-redux";

import { usePeopleActions } from "store/PeopleSlice";

import usePersistPersonToUpdate from "./usePersistPersonToUpdate";

const usePeople = () => {
  const { setValue, clearPersonToUpdatePersist } = usePersistPersonToUpdate();
  const {
    loadPeople,
    loadAdditionalPeople,
    createPerson,
    deletePerson,
    deletePersonStorage,
    addHeadToPerson,
    resetPeople,
    updatePerson,
    addPersonToUpdate,
    resetPeopleToUpdate: resetPeopleToUpdateStorage,
    decreaseHeadToPerson,
    loadPeopleToCast,
    resetPeopleToCast,
  } = usePeopleActions();

  const { people, meta, peopleToUpdate, peopleToCast } = useSelector((state) => state.PeopleSlice);

  useEffect(() => {
    if (peopleToUpdate?.length > 0) {
      setValue(peopleToUpdate);
    }
  }, [peopleToUpdate]);

  const personAttributes = (personParams, head) => ({
    data: {
      type: "people",
      attributes: personParams,
      relationships: {
        heads: {
          data: [
            {
              type: "heads",
              id: head.id,
            },
          ],
        },
      },
    },
  });

  const personUpdateAttributes = (person) => {
    const { name, relation, birthday, anniversary, defaultHead, newDefaultHeadId } = person;
    return {
      data: {
        id: person.id,
        type: "people",
        attributes: {
          name,
          relation,
          birthday,
          anniversary,
          defaultHeadId: newDefaultHeadId,
        },
        relationships: {
          defaultHead: {
            data: {
              id: defaultHead ? defaultHead.id : newDefaultHeadId,
              type: "default-heads",
            },
          },
        },
      },
    };
  };

  return {
    loadPeople,
    meta,
    loadAdditionalPeople,
    resetPeople,
    deletePerson: (personId) => {
      const params = { deleteHeads: true };
      return deletePerson(personId, params);
    },
    deletePersonKeepHeads: (personId) => {
      const params = { deleteHeads: false };
      return deletePerson(personId, params);
    },
    createPerson: (personParams, head) => {
      const attributes = personAttributes(personParams, head);
      return createPerson(attributes);
    },
    updatePerson: (person) => {
      const params = personUpdateAttributes(person);
      return updatePerson(person.id, params);
    },
    addHeadToPerson,
    people,
    totalPeopleCount: propOr(people.count, "totalCount", meta),
    peopleToUpdate,
    addPersonToUpdate,
    resetPeopleToUpdate: () => {
      resetPeopleToUpdateStorage();
      clearPersonToUpdatePersist();
    },
    decreaseHeadToPerson,
    deletePersonStorage,
    loadPeopleToCast,
    resetPeopleToCast,
    peopleToCast,
  };
};

export default usePeople;
