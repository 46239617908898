import { useRef } from "react";

import useVideoTracker from "hooks/useVideoTracker";
import { jjLogger } from "utils/logUtils";
import { isFunction } from "utils/helperUtils";
import { trackEventVideoRenderedError } from "utils/gAnalyticsUtils";
import TemplatePresenter from "presenters/TemplatePresenter";
import MakePresenter from "presenters/MakePresenter";

import useMakes from "./useMakes";

const useWebGlPlayer = (params) => {
  const { make } = useMakes();
  const transportRef = useRef(null);
  const playerRef = useRef(null);
  const { handleResetsOnPlay, resetProgressTracking, handleTimeUpdateTrackers } = useVideoTracker(params.templateGroup);

  const logError = (error) => {
    const message = error.message || error;
    jjLogger.logError(`WebGL Player Error: ${message}`);
  };

  const logDebug = (message) => {
    jjLogger.logDebug(`useWebGlPlayer.js: ${message}`);
  };

  const handleError = (error, callback) => {
    trackEventVideoRenderedError("WebGL");
    isFunction(callback) ? callback(error) : logError(error);
  };

  const onEnded = () => {
    resetProgressTracking();
  };

  const getPlayerInfo = (player) => ({
    currentTime: player?.currentTime,
    positionData: player?.positionData,
    templateData: player?.templateData,
    isPlaying: player?.isPlaying,
    isFullScreen: player?.isFullScreen,
    frameNumber: player?.frameNumber,
    isStreaming: player?.feature?.includes(".m3u8"),
  });

  const finishInit = (onLoaded) => {
    if (isFunction(onLoaded)) onLoaded(playerRef.current);

    const { videoPlayer } = playerRef.current.resources.video;
    videoPlayer.onended = onEnded;
    videoPlayer.ontimeupdate = handleTimeUpdateTrackers;
    videoPlayer.onplay = handleResetsOnPlay;
  };

  const init = async ({ onLoaded, onFailed, onWebGlError, controls }) => {
    import("webgl-render-library")
      .then(async (renderLib) => {
        const { SYPlayer, Transport } = renderLib;

        const contextLostHandler = (player) => {
          const playerInfo = {
            ...getPlayerInfo(player),
            templateName: TemplatePresenter.name(MakePresenter.template(make)),
            deviceInfo: navigator.userAgent,
          };
          handleError("Context lost", onWebGlError);
          jjLogger.log(`WebGL player info: ${JSON.stringify(playerInfo)}`);
        };

        logDebug("initializing SYPlayer");
        try {
          playerRef.current = await new SYPlayer(params, contextLostHandler).promise();

          logDebug("SYPlayer loaded, initializing Transport");
          if (controls) transportRef.current = new Transport(playerRef.current);

          finishInit(onLoaded);
        } catch (error) {
          handleError(error, onWebGlError);
        }
      })
      .catch((error) => {
        handleError(error, onFailed);
      });
  };

  const cleanup = () => {
    if (!playerRef.current) return;

    playerRef.current.removeEventListener("loaded", null);
    playerRef.current.destroy();

    if (transportRef.current) {
      transportRef.current.destroy();
    }

    playerRef.current = null;
    transportRef.current = null;
  };

  const exitFullscreen = () => {
    if (transportRef.current) {
      transportRef.current.closeFullScreen();
    }
  };

  const pausePlayer = () => {
    if (playerRef?.current) {
      playerRef.current.pause();
    }
  };

  const resetPlayer = () => {
    playerRef.current.seekTo(0);
    playerRef.current.pause();
  };

  return { init, cleanup, playerRef, exitFullscreen, pausePlayer, resetPlayer };
};

export default useWebGlPlayer;
