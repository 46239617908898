import React from "react";
import PropTypes from "prop-types";

import MegaMenuItemPresenter from "presenters/MegaMenuItemPresenter";

import useStyles from "./useStyles";
import MenuItem from "./components/MenuItem";

const Navigation = ({ menuItems }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {menuItems && menuItems.map((item) => <MenuItem key={item.name} menuItem={item.menu} />)}
    </div>
  );
};

Navigation.propTypes = {
  menuItems: PropTypes.arrayOf(MegaMenuItemPresenter.shape()),
};

export default Navigation;
