import { apiRoutesV2 } from "routes";
import FetchHelpers from "utils/FetchHelpers";

export default {
  index(params) {
    const url = apiRoutesV2.invites();
    return FetchHelpers.get(url, params);
  },
  create(params) {
    const url = apiRoutesV2.invites();
    return FetchHelpers.post(url, params);
  },
  show(slug, params) {
    const url = apiRoutesV2.invites(slug);
    return FetchHelpers.get(url, { include: "guests", ...params });
  },
  update(slug, params) {
    const url = apiRoutesV2.invites(slug);
    return FetchHelpers.patch(url, params, { include: "guests" });
  },
  delete(slug) {
    const url = apiRoutesV2.invites(slug);
    return FetchHelpers.delete(url);
  },
  addGuest(slug, params, role = null) {
    const url = apiRoutesV2.inviteGuests(slug);
    const queryParams = role ? { role } : {};
    return FetchHelpers.post(url, params, undefined, queryParams);
  },
  updateGuest(inviteSlug, guestSlug, params, role = null) {
    const url = apiRoutesV2.inviteGuests(inviteSlug, guestSlug);
    const queryParams = role ? { role } : {};
    return FetchHelpers.patch(url, params, queryParams);
  },
  deleteGuest(inviteSlug, guestSlug) {
    const url = apiRoutesV2.inviteGuests(inviteSlug, guestSlug);
    return FetchHelpers.delete(url);
  },
};
