import { useState } from "react";
import { inc } from "ramda";

const DEFAULT_PER_PAGE = 50;

const useIndexScroll = (data, perPage = DEFAULT_PER_PAGE, totalCount, onSuccess, condition = true) => {
  const buildParams = (number) => ({ page: { number, size: perPage } });
  const currentScroll = data?.length >= perPage ? Math.floor(data.length / perPage) : 0;
  const [page, setPage] = useState(currentScroll);
  const totalPages = totalCount && Math.ceil(totalCount / perPage);
  const hasMorePages = condition && page < totalPages;

  const handleLoadData = (onLoaded) => {
    const newPage = inc(page);
    const params = buildParams(newPage);
    return onSuccess(params).then(() => {
      setPage(newPage);
      if (onLoaded) {
        onLoaded();
      }
    });
  };

  return { handleLoadData, hasMorePages, page };
};

export default useIndexScroll;
