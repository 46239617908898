import { createSlice } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";

import { deserialize } from "utils/storeUtils";
import MorphablePackGroupRepository from "repositories/MorphablePackGroupRepository";

const initialState = {
  morphablePackGroup: null,
};

const morphablePackGroupSlice = createSlice({
  name: "morphablePackGroup",
  initialState,
  reducers: {
    loadMorphablePackGroup(state, { payload }) {
      state.morphablePackGroup = deserialize(payload);
    },
    clearMorphablePackGroupSuccess(state) {
      state.morphablePackGroup = null;
    },
  },
});

export const { actions } = morphablePackGroupSlice;
export default morphablePackGroupSlice.reducer;

export const useMorphablePackGroupActions = () => {
  const dispatch = useDispatch();

  const loadMorphablePackGroup = (slug) => {
    return MorphablePackGroupRepository.show(slug).then(({ data }) => {
      dispatch(morphablePackGroupSlice.actions.loadMorphablePackGroup(data));
      return data;
    });
  };

  const clearMorphablePackGroup = () => dispatch(morphablePackGroupSlice.actions.clearMorphablePackGroupSuccess());

  return { loadMorphablePackGroup, clearMorphablePackGroup };
};
