/* eslint-disable no-param-reassign */
import { initCanvas, canvasToBlob, transformContext } from "utils/canvasUtils";
import { gVisionToNewHead } from "utils/gVisionUtils";
import FacePresenter from "presenters/FacePresenter";
import { ZEROFACE, HEAD, HEAD_CUT, SCALE_HEAD_CUT } from "enums";

export const getFaceTransforms = (size, headObject, transforms) => {
  const { rotation: startRotation, faceMidPoint, faceSize } = headObject;
  const { rotation, scale, offset, isDefault } = transforms;
  const { width, height } = size;
  const initialScale = height / faceSize.height;
  const newScale = isDefault ? initialScale : scale;
  const angle = -(startRotation + rotation);
  const midPoint = { x: width / 2 - faceMidPoint.x, y: height / 2 - faceMidPoint.y };
  const dragOffset = { x: offset.x, y: offset.y };

  return {
    initialScale,
    newScale,
    angle,
    midPoint,
    dragOffset,
  };
};

const drawEditedHead = (ctx, sourceImage, headMask, headObject, transforms) => {
  const { newScale, angle, midPoint, dragOffset } = getFaceTransforms(HEAD_CUT, headObject, transforms);
  const [srcImageCnv, srcImageCtx] = initCanvas(HEAD_CUT);
  const [headCutCnv, headCutCtx] = initCanvas(HEAD);

  transformContext(srcImageCtx, { offset: dragOffset, scale: newScale, angle });
  srcImageCtx.drawImage(sourceImage, midPoint.x, midPoint.y);

  headCutCtx.drawImage(srcImageCnv, HEAD.width / 2 - HEAD_CUT.width / 2, HEAD.height / 2 - HEAD_CUT.height / 2);
  transformContext(headCutCtx, { scale: SCALE_HEAD_CUT });
  headCutCtx.globalCompositeOperation = "destination-in";
  headCutCtx.drawImage(headMask, 0, 0);

  transformContext(ctx, { scale: 1 / SCALE_HEAD_CUT });
  ctx.drawImage(headCutCnv, 0, 0);
};

const drawHead = (ctx, sourceImage, headObject, transforms) => {
  const { newScale, angle, midPoint, dragOffset } = getFaceTransforms(HEAD, headObject, transforms);
  const { width, height } = HEAD;
  const [image, imageCtx] = initCanvas(HEAD);

  imageCtx.clearRect(0, 0, width, height);
  imageCtx.save();
  transformContext(imageCtx, { offset: dragOffset, scale: newScale, angle });
  imageCtx.drawImage(sourceImage, midPoint.x, midPoint.y);
  imageCtx.restore();

  ctx.drawImage(image, 0, 0);
};

const drawHeadMask = (ctx, mask) => {
  ctx.globalCompositeOperation = "destination-in";
  ctx.drawImage(mask, 0, 0);
};

export const getDefaultTransforms = (transforms) => {
  if (transforms) return { ...transforms, angle: transforms.rotation / (Math.PI / 180) };

  return ZEROFACE;
};

export const intersectLayers = (face, svgImage, type = "image") => {
  return ["destination-over", "source-in"].map((operation) => {
    const [cnv, ctx] = initCanvas(HEAD);
    if (operation === "source-in") {
      const xMove = -5;
      const yMove = -10;
      const sizeIncrement = 10;
      ctx.drawImage(
        svgImage,
        0,
        0,
        svgImage.width,
        svgImage.height,
        xMove,
        yMove,
        svgImage.width + sizeIncrement,
        svgImage.height + sizeIncrement,
      );
    } else ctx.drawImage(svgImage, 0, 0);
    ctx.globalCompositeOperation = operation;
    ctx.drawImage(face, 0, 0);
    ctx.globalCompositeOperation = "destination-in";
    ctx.drawImage(face, 0, 0);
    if (type === "canvas") return cnv;
    return canvasToBlob(cnv);
  });
};

export const headCanvas = ({ image, gVisionData, headMask, transforms = {} }) => {
  const [canvas, context] = initCanvas(HEAD);
  const headObject = gVisionToNewHead(gVisionData, image);
  const safeTransforms = getDefaultTransforms(transforms);

  if (safeTransforms && !safeTransforms.isDefault) {
    drawEditedHead(context, image, headMask, headObject, safeTransforms);
    return canvas;
  }

  drawHead(context, image, headObject, safeTransforms);
  drawHeadMask(context, headMask);

  return canvas;
};

export const getRandomFace = (faces) => faces[Math.floor(Math.random() * faces.length)];

export const faceCreateAttributes = (key, svg) => {
  return {
    data: {
      attributes: {
        asset: {
          head_key: key,
        },
      },
      relationships: {
        jaw: {
          data: {
            attributes: {
              svg,
            },
            type: "jaws",
          },
        },
      },
      type: "heads",
    },
  };
};

export const faceUpdateAttributes = (personSlug, faceSlug) => {
  return {
    data: {
      id: faceSlug,
      type: "heads",
      relationships: {
        person: {
          data: {
            id: personSlug,
            type: "people",
          },
        },
      },
    },
  };
};

export const faceUpdateJawAttributes = (face, jawSvg) => {
  return {
    data: {
      attributes: {
        asset: FacePresenter.asset(face),
        createdAt: FacePresenter.createdAt(face),
        default: FacePresenter.default(face),
      },
      id: FacePresenter.id(face),
      relationships: {
        jaw: {
          data: {
            attributes: {
              svg: jawSvg,
            },
            type: "jaws",
          },
          person: {
            data: FacePresenter.person(face),
          },
        },
      },
      type: "heads",
    },
  };
};
