import { apiRoutes } from "routes";
import FetchHelpers from "utils/FetchHelpers";
import { newRelic, jjLogger } from "utils/logUtils";

const featureVideoAsset = (response) => {
  const newResponse = response;
  if (newResponse.data.included) {
    for (let i = 0; i < newResponse.data.included.length; i += 1) {
      const asset = newResponse.data.included[i].attributes.webglFeatureVideo;
      if (asset) {
        newResponse.data.included[i].attributes.webglFeatureVideo = FetchHelpers.toSameOrigin(asset);
      }
    }
  }

  return newResponse;
};

const sameOriginAssets = (response) => {
  let newResponse = response;
  try {
    let asset;
    for (let i = 0; i < newResponse.data.data.length; i += 1) {
      asset = newResponse.data.data[i].attributes.thumbnail;
      if (asset) {
        newResponse.data.data[i].attributes.thumbnail = FetchHelpers.toSameOrigin(asset);
      }

      asset = newResponse.data.data[i].attributes.shareThumbnail;
      if (asset) {
        newResponse.data.data[i].attributes.shareThumbnail = FetchHelpers.toSameOrigin(asset);
      }
    }

    newResponse = featureVideoAsset(newResponse);
  } catch (error) {
    newRelic(`MakeRepository Error: ${error.message}`, error);
    jjLogger.log(error.message);
  }

  return newResponse;
};

const sameOriginAsset = (response) => {
  let newResponse = response;
  try {
    let asset = newResponse.data.data.attributes.thumbnail;
    if (asset) {
      newResponse.data.data.attributes.thumbnail = FetchHelpers.toSameOrigin(asset);
    }

    asset = newResponse.data.data.attributes.shareThumbnail;
    if (asset) {
      newResponse.data.data.attributes.shareThumbnail = FetchHelpers.toSameOrigin(asset);
    }

    newResponse = featureVideoAsset(newResponse);
  } catch (error) {
    newRelic(`MakeRepository Error: ${error.message}`, error);
    jjLogger.log(error.message);
  }

  return newResponse;
};

export default {
  index(params) {
    const url = apiRoutes.makesPath(1, params);
    return FetchHelpers.get(url).then(sameOriginAssets);
  },
  create(params) {
    const url = apiRoutes.makesPath(1);

    return FetchHelpers.post(url, params).then(sameOriginAsset);
  },
  show(slug) {
    const url = apiRoutes.makePath(1, slug);
    return FetchHelpers.get(url).then(sameOriginAsset);
  },
  destroy(slug) {
    const url = apiRoutes.makePath(1, slug);
    return FetchHelpers.delete(url);
  },
  update(slug, params) {
    const url = apiRoutes.makePath(1, slug);

    return FetchHelpers.patch(url, params);
  },
};
