const getSlugFromKey = (key) => {
  const regex = /image_assets\/(.+?)\/original/;
  const match = key.match(regex);
  return match ? match[1] : null;
};

export const PER_PAGE = 5;
export const pagingParams = (number, size = PER_PAGE) => {
  return {
    page: {
      number,
      size,
    },
  };
};

export const inviteAttributes = ({ makeThumb, shareThumb, roles, templateId, templateGroupId, themeId }) => {
  return {
    data: {
      attributes: {
        "template-group-id": templateGroupId,
        "template-id": templateId,
        "theme-id": themeId,
        castings: roles.map((role) => {
          return {
            "role-rank": role.roleRank,
            head: role.headID,
          };
        }),
        "image-assets": [
          {
            subtype: "thumbnail",
            slug: getSlugFromKey(makeThumb),
          },
          {
            subtype: "share_thumbnail",
            slug: getSlugFromKey(shareThumb),
          },
        ],
      },
      type: "invites",
    },
  };
};

export const buildGuestAttributes = (guest, id) => {
  const { name, email, rsvp, additionalGuests } = guest;

  const guestAttributes = {
    data: {
      type: "guests",
      attributes: {
        name,
        email: email?.trim(),
        rsvp,
      },
    },
  };

  if (additionalGuests) guestAttributes.data.attributes["additional-guests"] = additionalGuests;

  return id ? { ...guestAttributes, data: { ...guestAttributes.data, id } } : guestAttributes;
};

export const updateSettingsAttributes = (
  inviteId,
  { from, subject, canSeeGuestList, invitesPerGuest, public: publicAttribute },
) => {
  return {
    data: {
      type: "invites",
      id: inviteId,
      attributes: {
        settings: {
          from,
          subject,
          "can-see-guest-list": canSeeGuestList,
          "invites-per-guest": invitesPerGuest,
          public: publicAttribute,
        },
      },
    },
  };
};
