import TemplateGroupPresenter from "presenters/TemplateGroupPresenter";
import MakePresenter from "presenters/MakePresenter";
import FmvrRepository from "repositories/FmvrRepository";
import { fmvrAttributes } from "utils/fmvrUtils";

const useFmvr = () => {
  const createFmvr = (make, templateGroup) => {
    if (TemplateGroupPresenter.isStarringYouClip(templateGroup)) {
      FmvrRepository.create(MakePresenter.id(make), fmvrAttributes(make, templateGroup));
    }
  };

  return { createFmvr };
};

export default useFmvr;
