import makeStyles from "@mui/styles/makeStyles";

export default makeStyles(
  (theme) => ({
    root: {
      height: "100%",
      display: "inline-flex",
      alignItems: "flex-end",
      justifyContent: "center",
      [theme.breakpoints.up("tablet")]: {
        justifyContent: "flex-start",
        alignItems: "center",
      },
    },
  }),
  { name: "Navigation" },
);
