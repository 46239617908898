const STORAGE_KEY = "selectedCastNum";

export default {
  get() {
    if (sessionStorage.getItem(STORAGE_KEY) === null) return null;
    return JSON.parse(sessionStorage.getItem(STORAGE_KEY));
  },

  set(castCount) {
    sessionStorage.setItem(STORAGE_KEY, castCount);
  },

  clear() {
    sessionStorage.removeItem(STORAGE_KEY);
  },
};
