import { createSlice } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";

import MakeMessagesRepository from "repositories/MakeMessagesRepository";

const initialState = {
  errors: null,
};

const sharesSlice = createSlice({
  name: "makeMessages",
  initialState,
  reducers: {
    createMakeMessageFail(state, { payload }) {
      state.errors = payload;
    },
    updateMakeMessageFail(state, { payload }) {
      state.errors = payload;
    },
    deleteMakeMessageFail(state, { payload }) {
      state.errors = payload;
    },
  },
});

export const { actions } = sharesSlice;
export default sharesSlice.reducer;

export const useMakeMessagesActions = () => {
  const dispatch = useDispatch();

  const createMakeMessage = (slug, params) => {
    return MakeMessagesRepository.create(slug, params).catch((error) => {
      dispatch(sharesSlice.actions.createMakeMessageFail(error));
      throw new Error(error);
    });
  };

  const updateMakeMessage = (slug, params) => {
    return MakeMessagesRepository.update(slug, params).catch((error) => {
      dispatch(sharesSlice.actions.updateMakeMessageFail(error));
      throw new Error(error);
    });
  };

  const deleteMakeMessage = (slug) => {
    return MakeMessagesRepository.delete(slug).catch((error) => {
      dispatch(sharesSlice.actions.deleteMakeMessageFail(error));
      throw new Error(error);
    });
  };

  return {
    createMakeMessage,
    updateMakeMessage,
    deleteMakeMessage,
  };
};
