import { useSelector } from "react-redux";

import { useIdentityActions } from "store/IdentitiesSlice";

const useIdentity = () => {
  const { loadIdentities, createIdentity, deleteIdentity } = useIdentityActions();

  const { identities, error } = useSelector((state) => state.IdentitiesSlice);

  return {
    loadIdentities,
    createIdentity,
    deleteIdentity,
    identities,
    error,
  };
};

export default useIdentity;
