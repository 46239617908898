import palette from "layouts/ThemeProvider/palette";

export default {
  MuiCssBaseline: {
    styleOverrides: {
      body: {
        backgroundColor: "#fff",
        color: palette.text.light,
      },
      "input, textarea, button": {
        fontSize: "inherit",
        fontWeight: "inherit",
      },
    },
  },
};
