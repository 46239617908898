import TemplateGroupPresenter from "presenters/TemplateGroupPresenter";

export const videoAttributes = (templateGroup) => {
  const isClip = TemplateGroupPresenter.isStarringYouClip(templateGroup);
  const isInviteGif = TemplateGroupPresenter.isStarringYouInviteGif(templateGroup);
  const loop = isClip || isInviteGif;
  const controls = !isInviteGif;
  const autoplay = isInviteGif;
  const muted = isInviteGif;
  const isVertical = TemplateGroupPresenter.isStarringYouVertical(templateGroup);
  const aspectRatio = TemplateGroupPresenter.aspectRatio(templateGroup);

  return {
    loop,
    controls,
    autoplay,
    muted,
    isVertical,
    aspectRatio,
  };
};
