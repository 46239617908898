import { useSelector } from "react-redux";

import { useTransactionRecordsActions } from "store/TransactionRecordSlice";

const useTransactionRecords = () => {
  const { loadTransactionRecords, loadTransactionRecord } = useTransactionRecordsActions();

  const { transactionRecords, transactionRecord } = useSelector((state) => state.TransactionRecordSlice);

  return {
    loadTransactionRecords,
    loadTransactionRecord,
    transactionRecords,
    transactionRecord,
  };
};

export default useTransactionRecords;
