import { List, ListItem, ListItemButton, ListItemText } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import clsx from "clsx";

import MegaMenuItemPresenter from "presenters/MegaMenuItemPresenter";

import useStyles from "./useStyles";

const LinkList = ({ list }) => {
  const clasess = useStyles();

  return (
    <List className={clasess.listContainer}>
      {list.map((link) => (
        <ListItem className={clasess.listItem} key={link.name} id={link.name}>
          <ListItemButton className={clsx(clasess.listItem, clasess.listButton)}>
            <Link className={clasess.link} to={link.linkTo}>
              <ListItemText> {link.name} </ListItemText>
            </Link>
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  );
};

LinkList.propTypes = {
  list: PropTypes.arrayOf(MegaMenuItemPresenter.shape()),
};

export default LinkList;
