import makeStyles from "@mui/styles/makeStyles";

export default makeStyles(
  (theme) => ({
    root: {
      zIndex: theme.zIndex.loadingModal,
    },
    paper: {
      overflowY: "initial",
      padding: [20, 24],
      width: 271,
      borderRadius: 10,
      boxShadow: "0 3px 6px 0 rgba(0,0,0,.25)",
      position: "relative",
      [theme.breakpoints.up("mobile")]: {
        width: 406,
        padding: [30, 40],
        borderRadius: 20,
      },
    },
    image: {
      width: "100%",
    },
    modalMessage: {
      margin: [35, "auto", 20, "auto"],
      textAlign: "center",
    },
    message: {
      marginBottom: 20,
    },
  }),
  { name: "LoadingModal" },
);
