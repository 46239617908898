import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import MuiCssBaseline from "./MuiCssBaseline";
import MuiTextField from "./MuiTextField";
import MuiOutlinedInput from "./MuiOutlinedInput";
import MuiFormHelperText from "./MuiFormHelperText";
import MuiIconButton from "./MuiIconButton";
import MuiButton from "./MuiButton";
import MuiBackdrop from "./MuiBackdrop";
import MuiSlider from "./MuiSlider";
import MuiDialog from "./MuiDialog";
import MuiDrawer from "./MuiDrawer";
import MuiLink from "./MuiLink";

export default {
  ...MuiCssBaseline,
  ...MuiTextField,
  ...MuiOutlinedInput,
  ...MuiFormHelperText,
  ...MuiIconButton,
  ...MuiButton,
  ...MuiBackdrop,
  ...MuiDialog,
  ...MuiSlider,
  ...MuiDrawer,
  ...MuiLink,
  MuiButtonBase: {
    defaultProps: {
      disableRipple: true,
      disableTouchRipple: true,
    },
  },
  MuiTypography: {
    defaultProps: {
      variantMapping: {
        large: "p",
        regular: "p",
        small: "p",
        caption: "p",
      },
    },
  },
  MuiTab: {
    defaultProps: {
      disableRipple: true,
      disableFocusRipple: true,
      disableTouchRipple: true,
    },
  },
  MuiTableSortLabel: {
    defaultProps: {
      IconComponent: ArrowDropDownIcon,
    },
  },
  MuiWithWidth: {
    defaultProps: {
      // Initial width property
      initialWidth: "lg", // Breakpoint being globally set 🌎!
    },
  },
  MuiAppBar: {
    defaultProps: {
      elevation: 0,
    },
  },
  MuiPaper: {
    defaultProps: {
      elevation: 0,
    },
  },
};
