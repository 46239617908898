import React, { useEffect } from "react";
import { AppBar, Toolbar } from "@mui/material";

import { useScreen, useMenu } from "hooks";

import MobileHeader from "./components/MobileHeader";
import DesktopHeader from "./components/DesktopHeader";
import Banner from "./components/Banner";
import useStyles from "./useStyles";

const Header = () => {
  const classes = useStyles();
  const { isDesktop } = useScreen();
  const { loadMenu, menuItems } = useMenu();

  useEffect(() => {
    loadMenu();
  }, []);

  return (
    <>
      <AppBar position="sticky" color="neutral">
        <Toolbar disableGutters classes={{ root: classes.toolbar }}>
          {isDesktop ? <Banner /> : null}
          {isDesktop ? <DesktopHeader menuItems={menuItems} /> : <MobileHeader menuItems={menuItems} />}
        </Toolbar>
      </AppBar>
    </>
  );
};

export default Header;
