/* global ga */
import { isNil, last, isEmpty } from "ramda";
import dayjs from "dayjs";

import localStorage, { RECIPIENT_SESSION_KEY } from "utils/localStorage";
import UserPresenter from "presenters/UserPresenter";
import MakePresenter from "presenters/MakePresenter";
import OfferPresenter from "presenters/OfferPresenter";
import TemplatePresenter from "presenters/TemplatePresenter";
import TemplateGroupPresenter from "presenters/TemplateGroupPresenter";
import SubscriptionPresenter from "presenters/SubscriptionPresenter";
import { FACE_DEFAULT_RELATION } from "enums";

import { adblockDetected } from "../hooks/useDetectAdblock";

const sessionData = {
  randomSessionId: null,
  clientId: null,
};

const autoHeadcutEventCategory = "Auto-headcut";

const dimension1 = () => {
  return localStorage.getItem(RECIPIENT_SESSION_KEY);
};

const dimension2 = (user) => {
  return UserPresenter.isRegistered(user) ? "yes" : "no";
};

const dimension3 = (user) => {
  return UserPresenter.role(user) ? UserPresenter.role(user) : null;
};

const dimension4 = () => {
  return localStorage.getItem(RECIPIENT_SESSION_KEY);
};

const dimension5 = (make) => {
  return MakePresenter.isOwner(make) ? "Owner" : "Recipient";
};

const dimension6 = (templateGroup) => {
  return TemplateGroupPresenter.id(templateGroup);
};

const dimension7 = () => {
  return "React";
};

const dimension15 = () => {
  return sessionData.randomSessionId;
};

const dimension17 = () => {
  return sessionData.clientId;
};

const dimension19 = () => {
  return dayjs(new Date()).toISOString();
};

const dimension20 = (user) => {
  if (isNil(user) || UserPresenter.isAnonymous(user)) return "anonymous";
  if (UserPresenter.isRegistered(user) && UserPresenter.notPaid(user)) return "registered";

  return SubscriptionPresenter.status(UserPresenter.activeSubscription(user));
};

const dimension21 = () => {
  const hasAddBlock = adblockDetected();
  return hasAddBlock === true ? "yes" : "no";
};

const userId = (user) => {
  return UserPresenter.isRegistered(user) ? UserPresenter.id(user) : null;
};

const flowState = (inFlow) => {
  return inFlow ? "in flow" : "out of flow";
};

const headCreationFlowState = (path) => {
  if (isNil(path)) return "";
  return path.startsWith("/account/people") ? "account-people" : last(path.split("/"));
};

const pageData = (path) => {
  const { origin } = window.location;

  return {
    page: path,
    location: `${origin}${path}`,
  };
};

export const initGAData = (clientId, randomSessionId) => {
  sessionData.randomSessionId = randomSessionId;
  sessionData.clientId = clientId;
  Object.freeze(sessionData);
};

export const trackPageView = (user, additionalFields) => {
  const { pathname, search, origin } = window.location;
  const path = pathname + search;

  const fieldsObject = {
    page: path,
    location: `${origin}${path}`,
    userId: userId(user),
    dimension1: dimension1(),
    dimension2: dimension2(user),
    dimension7: dimension7(),
    dimension15: dimension15(),
    dimension17: dimension17(),
    dimension19: dimension19(),
    dimension20: dimension20(user),
    dimension21: dimension21(),
    ...additionalFields,
  };

  ga("set", fieldsObject);
  ga("send", "pageview");
};

export const trackPageViewRegister = (user) => {
  trackPageView(user, { dimension4: dimension4() });
};

export const trackPageViewTemplate = (user, templateGroup) => {
  trackPageView(user, { dimension6: dimension6(templateGroup) });
};

export const trackPageViewCast = (user, totalFacesCount) => {
  trackPageView(user, { dimension13: totalFacesCount });
};

export const trackPageViewMakePreview = (user, templateGroup) => {
  trackPageView(user, { dimension6: dimension6(templateGroup) });
};

export const trackPageViewMakePreviewGate = (user, templateGroup) => {
  const path = "/view/make-preview/gate";

  trackPageView(user, {
    dimension6: dimension6(templateGroup),
    ...pageData(path),
  });
};

export const trackPageViewMake = (user, make, templateGroup) => {
  trackPageView(user, {
    dimension5: dimension5(make),
    dimension6: dimension6(templateGroup),
  });
};

export const trackPageViewAutoHeadcut = (user, backUrl) => {
  const path = `/create/head/auto/loading/${headCreationFlowState(backUrl)}`;
  trackPageView(user, pageData(path));
};

export const trackPageViewFaceFound = (user, faceNumber, backUrl) => {
  const path = `/create/person/${faceNumber}/${headCreationFlowState(backUrl)}`;
  trackPageView(user, pageData(path));
};

export const trackPageViewManualHeadcut = (user, backUrl) => {
  const path = `/create/head/head-cut/${headCreationFlowState(backUrl)}`;
  trackPageView(user, pageData(path));
};

export const trackPageViewManualJawcut = (user, backUrl) => {
  const path = `/create/head/jaw-cut/${headCreationFlowState(backUrl)}`;
  trackPageView(user, pageData(path));
};

export const trackPageViewCreatePerson = (user, backUrl) => {
  const path = `/create/head/person/singlescreen/${headCreationFlowState(backUrl)}`;
  trackPageView(user, pageData(path));
};

export const trackPageViewPersonSummary = (user, backUrl) => {
  const path = `/create/head/person/sumary/${headCreationFlowState(backUrl)}`;
  trackPageView(user, pageData(path));
};

export const trackPageViewWhoIsThis = (user, backUrl) => {
  const path = `/create/head/whoisthis/${headCreationFlowState(backUrl)}`;
  trackPageView(user, pageData(path));
};

export const trackPageViewGotEveryone = (user, backUrl) => {
  const path = `/create/head/auto/goteveryone/${headCreationFlowState(backUrl)}`;
  trackPageView(user, pageData(path));
};

export const trackPageView404 = (user, referrer) => {
  const path = isNil(referrer) ? "/not-found" : referrer;
  trackPageView(user, pageData(path));
};

const trackEventLoginRegister = (action, inFlow, user) => {
  ga("send", {
    hitType: "event",
    eventCategory: "Account",
    eventAction: action,
    eventLabel: flowState(inFlow),
    dimension2: "yes",
    dimension3: dimension3(user),
    dimension7: dimension7(),
  });
};

const trackEventMethodLoginSignUp = (user, method, action) => {
  ga("send", {
    hitType: "event",
    eventCategory: "Account",
    eventAction: action,
    eventLabel: method,
    dimension2: "yes",
    dimension3: dimension3(user),
    dimension7: dimension7(),
  });
};

export const trackEventLogin = (inFlow, user, method) => {
  trackEventLoginRegister("Login Success", inFlow, user);
  trackEventMethodLoginSignUp(user, method, "Login Method");
};

export const trackEventRegistration = (inFlow, user, method) => {
  trackEventLoginRegister("Registration Success", inFlow, user);
  trackEventMethodLoginSignUp(user, method, "Register Method");
};

export const trackEventDeletionRequest = () => {
  ga("send", {
    hitType: "event",
    eventCategory: "Account",
    eventAction: "Deletion Request",
    dimension7: dimension7(),
  });
};

export const trackEventSearch = (searchTerm, results, user) => {
  ga("send", {
    hitType: "event",
    eventCategory: "Discovery",
    eventAction: "Search",
    eventLabel: searchTerm,
    eventValue: results,
    dimension2: dimension2(user),
    dimension3: dimension3(user),
    dimension7: dimension7(),
  });
};

export const trackEventPurchaseSuccess = (offer, inFlow, user) => {
  ga("send", {
    hitType: "event",
    eventCategory: "Purchase",
    eventAction: OfferPresenter.name(offer),
    eventLabel: flowState(inFlow),
    eventValue: OfferPresenter.priceInCents(offer),
    dimension2: dimension2(user),
    dimension7: dimension7(),
  });
};

export const trackEventUploadImageClick = (type, user) => {
  ga("send", {
    hitType: "event",
    eventCategory: "Personalize",
    eventAction: "Upload Photo",
    eventLabel: type,
    dimension2: dimension2(user),
    dimension7: dimension7(),
  });
};

export const trackEventFaceOrg = (user, relation, entryPoint) => {
  ga("send", {
    hitType: "event",
    eventCategory: "faceorg",
    eventAction: relation.toLowerCase(),
    eventLabel: entryPoint,
    dimension2: dimension2(user),
  });
};

export const trackEventHeadUploadSuccess = (type, user) => {
  ga("send", {
    hitType: "event",
    eventCategory: "Personalize",
    eventAction: "Head Image Upload",
    eventLabel: type,
    metric2: 1,
    dimension2: dimension2(user),
    dimension7: dimension7(),
  });
};

export const trackEventFaceDetectionSuccess = (heads, user) => {
  ga("send", {
    hitType: "event",
    eventCategory: "Personalize",
    eventAction: "Face Detected",
    eventLabel: "Google Cloud Vision",
    eventValue: heads,
    dimension2: dimension2(user),
    dimension7: dimension7(),
  });
};

const trackEventFaceFound = (action, faceNumber) => {
  ga("send", {
    hitType: "event",
    eventCategory: autoHeadcutEventCategory,
    eventAction: action,
    eventValue: faceNumber,
    dimension7: dimension7(),
  });
};

export const trackEventFaceFoundOK = (faceNumber) => {
  trackEventFaceFound("Accept", faceNumber);
};

export const trackEventFaceFoundSkip = (faceNumber) => {
  trackEventFaceFound("Skip", faceNumber);
};

export const trackEventFaceFoundEdit = (faceNumber) => {
  trackEventFaceFound("Edit", faceNumber);
};

const trackEventGotEveryone = (label, heads) => {
  ga("send", {
    hitType: "event",
    eventCategory: autoHeadcutEventCategory,
    eventAction: "Got Everyone",
    eventLabel: label,
    eventValue: heads,
    dimension7: dimension7(),
  });
};

export const trackEventGotEveryoneYes = (heads) => {
  trackEventGotEveryone("Yes", heads);
};

export const trackEventGotEveryoneNo = (heads) => {
  trackEventGotEveryone("No", heads);
};

export const trackEventMakeCreationSuccess = (templateGroup, user) => {
  const castCount = TemplateGroupPresenter.castsCount(templateGroup)
    ? TemplateGroupPresenter.castsCount(templateGroup)
    : 0;
  ga("send", {
    hitType: "event",
    eventCategory: "Personalize",
    eventAction: "Make Creation Success",
    eventLabel: TemplateGroupPresenter.name(templateGroup),
    eventValue: castCount,
    metric1: 1,
    dimension2: dimension2(user),
    dimension7: dimension7(),
  });
};

export const trackEventMakeShareSuccess = (type, template, user) => {
  ga("send", {
    hitType: "event",
    eventCategory: "Share",
    eventAction: type,
    eventLabel: TemplatePresenter.id(template),
    metric4: 1,
    dimension2: dimension2(user),
    dimension7: dimension7(),
  });
};

export const trackEventTemplateThumbnailClick = (templateGroup, user) => {
  ga("send", {
    hitType: "event",
    eventCategory: "UX",
    eventAction: "Click",
    eventLabel: TemplateGroupPresenter.name(templateGroup),
    dimension2: dimension2(user),
    dimension7: dimension7(),
  });
};

export const trackEventVideoRendered = (type) => {
  ga("send", {
    hitType: "event",
    eventCategory: "Render",
    eventAction: type,
    eventLabel: window.location.href,
    nonInteraction: true,
    dimension1: dimension1(),
    dimension4: dimension4(),
  });
};

export const trackEventVideoRenderedError = (type) => {
  ga("send", {
    hitType: "event",
    eventCategory: "Render Error",
    eventAction: type,
    eventLabel: window.location.href,
    nonInteraction: true,
    dimension7: dimension7(),
  });
};

export const trackEvent404Error = (referrer) => {
  ga("send", {
    hitType: "event",
    eventCategory: "404 Error",
    eventAction: window.location.href,
    eventLabel: referrer,
    nonInteraction: true,
    dimension7: dimension7(),
  });
};

export const trackEventWebcamError = (error) => {
  ga("send", {
    hitType: "event",
    eventCategory: "Webcam Error",
    eventAction: error,
    nonInteraction: true,
    dimension7: dimension7(),
  });
};

export const trackEventEditRenewal = () => {
  ga("send", {
    hitType: "event",
    eventCategory: "Account",
    eventAction: "Edit Renewal",
    eventLabel: "Edit Renewal Click",
    dimension7: dimension7(),
  });
};

export const trackEventConfirmRenewalOff = () => {
  ga("send", {
    hitType: "event",
    eventCategory: "Account",
    eventAction: "Confirm Renewal OFF",
    eventLabel: "Confirmation Modal",
    dimension7: dimension7(),
  });
};

const trackEventTurnRenewalOff = (label) => {
  ga("send", {
    hitType: "event",
    eventCategory: "Account",
    eventAction: "Turn Renewal OFF",
    eventLabel: label,
    dimension7: dimension7(),
  });
};

export const trackEventTurnRenewalOffScreen = () => {
  trackEventTurnRenewalOff("Edit Renewal Screen");
};

export const trackEventTurnRenewalOffFeedback = () => {
  trackEventTurnRenewalOff("Feedback Modal");
};

const trackEventKeepRenewal = (label) => {
  ga("send", {
    hitType: "event",
    eventCategory: "Account",
    eventAction: "Keep Renewal",
    eventLabel: label,
    dimension7: dimension7(),
  });
};

export const trackEventKeepRenewalScreen = () => {
  trackEventKeepRenewal("Edit Renewal Screen");
};

export const trackEventKeepRenewalFeedback = () => {
  trackEventKeepRenewal("Feedback Modal");
};

export const trackEventKeepRenewalConfirmation = () => {
  trackEventKeepRenewal("Confirmation Modal");
};

export const trackEventTurnRenewalOn = () => {
  ga("send", {
    hitType: "event",
    eventCategory: "Account",
    eventAction: "Turn Renewal ON",
    eventLabel: "Turn Renewal ON",
    dimension7: dimension7(),
  });
};

export const trackEventSendFeedback = (feedback) => {
  ga("send", {
    hitType: "event",
    eventCategory: "Account",
    eventAction: "Feedback",
    eventLabel: feedback,
    dimension7: dimension7(),
  });
};

const trackEventCloseModal = (label) => {
  ga("send", {
    hitType: "event",
    eventCategory: "Account",
    eventAction: "Close modal",
    eventLabel: label,
    dimension7: dimension7(),
  });
};

export const trackEventCloseFeedbackModal = () => {
  trackEventCloseModal("Close Feedback Modal");
};

export const trackEventCloseConfirmRenewalModal = () => {
  trackEventCloseModal("Close Confirmation Modal");
};

export const trackEventAddingPerson = (label, faceNumber, user) => {
  ga("send", {
    hitType: "event",
    eventCategory: autoHeadcutEventCategory,
    eventAction: "Select Person",
    eventLabel: isNil(label) ? "Default" : label,
    eventValue: faceNumber,
    dimension1: dimension1(),
    dimension2: dimension2(user),
    dimension3: dimension3(user),
    dimension7: dimension7(),
  });
};

export const trackEventAddFaceOnly = (faceNumber, user) => {
  ga("send", {
    hitType: "event",
    eventCategory: autoHeadcutEventCategory,
    eventAction: "Add Face Only",
    eventLabel: "Keep Face Only",
    eventValue: faceNumber,
    dimension1: dimension1(),
    dimension2: dimension2(user),
    dimension3: dimension3(user),
    dimension7: dimension7(),
  });
};

export const trackEventPersonSave = (label) => {
  ga("send", {
    hitType: "event",
    eventCategory: autoHeadcutEventCategory,
    eventAction: "New Person Relation Saved",
    eventLabel: isNil(label) ? "Default" : label,
    dimension7: dimension7(),
  });
};

export const trackEventSaveFaceToPerson = (label, faceNumber, user) => {
  ga("send", {
    hitType: "event",
    eventCategory: autoHeadcutEventCategory,
    eventAction: "Add Face to Person",
    eventLabel: isNil(label) ? "Default" : label,
    eventValue: faceNumber,
    dimension1: dimension1(),
    dimension2: dimension2(user),
    dimension3: dimension3(user),
    dimension7: dimension7(),
  });
};

const trackEventAddPersonInfo = (label, faceNumber, user) => {
  ga("send", {
    hitType: "event",
    eventCategory: autoHeadcutEventCategory,
    eventAction: "Add Person Info",
    eventLabel: isNil(label) ? "Default" : label,
    eventValue: faceNumber,
    dimension1: dimension1(),
    dimension2: dimension2(user),
    dimension3: dimension3(user),
    dimension7: dimension7(),
  });
};

export const trackEventAddPersonInfoName = (faceNumber, user) => {
  trackEventAddPersonInfo("Name", faceNumber, user);
};

export const trackEventAddPersonInfoRelation = (label, faceNumber, user) => {
  const labelFilter = isEmpty(label) ? FACE_DEFAULT_RELATION : label;
  trackEventAddPersonInfo(labelFilter, faceNumber, user);
};

export const trackEventAddPersonInfoBirthday = (faceNumber, user) => {
  trackEventAddPersonInfo("Birthday", faceNumber, user);
};

export const trackEventAddPersonInfoAnniversary = (faceNumber, user) => {
  trackEventAddPersonInfo("Anniversary", faceNumber, user);
};

export const trackTimingFMVR = (template, value) => {
  ga("send", {
    hitType: "timing",
    timingVar: "FMVR Render v2",
    timingCategory: "Video",
    timingLabel: TemplatePresenter.id(template),
    timingValue: value,
    dimension7: dimension7(),
  });
};

export const trackCalendarPeopleClicked = (person, celebrate) => {
  const relation = isEmpty(person.relation) ? "no-relation" : person.relation;
  ga("send", {
    hitType: "event",
    eventCategory: "Discovery",
    eventLabel: relation,
    eventAction: celebrate,
    dimension7: dimension7(),
  });
};

export const trackCalendarMakeCreation = () => {
  ga("send", {
    hitType: "event",
    eventCategory: "Personalize",
    eventAction: "Calendar Make",
    dimension7: dimension7(),
  });
};

export const trackCalendarWidgetClick = (label) => {
  ga("send", {
    hitType: "event",
    eventCategory: "Discovery",
    eventLabel: label,
    eventAction: "Calendar Widget",
    dimension7: dimension7(),
  });
};

export const trackNotificationCenterClick = (type) => {
  ga("send", {
    hitType: "event",
    eventCategory: "notification",
    eventLabel: `${type} click`,
    eventAction: type,
  });
};

export const trackNotificationCenterCompletion = (type) => {
  ga("send", {
    hitType: "event",
    eventCategory: "notification",
    eventLabel: `${type} complete`,
    eventAction: type,
  });
};
