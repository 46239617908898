import { createSlice } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { union } from "ramda";

import { ALGOLIA_FILTERS } from "enums";

const initialState = {
  castNumFilters: [],
  templateGroupTypeFilters: [],
  sortFilterSelected: null,
  isClearAllClicked: false,
  itemToRemove: null,
};

const algoliaSlice = createSlice({
  name: "algolia",
  initialState,
  reducers: {
    addOneFilter(state, { payload }) {
      payload.filterType === ALGOLIA_FILTERS.castNumOptions
        ? (state.castNumFilters = union(state.castNumFilters, [payload.item])
            .sort()
            .reverse())
        : (state.templateGroupTypeFilters = union(state.templateGroupTypeFilters, [payload.item]).sort());
    },
    removeOneFilter(state, { payload }) {
      payload.filterType === ALGOLIA_FILTERS.castNumOptions
        ? (state.castNumFilters = state.castNumFilters.filter((item) => item !== payload.item))
        : (state.templateGroupTypeFilters = state.templateGroupTypeFilters.filter((item) => item !== payload.item));
    },
    clearFilter(state, { payload }) {
      payload === ALGOLIA_FILTERS.castNumOptions ? (state.castNumFilters = []) : (state.templateGroupTypeFilters = []);
    },
    clearAllFilters(state) {
      state.castNumFilters = [];
      state.templateGroupTypeFilters = [];
    },
    setIsClearAllClicked(state, { payload }) {
      state.isClearAllClicked = payload;
    },
    setItemToRemove(state, { payload }) {
      state.itemToRemove = payload;
    },
    setSortFilterSelected(state, { payload }) {
      state.sortFilterSelected = payload;
    },
  },
});

export const { actions } = algoliaSlice;
export default algoliaSlice.reducer;

export const useAlgoliaActions = () => {
  const dispatch = useDispatch();

  const addOneFilter = (params) => {
    dispatch(algoliaSlice.actions.addOneFilter(params));
  };

  const removeOneFilter = (params) => {
    dispatch(algoliaSlice.actions.removeOneFilter(params));
  };

  const clearFilter = (params) => {
    dispatch(algoliaSlice.actions.clearFilter(params));
  };

  const clearAllFilters = () => {
    dispatch(algoliaSlice.actions.clearAllFilters());
  };

  const setIsClearAllClicked = (params) => {
    dispatch(algoliaSlice.actions.setIsClearAllClicked(params));
  };

  const setItemToRemove = (params) => {
    dispatch(algoliaSlice.actions.setItemToRemove(params));
  };

  const setSortFilterSelected = (sortLabel) => {
    dispatch(algoliaSlice.actions.setSortFilterSelected(sortLabel));
  };

  return {
    addOneFilter,
    removeOneFilter,
    clearFilter,
    clearAllFilters,
    setIsClearAllClicked,
    setItemToRemove,
    setSortFilterSelected,
  };
};
