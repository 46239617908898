import { isEmpty, isNil } from "ramda";
import { useSelector } from "react-redux";
import dayjs from "dayjs";

import { DATE_TYPE } from "enums";
import { useCalendarActions } from "store/CalendarSlice";
import PersonPresenter from "presenters/PersonPresenter";

const useCalendar = () => {
  const { loadCelebrations, meta } = useCalendarActions();

  const { celebrations } = useSelector((state) => state.CalendarSlice);

  const PER_PAGE = 6;
  const PAGE = 1;

  const peopleParams = (loadPeopleRelation = false) => {
    const paramsToReturn = {
      anniversaryParams: {
        page: { number: PAGE, size: PER_PAGE },
        filter: {
          calendar: "only-anniversaries",
          "start-date": dayjs().format("YYYY-MM-DD"),
        },
      },
      birthdayParams: {
        page: { number: PAGE, size: PER_PAGE },
        filter: {
          calendar: "only-birthdays",
          "start-date": dayjs().format("YYYY-MM-DD"),
        },
      },
    };

    if (loadPeopleRelation) paramsToReturn.relationParams = { filter: { relation: "partner,mother,father" } };

    return paramsToReturn;
  };

  const getPeopleCalendar = async () => {
    if (!isEmpty(celebrations)) {
      return Promise.resolve(celebrations);
    }

    const loadedPeople = await loadCelebrations(peopleParams());

    return loadedPeople;
  };

  const getPeopleCalendarBirthday = async () => {
    const peopleCalendar = await getPeopleCalendar();
    return peopleCalendar
      .filter((person) => person.dateType === DATE_TYPE.birthday)
      .sort((currentPerson, nextPerson) => {
        if (currentPerson.person.birthday === nextPerson.person.birthday) {
          return nextPerson.person.createdAt - currentPerson.person.createdAt;
        }
        return 0;
      });
  };

  const personWithBirthdaysInThrityDays = (peopleBirthdays) => {
    const orderPersonsWithBirthdayInThrityDays = peopleBirthdays?.filter(({ person }) => {
      const personBirthDay = PersonPresenter.birthday(person);
      const actualDate = dayjs();
      const formattedPersonBirthDay = dayjs(personBirthDay).year(actualDate.year());
      const nextMonthDate = dayjs(actualDate).add(1, "month");
      return dayjs(formattedPersonBirthDay).isBetween(actualDate, nextMonthDate);
    });
    if (isNil(orderPersonsWithBirthdayInThrityDays) || orderPersonsWithBirthdayInThrityDays?.length === 0) {
      return null;
    }
    return orderPersonsWithBirthdayInThrityDays[0]?.person;
  };

  return {
    loadCelebrations,
    celebrations,
    meta,
    getPeopleCalendar,
    getPeopleCalendarBirthday,
    personWithBirthdaysInThrityDays,
    peopleParams,
  };
};

export default useCalendar;
