import { SHARE_METHODS } from "enums";

import useTemplateGroup from "./useTemplateGroup";
import usePublicInviteLink from "./usePublicInviteLink";
import useNotifications from "./useNotifications";
import useInvites from "./useInvites";
import useLastEcard from "./useLastEcard";
import useJoinModal from "./useJoinModal";

const useInviteLinkCopy = ({ setOpenCopyConfirmationModal }) => {
  const { handleCopyPublicLink, isLoading } = usePublicInviteLink();
  const { addErrorNotification } = useNotifications();
  const { setShareType } = useInvites();
  const { isFlowRequired } = useLastEcard();
  const { templateGroup } = useTemplateGroup();
  const { openJoinModal } = useJoinModal();

  const copyPublicLink = async () => {
    if (isFlowRequired) {
      setShareType(SHARE_METHODS.LINK);
      openJoinModal();
      return true;
    }

    try {
      await handleCopyPublicLink(templateGroup);
      setOpenCopyConfirmationModal(true);
      return true;
    } catch {
      addErrorNotification();
      return false;
    }
  };

  return {
    copyPublicLink,
    isLoadingPublicLink: isLoading,
  };
};

export default useInviteLinkCopy;
