import React from "react";
import PropTypes from "prop-types";
import { Box, Drawer, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import useStyles from "./useStyles";

const MenuDrawer = ({ anchor, open, onClose, children }) => {
  const classes = useStyles();

  return (
    <Drawer
      anchor={anchor}
      open={open}
      onClose={onClose}
      PaperProps={{ sx: { width: "90%" } }}
      ModalProps={{ keepMounted: true }}
    >
      <div className={classes.menu}>
        <Box className={classes.close}>
          <IconButton alt="Close" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>

        {children}
      </div>
    </Drawer>
  );
};

MenuDrawer.propTypes = {
  anchor: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  children: PropTypes.node,
};

MenuDrawer.defaultProps = {
  anchor: "right",
  open: false,
};

export default MenuDrawer;
