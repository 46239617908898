import { createSlice } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";

import AuthRepository from "repositories/AuthRepository";
import localStorage, { LOCAL_STORAGE_AUTH_KEY } from "utils/localStorage";
import emberLoginSync from "utils/emberLoginSync";
import selectedCastNumber from "utils/selectedCastNumber";
import { getAuthFromLocalStorage } from "utils/authUtils";
import { appRoutes } from "routes";

const initialState = {
  auth: getAuthFromLocalStorage(),
  needsRefreshScheduled: true,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    getAuth(state, { payload }) {
      state.auth = payload;
    },
    resetAuth(state) {
      state.auth = {};
    },
    scheduleRefresh(state, { payload }) {
      state.needsRefreshScheduled = payload;
    },
  },
});

export const { actions } = authSlice;
export default authSlice.reducer;

export const useAuthActions = () => {
  const dispatch = useDispatch();

  const saveAuthData = (data) => {
    dispatch(authSlice.actions.getAuth(data));
    localStorage.setItem(LOCAL_STORAGE_AUTH_KEY, { auth: { ...data } });
    emberLoginSync.setAuthInfo(data);
  };

  const signIn = (attrs) => {
    return AuthRepository.getToken(attrs, { separator: "_" }).then(({ data }) => {
      saveAuthData(data);
      dispatch(authSlice.actions.scheduleRefresh(true));
      return Promise.resolve();
    });
  };

  const signOut = () => {
    const auth = getAuthFromLocalStorage();
    const attrs = { token: auth.accessToken };

    AuthRepository.revokeToken(attrs).then(() => {
      dispatch(authSlice.actions.resetAuth());
      localStorage.removeItem(LOCAL_STORAGE_AUTH_KEY);
      emberLoginSync.logOut();
      localStorage.clearForSignOut();
      selectedCastNumber.clear();
      window.location.href = appRoutes.rootPath();
    });
  };

  const scheduleRefresh = (schedule) => {
    dispatch(authSlice.actions.scheduleRefresh(schedule));
  };

  const refreshAuthData = (refreshToken) => {
    const attrs = {
      grant_type: "refresh_token",
      refresh_token: refreshToken,
    };

    return AuthRepository.getToken(attrs, { separator: "_" })
      .then(({ data }) => {
        saveAuthData(data);
        return Promise.resolve(data);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };

  return {
    signIn,
    signOut,
    scheduleRefresh,
    refreshAuthData,
  };
};
