import { useEffect, useState } from "react";
import { isEmpty } from "ramda";

const usePasswordCheckList = () => {
  const [passwordFocused, setPasswordFocused] = useState(false);
  const [validPassword, setValidPassword] = useState(false);
  const [focusedFirstTime, setFocusedFirstTime] = useState(false);

  useEffect(() => {
    if (focusedFirstTime) {
      setPasswordFocused(!validPassword);
    }
  }, [validPassword, focusedFirstTime]);

  const getPasswordHasErrors = (value) => {
    return !isEmpty(value) && !validPassword;
  };

  const onFocus = () => {
    if (!focusedFirstTime) {
      setFocusedFirstTime(true);
    }
  };

  const onBlur = () => {
    if (validPassword) {
      setPasswordFocused(false);
    }
  };

  return {
    getPasswordHasErrors,
    passwordFocused,
    setValidPassword,
    onFocus,
    onBlur,
  };
};

export default usePasswordCheckList;
