import { createSlice } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";

import MenuRepository from "repositories/MenuRepository";

const initialState = {
  info: {},
  menuItems: [],
  error: null,
};

const menuSlice = createSlice({
  name: "menu",
  initialState,
  reducers: {
    storeMenu(state, { payload }) {
      const { menuItems, name, contentType, slug } = payload;
      state.menuItems = menuItems;
      state.info = { name, contentType, slug };
    },
    setError(state, { payload }) {
      state.error = payload;
    },
  },
});

export const { actions } = menuSlice;
export default menuSlice.reducer;

export const useMenuActions = () => {
  const dispatch = useDispatch();

  const loadMenu = () => {
    return MenuRepository.get()
      .then(({ data }) => {
        dispatch(menuSlice.actions.storeMenu(data));
      })
      .catch((error) => {
        dispatch(menuSlice.actions.setError(error));
      });
  };

  return {
    loadMenu,
  };
};
