import R from "ramda";
import { useCookie } from "react-recipes";
import { useSelector } from "react-redux";

import { useOffersActions } from "store/OffersSlice";
import useRouter from "hooks/useRouter";
import useOfferGroups from "hooks/useOfferGroups";
import { jjLogger } from "utils/logUtils";

const OFFER_COOKIE_EXPIRE_DAYS = 14;

const useOffers = () => {
  const { loadOffers, switchCurrentOffer } = useOffersActions();
  const { currentOffer: currentOfferFromOfferGroups, loadOfferGroups } = useOfferGroups();
  const { currentOffer, offers } = useSelector((state) => state.OffersSlice);

  const {
    query: { offer: queryOffer = null },
  } = useRouter();

  const [currentOfferId, setCurrentOfferId] = useCookie("offer");

  const setCurrentOffer = () => {
    if (!R.isNil(queryOffer)) {
      setCurrentOfferId(queryOffer, OFFER_COOKIE_EXPIRE_DAYS);
    }
  };

  const fetchOffers = async (uriSlugs) => {
    try {
      await loadOffers(uriSlugs);
    } catch (error) {
      jjLogger.logError(
        `useOffers.js - fetchOffers() - Error: Could not load offer "${uriSlugs}". ${
          error?.message ? error.message : error
        }`,
      );
      await loadOfferGroups();
    }
  };

  const loadCurrentOffer = async () => {
    R.isNil(currentOfferId) ? await loadOfferGroups() : await fetchOffers(currentOfferId);
  };

  return {
    currentOffer: currentOffer || currentOfferFromOfferGroups,
    offers,
    fetchOffers,
    switchCurrentOffer,
    setCurrentOffer,
    loadCurrentOffer,
  };
};

export default useOffers;
