const ENTRY_POINTS = {
  CASTING: "casting",
  SHARE: "after share",
  PEOPLE: "people screen",
  CALENDAR: "calendar widget",
  PREVIEW: "preview",
  ORPHAN: "orphan head",
};

Object.freeze(ENTRY_POINTS);

export { ENTRY_POINTS };
