import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { isNil } from "ramda";
import { Dialog, Box, Typography, LinearProgress } from "@mui/material";

import { deserializeFunction } from "utils/storeUtils";
import loader from "assets/images/hamster.gif";
import { mobileDebugOn } from "utils/logUtils";

import useStyles from "./useStyles";

const LoadingModal = ({ labelFunc, progress }) => {
  const classes = useStyles();
  const [seconds, setSeconds] = useState(0);
  const addSecond = () => setSeconds((prevSeconds) => prevSeconds + 1);

  useEffect(() => {
    const interval = setInterval(addSecond, 1000);
    return () => clearInterval(interval);
  }, [labelFunc]);

  const getLabel = useMemo(() => labelFunc && deserializeFunction(labelFunc), [labelFunc]);

  const getMessage = () => {
    if (getLabel) return getLabel(seconds);
    return "Loading";
  };

  const message = getMessage();

  const renderProgress = () => {
    const percent = Math.round(progress * 100);

    return (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box sx={{ width: "100%", mr: 1 }}>
          <LinearProgress variant="determinate" value={percent} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="large">{`${percent}%`}</Typography>
        </Box>
      </Box>
    );
  };

  return (
    <Dialog
      disableScrollLock={mobileDebugOn()}
      classes={{ root: classes.root }}
      PaperProps={{ classes: { root: classes.paper } }}
      open
    >
      <img src={loader} alt="loader" className={classes.image} />
      <div className={classes.modalMessage}>
        <Typography variant="h2" fontWeight="Regular" className={classes.message}>
          {message}
        </Typography>
      </div>
      {!isNil(progress) && renderProgress()}
    </Dialog>
  );
};

LoadingModal.propTypes = {
  labelFunc: PropTypes.string,
  progress: PropTypes.number,
};

export default LoadingModal;
