import React from "react";
import { NavLink } from "react-router-dom";
import { Link, Typography } from "@mui/material";

import { appRoutes, externalRoutes } from "routes";
import logo from "assets/images/common/logo.png";
import twitterLogo from "assets/images/common/twitter.png";
import facebookLogo from "assets/images/common/facebook.png";
import instagramLogo from "assets/images/common/instagram.png";
import pinterestLogo from "assets/images/common/pinterest.png";
import youtubeLogo from "assets/images/common/youtube.png";
import ResponsiveImage from "components/ResponsiveImage";

import NotSellMyDataLink from "./components/NotSellMyDataLink";
import useStyles from "./useStyles";

const Footer = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.main}>
        <Link id="footer-logo" to={appRoutes.rootPath()} component={NavLink}>
          <ResponsiveImage src={logo} alt="JibJab" className={classes.logo} width={98} height={90} widthMobile={98} />
        </Link>

        <nav className={classes.nav}>
          <Link id="footer-about" className={classes.navLink} to={appRoutes.aboutUsPath()} component={NavLink}>
            About
          </Link>
          <Link
            id="footer-help"
            className={classes.navLink}
            href={externalRoutes.helpPath()}
            target="_blank"
            rel="noreferrer"
          >
            Help
          </Link>
          <Link id="footer-terms" className={classes.navLink} to={appRoutes.termsOfServicePath()} component={NavLink}>
            Terms
          </Link>
          <Link id="footer-privacy" className={classes.navLink} to={appRoutes.privacyPath()} component={NavLink}>
            Privacy
          </Link>
        </nav>

        <nav className={classes.social}>
          <Link href={externalRoutes.twitterPath()} target="_blank" rel="noreferrer">
            <ResponsiveImage
              id="footer-twitter"
              className={classes.socialLogo}
              src={twitterLogo}
              alt="JibJab Twitter"
              width={32}
              widthMobile={32}
            />
          </Link>
          <Link href={externalRoutes.facebookPath()} target="_blank" rel="noreferrer">
            <ResponsiveImage
              id="footer-facebook"
              className={classes.socialLogo}
              src={facebookLogo}
              alt="JibJab Facebook"
              width={32}
              widthMobile={32}
            />
          </Link>
          <Link href={externalRoutes.instagramPath()} target="_blank" rel="noreferrer">
            <ResponsiveImage
              id="footer-instagram"
              className={classes.socialLogo}
              src={instagramLogo}
              alt="JibJab Instagram"
              width={32}
              widthMobile={32}
            />
          </Link>
          <Link href={externalRoutes.pinterestPath()} target="_blank" rel="noreferrer">
            <ResponsiveImage
              id="footer-pinterest"
              className={classes.socialLogo}
              src={pinterestLogo}
              alt="JibJab Pinterest"
              width={32}
              widthMobile={32}
            />
          </Link>
          <Link href={externalRoutes.youtubePath()} target="_blank" rel="noreferrer">
            <ResponsiveImage
              id="footer-youtube"
              className={classes.socialLogo}
              src={youtubeLogo}
              alt="JibJab Youtube"
              width={32}
              widthMobile={32}
            />
          </Link>
        </nav>
      </div>

      <NotSellMyDataLink />

      <Typography variant="caption" align="center" className={classes.copyright}>
        JibJab®, Sendables®, Starring You® and JokeBox® are registered trademarks of JibJab Catapult CA, Inc. ©
        Copyright 1999–{new Date().getFullYear()} JibJab Catapult CA, Inc. All Rights Reserved.
      </Typography>
    </div>
  );
};

export default Footer;
