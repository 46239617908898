import { createSlice } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";

import { deserialize } from "utils/storeUtils";
import CategoryRepository from "repositories/CategoryRepository";

const initialState = {
  category: null,
};

const categorySlice = createSlice({
  name: "category",
  initialState,
  reducers: {
    loadCategorySuccess(state, { payload }) {
      state.category = deserialize(payload);
    },
    resetCategory(state) {
      state.category = null;
    },
  },
});

export const { actions } = categorySlice;
export default categorySlice.reducer;

export const useCategoryActions = () => {
  const dispatch = useDispatch();

  const loadCategory = (params) => {
    return CategoryRepository.index(params).then(({ data }) => {
      dispatch(actions.loadCategorySuccess(data));
    });
  };

  const loadCategoryFromContentful = (slug) => {
    return CategoryRepository.contentfulShow(slug).then(({ data }) => {
      const type = data.data?.attributes?.type;
      if (type && type !== "Ecards") throw new Error(`${slug} category is not a valid ecards category`);
      dispatch(actions.loadCategorySuccess(data));
    });
  };

  const loadInviteCategory = (slug) => {
    return CategoryRepository.contentfulShow(slug).then(({ data }) => {
      const type = data.data?.attributes?.type;
      if (!type) throw new Error(`${slug}  category does not have a type property`);
      if (type && type !== "Invites") throw new Error(`${slug} category is not a valid invite category`);
      dispatch(actions.loadCategorySuccess(data));
    });
  };

  const resetCategory = () => dispatch(actions.resetCategory());

  return {
    loadCategory,
    resetCategory,
    loadCategoryFromContentful,
    loadInviteCategory,
  };
};
