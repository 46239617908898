import { useSelector } from "react-redux";

import { useHomePageActions } from "store/HomePageSlice";

const useHomePage = () => {
  const { loadHomePage } = useHomePageActions();

  const { homePage, featuredEcards, carousel, viewMoreButton } = useSelector((state) => state.HomePageSlice);

  return {
    loadHomePage,
    homePage,
    carousel,
    featuredEcards,
    viewMoreButton,
  };
};

export default useHomePage;
