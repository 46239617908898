import { createSlice } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";

const initialState = {
  hitSelected: null,
};

const algoliaTrackerSlice = createSlice({
  name: "algoliaTracker",
  initialState,
  reducers: {
    setHitSelected(state, { payload }) {
      state.hitSelected = payload;
    },
  },
});

export const { actions } = algoliaTrackerSlice;
export default algoliaTrackerSlice.reducer;

export const useAlgoliaTrackerActions = () => {
  const dispatch = useDispatch();

  const setHitSelected = (params) => {
    dispatch(algoliaTrackerSlice.actions.setHitSelected(params));
  };

  const clearHitSelected = () => {
    dispatch(algoliaTrackerSlice.actions.setHitSelected(null));
  };

  return {
    setHitSelected,
    clearHitSelected,
  };
};
