const FACE_RELATIONS = {
  me: "Me",
  partner: "Partner",
  mother: "Mom",
  father: "Dad",
  family: "Family",
  friend: "Friend",
  pet: "Pet",
};

const FACE_RELATIONS_TAGS = {
  valentines: "partner",
  mother: "mother",
  father: "father",
  anniversary: "partner",
};

const RELATIONS_WITH_ANNIVERSARY = {
  partner: "Partner",
};

const UNIQUE_FACE_RELATIONS = {
  me: "Me",
  partner: "Partner",
};

const UNIQUE_RELATIONS_KEYS = ["me", "partner"];

Object.freeze(FACE_RELATIONS);
Object.freeze(RELATIONS_WITH_ANNIVERSARY);
Object.freeze(UNIQUE_FACE_RELATIONS);
Object.freeze(FACE_RELATIONS_TAGS);

export {
  FACE_RELATIONS,
  RELATIONS_WITH_ANNIVERSARY,
  UNIQUE_RELATIONS_KEYS,
  UNIQUE_FACE_RELATIONS,
  FACE_RELATIONS_TAGS,
};
