import { useSelector } from "react-redux";

import { usePageActions } from "store/PageSlice";

const usePage = () => {
  const { loadPage } = usePageActions();

  const { page } = useSelector((state) => state.PageSlice);

  return {
    loadPage,
    page,
  };
};

export default usePage;
