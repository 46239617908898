import { useSelector } from "react-redux";

import { useMorphablePackGroupActions } from "store/MorphablePackGroupSlice";

const useMorphablePackGroup = () => {
  const { loadMorphablePackGroup, clearMorphablePackGroup } = useMorphablePackGroupActions();
  const { morphablePackGroup } = useSelector((state) => state.MorphablePackGroupSlice);

  return { loadMorphablePackGroup, morphablePackGroup, clearMorphablePackGroup };
};

export default useMorphablePackGroup;
