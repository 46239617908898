export default {
  rootPath: () => "/",
  termsOfServicePath: () => "/terms-of-service",
  termsOfSalePath: () => "/terms-of-sale",
  privacyPath: () => "/privacy",
  legalPath: () => "/legal",
  aboutUsPath: () => "/about-us",
  loginPath: () => "/login",
  logoutPath: () => "/logout",
  signUpPath: () => "/create/account",
  resetPasswordPath: () => "/password-reset",
  resetPasswordReturnPath: (token) => `/password-reset-return/${token}`,
  loginHelpPath: () => "/login-help",
  oAuthRedirectPath: () => "/oauth/redirect",

  myEcardsPath: () => "/account/ecards",
  myInvitesPath: () => "/account/invites",
  manageInvitePath: (slug) => `/account/invites/${slug}`,
  myAccountPath: () => "/account/overview",
  changeNamePath: () => "/account/name",
  changeEmailPath: () => "/account/email",
  changePasswordPath: () => "/account/password",
  receiptsPath: () => "/account/receipts",
  receiptPath: (id) => `/account/receipt/${id}`,
  subscriptionDetailsPath: () => "/account/subscription-details",
  editRenewalPath: () => "/account/edit-renewal",
  updateCreditCardPath: () => "/account/update-creditcard",
  categoryPath: (slug) => `/browse/${slug}`,
  categoriesPath: () => "/browse",
  invitesPath: () => "/invites",
  starringYouAIPath: () => "/ai-photos",
  invitePath: (slug) => `/invites/browse/${slug}`,
  inviteSubcategoryPath: (slug, subcategory) => `/invites/browse/${slug}/${subcategory}`,
  templateGroupPath: (slug) => `/view/template/${slug}`,
  facePath: () => "/face",
  newCastPath: (slug) => `/create/cast/${slug}`,
  makeViewPath: (slug, id) => `/view/make/${slug}/${id}`,
  makePreviewPath: (slug, id) => `/view/make-preview/${slug}/${id}`,
  invitesPreviewPath: (slug) => `/invites/view/template/${slug}`,
  morphablePackGroupPath: (slug) => `/ai-photos/view/template/${slug}`,
  invitesCastingPath: (slug) => `/invites/create/cast/${slug}`,
  inviteMakePreviewPath: (slug) => `/invites/make-preview/${slug}`,
  invitePublicViewPath: (invite) => `/invites/view-public/${invite}`,
  inviteGuestViewPath: (tg, invite, guest) => `/invites/view/make/${tg}/${invite}/${guest}`,
  syaiPackPreviewPath: (slug) => `/ai-photos/preview/${slug}`,
  syaiPackViewPath: (slug, hash = "") => `/account/ai-photos/library/${slug}${hash ? `#${hash}` : ""}`,
  syaiPhotoConfirmationPath: () => "/ai-photos/confirmation",
  syaiMyPacksPath: () => "/account/ai-photos/library",
  paymentPath: () => "/create/purchase/payment",
  purchaseConfirmationPath: () => "/create/purchase/confirmation",
  paypalConfirmationPath: () => "/paypal/return",
  activityPath: () => "/activity",
  peoplePath: () => "/account/people",
  searchPath: () => "/search",
  page404Path: () => "/not-found",
  unauthorizedPath: () => "/unauthorized",
};
